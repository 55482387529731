﻿@media (min-width: 480px) {
    .bootstrap-container-block {
        .col {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
        }
    }
}

@media (max-width: 480px) {
    .bootstrap-container-block {
        .col {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}

.bootstrap-container-block {
    .bootstrap-col-content {
        word-wrap: break-word;
        a {
            color: $white;
        }
    }

    .responsive-col-margin {
        @media screen and (max-width: 768px) {
            //display: none;
            margin-top: 15px !important;
            margin-bottom: 15px !important;
        }
    }
    .responsive-col-border-width {
        @media screen and (max-width: 768px) {
            width: 92% !important;
            //margin: 10px auto !important;
        }
    }
}

.bootstrap-Container-Width {
    max-width: 191rem !important;
    //min-height: 400px !important;
}
