//SHARED between MenuItemBlock and Header
.megamenu {
    font-size: 1.8rem;
    font-weight: 400;
    position: absolute;
    left: 0;
    right: 0;
    text-align: left;
    padding-top: 3rem;
    z-index: 10;
    display: none;
    background-color: $white;
    margin-top: 1rem;

    .subtitle {
        color: $black;
        font-size: 1.8rem;
        display: block;
        margin-bottom: 1.5rem;
    }

    .title {
        display: inline-block;
        font-size: 2rem;
        font-weight: 400;
        color: $accent;
        text-transform: none;
        margin-bottom: 1.5rem;
    }

    .title-big {
        color: $accent;
        font-size: 2.5rem;
        font-weight: 400;
        text-transform: none;
        margin-bottom: 1.9rem;
    }

    .menu-column {
        //flex: 1 1 0px;

        &.two-menu-column {
            &:first-child {
                width: 33%;
                display: inline-block;
                padding: 0 15px;
                margin-right: 0;
            }

            &:not(:first-child) {
                width: 66%;
                display: inline-block;
                padding: 0 15px;
            }
        }

        &.three-menu-column {
            &:first-child {
                width: 33%;
                display: inline-block;
                padding: 0 15px;
                margin-right: 0;
            }

            &:not(:first-child) {
                width: 33%;
                display: inline-block;
                padding: 0 15px;
            }
        }

        &.four-menu-column {
            &:first-child {
                width: 25%;
                display: inline-block;
                padding: 0 15px;
                margin-right: 0;
            }

            &:not(:first-child) {
                width: 25%;
                display: inline-block;
                padding: 0 15px;
            }
        }

        &.five-menu-column {
            &:first-child {
                width: 32%;
                display: inline-block;
                padding: 0 15px;
                margin-right: 0;
            }

            &:not(:first-child) {
                width: 17%;
                display: inline-block;
                padding: 0 15px;
            }
        }
    }

    .submenu-content {
        padding-left: 1.5rem;
        padding-bottom: 10px;

        @media (max-width: 767px) {
            margin-bottom: 2rem;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .contact-item {
            margin-bottom: 1rem;

            a {
                font-size: 1.8rem;
            }
        }

        .telephone-wrapper {
            margin-bottom: 3rem;
        }
    }

    .item-link {
        font-weight: 700;
        color: $megaMenuItemColor;

        &:hover,
        &:active,
        &:focus {
            color: $accent;
            text-decoration: none;
            border-bottom: 3px solid $accent;
        }

        &:visited {
            color: $accent;
            text-decoration: none;
        }
    }

    .list-item {
        .description {
            font-size: 1.6rem;
            color: $megaMenuDescriptionColor;
        }

        .item-link {
            font-size: 2rem;
            color: $megaMenuListItemColor;
            display: block;
            margin-bottom: .5rem;

            &:hover,
            &:active,
            &:focus,
            &:visited {
                border: none;
            }
        }
    }

    .subcategory-link, .subcategory-link-hover {
        display: block;
        margin-bottom: 0.6rem;
        font-size: 1.6rem;
        color: $black;
        /*pointer-events:none;*/
    }

    .media-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 2rem;

        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
        }

        .media-img {
            min-width: 3.3rem;
            margin-right: 2rem;
            max-width: 10rem;
            max-height: 15rem;

            &.tight {
                max-width: 3.3rem;
            }
        }

        .content {
            .title-link {
                font-size: 2rem;
                max-width: 13.4rem;
                line-height: 3.1rem;

                & + .description {
                    font-size: 1.6rem;
                    line-height: 2.1rem;
                    max-width: 36rem;
                    margin-top: 1rem;
                }
            }
        }
    }

    .featured-media-wrapper {
        .title {
            font-size: 1.8rem;
            color: $black;
            line-height: 2.3rem;
        }

        .description {
            font-size: 1.5rem;
            color: $black;
            line-height: 1.9rem;
        }
    }

    .product-list {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 14px;
        gap: 1rem 2rem;
    }
}

.close-button {
    padding: 0;
    border: none;
    margin: 1rem 3rem;

    .close-icon {
        font-size: 3.5rem;
        color: $accent;

        &::before {
            content: "\f00d";
            font-family: "Font Awesome 5 Free";
            font-weight: 700;
        }
    }

    &-account {
        position: absolute;
        right: 3rem;
        margin: 0;
        z-index: 2;
    }
}

.megamenu-mobile {
    text-align: right;
    padding: 0;
    padding-bottom: 20rem;
    border-bottom: 1px solid $grey70;
    background-color: $white;

    .container {
        padding: 0;
        align-items: flex-end !important;
        position: relative;
        text-align: right;
        flex-direction: column;

        .nav-item {
            padding: 0 2rem;
            border-bottom: 1px solid $grey80;
            position: relative;

            &:first-child {
                border-top: 1px solid $grey80;
            }

            .product-list {
                max-width: 25rem;
                margin-left: 4rem;
            }

            .category-link {
                font-size: 2rem;
                padding: 1.5rem 0;
                display: inline-block;

                &:hover,
                &:focus,
                &:active {
                    text-decoration: none;
                    border: none;
                }

                &:hover,
                &:focus,
                &:active {
                    color: $black;
                    text-decoration: none;
                }
            }

            .category-content {
                padding-bottom: 2rem;
            }

            .subcategory-link, .subcategory-link-hover {
                max-width: 30rem;
                line-height: 1.5;
                margin-bottom: 1rem;
                padding: 0 4rem;
                position: relative;

                &.collapse-icon {
                    &::after {
                        content: "\f078";
                        font-family: "Font Awesome 5 Free";
                        font-weight: 700;
                        padding-right: 1rem;
                        position: absolute;
                        left: 1rem;
                        top: 0;
                    }

                    &.opened::after {
                        content: "\f077";
                        font-family: "Font Awesome 5 Free";
                        font-weight: 700;
                        padding-right: 1rem;
                        position: absolute;
                        left: 1rem;
                        top: 0;
                    }
                }
            }
        }

        .menu-content {
            text-align: left;
            width: 100%;

            .submenu-content {
                padding: 0;

                li {
                    margin-right: 0;
                    width: 50%;
                }
            }
        }
    }
}
