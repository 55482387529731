.paragraph-block > ul {
    li::before {
        content: "\2022";
        color: $accent;
        display: inline-block;
        width: 1em;
        font-size: 2rem;
        line-height: 2.4rem;
        font-weight: bold;
        margin-left: 0;
    }

    li {
        color: #000000;
        line-height: 2.4rem;
        margin-bottom: 0.8rem;
    }
}

.paragraph-block a {
    font-size: 1.6rem;
    font-weight: 700;
    color: $accent;

    a {
        font-family: $quaternaryFont;
        font-size: 1.4rem;
        color: $accent;

        .primary-button {
            color:#ffffff;
        }

        &:visited {
            color: $accent;
        }
    }
}