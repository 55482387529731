$stripe_icon_background: $white;

.icon-stripe-component
{
    padding: 2.5rem 0;

    .container {
        display: flex;

        @media(max-width: 767px) {
            flex-wrap: wrap;
        }
    }

    .icon-element {
        display: flex;
        align-items: center;
        justify-items: center;
        width: 25%;

        @media(max-width: 767px) {
            width: 50%;
            margin: 1rem 0;
        }

        .stripe-icon {
            width: 6rem;
            height: 6rem;
            color: $stripe_icon_background;
            margin-right: 1.5rem;

            @media(max-width: 767px) {
                font-size: 4rem;
            }
        }

        .description {
            font-style: italic;

            @media(max-width: 767px) {
                font-size: 1.4rem;
            }
        }
    }
}