﻿.card {
    display: block;
    max-width: 77.3rem;
    box-shadow: 0px 3px 6px rgba($black, .26);

    .title-wrapper {
        padding: 1.6rem 6.3rem;
        background: $accent;
        color: $white;
        border-radius: 6px;

        &.bg-light {
            background: $white;
        }

        @media (max-width: 767px) {
            padding: 2.3rem 2.5rem;
        }

        .title {
            font-family: $secondaryFont;
            font-size: 3rem;
            text-transform: none;
            line-height: 1;
            margin-bottom: .2rem;

            @media (max-width: 767px) {
                font-size: 2.5rem;
            }
        }

        .subtitle {
            font-size: 1.5rem;
            line-height: 1;
        }
    }
}
