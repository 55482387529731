//SHARED from the international popup
.int-close {
    position: absolute;
    right: 1rem;
    top: 1rem;
}

.int-modal-header{
    display:flex;
    flex-direction:row-reverse;
    padding-bottom: 8rem;
}

.int-globe-icon {
    height: 4rem;
    padding-right: 0.6rem;
}

.int-flag-icon {
    height: 5rem;
    margin-bottom: 1rem;

    &.selected {
        outline-color: $accent;
        outline-width: 0.3rem;
        outline-style: solid;
        border-radius: 2px;
    }
}



.int-modal-current-region {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 2rem;
}

.int-modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
}

.international-modal-underline {
    width: 90%;
    border-bottom: 2px solid $accent;
}

.int-modal-dialog {
    //margin: 1.75rem auto;
    position: relative;
    width: auto;
    height: 100%;
    pointer-events: none;
}

.int-card-body {
    min-height: 1px;
    padding: 2rem;

    .int-card-link {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.international-modal-language-select {
    display: flex;
    flex-direction: column;
    padding-top: 3rem;
}

.int-span {
    color: $defaultTextColor;
    font-size: 3rem;
}

.int-option {
    color: $defaultTextColor;
    font-size: 1.5rem;
}

.card-component {
    display: block;
    max-width: 77.3rem;
    box-shadow: 0px 3px 6px rgba($black, .26);

    .title-wrapper {
        padding: 1.6rem 6.3rem;
        background: $accent;
        color: $white;
        border-radius: 6px;

        @media (max-width: 767px) {
            padding: 2.3rem 2.5rem;
        }

        .title {
            font-family: $secondaryFont;
            font-size: 3rem;
            text-transform: none;
            line-height: 1;
            margin-bottom: .2rem;

            @media (max-width: 767px) {
                font-size: 2.5rem;
            }
        }

        .subtitle {
            font-size: 1.5rem;
            line-height: 1;
        }
    }

    .content-wrapper {
        .form-group {
            width: 100%;

            label {
                color: $black;
                margin-bottom: .8rem;
                font-size: 1.5rem;
            }

            input {
                background: $white;
                border: 1px solid $black;
                font-size: 1.5rem;
            }

            select {
                background: $white;
                border: 1px solid $black;
                font-size: 1.5rem;
            }
        }
    }
}

.admin-block {
    background-color: rgba($accent, 0.3);
}

.flag-icon {
    max-width: 6rem;
    max-height: 6rem;

    &.selected {
        outline-color: blue;
        outline-width: 0.3rem;
        outline-style: solid;
        border-radius: 2px;
    }
}