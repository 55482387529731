﻿//************************************
//************************************
//OLD Find-a-Tool
.iframe-container {
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
}

.fat-iframe {
    width: 100%;
    overflow: auto;
    height: 786px;
}

//************************************
//************************************
//NEW FAT SEARCH PAGE
#fat-component.container {
    @media (min-width:576px) {
        max-width: 540px;
    }

    @media (min-width:768px) {
        max-width: 720px;
    }

    @media (min-width:992px) {
        max-width: 1140px;
    }

    @media (min-width:960px) {
        max-width: 1140px;
    }
}
.find-a-tool {
    &-header {
        background-color: $accent;
        text-align: center;
        margin-bottom: 10px;

        h2 {
            color: $white;
            padding: 0.5rem 0;
        }
    }

    &-secondary {
        &-header, &-filter-radio {
            margin-bottom: 1.6rem;

            .form-check {
                margin-bottom: 0.8rem;
            }
        }

        &-header {
          margin-top:50px;
        }
    }

    &-subheader {
        background-color: $grey90;
        text-align: center;
        margin-bottom: 10px;

        h3 {
            color: $grey50;
            padding: 0.25rem 0;
        }
    }
}

.fat-h1 {
    color: $h1FontColor;
    text-transform: uppercase;
    font-family: "Roboto", sans-serif;
    font-size: 3.2rem;
    font-weight: 900;
    margin-top: 30px;
}

.fat-section {
    margin-left: -10px;
    margin-right: -10px;
}
.fat-group {
    padding-right: 10px;
    padding-left: 10px;
}

.fat-group-row {
    margin-top: 20px;
    margin-left: -5px;
    margin-right: -5px;
}

.find-a-tool-subsection {
    .find-a-tool-subsection-grouping {
        padding-left:5px;
        padding-right:5px;
        a {
            min-height: 175px;
        }
    }

    a {
        display: block;
    }

    .find-a-tool-item-label {
        margin: 0.5rem auto 2rem;
        font-size: 1.28rem;
        font-family: Roboto, sans-serif;
        color: black;
        text-align: center;
        display:block;
        min-height:50px;
        line-height:1.6rem;
    }
}

.fat-material-link {
    color: #222;
    border-bottom: 2px solid #222;
    font-weight:700;
    display:inline;
}
.fat-material-div {
    padding-bottom: 10px;
}
.find-a-tool-component {
    margin-bottom: 20px;
}

//************************************
//************************************
//NEW FAT RESULTS PAGE
.find-a-tool-secondary-filter-radio {
    .form-radio-button {
        border: 0 solid, transparent;
    }
}

//down caret to open the selector (FAT)
button.fat-reset-button {
    padding: 10px 15px;
    margin: 25px auto;
    color: white;
    background-color: #d2232a;
    border: 1px solid #707070;
    display: block;
    min-width: 120px;
    cursor: pointer;
    border-radius: 0;
    font-size: 1.6rem;
    font-weight: 400;
    line-height:1.5;
}

h3.fat-results-header {
    color: $accent;
    font-size: 2.4rem;
    font-weight: 700;

    span {
        color: black;
    }
}

.fat-compare-button {
    background-color: transparent;
    color: $accent;
    max-height: 2rem;
    border: none;
    border-bottom: 2px solid $accent;
    display: inline;
    padding: 0 0 14px 0;
    border-radius: 0;

    &:disabled {
        color: black;
        border: none;
    }
}

input.fat-primary-filter {
    background-color: white;
    opacity: 1;
    border-color: transparent;
    border-bottom: solid 2px $fatPrimaryFilterBorderColor;
    max-height: 2.5rem;
    color: $black;


    &:disabled {
        background-color: white;
        opacity: 1;
        border-color: transparent;
        border-bottom: solid 2px $fatPrimaryFilterBorderColor;
        max-height: 2.5rem;
    }

    &:focus {
        outline:none;
        box-shadow:none;
        border-color: transparent;
        border-bottom: solid 2px $fatPrimaryFilterBorderColor;
    }
}
select.fat-primary-filter {
    background-color: $fatPrimaryFilterFillColor;
    border-color: transparent;
    color: $black;
    overflow-y: auto;
}


input.fat-secondary-filter {
    background-color: white;
    opacity: 1;
    border-color: transparent;
    border-bottom: solid 2px $fatPrimaryFilterBorderColor;
    max-height: 2.5rem;
    color: $black;

    &:disabled {
        background-color: white;
        opacity: 1;
        border-color: transparent;
        border-bottom: solid 2px $fatPrimaryFilterBorderColor;
        max-height: 2.5rem;
    }

    &[readonly] {
        background-color: white;
        opacity: 1;
        border-color: transparent;
        border-bottom: solid 2px $fatPrimaryFilterBorderColor;
        max-height: 2.5rem;
    }
}
div.fat-secondary-filter {
    background-color: white;
    opacity: 1;
    border-color: transparent;
    border-bottom: solid 2px $fatPrimaryFilterBorderColor;
    &.multi-popup {
      background-color:$grey90;
      label {
        color:black;
      }
    }
}

.find-a-tool-results {
    margin: 2.6rem auto 1.6rem;
    max-width: 1100px;

    .combo-input:focus {
        outline: none;
    }

    .find-a-tool-secondary-filter{
      input {
        &:focus {
          box-shadow:none;
        }
      }
    }
    .form-group label {
        color: $grey44;
    }

    .right-icon {
        float: right;
        padding: 0;
    }

    .fat-user-options {
        margin-bottom: 15px;
        margin-top: 20px;

        p {
            color: $grey44;
            font-weight: 700;
            font-size: 1.6rem;
            margin-bottom: -5px;
        }

        .x-button {
            padding: 0;
            padding-left: 2px;
            color: red;
            font-size: 1.92rem;
            font-weight: 400;
        }
    }

    .fat-message {
        padding: 100px 0;
        color: $grey44;
        text-align: center;
        width: 400px;
        margin: auto;
        font-size: 2.4rem;
        font-weight: 700;
    }

    .align-bottom {
        align-items: flex-end;
    }

    .form-check {
        padding-left: 2rem;

        &-input {
            margin-left: -2rem;
        }
    }

    span.primary {
        color: $accent;
    }

    .fat-sort {
        border: none;
        border-bottom: 2px solid #707070;
        max-height: 2.8rem;
        color: $accent;

        &:focus > option {
            background: $grey90;
        }
    }

    .fat-breadcrumb {
        display: inline-block;
        padding: 1rem 1.5rem;
        color: $accent;
        font-weight: 700;

        &:hover {
            border-radius: 4px;
            outline: 1px solid red;
        }

        .x-button {
            padding: 0;
            color: red;
            font-size: 1.92rem;
            font-weight: 400;
        }
    }

    .primary-button {
        padding: 1.8rem 3.2rem;
    }

    .p-autocomplete {
        display: flex;

        &-multiple-container {
            flex: 1 1 100%;
        }
    }
    //fat compare modal
    .variants-comparison {
        display: flex;
        /*justify-content: space-around;*/

        .find-a-tool-comparison {
            flex: 1 1 auto;
            border-top: 2px solid #ccd1cf;

            .dim-diff {
                background-color: yellow;
            }
        }

        .variant-dimension {
            &-output {
                border-bottom: 2px solid #ccd1cf;
                padding-left: 4rem;
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
            }
        }
    }
    //result card container
    &-card-container {
        display: flex;
        flex-wrap: wrap;

        .modal & {
            justify-content: space-evenly;
        }
    }

    .tool-header {
        width: 100%;
        text-align: center;
        padding-bottom: 10px;
        padding-top: 10px;

        h3 {
            color: white;
            font-size: 1.92rem;
            font-weight: 700;
            margin: 0 15px;
            background-color: $accent;
            padding: 10px;
        }
    }


    &-compare-card-container {
        display: flex;
        //flex-wrap: wrap;

        .modal & {
            justify-content: center;
        }
    }
    //result card
    &-card {
        margin: 15px 25px;
        border: 1px solid $fatCardBorderColor;
        border-radius: 8px;
        padding: 10px 10px 45px 65px;
        position: relative;
        width: 217px;
        height: 257px;

        @media (max-width: 1200px) {
            margin: 15px 5px;
        }

        .card-content {

            h6.primary {
                font-size: 1.4rem;
                height: 8.6rem;
                margin: 0;
            }

            a > .name-wrapper {
                overflow: hidden;
                height: 3.6em;

                h6.name {
                    line-height: 1.2em;
                }
            }

            .detail-text-wrapper {
                height: 2rem;
                overflow: hidden;
            }

            .detail-text {
                font-size: 1rem;
                margin: 10px 0;
                position: relative;
                line-height: 1.2em;
            }

            .product-id {
                margin: 5px 0;

                a {
                    font-size: 1.6rem;
                    font-weight: 700;
                    text-decoration: underline;
                    color: #5b6665;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            .numbers {
                font-size: 1rem;
                padding: 5px 5px 5px 10px;
                background-color: $grey90; //$bg-light-gray;
                margin: 15px 0 10px 0;
                color: $black;

                p {
                    margin: 0;
                }

                .right {
                    float: right;
                }
            }

            .form-group {
                input ~ label {
                    padding-left: 0rem;
                }
            }

            .compare-check {
                text-align: right;
                padding-right: 5px;

                .form-check-label {
                    font-size: 1rem;
                    //color: $grey90; //$info-gray;
                }

                input[type="checkbox"]:checked + label::after {
                    content: '';
                    position: absolute;
                    width: 10px;
                    height: 5px;
                    background: rgba(0, 0, 0, 0);
                    top: 0.9ex;
                    left: 0.4ex;
                    border: 3px solid #D2232A;
                    border-top: none;
                    border-right: none;
                    transform: rotate(-45deg);
                }

                input[type="checkbox"] {
                    line-height: 2.1ex;
                }

                input[type="radio"],
                input[type="checkbox"] {
                    position: absolute;
                    left: -999em;
                }

                input[type="checkbox"] + label {
                    position: relative;
                    overflow: hidden;
                    cursor: pointer;
                    line-height: 1.35;
                }

                input [type="checkbox"][disabled] + label {
                    color: $grey90;
                }

                input[type="checkbox"] + label::before {
                    content: "";
                    display: inline-block;
                    vertical-align: -25%;
                    height: 14px;
                    width: 14px;
                    background-color: white;
                    border: 1px solid rgb(166, 166, 166);
                    border-radius: 1px;
                    box-shadow: inset 0 2px 5px rgba(0,0,0,0.25);
                    margin-right: 0.5em;
                }

                input[type="checkbox"]:checked + label::before {
                    background: white !important;
                }

                input[type="checkbox"][disabled] + label::before {
                    background-color: $grey70; //$checkbox-gray;
                    //color: $grey;
                }
            }
        }

        .left-img {
            position: absolute;
            left: 0;
            bottom: 35px;
            width: 56px;
            border-top-left-radius: 8px;
            display: block;
            overflow: hidden;

            img {
                width: 100%;
                height: auto;
            }
        }

        .bottom-links {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 35px;
            color: white;

            a {
                color: white;
                font-size: 1rem;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }

            .left-link {
                width: 65%;
                height: 100%;
                background-color: $grey50;
                text-align: right;
                padding-right: 10px;
                padding-top: 5px;
                float: left;
                border-bottom-left-radius: 8px;
            }

            .right-link {
                width: 35%;
                height: 100%;
                padding-left: 10px;
                padding-top: 5px;
                float: right;
                border-bottom-right-radius: 8px;

                &.primary {
                    background-color: $accent;
                }

                &.secondary {
                    background-color: green;
                }
            }
        }
    }
}
    //scroll back to top button
    .floating-fat-image {
        position: fixed;
        height: 45px;
        bottom: 50px;
        right: 26vw;
        z-index: 5;
    }



    @media (max-width: 992px) {
        .find-a-tool-results-card {
            .card-content {
                .compare-check {
                    display: none;
                }
            }
        }
    }

    @media (max-width: 540px) {
        .find-a-tool-results-card {
            width: 180px;
            margin: 3px auto;

            .card-content {
                .product-id > a {
                    font-size: 0.7rem;
                }

                .detail-text {
                    margin: 5px 5px 5px 0;
                }

                .numbers {
                    padding: 5px 3px;
                }
            }
        }
    }
    /*
#fat-results {
.fat-user-options {
    h2 {
        display: inline-block;
    }
}
}

.find-a-tool-header {
background-color: $accent;
text-align: center;

h2 {
    color: $white;
    padding: 0.5rem 0;
}
}

.find-a-tool-subheader {
background-color: $grey90;
text-align: center;

h3 {
    color: $grey50;
    padding: 0.25rem 0;
}
}

.find-a-tool-subsection {
.find-a-tool-subsection-grouping {
    a {
        min-height: 175px;
    }
}

a {
    display: block;
}

h4 {
    margin: 0.5rem auto 2rem;
    font-size: 1.4rem;
    color: black;
    text-align: center;
}
}

.find-a-tool-results {
.primary-button {
}

&-component {
    .button-container {
        padding-bottom: 1rem;
    }
}


&-card-container {
    display: flex;
    flex-wrap: wrap;
}

&-card {
    margin: 15px 25px;
    border: 1px solid $grey90;
    border-radius: 8px;
    padding: 10px 10px 45px 65px;
    position: relative;
    width: 217px;
    height: 257px;

    #modal-compare & {
        margin: 15px;
    }

    @media (max-width: 1200px) {
        margin: 15px 5px;
    }

    .card-content {

        h6.primary {
            font-size: 1.4rem;
        }

        a > .name-wrapper {
            overflow: hidden;
            height: 3.6em;

            h6.name {
                line-height: 1.2em;
            }
        }

        .detail-text-wrapper {
            height: 2.4em;
            overflow: hidden;
        }

        .detail-text {
            font-size: 1rem;
            margin: 10px 0;
            position: relative;
            line-height: 1.2em;
        }

        .product-id {
            margin: 5px 0;

            a {
                font-size: 1.4rem;
                font-weight: 700;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        .numbers {
            font-size: 1rem;
            padding: 5px 5px 5px 10px;
            background-color: $grey90; //$bg-light-gray;
            margin: 15px 0 10px 0;
            color: $black;

            p {
                margin: 0;
            }

            .right {
                float: right;
            }
        }

        .compare-check {
            text-align: right;
            padding-right: 5px;

            .form-check-label {
                font-size: 1rem;
                //color: $grey90; //$info-gray;
            }

            input[type="checkbox"]:checked + label::after {
                content: '';
                position: absolute;
                width: 10px;
                height: 5px;
                background: rgba(0, 0, 0, 0);
                top: 0.9ex;
                left: 0.4ex;
                border: 3px solid #D2232A;
                border-top: none;
                border-right: none;
                transform: rotate(-45deg);
            }

            input[type="checkbox"] {
                line-height: 2.1ex;
            }

            input[type="radio"],
            input[type="checkbox"] {
                position: absolute;
                left: -999em;
            }

            input[type="checkbox"] + label {
                position: relative;
                overflow: hidden;
                cursor: pointer;
            }

            input[type="checkbox"] + label::before {
                content: "";
                display: inline-block;
                vertical-align: -25%;
                height: 14px;
                width: 14px;
                background-color: white;
                border: 1px solid rgb(166, 166, 166);
                border-radius: 1px;
                box-shadow: inset 0 2px 5px rgba(0,0,0,0.25);
                margin-right: 0.5em;
            }

            input[type="checkbox"]:checked + label::before {
                background: white !important;
            }

            .maxCompareExceeded input[type="checkbox"] + label::before {
                background-color: $grey90; //$checkbox-gray;
            }
        }
    }

    .left-img {
        position: absolute;
        left: 0;
        bottom: 35px;
        width: 56px;
        border-top-left-radius: 8px;
        display: block;
        overflow: hidden;

        img {
            width: 100%;
            height: auto;
        }
    }

    .bottom-links {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 35px;
        color: white;

        a {
            color: white;
            font-size: 1rem;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }

        .left-link {
            width: 65%;
            height: 100%;
            background-color: $grey50;
            text-align: right;
            padding-right: 10px;
            padding-top: 5px;
            float: left;
            border-bottom-left-radius: 8px;
        }

        .right-link {
            width: 35%;
            height: 100%;
            padding-left: 10px;
            padding-top: 5px;
            float: right;
            border-bottom-right-radius: 8px;

            &.primary {
                background-color: $accent;
            }

            &.secondary {
                background-color: green;
            }
        }
    }
}
}*/
