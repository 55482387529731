.subscribe-form {
    .form-check-emailSignUp {
        display: flex !important;
    }

    #emailSignUpCheckbox {
        //font-size: 16px;
        //width: 120px;
        //height: max-content;
        margin-right: 10px;
        //align-content: end;
        align-self: flex-start;
        margin-top: 7px;
    }
}

button {
    &.newsletter-button {
        font-family: $secondaryFont;
        font-weight: 700;
        text-align: center;
        border: 2px solid $newsletter-button-border-color;
        background-color: $newsletter-button-background-color;
        color: $newsletter-button-text-color;
        padding: 1.3rem 5rem;

        &:active {
            background-color: $newsletter-button-background-pressed-color;
            border-color: $newsletter-button-border-pressed-color;
            color: $newsletter-button-text-pressed-color;
        }

        &:hover {
            border-color: rgba($newsletter-button-border-color, .8);
            background-color: rgba($newsletter-button-background-color, .8);

            &:active {
                background-color: $newsletter-button-background-pressed-color;
                border-color: $newsletter-button-border-pressed-color;
                color: $newsletter-button-text-pressed-color;
            }
        }
    }
}


#newsletter-block {
    padding: 5.2rem 0;
    background-color: $newsletterRibbonBackgroundColor;

    .content-wrapper {
        text-align: center;

        .signed-up-wrapper {
            display: block;

            .title-news {
                color: $white;
                font-size: 1.7em;
                text-transform: none;
            }

            .subtitle {
                color: $white;
            }

            .link-item {
                color: $white;
                display: block;
            }
        }
    }

    .container {
        display: flex;

        .title-news {
            color: $white;
            font-size: 1.7em;
            text-transform: none;
        }

        .form-group {
            width: 100%;

            .form-control {
                font-size: 2rem;
                background: $white;
                padding-left: 2rem;
                font-family: "Lato",sans-serif;
                color: black;
                border: 1px solid $white;
                width: 51.5rem;
                height: 5.5rem;

                @media screen and (max-width: 1100px) {
                    width: 100%;
                    margin-bottom: 20px !important;
                }


                &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: black;
                    opacity: 1; /* Firefox */
                }

                &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                    color: black;
                }

                &::-ms-input-placeholder { /* Microsoft Edge */
                    color: black;
                }
            }

            button {
                border: 2px solid $white;
                margin-left: 4rem;

                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }
}


.newsletter-modal {
    font-size: 1.6rem;

    .title {
        text-align: center;
    }

    .modal-content {
        background: $grey90;
        padding: 2rem;

        .subtitle {
            font-size: 1.6rem;
        }
    }

    .button-wrapper {
        text-align: center;
    }
}
