.contact-page-component {
    background: $white;

    .title-wrapper {
        background: $background;
    }

    .contact-subtitle {
        color: $accent;
        font-size: 2.5rem;
        font-family: $tertiaryFont;
        text-align: center;
        padding-top: 5rem;
        padding-bottom: 2.5rem;
        border-bottom: 1px solid $grey60;
        margin-bottom: 4.5rem;
        display: block;

        span {
            font-weight: 600;
        }
    }

    .info-holder {
        .email, .phone {
            font-size: 2rem;
            color: $accent;
            font-family: $primaryFont;
        }

        .phone-item,
        .fax-item,
        .sales-item {
            font-size: 1.6rem;
            color: $black;
            font-family: $primaryFont;
        }

        .email-link, .telephone {
            font-size: 1.6rem;
            font-family: $primaryFont;
        }
    }

    .contact-wrapper {
        .title-form {
            background: $accent;
            border-radius: 6px;

            .title {
                margin-bottom: 0;
                color: $white;
                text-transform: none;
                font-size: 2.2rem;
                padding: 1.2rem 3.5rem;
            }
        }

        .card-wide {
            box-shadow: 0px 2px 6px rgba($black, .16);
            padding: 3.5rem 3.5rem;
            margin-bottom: 4rem;
            color: $black;

            .form-group {
                margin-bottom: 2rem;

                .form-control {
                    background: #fff;
                    border: 1px solid #e6e6e6;
                    font-size: 1.5rem;
                }
            }
        }

        .form-fluid {
            .form-control {
                height: 17rem;
            }
        }
    }

    .main-wrapper {
        .contact-mail {
            padding-left: 40px;

            @media (max-width: 767px) {
                padding-left: 15px;
                padding-bottom: 20px;
            }
        }

        .email, .phone {
            font-size: 2rem;
            color: $grey60;
            font-family: $tertiaryFont;
        }

        .phone-item,
        .fax-item,
        .sales-item,
        .tech-item {
            font-size: 1.4rem;
            color: $black;
        }

        .email-link, .telephone {
            font-size: 1.4rem;
        }

        .contact-map {
            margin-top: 3rem;
        }
    }

    .error-message-wrapper {
        height: 100%;
        align-items: center;
        display: flex;
        justify-content: center;

        .info {
            color: $accent;
            font-size: 2rem;
        }
    }
}
