.card-wrapper {
    &.last {
        .distributor-card-component {
            .distributor-tooltip {
                width: 50rem;
                top: 5.5rem;
                transform: translateY(0) !important;
                padding: 3.4rem;
                position: absolute;
                box-shadow: $shadow-box;
                right: -100px;
                left: auto;

                &:before {
                    margin-left: 116px;
                }

                &:after {
                    margin-left: 118px;
                }

                &.arrow-bottom {
                    top: -6.5rem;
                    left: -11.5rem;
                    width: auto;
                    padding: 1rem 2rem;

                    &::before,
                    &::after {
                        left: 75%;
                    }
                }

                @media (max-width: 1024px) {
                    max-width: 42rem;
                    left: -16rem;
                    padding: 2rem 2.7rem;

                    &::before,
                    &::after {
                        left: 15%;
                    }
                }

                @media (max-width: 991px) {
                    max-width: 37rem;
                    left: -6rem;
                    padding: 2rem 2.7rem;
                    right: auto;

                    &::before,
                    &::after {
                        left: -12%;
                    }
                }

                @media (max-width: 480px) {
                    width: auto;
                }

                .close-wrapper {
                    position: absolute;
                    top: 1.4rem;
                    right: 2rem;

                    span {
                        font-size: 3rem;
                        color: $grey60;
                    }
                }

                .content-wrapper {
                    .subtitle {
                        margin: 0;
                        line-height: 1.2;
                    }

                    .location {
                        color: $black;
                        font-weight: 700;
                        margin-bottom: 3.2rem;
                    }
                }

                .contact-wrapper {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 1.8rem;

                    .contact {
                        span {
                            display: inline;
                            color: $black;

                            @media (max-width: 991px) {
                                font-size: 1.3rem;
                            }
                        }

                        .info {
                            font-weight: 700;
                        }
                    }

                    .primary-button {
                        max-height: 5rem;
                        padding: 1.5rem 3rem;

                        @media (max-width: 991px) {
                            padding: 1.3rem 2rem;
                            white-space: nowrap;
                            font-size: 1.3rem;
                            max-height: 4rem;
                        }
                    }
                }

                .map-wrapper {
                    width: 100%;

                    .address {
                        font-size: 1.8rem;
                        margin-bottom: 1.2rem;

                        @media (max-width: 991px) {
                            font-size: 1.4rem;
                        }
                    }
                }
            }
        }
    }
}

.distributor-card-component {
    border: 1px solid $grey60;
    background: $white;
    padding: 6.6rem 6rem;
    position: relative;

    @media (max-width: 1200px) {
        padding: 6.6rem 3rem;
    }

    @media (max-width: 991px) {
        max-width: 45rem;
        margin: 0 auto;
    }

    .h5.subtitle {
        min-height: 55px;
    }

    .icon-wrapper {
        position: absolute;
        top: 1rem;
        right: 2rem;

        .icon {
            font-size: 3rem;
            color: $black;

            &.icon-my-list {
                &.favorite {
                    background: $accent;
                    padding: 5px 10px 1px;
                    border-radius: 0.4rem;

                    &:before {
                        color: $white;
                    }
                }
            }
        }
    }

    .content-wrapper {
        padding: 0;

        .subtitle {
            font-size: 2.2rem;
            margin-bottom: 2.6rem;
        }

        .info-wrapper {
            margin-bottom: 5.4rem;

            span {
                font-size: 1.6rem;
                font-weight: 700;
                display: block;
            }
        }

        hr {
            margin-bottom: 2rem;
            border-color: $grey60;
        }

        .button-wrapper {
            height: 4.4rem;
            display: flex;
            align-items: center;
            padding-right: 2rem;
            justify-content: space-between;

            @media (max-width: 1300px) {
                padding: 0;
            }

            .info-link {
                font-family: $tertiaryFont;
                font-size: 1.5rem;
                color: $accent;
            }

            .primary-button {
                padding: 1.2rem 2rem;
            }
        }
    }

    .distributor-tooltip {
        width: 50rem;
        top: 5.5rem;
        transform: translateY(0) !important;
        padding: 3.4rem;
        position: absolute;
        box-shadow: $shadow-box;
        left: -40px;

        &.arrow-bottom {
            top: -6.5rem;
            left: -11.5rem;
            width: auto;
            padding: 1rem 2rem;

            &::before,
            &::after {
                left: 75%;
            }
        }

        @media (max-width: 991px) {
            max-width: 37rem;
            left: -6rem;
            padding: 2rem 2.7rem;

            &::before,
            &::after {
                left: 25%;
            }
        }

        @media (max-width: 480px) {
            width: auto;
        }

        .close-wrapper {
            position: absolute;
            top: 1.4rem;
            right: 2rem;

            span {
                font-size: 3rem;
                color: $grey60;
            }
        }

        .content-wrapper {
            .subtitle {
                margin: 0;
                line-height: 1.2;
            }

            .location {
                color: $black;
                font-weight: 700;
                margin-bottom: 3.2rem;
            }
        }

        .contact-wrapper {
            display: flex;
            justify-content: space-between;
            margin-bottom: 1.8rem;

            .contact {
                span {
                    display: inline;
                    color: $black;

                    @media (max-width: 991px) {
                        font-size: 1.3rem;
                    }
                }

                .info {
                    font-weight: 700;
                }
            }

            .primary-button {
                max-height: 5rem;
                padding: 1.5rem 3rem;

                @media (max-width: 991px) {
                    padding: 1.3rem 2rem;
                    white-space: nowrap;
                    font-size: 1.3rem;
                    max-height: 4rem;
                }
            }
        }

        .map-wrapper {
            width: 100%;

            .address {
                font-size: 1.8rem;
                margin-bottom: 1.2rem;

                @media (max-width: 991px) {
                    font-size: 1.4rem;
                }
            }
        }
    }
}


.find-distributor-component {
    .content-wrapper {
        background-color: $white;
        padding: 1.8rem 0 0;

        .description {
            max-width: 60rem;
        }

        .local-distributor-wrapper {
            border-bottom: 1px solid $grey60;
            padding: 1.4rem 0;

            .selectric {
                width: 12.6rem;
            }

            @media (max-width: 767px) {
                padding: 1.8rem 0 2.2rem;
            }

            .container {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;

                @media (max-width: 767px) {
                    display: block;
                    text-align: center;
                }
            }

            .title {
                display: inline-block;
                margin: 0;
                font-size: 2.2rem;

                @media (max-width: 767px) {
                    font-size: 1.8rem;
                    margin-bottom: 1.6rem;
                }
            }

            .form-group {
                width: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0;
            }

            label {
                white-space: nowrap;
                margin: 0 2rem 0;
                font-weight: 700;
                font-family: $primaryFont;
                font-size: 1.6rem;
                color: $black;

                @media (max-width: 767px) {
                    font-size: 1.4rem;
                }
            }

            select {
                width: 23rem;
                border: 1px solid $grey90;

                @media (max-width: 767px) {
                    width: 14rem;
                }
            }
        }

        .distributor-results-wrapper {
            display: flex;
            align-items: center;
            background-color: $grey90;
            min-height: 150px;


            .loader {
                display: flex;
                //align-items:baseline;
                padding-top: 20px;

                .text {
                    font-size: 1.8rem;
                }

                .dots {
                    display: flex;
                    position: relative;
                    top: 12px;
                    left: -10px;
                    width: 66px;
                    animation: dots 4s ease infinite 1s;

                    div {
                        position: relative;
                        width: 4px;
                        height: 4px;
                        margin-right: 6px;
                        border-radius: 100%;
                        background-color: $grey60;

                        &:nth-child(1) {
                            width: 4px;
                            height: 4px;
                            margin: 5px;
                            margin-right: 6px;
                            animation: show-dot 4s ease-out infinite 1s;
                        }

                        &:nth-child(4) {
                            background-color: transparent;
                            animation: dot-fall-left 4s linear infinite 1s;

                            &:before {
                                position: absolute;
                                width: 4px;
                                height: 4px;
                                margin-right: 6px;
                                border-radius: 100%;
                                background-color: $grey60;
                                content: '';
                                animation: dot-fall-top 4s cubic-bezier(0.46, 0.02, 0.94, 0.54) infinite 1s;
                            }
                        }
                    }
                }
            }


            @keyframes dots {
                0% {
                    left: -10px;
                }

                20%,100% {
                    left: 10px;
                }
            }

            @keyframes show-dot {
                0%,20% {
                    width: 0px;
                    height: 0px;
                    margin: 5px;
                    margin-right: 6px;
                }

                30%,100% {
                    width: 4px;
                    height: 4px;
                    margin: 0px;
                    margin-right: 6px;
                }
            }

            @keyframes dot-fall-left {
                0%, 5% {
                    left: 0px;
                }

                100% {
                    left: 200px;
                }
            }

            @keyframes dot-fall-top {
                0%, 5% {
                    top: 0px;
                }

                30%,100% {
                    top: 50vh;
                }
            }

            .cards-list-wrapper {
                display: grid;
                gap: 2.5rem;
                margin: 2rem auto;

                @media (min-width: 768px) {
                    gap: 5rem;
                    grid-template-columns: repeat(3, 1fr);
                    margin: 2rem auto;
                }
            }

            .no-matches {
                p {
                    color: $grey60;
                }
            }
        }

        .section-title {
            position: relative;

            @media (max-width: 991px) {
                margin: 2rem 0 4rem;
            }

            hr {
                margin: 0;
            }

            .subtitle {
                padding: 1rem 5rem;
                background: $grey90;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .search-distributor-component {
        border-bottom: 1px solid $grey60;

        .container {
            display: flex;
        }


        .search-location-wrapper {
            padding: 4rem 7rem;
            width: 40%;

            @media (max-width: 991px) {
                width: 100%;
                padding: 4rem 2rem;
            }

            .subtitle {
                margin-bottom: 1.8rem;
                margin-left: -1rem;
            }

            .primary-button {
                margin-bottom: 4.8rem;
                padding: 1rem 1.5rem;

                .icon {
                    font-family: $primaryFont !important;

                    &.icon-location {
                        &:before {
                            margin-right: 1rem;
                        }
                    }
                }

                &:last-child {
                    margin: 0;
                }
            }

            form {
                .form-row {
                    max-width: 32rem;
                    margin-bottom: 1.8rem;

                    input,
                    select {
                        font-family: $tertiaryFont;
                        border: 1px solid $grey90;
                    }

                    input {
                        padding: 0 2rem;
                    }

                    .selectric {
                        max-width: 12rem;
                    }
                }

                .primary-button {
                    padding: 1rem 3rem;
                }
            }

            .province {
                margin-bottom: 0;
                margin-right: 3rem;
            }
        }

        .search-state-wrapper {
            width: 60%;

            @media (max-width: 991px) {
                display: none;
            }

            .subtitle {
                margin-bottom: 7rem;
            }

            .maps-wrapper {
                img {
                    transform: scale(1.5);
                    transform-origin: 0% 0%;

                    &:last-child {
                        margin-left: 20rem;
                    }

                    @media (max-width: 1400px) {
                        transform: scale(1);

                        &:last-child {
                            margin-left: 5rem;
                        }
                    }
                }
            }
        }
    }

    .distributor-modal {
        .modal {
            background: rgba(54,54,54,0.7);
        }

        .modal-content {
            border: none;
            background: none;

            .modal-body {
                padding: 0;

                .card {
                    padding: 15px 20px 30px;
                }


                .card-header {
                    background: none;
                    border-bottom: none;

                    .close-button {
                        float: right;
                        margin: 0;
                    }

                    .subtitle {
                        font-family: $tertiaryFont;
                        font-size: 2rem;
                        line-height: 3.1rem;
                        color: $accent;
                        text-align: center;
                        clear: both;
                    }

                    .title {
                        text-transform: none;
                        text-align: center;
                    }
                }

                .card-body {
                    .primary-button {
                        margin: 0 auto;
                        display: block;
                        padding: 1.2rem 7rem;
                    }
                }

                .create-btn {
                    text-align: center;
                    display: block;
                    transition: all .2s ease-in-out;

                    &:hover {
                        color: $accent;
                        transform: scale(1.1);
                        transition: all .2s ease-in-out;
                    }
                }
            }
        }
    }
}
