.tool-description-component {
    background: $white;

    .title-wrapper {
        padding: 11rem 0 4rem;
        border-bottom: 1px solid $grey60;

        @media (max-width: 767px) {
            padding: 2rem 1.5rem 2.4rem;
            border: none;
        }

        .title {
            @media (max-width: 767px) {
                font-size: 2rem;
            }
        }

        .subtitle {
            @media (max-width: 767px) {
                font-size: 1.5rem;
            }
        }
    }

    .content-wrapper {
        padding: 6rem 0;

        @media (max-width: 767px) {
            padding: 0;
        }

        .tool-row {
            position: relative;

            .subtitle {
                position: absolute;
                font-weight: 400;
                padding-right: 14rem;
                font-family: $tertiaryFont;
                text-transform: none;
                font-size: 2rem;
                background: $white;
                transform: translateY(-50%);

                @media (max-width: 767px) {
                    position: static;
                    transform: translateY(0);
                    margin-bottom: 1.2rem;
                }
            }

            .content {
                display: flex;
                flex-wrap: wrap;
                padding: 5rem 5rem;
                border-top: 1px solid $grey60;

                @media (max-width: 767px) {
                    padding: 3rem;
                    margin: 0 -1.5rem;
                }
            }

            .tool-item {
                width: 25%;
                margin-bottom: 3.7rem;

                @media (max-width: 767px) {
                    width: 100%;
                }

                .item-link {
                    display: block;
                    max-width: 30rem;
                }

                .img-wrapper {
                    margin-bottom: 2rem;
                    max-width: 20rem;
                }

                .tool-title {
                    display: inline-block;
                    font-size: 1.8rem;
                    font-family: $primaryFont;
                    color: $black;
                    margin: 0;
                }

                .tool-decription {
                    font-size: 1.5rem;
                    max-width: 30rem;
                    line-height: 2rem;
                    font-family: $primaryFont;
                }
            }
        }
    }
}
