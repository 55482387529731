//SHARED by various account pages
.password-card-component {
    position: relative;
    margin: 15rem auto;
    border-radius: 6px;

    .content-wrapper {
        padding: 4.3rem 6.3rem;

        @media (max-width: 767px) {
            padding: 4.3rem 2.5rem;
            margin-bottom: 10rem;
        }

        .form-group {
            margin-bottom: 4.5rem;
            position: relative;

            input {
                width: 51rem;

                @media (max-width: 767px) {
                    width: 100%;
                }
            }

            .error-text {
                position: absolute;
                bottom: -2.3rem;
            }
        }

        .button-wrapper {
            margin-bottom: 1.5rem;

            @media (max-width: 767px) {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .primary-button {
                @media (max-width: 767px) {
                    font-size: 1.3rem;
                }
            }

            .secondary-button {
                font-family: $primaryFont;
                font-size: 1.5rem;
                color: $grey60;
                text-decoration: underline;
                margin-left: 8rem;

                @media (max-width: 767px) {
                    margin-left: 0;
                    margin-right: 5rem;
                }

                &:hover,
                &:focus,
                &:active {
                    text-decoration: underline;
                }
            }
        }
    }

    .cancel-button {
        font-size: 1.5rem;
        font-family: $primaryFont;
        text-decoration: underline;
        margin-left: 8rem;
        color: $grey60;
    }

    .success-message {
        padding: 12rem 8.3rem;
        text-align: center;

        @media (max-width: 767px) {
            padding: 4.3rem 2.5rem;
        }
    }

    .side-card-component {
        margin: 0 auto 10rem;
        display: block;
        //max-width: 50rem;
    }
    // Reset Crad
    &.reset-password-card {
        .content-wrapper {
            .form-group {
                &:first-child {
                    margin-bottom: 2.2rem;
                }

                .form-control {
                    @media (max-width: 767px) {
                        max-width: 100%;
                    }

                    &::placeholder {
                        font-family: Arial, Helvetica, sans-serif;
                        color: $grey70;
                        font-size: 2rem;
                    }
                }
            }
        }
    }
}
