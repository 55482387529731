.tool-information-component {
    padding-bottom: 4rem;
    background: $white;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }

    .title-wrapper {
        padding: 12rem 0 3rem;

        @media (max-width: 767px) {
            padding: 2rem 0 1.7rem;
        }

        .title {
            @media (max-width: 767px) {
                font-size: 2rem;
            }
        }
    }

    .mobile-hide {
        @media (max-width: 767px) {
            display: none;
        }
    }

    .form-section {
        margin-bottom: 5rem;

        &:last-child {
            margin-bottom: 2rem;
        }

        .subtitle {
            font-size: 1.8rem;
            font-family: $primaryFont;
            font-weight: 400;
            color: $black;
            margin-bottom: 2.8rem;

            select {
                color: #738087;
                cursor: text;
                font-family: "Arial";
                font-size: 13.33px;
                font-stretch: normal;
                font-weight: 400;
            }

            & + .description {
                margin-top: -2.8rem;
                margin-bottom: 2.8rem;

                &:last-child {
                    margin-left: 5rem;
                    margin-bottom: 0;
                }
            }

            @media (max-width: 767px) {
                font-size: 1.8rem;
            }

            @media (max-width: 570px) {
                width: 100%;
            }
        }

        .form-wrapper {
            display: flex;
            flex-wrap: wrap;

            @media (max-width: 767px) {
                margin-left: -2rem;
            }

            & > .subtitle {
                margin-bottom: 0;
                margin-right: 2rem;
            }



            .form-group {
//                width: 25%;
                padding-left: 5rem;

                @media (max-width: 1200px) {
                    width: auto;
                    padding-left: 2rem;
                }

                @media (max-width: 360px) {
                    width: 100%;
                    padding-left: 2rem;
                }

                &.pl-4 {
                    @media (max-width: 360px) {
                        padding-left: 0 !important;
                    }
                }


                &.update-btn {
                    a {
                        padding: 1.1rem 3rem;
                    }
                }
            }



            .form-fluid {
                width: 100%;
            }

            .half-fluid {
                width: 40%;

                @media (max-width: 767px) {
                    width: 100%;
                }
            }

            label {
                text-transform: uppercase;
                font-size: 1.2rem;
                color: $black;
                white-space: nowrap;

                @media (max-width: 767px) {
                    font-size: 1.1rem;
                    text-transform: none;
                }
            }

            .form-control {
                background: $white;
                border: 1px solid $grey70;
                font-size: 1.5rem;
            }

            textarea {
                height: 17rem;
            }

            .etch {
                display: none;
            }

            &.rn-tool-form,
            &.s-rn-tool-form {
                .r,
                .a {
                    display: none;
                }
            }

            &.cr-rn-tool {
                .a {
                    display: none;
                }
            }

            &.cr-tool-form {
                .l3,
                .d3,
                .a {
                    display: none;
                }
            }

            &.s-tool-form,
            &.b-tool-form {
                .l3,
                .d3,
                .r,
                .a {
                    display: none;
                }
            }

            &.hpcm-tool-form {
                .l3,
                .d3,
                .r,
                .co,
                .chp {
                    display: none;
                }

                .etch {
                    display: inline-block;
                }
            }

            &.hcm-tool-form {
                .l3,
                .d1,
                .d3,
                .r,
                .co,
                .chp {
                    display: none;
                }

                .etch {
                    display: inline-block;
                }
            }

            .error-text {
                .update-error {
                    margin-left: 10rem;
                }
            }
        }

        .form-inline {
            .form-control {
                margin-left: 1.6rem;
                max-width: 15rem;

                @media (max-width: 767px) {
                    margin-left: 0;
                }
            }

            @media (max-width: 767px) {
                margin-left: 0;
            }
        }

        .input-fluid {
            width: 100%;
            max-width: 100% !important;

            &::placeholder {
                color: red;
            }
        }
    }

    .dropzone {
        max-width: 30rem;
        margin-left: 5rem;
        border: 1px dashed $grey70;
        text-align: center;

        @media (max-width: 767px) {
            margin: 0 auto;
            margin-bottom: 2rem;
        }

        .icon-upload {
            color: $accent;
            font-size: 4rem;
            pointer-events: none;
        }

        .dz-message {
            display: none;
        }

        .subtitle {
            font-size: 2rem;
            pointer-events: none;
        }

        .primary-button {
            margin-bottom: 2rem;
            padding: 1rem 4rem;
            cursor: pointer;
        }

        .dz-preview {
            position: relative;

            .dz-remove {

                &:after {
                    font-family: 'Font Awesome 5 Free';
                    font-weight: 700;
                    font-size: 3rem;
                    content: "\f00d";
                    color: #8f8f8f;
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-left: -27px;
                    margin-top: -27px;
                    z-index: 999;
                    right: 0;
                    background: #ffffff;
                    width: 54px;
                    height: 54px;
                    line-height: 54px;
                    border-radius: 100%;
                    opacity: 0.9;
                    border: 1px solid #8f8f8f;
                }
            }
        }
    }


    .button-wrapper {
        text-align: center;
        margin-bottom: 5rem;

        .g-recaptcha {
            margin-left: 5rem;
        }

        .primary-button {
            width: 16.4rem;
            padding: 1.5rem 0;
        }
    }

    .success-message {
        text-align: center;
        margin: 3rem 0;

        .fa-check-circle {
            font-size: 5rem;
            color: $accent;
            margin-bottom: 3rem;
        }

        p {
            font-size: 3rem;
            font-family: $primaryFont;
            color: $accent;
        }
    }

    .form-wrapper {
        &.measure {


            .input-measure-wrapper {
                position: relative;

                input {
                    font-size: 1.6rem;

                    &:focus {
                        background: $white;
                    }
                }
            }

            #measure-box {
                text-align: left;
                background: $white;
                color: $grey60;
                padding: 1rem;
                font-size: 1.6rem;
                font-family: $tertiaryFont;
                position: absolute;
                left: 0;
                width: 100%;
                top: 40px;
                z-index: 999;
                box-shadow: rgb(51, 51, 51) 0px 0.5px 5px 0px;

                .box {
                    margin-top: 1.5rem;

                    &:nth-child(1) {
                        margin-top: 0;
                    }
                }

                a {
                    width: 100%;
                    color: $grey60;
                    font-size: 1.3rem;
                    font-family: $tertiaryFont;
                    line-height: 2rem;
                    display: block;
                }
            }
        }
    }
}

.drawing-measurements {
    padding: 2.7rem 0 2rem;
    background: $background;
    margin-bottom: 5rem;

    .title {
        font-family: $primaryFont;
        font-weight: 400;
        font-size: 2.2rem;
        text-transform: none;
        color: $black;
        margin-bottom: 2rem;

        @media (max-width: 767px) {
            font-size: 1.8rem;
        }
    }

    .custom-form {
        text-align: center;
        width: 104.8rem;
        position: relative;
        margin: 0 auto;

        @media (max-width: 1100px) {
            width: 74rem;
        }

        @media (max-width: 767px) {
            width: auto;
        }

        img {
            width: 100%;
        }

        .tool-image {
            @media (max-width: 767px) {
                display: none;
            }
        }

        .mobile-tool-image {
            display: none;

            @media (max-width: 767px) {
                display: block;
            }
        }

        .form-control {
            width: 15rem;
            background: $white;
            border: 1px solid $grey70;
            font-size: 1.4rem;
            position: absolute;
            padding: 0 0 0 .4rem;

            @media (max-width: 1100px) {
                width: 9rem;
            }

            @media (max-width: 767px) {
                display: none;
            }

            &::placeholder {
                color: $grey60;
                font-size: 1.2rem;
            }
        }

        input[type=number].form-control:disabled {
            background: #F5F5F5;
        }

        &.rn-tool,
        &.cr-rn-tool,
        &.s-rn-tool {
            .l1 {
                top: 13.4rem;
                left: 65.2rem;

                @media (max-width: 1100px) {
                    top: 8.8rem;
                    left: 46rem;
                }
            }

            .l2 {
                top: 5.8rem;
                left: 7.2rem;

                @media (max-width: 1100px) {
                    top: 4rem;
                    left: 4.2rem;
                }
            }

            .l3 {
                top: 9.8rem;
                left: 41.2rem;

                @media (max-width: 1100px) {
                    top: 7rem;
                    left: 30.2rem;
                }
            }

            .d1 {
                top: 21.8rem;
                left: 87.2rem;

                @media (max-width: 1100px) {
                    top: 15rem;
                    left: 61.6rem;
                }
            }

            .d2 {
                top: 12.8rem;
                left: 12.2rem;

                @media (max-width: 1100px) {
                    top: 9rem;
                    left: 9.2rem;
                }
            }

            .d3 {
                top: 31rem;
                left: 36rem;

                @media (max-width: 1100px) {
                    top: 22rem;
                    left: 26rem;
                }
            }

            .r {
                display: none;
            }

            .a {
                display: none;
            }
        }

        &.cr-rn-tool,
        &.s-rn-tool {
            .l1 {
                top: 14.4rem;

                @media (max-width: 1100px) {
                    top: 9.8rem;
                }
            }

            .l2 {
                top: 7.4rem;

                @media (max-width: 1100px) {
                    top: 5rem;
                }
            }

            .l3 {
                top: 11.4rem;

                @media (max-width: 1100px) {
                    top: 7.4rem;
                }
            }

            .d1 {
                top: 22.2rem;
                left: 88.2rem;

                @media (max-width: 1100px) {
                    left: 62.6rem;
                }
            }

            .d2 {
                top: 32rem;

                @media (max-width: 1100px) {
                    top: 23rem;
                }
            }

            .d3 {
                left: 40rem;

                @media (max-width: 1100px) {
                    left: 29rem;
                }
            }

            .r {
                display: block;
                top: 29.8rem;
                left: 60.2rem;

                @media (max-width: 1100px) {
                    top: 21rem;
                    left: 42rem;
                }

                @media (max-width: 767px) {
                    display: none;
                }
            }

            .a {
                display: none;
            }
        }

        &.s-rn-tool {
            .l1 {
                top: 14rem;
                left: 66.2rem;

                @media (max-width: 1100px) {
                    top: 9.6rem;
                    left: 46.6rem;
                }
            }

            .l3 {
                top: 10.4rem;

                @media (max-width: 1100px) {
                    top: 7.4rem;
                }
            }

            .d1 {
                left: 88.8rem;

                @media (max-width: 1100px) {
                    left: 61.6rem;
                }
            }

            .r {
                display: none;
            }
        }

        &.cr-tool,
        &.s-tool,
        &.b-tool {
            .l1 {
                top: 12.4rem;
                left: 63.4rem;

                @media (max-width: 1100px) {
                    top: 8.2rem;
                    left: 44.4rem;
                }
            }

            .l2 {
                top: 5.4rem;
                left: 17.2rem;

                @media (max-width: 1100px) {
                    top: 3.4rem;
                    left: 13.2rem;
                }
            }

            .l3 {
                display: none;
            }

            .d1 {
                top: 25.8rem;
                left: 92.6rem;

                @media (max-width: 1100px) {
                    top: 18rem;
                    left: 64.6rem;
                }
            }

            .d2 {
                top: 25.4rem;
                left: 31.4rem;

                @media (max-width: 1100px) {
                    top: 18rem;
                    left: 22.2rem;
                }
            }

            .d3 {
                display: none;
            }

            .r {
                top: 34.4rem;
                left: 62.2rem;

                @media (max-width: 1100px) {
                    top: 24rem;
                    left: 44.2rem;
                }
            }

            .a {
                display: none;
            }
        }

        &.s-tool {
            .l1 {
                left: 61.4rem;

                @media (max-width: 1100px) {
                    top: 8.4rem;
                    left: 44rem;
                }
            }

            .l2 {
                top: 5rem;
                left: 16.2rem;

                @media (max-width: 1100px) {
                    top: 2.8rem;
                    left: 13.2rem;
                }
            }

            .d1 {
                top: 26.6rem;
                left: 91.8rem;

                @media (max-width: 1100px) {
                    top: 18rem;
                    left: 64.8rem;
                }
            }

            .d2 {
                top: 26.4rem;
                left: 29.2rem;

                @media (max-width: 1100px) {
                    top: 18rem;
                    left: 20.8rem;
                }
            }

            .r {
                display: none;
            }
        }

        &.b-tool {
            .l1 {
                top: 13.6rem;
                left: 62.4rem;

                @media (max-width: 1100px) {
                    top: 9.2rem;
                    left: 43.4rem;
                }
            }

            .l2 {
                top: 9rem;
                left: 16.2rem;

                @media (max-width: 1100px) {
                    top: 5.8rem;
                    left: 13.2rem;
                }
            }

            .d1 {
                top: 25.8rem;
                left: 91.4rem;

                @media (max-width: 1100px) {
                    top: 17.6rem;
                    left: 64.6rem;
                }
            }

            .d2 {
                top: 26.4rem;
                left: 31.2rem;

                @media (max-width: 1100px) {
                    top: 18rem;
                    left: 22.2rem;
                }
            }

            .r {
                display: none;
            }
        }

        &.hpcm-tool,
        &.hcm-tool {
            .l1 {
                top: 12.2rem;
                left: 49.2rem;

                @media (max-width: 1100px) {
                    top: 8.2rem;
                    left: 34.4rem;
                }
            }

            .l2 {
                top: 6.6rem;
                left: 12.2rem;

                @media (max-width: 1100px) {
                    top: 4.4rem;
                    left: 9.2rem;
                }
            }

            .l3 {
                display: none;
            }

            .d1 {
                top: 34.4rem;
                left: 67rem;

                @media (max-width: 1100px) {
                    top: 24.4rem;
                    left: 47.6rem;
                }
            }

            .d2 {
                top: 25.4rem;
                left: 31.8rem;

                @media (max-width: 1100px) {
                    top: 17rem;
                    left: 22.8rem;
                }
            }

            .d3 {
                display: none;
            }

            .r {
                display: none;
            }

            .a {
                top: 25.2rem;
                left: 84rem;

                @media (max-width: 1100px) {
                    top: 17.2rem;
                    left: 59.2rem;
                }
            }
        }

        &.hcm-tool {
            .l1 {
                top: 11rem;
                left: 51.8rem;

                @media (max-width: 1100px) {
                    top: 7.2rem;
                    left: 36rem;
                }
            }

            .l2 {
                @media (max-width: 1100px) {
                    top: 4.2rem;
                }
            }

            .d1 {
                display: none;
            }

            .d2 {
                top: 25.6rem;
                left: 28.8rem;

                @media (max-width: 1100px) {
                    top: 17.4rem;
                    left: 20.8rem;
                }
            }

            .a {
                top: 25.4rem;
                left: 84.8rem;

                @media (max-width: 1100px) {
                    top: 17.2rem;
                    left: 59.2rem;
                }
            }
        }
    }

    .form-wrapper {
        &.measure {


            .input-measure-wrapper {
                position: relative;

                input {
                    font-size: 1.6rem;

                    &:focus {
                        background: $white;
                    }
                }
            }

            #measure-box {
                text-align: left;
                background: $white;
                color: $grey60;
                padding: 1rem;
                font-size: 1.6rem;
                font-family: $tertiaryFont;
                position: absolute;
                left: 0;
                width: 100%;
                top: 40px;
                z-index: 999;
                box-shadow: rgb(51, 51, 51) 0px 0.5px 5px 0px;

                .box {
                    margin-top: 1.5rem;

                    &:nth-child(1) {
                        margin-top: 0;
                    }
                }

                a {
                    width: 100%;
                    color: $grey60;
                    font-size: 1.3rem;
                    font-family: $tertiaryFont;
                    line-height: 2rem;
                    display: block;
                }
            }
        }
    }
}

//only used by statics (remove when we remove statics pages -- not part of website)
.contact-component {
    background: $grey90;
    padding: 3.9rem 0 7.1rem;
    text-align: center;

    @media (max-width: 767px) {
        text-align: left;
    }

    .container {
        @media (max-width: 767px) {
            padding: 0 3rem;
        }
    }

    .title {
        font-family: $primaryFont;
        text-transform: none;

        @media (max-width: 767px) {
            font-size: 1.9rem;
            margin-bottom: 1rem;
        }
    }

    .subtitle {
        margin-bottom: 6.7rem;

        @media (max-width: 767px) {
            font-size: 1.6rem;
            margin-bottom: 3.5rem;
        }
    }

    .icon-row {
        display: flex;
        margin: 0 auto;
        justify-content: space-between;
        max-width: 40rem;

        @media (max-width: 767px) {
            display: block;
        }

        .icon-wrapper {
            display: flex;
            flex-direction: column;
            width: 16rem;

            @media (max-width: 767px) {
                text-align: center;
                width: 100%;
                margin-bottom: 3rem;
            }

            .icon {
                font-size: 10rem;
                margin-bottom: 2.7rem;
            }

            .primary-button {
                width: 100%;
            }
        }
    }
}
