//********************************************
//********************************************
//EXPORTED MEDIA LIMITS

$mobile-max-width: 480px;
$tablet-min-width: 481px;
$tablet-max-width: 767px;
$desktop-min-width: 768px;
$desktop-max-width: 1200px;
$ultra-desktop-min-width: 1201px;

.desktop {
  display:inherit;
}
.mobile {
    display: inherit;
}

@media screen and (max-width:575px) {
    .desktop {
        display: none !important;
    }
}

@media screen and (min-width:576px) {
    .mobile {
        display: none !important;
    }
}
