/// <reference path="variables.scss" />

.form-group {
    display: block;
    width:100%;
}

.form-control {
    font-size: 1.6rem;
    height: 4.6rem;

    &::placeholder {
        font-family: $secondaryFont;
        font-weight: 600;
        font-size: 1.6rem;
    }
}

.combo-popup {
    z-index: 30;
    position: absolute;
    border: 1px solid black;
    background-color: $grey90;
    overflow-y: auto;
}


.multi-popup {
    z-index: 30;
    position: absolute;
    //border: 1px solid black !important;
    max-height: 200px;
    background-color: $grey90;
    overflow-y: auto;
    overflow-x: hidden;

    .row {
        margin: 0;
    //    background-color: $grey90;
    }
}

.row-bottom-gap {
    padding-bottom: 1.6rem;
}

//form input buttons
button {
    //shared by lots of forms
    &.password-eye {
        float: right;
        margin-top: -4.5rem;
        background: transparent;
        max-width: 30px;
        max-height: 1rem;
    }
    //clear X (FAT)
    &.form-input-clear {
        position: absolute;
        right: 1rem;
        width: 25px;
        padding: 5px;
        color: #d2232a;
        min-width: auto;
        background-color: transparent;
        border: none;
        margin: -3rem 0 0 0;
        font-size: 1.92rem;
        font-weight: 400;

        &:hover {
            color: #d2232a;
            background-color: transparent;
        }
    }
    //down caret to open the selector (FAT)
    &.form-input-open {
        position: absolute;
        right: 1.9rem;
        border-radius: 0;
        padding: 5px;
        color: #d2232a;
        min-width: auto;
        background-color: transparent;
        border: none;
        border-bottom: 2px solid $grey44;
        border-right: 2px solid $grey44;
        margin: -3rem 0 0 0;
        font-size: 1.92rem;
        font-weight: 400;
        width: 10px;
        height: 10px;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);

        &:hover {
            color: #d2232a;
            background-color: transparent;
        }
    }
}

.multiselect-container {
    button.form-input-open {
        right: 0;
    }

    button.form-input-clear {
        right: 0.5rem;
    }
}

.content-wrapper {
    .form-group {
        width: 100%;

        .form-control {
            font-size: 1.6rem;
        }

        label {
            color: $black;
            margin-bottom: .8rem;
            font-size: 1.5rem;
        }

        input {
            background: $white;
            border: 1px solid $black;
            font-size: 1.5rem;
        }

        select {
            background: $white;
            border: 1px solid $black;
            font-size: 1.5rem;
        }
    }
}

.selectric {
    border-radius: .5rem;
    margin-bottom: 1rem;
    background: $white;
    border: 1px solid $grey70;

    .button {
        font-size: 0;
        background: $white;

        &::after {
            font-family: "Font Awesome 5 Free";
            content: "\f078" !important;
            font-weight: 700;
            font-size: 1rem;
            border: none;
            color: $grey60;
        }
    }

    .selectric-items li {
        background: $white;
    }
}
// Contact Form
.form-check-emailSignUp {
    display: flex !important;
}

.email-signup-header {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
        font-size: 3rem;
        color: $white;
    }

    .subtitle {
        color: $white;
    }
}

.modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;

    .emailSignupFooter {
        text-align: center;
        font-size: 3rem;
        color: $accent;
    }
}

#emailSignUpCheckbox {
    //font-size: 16px;
    //width: 120px;
    //height: max-content;
    margin-right: 10px;
    //align-content: end;
    align-self: flex-start;
    margin-top: 7px;
}
/*Checkbox and radio*/

.input-wrapper {
    font-size: 0px;
    line-height: 1;
}

.input-wrapper input[type=checkbox] {
    width: 20px;
    height: 20px;
    margin: 0;
    padding: 0;
    opacity: 0;
}

.input-wrapper input[type=checkbox] + label {
    display: inline-block;
    margin-left: -25px;
    margin-bottom: -1.5rem;
}

.input-wrapper input[type=checkbox] + label::before {
    font-family: "Font Awesome 5 Free";
    font-weight: 700;
    display: inline-block;
    min-width: 1em;
}

.input-wrapper input[type=checkbox] + label::before {
    content: "\f0c8";
    font-size: 3rem;
    font-weight: 400;
    color: $grey60;
}

.input-wrapper input[type=checkbox]:checked + label::before {
    content: "\f14a";
    font-size: 3rem;
    color: $accent;
}
