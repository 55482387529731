
//details ribbon
.media-link-list-component, .product-resources-section {
    background-color: $productRibbonBackgroundColor;
    color: $productRibbonTextColor;
    margin-top: 3rem;

    a {
        color: $productRibbonTextColor;
    }

    .icon {
        &::before {
            color: $productRibbonIconColor;
        }
    }
}

//product table
.product-table-component {

    .product-table {
        font-size: 1.2rem !important;
        overflow: auto;
        color: #3D3C3C;
        text-align: center;
        border-collapse: collapse;
    }

    .icon {
        &:hover {
            color: $accent;
            cursor: pointer;
        }
    }

    .cartSelect {
        &:hover {
            color: #EB642D;
            cursor: pointer;
        }
    }

    a {
        &:hover {
            color: #1c3E77;
        }
    }

    tr:nth-child(odd) {
        background-color: white;
    }

    tr:nth-child(even) {
        background-color: $grey90;
    }
}

.product-table-subheader {
    background-color: $grey90;
}

.product-table-thead {
    text-align: center;

    .sorting {
        position: relative;

        &:after {
            content: "\f0dc";
            color: #000;
            right: 5px;
            font-size: 1.5rem;
            top: 35%;
            position: absolute;
            font-family: "Font Awesome 5 Free";
        }
    }

    .sorting_asc {
        position: relative;

        &:after {
            content: "\f0de";
            color: #000;
            right: 5px;
            font-size: 1.5rem;
            top: 35%;
            position: absolute;
            font-family: "Font Awesome 5 Free";
        }
    }

    .sorting_desc {
        position: relative;

        &:after {
            content: "\f0dd";
            color: #000;
            right: 5px;
            font-size: 1.5rem;
            top: 35%;
            position: absolute;
            font-family: "Font Awesome 5 Free";
        }
    }
}

.dataTables_scrollBody {
    .sorting:after,
    .sorting_asc:after,
    .sorting_desc:after {
        display: none;
    }
}

.dataTables_wrapper {
    width: 100%;
}

.dataTables_wrapper .dataTables_scroll {
    overflow-x: auto;
    overflow-y: auto;
    width: 100%;
}

.dataTables_empty {
    display: none;
}

div.dataTables_scrollFootInner .dataTables_filter {
    display: none;
}

.product-table-tbody {
    td {
        text-align: center;
        border-right: 1px solid $grey60;
        border-bottom: 1px solid $grey60;

        &:last-child {
            border-right: 0px;
        }

        > a {
            text-decoration: underline;
            font-size: 1.5rem;
            font-weight: 500;
        }
    }

    .dropdown-wrapper {
        .new-list-dropdown {
            width: 232px;

            @media (max-width: 767px) {
                left: auto;
                right: 0;
            }


            .close-button {
                .close-icon {
                    font-size: 2rem;
                }
            }

            form {
                .form-control {
                    width: 100%;
                    max-width: 100%;
                    margin: 4px 0;
                    display: block;
                }

                .quantity-wrapper {
                    input {
                        padding: 0;
                    }
                }
            }
        }
    }
}

.product-table-datum {
    min-width: 75px;
}

.dataTable tbody th,
.dataTable tbody td {
    padding: 4px 5px;
    //color: #707070 !important;
    //font-size: 1.2rem;
}

table.dataTable thead th,
table.dataTable thead td {
    border: 0;
    padding: 5px 15px;
}

.bold {
    font-weight: bold;
}

table.dataTable thead th.white-text {
    color: #ffffff !important
}

.product-table-td-note {
    background: white;
    font-size: 12px;
    text-align: left;
    position: relative;
    font-style: italic;
}

.dataTables_info {
    display: none;
}


// HEADER FORMATTING FROM JSON
.hpc-inline {
    display: inline-block;
    vertical-align: middle;
}

.hpc-block {
    display: block;
    margin-left: 4px;
}

.hpc-top {
    font-size: 75%;
    font-weight: bold;
    margin-bottom: -5px;
}

.hpc-bottom {
    font-size: 75%;
    font-weight: bold;
    display: block;
    vertical-align: top;
    margin-top: -10px;
}
//cell borders
.t {
    border-top: 1px solid $grey60 !important;
}

.b {
    border-bottom: 1px solid $grey60 !important;
}

.l {
    border-left: 1px solid $grey60 !important;
}

.r {
    border-right: 1px solid $grey60 !important;
}

@media (min-width: 768px) {
    .dataTables_filter {
        display: none;
    }
}

@media (max-width: 767px) {

    #product-table-advanced-search {
        display: none;
    }

    .dataTables_scrollFoot {
        width: 100% !important;
    }

    .dataTables_wrapper {

        .dataTables_filter {
            margin-bottom: 1.5rem;
            float: right;
            margin-top: 0px;
            position: relative;

            input {
                display: inline-block;
                font-size: 1.6rem;
                padding: 0 4rem 0 2rem;
                font-family: $secondaryFont;
                font-weight: 400;
                border: none;
                background: $grey90;
                line-height: 3rem;
                height: 4rem;
                max-width: 200px;
                outline: none;

                &::-webkit-search-decoration,
                &::-webkit-search-cancel-button,
                &::-webkit-search-results-button,
                &::-webkit-search-results-decoration {
                    display: none;
                }
            }

            &:before {
                display: block;
                position: absolute;
                z-index: 0;
                right: 1rem;
                font-family: 'helical-icons';
                content: "\e93f";
                top: 50%;
                font-size: 2.8rem;
                transform: translateY(-50%);
                cursor: pointer;
            }
        }

        .dataTables_length {
            float: left;
            background: $grey90;
            height: 4rem;
            font-family: $secondaryFont;
            font-size: 1.6rem;
            padding: 1rem;
            color: $grey60;

            select {
                border: none;
                background: $grey90;
                color: $grey60;
                outline: none;
            }
        }

        .dataTables_paginate {
            margin-top: 0;
            padding-top: 0;
            display: none;

            .paginate_button {
                color: $accent !important;
                outline: none;

                &:hover {
                    opacity: 0.5;
                    border: 0px !important;
                    background: $white;
                    color: $accent !important;
                    box-shadow: none;
                }

                &.disabled {
                    color: $grey70 !important;
                    pointer-events: none;
                }
            }
        }
    }
}

/*.dataTables_scrollHead,
.dataTables_scrollBody,
.dataTables_scrollFoot {
overflow:unset!important;
}
.dataTables_scroll {
    overflow:scroll!important;
}*/


.media-link-list-component {

    .list-wrapper {
        padding: 1.3rem 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;

        @media (max-width: 768px) {
            display: none !important;
        }

        .list-item {
            font-size: 0;
            margin-right: 1.2rem;
            margin-bottom: 1rem;
            //white-space: pre-line;
            &:last-child {
                //margin-right: 0;
            }

            @media (max-width: 991px) {
                display: inline-block;
                margin-bottom: 2.3rem;

                &:last-child {
                    margin: 0;
                }
            }

            @media (max-width: 480px) {
                display: block;
            }

            .icon-wrapper {
                display: inline-block;
                overflow-x: hidden;
                vertical-align: middle;
                line-height: 1;

                .icon {
                    &::before {
                        font-size: 3.8rem;

                        @media (max-width: 991px) {
                            font-size: 3.2rem;
                        }
                    }
                }
            }

            .info-item {
                margin-left: 1.2rem;
                text-transform: uppercase;
                display: inline-block;
                font-family: $secondaryFont;
                font-size: 1.4rem;
                font-weight: 700;
                vertical-align: middle;
            }
        }
    }
}
