//SHARED by various account-related pages
.side-card-component {
    margin-left: 8.2rem;
    display: inline-block;
    margin-top: 21.3rem;
    @media (max-width: 1200px) {
        margin-left: 3rem;
    }
    @media (max-width: 767px) {
        max-width: 35rem;
        display: block;
        margin: 10rem auto;
    }
    .image-wrapper {
        min-width: 5rem;
        width: 100%;
        max-width: 5rem;
        .icon {
            color: $accent;
            font-size: 5.8rem;
        }
        img {
            width: 100%;
            max-height: 6rem;
        }
    }
    .title {
        font-family: $secondaryFont;
        font-size: 2.2rem;
        margin-bottom: 2.9rem;
        text-transform: none;
        @media (max-width: 1200px) {
            font-size: 2rem;
        }
    }
    .link-list {
        //margin-left: 2rem;
    }
    .list-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 6.9rem;
        .list-content {
            margin-left: 2rem;
            .subtitle {
                font-size: 2.1rem;
                @media (max-width: 1200px) {
                    font-size: 1.9rem;
                }
            }
            .description {
                color: $grey60;
                font-weight: 700;
                font-size: 1.7rem;
                max-width: 25.7rem;
                @media (max-width: 1200px) {
                    font-size: 1.5rem;
                }
            }
        }
    }
    .link-list {
        .list-item {
            display: block;
            color: $grey60;
            margin-bottom: 3rem;
            font-size: 1.7rem;
            font-weight: 700;
        }
        .link-item {
            color: $accent;
            font-family: $primaryFont;
            font-size: 1.7rem;
            font-weight: 700;
        }
    }
    .contact-wrapper {
        .subtitle {
            font-family: $secondaryFont;
            color: $grey60;
            font-size: 2rem;
            font-weight: 700;
            margin-bottom: 1.4rem;
        }
    }
}