//SHARED between product table and variant details pages
.icons-section-component {
    text-align: center;
    width: 50%;
    .icons-content {
        display: flex;
        flex-wrap: wrap;
        @media (max-width: 767px) {
            width: 14rem;
            margin: 0 auto;
            justify-content: space-between;
        }
    }
    .icon-wrapper {
        text-align: center;
        width: 8rem;
        margin-right: 4.2rem;
        margin-bottom: 3rem;
        @media (max-width: 767px) {
            width: 6rem;
            margin-right: 0;
        }
        &:last-child {
            margin: 0;
        }
        .icon-text {
            font-family: $primaryFont;
            color: $black;
            font-size: 1.2rem;
        }
    }
}