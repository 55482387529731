//SHARED by multiple blocks
.green-bg {
    background-color: $mainRibbonBackgroundColor;
    color: $mainRibbonTextColor;
}

.primary-ribbon {
    background-color: $mainRibbonBackgroundColor;
    color: $mainRibbonTextColor;
}


