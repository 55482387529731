﻿@media (min-width: 480px) {
    .safety-data-search {
        form {
            span {
                float: right;
            }
        }
    }
}

@media (max-width: 480px) {
    .safety-data-search {
        form {
            span {
                text-align: center;
            }

            .col-md-4 {
                text-align: center;
            }
        }
    }
}

.safety-data-search {
    form {
        margin-bottom: 15px;

        span {
            font-size: 16px;
        }

        input {
            padding-top: 25px;
            padding-bottom: 25px;
            font-size: 16px;
        }

        button.wide {
            width: 100% !important;
        }

        button, input {
            position: relative;
            display: block;
            margin: 5px auto;
        }
    }

    h2 {
        text-align: center;
        padding-top: 1.5rem;
        padding-bottom: 2rem;
        color: $accent;
    }
}


.sds-search-card {
    padding: 20px 5% 20px 5%;
    background-color: #ffffff;

    .container {
        padding: 0 5% 0 5%;
        background-color: #f9f9f9;
    }
}

.sds-card {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

    h2 {
        text-align: center;
        padding-top: 1.5rem;
        font-weight: 900;
        color: $grey60;
    }

    h3 {
        padding-bottom: 0rem;
        Padding-top: 0rem;
    }

    h4 {
        margin-bottom: 1.5rem;
        margin-top: 2.5rem;
    }

    hr {
        margin-top: 1.5rem;
        margin-bottom: 4rem;
    }

    a {
        font-family: $secondaryFont;
        font-size: 1.4rem;
        color: $accent !important;
        font-weight: 600;
        text-decoration-line: underline;
    }
}
