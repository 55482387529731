@charset "UTF-8";
/*************************************
HARVEY TOOL ---- Variables used across the website
*************************************/
/*$prinary-button-text-color: $main;
$primary-button-text-pressed-color: $black;
$primary-button-background-color: $white;
$primary-button-background-pressed-color: $white;
$primary-button-border-color: $accent;
$primary-button-border-pressed-color: $valorgreen;

$secondary-button-text-color: $white;
$secondary-button-text-pressed-color: $white;
$secondary-button-background-color: $main;
$secondary-button-background-pressed-color: $valorgreen;
$secondary-button-border-color: $main;
$secondary-button-border-pressed-color: $valorgreen;

$general-button-text-color: $white;
$general-button-text-pressed-color: $white;
$general-button-background-color: $accent;
$general-button-background-pressed-color: $accent;
$general-button-border-color: $accent;
$general-button-border-pressed-color: $accent;*/
.ha-uncoat {
  background: #b6b6b6;
}

.ha-tin {
  background: #F5B630;
}

.ha-altin {
  background: #87C6DD;
}

.ha-nano {
  background: #CCA8DC;
}

.ha-ti-hard {
  background: #8E989E;
}

.ha-ti-nano {
  background: #F68D62;
}

.ha-zrn {
  background: #FFE98F;
}

.ha-tib {
  background: #BCA491;
}

.ha-amor {
  background: #9BCF7E;
}

.ha-cvd {
  background: #B3AFD3;
}

.ha-pcd {
  background: #EBC6D4;
}

.light-gray-text {
  color: #b2b2b2;
}

.light-gray-bg {
  background: #b2b2b2;
}

.light-black-text {
  color: #333333;
}

.light-black-bg {
  background: #333333;
}

.lighter-gray-text {
  color: #e5e5e5;
}

.lighter-gray-bg {
  background: #e5e5e5;
}

.green-text {
  color: #1c3E77;
}

.dark-gray-text {
  color: #999999;
}

.dark-gray-bg {
  background: #999999;
}

.white-text {
  color: #ffffff;
}

.white-bg {
  background: #ffffff;
}

.highlight-bg {
  background: #fff33e;
}

/*************************************
Buttons 
*************************************/
button {
  border: none;
  background-color: #ffffff;
  outline: none;
  font-size: 1.5rem;
  font-weight: 700;
  border-radius: 0.4rem;
  padding: 1.8rem 3.2rem;
  text-transform: capitalize;
  line-height: 1;
}
button.small {
  padding: 1rem 2.2rem;
  font-weight: 700;
  text-align: center;
}
button.wide {
  padding: 1.5rem 0;
  width: 16.4rem;
  font-size: 1.6rem;
}

a.primary-button, a.secondary-button, a:disabled {
  display: inline-block;
  border: none;
  background-color: #ffffff;
  outline: none;
  font-size: 1.5rem;
  font-weight: 600;
  border-radius: 0.4rem;
  text-transform: capitalize;
  line-height: 1;
}
a:hover {
  text-decoration: none;
}
a.wide {
  padding: 1.5rem 0;
  width: 16.4rem;
  font-size: 1.6rem;
}

button.primary-button, button.secondary-button,
a.primary-button,
a.secondary-button {
  font-family: "Franklin Gothic URW", sans-serif;
  font-weight: 700;
  text-align: center;
}
button.primary-button,
a.primary-button {
  border: 2px solid #1c3E77;
  background-color: #1c3E77;
  border-color: #1c3E77;
  color: #ffffff;
  padding: 1.3rem 5rem;
}
button.primary-button.wide,
a.primary-button.wide {
  padding: 1.5rem 0;
  width: 16.4rem;
  font-size: 1.6rem;
}
button.primary-button:active,
a.primary-button:active {
  background-color: #1c3E77;
  border-color: #1c3E77;
  color: #ffffff;
}
button.primary-button:hover,
a.primary-button:hover {
  border-color: rgba(28, 62, 119, 0.8);
  background-color: rgba(28, 62, 119, 0.8);
}
button.primary-button:hover:active,
a.primary-button:hover:active {
  background-color: #1c3E77;
  border-color: #1c3E77;
  color: #ffffff;
}
button.primary-button:disabled,
a.primary-button:disabled {
  border: 2px solid #b2b2b2;
  background-color: #b2b2b2;
}
button.primary-button.outline,
a.primary-button.outline {
  background-color: transparent;
  border: 2px solid #1c3E77;
  color: #1c3E77;
}
button.primary-button.outline:hover,
a.primary-button.outline:hover {
  background-color: rgba(28, 62, 119, 0.5);
}
button.primary-button.outline:disabled,
a.primary-button.outline:disabled {
  opacity: 0.4;
}
button.primary-button.white-outline,
a.primary-button.white-outline {
  background-color: transparent;
  border: 2px solid #ffffff;
  color: #ffffff;
}
button.primary-button.white-outline:hover,
a.primary-button.white-outline:hover {
  background-color: rgba(255, 255, 255, 0.5);
}
button.primary-button.white-outline:disabled,
a.primary-button.white-outline:disabled {
  opacity: 0.4;
}
button.secondary-button,
a.secondary-button {
  border: 2px solid #999999;
  background-color: #999999;
  border-color: #999999;
  color: #ffffff;
}
button.secondary-button:active,
a.secondary-button:active {
  background-color: #999999;
  border-color: #999999;
  color: #ffffff;
}
button.secondary-button:hover,
a.secondary-button:hover {
  background-color: #666666;
  color: #ffffff;
}
button.secondary-button:disabled,
a.secondary-button:disabled {
  opacity: 0.4;
}
button.secondary-button:disabled:hover,
a.secondary-button:disabled:hover {
  background-color: transparent;
  color: #999999;
}
button.secondary-button.outline,
a.secondary-button.outline {
  background-color: transparent;
  border: 2px solid #999999;
  color: #000000;
}
button.secondary-button.outline:hover,
a.secondary-button.outline:hover {
  background-color: rgba(28, 62, 119, 0.5);
}
button.secondary-button.outline:disabled,
a.secondary-button.outline:disabled {
  opacity: 0.4;
}
button.secondary-button.outline:disabled:hover,
a.secondary-button.outline:disabled:hover {
  background-color: transparent;
  color: #000000;
}
button:disabled:hover,
a:disabled:hover {
  pointer-events: none;
  cursor: none;
}
button.link-button,
a.link-button {
  font-size: 1.3rem;
  font-weight: 400;
  font-family: "Franklin Gothic URW", sans-serif;
  padding: 0;
}

a:hover h3 {
  color: #1c3E77;
}

.button-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  min-width: 250px;
  padding: 5% 0;
}

.slick-prev:before,
.slick-next:before {
  color: #000000;
}

.slick-next:before {
  font-family: "Font Awesome 5 Free";
  content: "\f054";
}

.slick-prev:before {
  font-family: "Font Awesome 5 Free";
  content: "\f053";
}

.form-group {
  display: block;
  width: 100%;
}

.form-control {
  font-size: 1.6rem;
  height: 4.6rem;
}
.form-control::placeholder {
  font-family: "Franklin Gothic URW", sans-serif;
  font-weight: 600;
  font-size: 1.6rem;
}

.combo-popup {
  z-index: 30;
  position: absolute;
  border: 1px solid black;
  background-color: #e5e5e5;
  overflow-y: auto;
}

.multi-popup {
  z-index: 30;
  position: absolute;
  max-height: 200px;
  background-color: #e5e5e5;
  overflow-y: auto;
  overflow-x: hidden;
}
.multi-popup .row {
  margin: 0;
}

.row-bottom-gap {
  padding-bottom: 1.6rem;
}

button.password-eye {
  float: right;
  margin-top: -4.5rem;
  background: transparent;
  max-width: 30px;
  max-height: 1rem;
}
button.form-input-clear {
  position: absolute;
  right: 1rem;
  width: 25px;
  padding: 5px;
  color: #d2232a;
  min-width: auto;
  background-color: transparent;
  border: none;
  margin: -3rem 0 0 0;
  font-size: 1.92rem;
  font-weight: 400;
}
button.form-input-clear:hover {
  color: #d2232a;
  background-color: transparent;
}
button.form-input-open {
  position: absolute;
  right: 1.9rem;
  border-radius: 0;
  padding: 5px;
  color: #d2232a;
  min-width: auto;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #707070;
  border-right: 2px solid #707070;
  margin: -3rem 0 0 0;
  font-size: 1.92rem;
  font-weight: 400;
  width: 10px;
  height: 10px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
button.form-input-open:hover {
  color: #d2232a;
  background-color: transparent;
}

.multiselect-container button.form-input-open {
  right: 0;
}
.multiselect-container button.form-input-clear {
  right: 0.5rem;
}

.content-wrapper .form-group {
  width: 100%;
}
.content-wrapper .form-group .form-control {
  font-size: 1.6rem;
}
.content-wrapper .form-group label {
  color: #000000;
  margin-bottom: 0.8rem;
  font-size: 1.5rem;
}
.content-wrapper .form-group input {
  background: #ffffff;
  border: 1px solid #000000;
  font-size: 1.5rem;
}
.content-wrapper .form-group select {
  background: #ffffff;
  border: 1px solid #000000;
  font-size: 1.5rem;
}

.selectric {
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  background: #ffffff;
  border: 1px solid #b2b2b2;
}
.selectric .button {
  font-size: 0;
  background: #ffffff;
}
.selectric .button::after {
  font-family: "Font Awesome 5 Free";
  content: "\f078" !important;
  font-weight: 700;
  font-size: 1rem;
  border: none;
  color: #999999;
}
.selectric .selectric-items li {
  background: #ffffff;
}

.form-check-emailSignUp {
  display: flex !important;
}

.email-signup-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.email-signup-header .title {
  font-size: 3rem;
  color: #ffffff;
}
.email-signup-header .subtitle {
  color: #ffffff;
}

.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modal-body .emailSignupFooter {
  text-align: center;
  font-size: 3rem;
  color: #1c3E77;
}

#emailSignUpCheckbox {
  margin-right: 10px;
  align-self: flex-start;
  margin-top: 7px;
}

/*Checkbox and radio*/
.input-wrapper {
  font-size: 0px;
  line-height: 1;
}

.input-wrapper input[type=checkbox] {
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
  opacity: 0;
}

.input-wrapper input[type=checkbox] + label {
  display: inline-block;
  margin-left: -25px;
  margin-bottom: -1.5rem;
}

.input-wrapper input[type=checkbox] + label::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  display: inline-block;
  min-width: 1em;
}

.input-wrapper input[type=checkbox] + label::before {
  content: "\f0c8";
  font-size: 3rem;
  font-weight: 400;
  color: #999999;
}

.input-wrapper input[type=checkbox]:checked + label::before {
  content: "\f14a";
  font-size: 3rem;
  color: #1c3E77;
}

.modal-branding.modal.modal-static .modal-dialog {
  transform: none;
}
.modal-branding .modal-header .modal-title {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 2rem;
  color: #ffffff;
}
.modal-branding .modal-header .close {
  color: #ffffff;
}

/*************************************
TITAN ---- Font faces
*************************************/
body {
  font-family: "Franklin Gothic URW", sans-serif;
  font-size: 1.6rem;
}

hr {
  margin-top: 1.5rem;
  margin-bottom: 4rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.page-title {
  color: white !important;
}

.h1 {
  color: #999999;
  font-family: "Franklin Gothic URW", sans-serif;
  font-size: 3rem;
  font-weight: 900;
}

.h2 {
  font-family: "Franklin Gothic URW", sans-serif;
  font-weight: 700;
  font-style: normal;
  Font-size: 2.8rem;
}
.h2 a {
  color: #1c3E77 !important;
}

.h3 {
  font-family: "Franklin Gothic URW", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 2rem;
  color: #1c3E77;
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.h4 {
  font-family: "Franklin Gothic URW", sans-serif;
  font-weight: 600;
  font-size: 1.8rem;
  color: #8c9091;
}

p {
  color: #000000;
  font-size: 1.6rem;
}
p.small {
  color: #999999;
  font-size: 1.6rem;
}
p.large {
  font-size: 1.6rem;
}

/*a {
    font-weight: 600;
    text-decoration-line: underline;
    color: $accent;
}*/
.title {
  font-family: "Franklin Gothic URW", sans-serif;
  font-size: 3rem;
  font-weight: 900;
  color: #8c9091;
  text-transform: uppercase;
}

.productTitle {
  font-family: "Franklin Gothic URW", sans-serif;
  font-size: 3.4rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #707070;
}

.variantTitle {
  font-family: "Franklin Gothic URW", sans-serif;
  font-size: 3.4rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #707070;
}

.resourcePDP {
  font-family: "Franklin Gothic URW", sans-serif;
  font-size: 2.5rem;
  font-weight: 900;
  text-transform: uppercase;
}

.subtitle {
  font-family: "Franklin Gothic URW", sans-serif;
  font-size: 2rem;
  font-weight: 500;
}

.quote {
  font-family: "Franklin Gothic URW", sans-serif;
  font-style: italic;
  font-size: 2rem;
}

a {
  font-family: "Franklin Gothic URW", sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  color: #1c3E77;
}

ul {
  list-style: none;
}

.devider {
  background: #b2b2b2;
  margin: 0;
}

.error-text {
  color: #ff0000;
  font-size: 1.2rem;
}

.collapse-icon[aria-expanded=true]::after {
  content: "\f077";
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  padding-right: 1rem;
  position: absolute;
  right: 2rem;
}
.collapse-icon[aria-expanded=false]::after {
  content: "\f078";
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  padding-right: 1rem;
  position: absolute;
  right: 2rem;
}

.title-wrapper {
  padding: 7.7rem 0 1.6rem;
  border-bottom: 1px solid #e5e5e5;
}

.title-wrapper-small {
  text-align: center;
  padding: 1.8rem 0;
}
.title-wrapper-small .title {
  font-size: 2.2rem;
  line-height: 2.9rem;
  margin-bottom: 0;
}

/*************************************
Icons
*************************************/
@font-face {
  font-family: "helical-icons";
  src: url("/Content/fonts/vendor/icomoon/fonts/helical-icons.eot?xr9k2");
  src: url("/Content/fonts/vendor/icomoon/fonts/helical-icons.eot?xr9k2") format("embedded-opentype"), url("/Content/fonts/vendor/icomoon/fonts/helical-icons.ttf?xr9k2") format("truetype"), url("/Content/fonts/vendor/icomoon/fonts/helical-icons.woff?xr9k2") format("woff"), url("/Content/fonts/vendor/icomoon/fonts/helical-icons.svg?xr9k2") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "international-icons";
  src: url("/Content/images/countryIcons/global.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
.icon {
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
}
.icon:before, .icon:after {
  font-family: "helical-icons";
}

.fa-icon::before {
  font-family: "Font Awesome 5 Free";
}

.input-search-wrapper {
  position: relative;
}
.input-search-wrapper::before {
  display: block;
  position: absolute;
  z-index: 0;
  right: 1rem;
  font-family: "helical-icons";
  content: "\e93f";
  top: 50%;
  font-size: 2rem;
  font-weight: 700;
  transform: translateY(-50%);
  cursor: pointer;
}
.input-search-wrapper.hasCleard::before {
  display: none;
}

.clearable {
  position: relative;
}
.clearable .clear {
  display: none;
  background: transparent;
  position: absolute;
  color: #999999;
  padding: 0;
  font-family: "Franklin Gothic URW", sans-serif;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.icon-location:before {
  content: "\e900";
}

.icon-user:before {
  content: "\e902";
}

.icon-my-list:before {
  content: "\e903";
}

.icon-cam:before {
  content: "\e939";
}

.icon-customize:before {
  content: "\e93a";
}

.icon-email:before {
  content: "\e93e";
}

.icon-find-tool:before {
  content: "\e93f";
}

.icon-loupe:before {
  content: "\e940";
}

.icon-map:before {
  content: "\e941";
}

.icon-pdf:before {
  content: "\e942";
}

.icon-phone:before {
  content: "\e943";
}

.icon-sim:before {
  content: "\e944";
}

.icon-speed-feeds:before {
  content: "\e945";
}

.icon-star:before {
  content: "\e94e";
}

.icon-table:before {
  content: "\e94f";
}

.icon-phone1:before {
  content: "\e950";
}

.icon-phone-one:before {
  content: "\e903";
}

.icon-email-one:before {
  content: "\e904";
}

.icon-upload:before {
  content: "\e905";
}

.icon-shopping-cart:before {
  content: "๢";
  padding-right: 5px;
}

.icon-global:before {
  content: url("/../images/countryIcons/global.svg");
  padding-right: 5px;
}

.icon-Helical_Icons_Location-01:before {
  content: "\e904";
}

.icon-Helical_Icons_Location-011:before {
  content: "\e900";
}

/*************************************
ADA Compliant Focus
*************************************/
.tab-user a:focus, .tab-user .form-control:focus, .tab-user button:focus {
  outline: 1px dashed black;
  border-color: none;
  box-shadow: black;
}

@media (min-width: 480px) {
  .bootstrap-container-block .col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
}
@media (max-width: 480px) {
  .bootstrap-container-block .col {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.bootstrap-container-block .bootstrap-col-content {
  word-wrap: break-word;
}
.bootstrap-container-block .bootstrap-col-content a {
  color: #ffffff;
}
@media screen and (max-width: 768px) {
  .bootstrap-container-block .responsive-col-margin {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
}
@media screen and (max-width: 768px) {
  .bootstrap-container-block .responsive-col-border-width {
    width: 92% !important;
  }
}

.bootstrap-Container-Width {
  max-width: 191rem !important;
}

.card {
  display: block;
  max-width: 77.3rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.26);
}
.card .title-wrapper {
  padding: 1.6rem 6.3rem;
  background: #1c3E77;
  color: #ffffff;
  border-radius: 6px;
}
.card .title-wrapper.bg-light {
  background: #ffffff;
}
@media (max-width: 767px) {
  .card .title-wrapper {
    padding: 2.3rem 2.5rem;
  }
}
.card .title-wrapper .title {
  font-family: "Franklin Gothic URW", sans-serif;
  font-size: 3rem;
  text-transform: none;
  line-height: 1;
  margin-bottom: 0.2rem;
}
@media (max-width: 767px) {
  .card .title-wrapper .title {
    font-size: 2.5rem;
  }
}
.card .title-wrapper .subtitle {
  font-size: 1.5rem;
  line-height: 1;
}

.footer-component {
  background: #f8f8f8;
  border-top: 2px solid #f8f8f8;
  color: #1c3E77;
  font-size: 1.2rem;
  padding: 3rem 0 0;
  z-index: 1;
}
@media (max-width: 767px) {
  .footer-component > hr {
    display: none;
  }
}
@media (max-width: 767px) {
  .footer-component .desktop-footer {
    display: none;
  }
}
.footer-component a {
  color: inherit;
  font-size: 1.2rem;
}
.footer-component .footer-navigation {
  display: flex;
  margin-bottom: 2rem;
}
.footer-component .footer-navigation .title {
  text-transform: none;
  font-size: 1.6rem;
  color: #1c3E77;
}
.footer-component .contact-wrapper {
  padding: 0;
  margin: 0;
  width: 35%;
  display: inline-block;
}
.footer-component .contact-wrapper .address-wrapper {
  margin-top: 2rem;
}
.footer-component .menu-list {
  width: 65%;
  display: flex;
}
.footer-component .menu-list .menu-column {
  width: 33.33%;
  padding: 0 1rem;
}
.footer-component .social-content {
  max-width: 40rem;
  margin: 0 auto;
}
.footer-component .social-content .title {
  text-transform: none;
  font-size: 1.5rem;
  line-height: 1;
  color: #1c3E77;
}
.footer-component .social-content .subtitle {
  font-size: 1.3rem;
}
.footer-component .social-content .form-group {
  margin-bottom: 0.5rem;
  width: unset;
}
.footer-component .social-content .form-group .form-control {
  font-size: 1.6rem;
}
.footer-component .social-content .form-group .primary-button {
  margin-left: 3rem;
  padding: 1rem 2.4rem;
}
.footer-component .social-content .social-list-wrapper {
  display: flex;
  font-weight: 700;
  align-items: center;
}
.footer-component .social-content .social-list-wrapper span {
  margin-right: 1rem;
}
.footer-component .social-content .social-media-component {
  margin: 0;
}
@media (max-width: 767px) {
  .footer-component .social-content .social-media-component {
    display: flex;
    margin-bottom: 2rem !important;
  }
}
@media (max-width: 767px) {
  .footer-component .social-content .social-media-component .media-link {
    max-width: 3rem;
    margin: 0 0.1rem;
  }
}
@media (max-width: 1024px) {
  .footer-component .social-content #email-form .form-group input {
    max-width: 17rem;
  }
}
.footer-component .secondary-footer {
  padding-bottom: 1rem;
  padding-top: 1rem;
  background-color: #8c9091;
}
@media (max-width: 767px) {
  .footer-component .secondary-footer {
    display: none;
  }
}
.footer-component .secondary-footer .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer-component .mobile-footer {
  display: none;
}
.footer-component .mobile-footer .social-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #999999;
}
.footer-component .mobile-footer .subtitle {
  display: block;
  color: white;
  font-weight: 700;
  font-size: 1.6rem;
  margin-bottom: 1.4rem;
  font-family: "Franklin Gothic URW", sans-serif;
}
.footer-component .mobile-footer .social-media-component {
  margin-bottom: 2.4rem;
}
.footer-component .mobile-footer .primary-button {
  margin-bottom: 2.8rem;
  padding: 1.1rem 2.4rem;
}
.footer-component .mobile-footer .menu-list {
  width: 100%;
  max-width: 35rem;
  margin: 2rem auto;
}
.footer-component .mobile-footer .menu-list .menu-column {
  width: 50%;
  margin-left: 3rem;
}
.footer-component .mobile-footer .menu-list .menu-column a {
  line-height: 1.5rem;
  display: inline-block;
}
.footer-component .mobile-footer .menu-list .menu-column li {
  margin-bottom: 2px;
}
.footer-component .mobile-footer .contact-wrapper {
  width: 100%;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 1rem;
}
.footer-component .mobile-footer .contact-wrapper .title {
  color: #1c3E77;
  font-size: 1.6rem;
  text-transform: none;
}
@media (max-width: 767px) {
  .footer-component .mobile-footer {
    display: block;
  }
}
.footer-component .secondary-mobile-footer {
  text-align: center;
  padding-bottom: 1.6rem;
}
.footer-component hr {
  border-top: 1px solid #999999;
}
.footer-component .quote {
  font-size: 1.6rem;
  color: #ffffff;
  border: 1px #f8f8f8;
}
.footer-component .form-inline .form-control {
  padding-right: 3rem;
}
.footer-component .signed-up-wrapper {
  display: block;
}
.footer-component .signed-up-wrapper .link-item {
  display: block;
}

img.icon {
  width: 1.5rem;
  height: 1.5rem;
}

.post-footer {
  display: flex;
  padding: 2px;
  justify-content: space-between;
  height: 2rem;
  background: #8c9091;
}
.post-footer span {
  color: #ffffff;
}

.form-check {
  margin-bottom: 0.8rem;
  margin-top: 0.8rem;
}
.form-check .form-check-label {
  font-size: 1.2rem;
  color: #000000;
  margin-left: 1rem;
}
.form-check .form-check-label-accent {
  color: #1c3E77;
}

.form-control {
  font-size: 1.6rem;
  height: 4.6rem;
}
.form-control::placeholder {
  font-family: "Franklin Gothic URW", sans-serif;
  font-weight: 600;
  font-size: 1.6rem;
}

.form-radio-button {
  border: 1px solid black;
  padding: 1rem 2rem 0.25rem;
  border-radius: 0.25rem;
}

.multiselect-container {
  position: relative;
}

.multiselect-popover {
  position: absolute;
  z-index: 100;
  border: 1px solid black;
  background-color: #ffffff;
  max-height: 250px;
  overflow-y: scroll;
}

input.multiselect-input {
  background-color: transparent !important;
  width: 90%;
  z-index: 3;
  display: inline;
  position: relative;
  border: 1px solid transparent !important;
  font-size: 1.6rem;
  outline: none;
}

.multiselect-suggestion {
  background-color: white;
  position: absolute;
  display: flex;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 0;
  height: 4.6rem;
  padding-left: 0.75rem;
  border: 1px solid #000000;
  font-size: 1.6rem;
}

a.multiselect-icon {
  padding-top: 1.6rem;
  right: 0;
  position: absolute;
  font-size: 1.1rem;
  margin-right: -2.4rem;
}

.g-recaptcha {
  padding: 1rem 0;
}

.form-fields {
  display: grid;
}
.form-fields.columns-2 {
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.form-group label {
  color: #000000;
  padding-right: 10px;
}
.form-group .vertical_center {
  margin: 0;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.form-group input {
  background: #ffffff;
  border: 1px solid #000000;
}
.form-group .suggestion {
  background: #ffffff;
  border: 1px solid #000000;
}
.form-group select {
  background: #ffffff;
  border: 1px solid #000000;
}

.selectric {
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  background: #ffffff;
  border: 1px solid #b2b2b2;
}
.selectric .button {
  font-size: 0;
  background: #ffffff;
}
.selectric .button::after {
  font-family: "Font Awesome 5 Free";
  content: "\f078" !important;
  font-weight: 700;
  font-size: 1rem;
  border: none;
  color: #999999;
}
.selectric .selectric-items li {
  background: #ffffff;
}

.form-check-emailSignUp {
  display: flex !important;
}

#emailSignUpCheckbox {
  margin-right: 10px;
  align-self: flex-start;
  margin-top: 7px;
}

/*Checkbox and radio*/
.input-wrapper {
  font-size: 0px;
  line-height: 1;
}

.input-wrapper input[type=checkbox] {
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
  opacity: 0;
}

.input-wrapper input[type=checkbox] + label {
  display: inline-block;
  margin-left: -25px;
  margin-bottom: -1.5rem;
}

.input-wrapper input[type=checkbox] + label::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  display: inline-block;
  min-width: 1em;
}

.input-wrapper input[type=checkbox] + label::before {
  content: "\f0c8";
  font-size: 3rem;
  font-weight: 400;
  color: #999999;
}

.input-wrapper input[type=checkbox]:checked + label::before {
  content: "\f14a";
  font-size: 3rem;
  color: #1c3E77;
}

.content-wrapper .form-group {
  width: 100%;
}
.content-wrapper .form-group input ~ label {
  padding-left: 0.5rem;
}

.offscreen {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.pre-header {
  background: #8c9091;
  display: flex;
  justify-content: space-between;
  height: 3.6rem;
}
.pre-header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.brand-logo-component {
  padding-left: 2rem;
  display: flex;
  align-items: center;
}
.brand-logo-component li {
  display: inline;
}
.brand-logo-component .brand-logo {
  margin-right: 2rem;
}
.brand-logo-component .brand-logo img {
  max-height: 2rem;
}
.brand-logo-component .brand-logo-image {
  max-height: 1.3rem;
}

.international-header-component {
  max-height: 1.25rem;
  height: auto;
  margin-right: 1.25rem;
  vertical-align: middle;
}
.international-header-component .int-flag-icon-tiny {
  max-height: 1.7rem;
  height: auto;
  padding-left: 0.5rem;
}
.international-header-component a {
  display: flex;
  font-size: 1.2rem;
  height: auto;
  color: #ffffff;
  text-wrap: nowrap;
}

.header-component {
  font-size: 1.6rem;
  background-color: #ffffff;
  border-bottom: 2px solid #b2b2b2;
}
@media (max-width: 767px) {
  .header-component {
    border: none;
    padding-bottom: 0;
  }
}
.header-component .form-group {
  margin-bottom: 0;
}
.header-component #skip {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  margin-left: 0;
  text-align: center;
  list-style: outside none;
}
.header-component #skip li {
  list-style-type: none;
}
.header-component #skip li a {
  position: absolute;
  left: -99999px;
  top: 50px;
}
.header-component #skip li a:focus, .header-component #skip li a:active {
  position: relative;
  left: 0;
  z-index: 9999;
  width: auto;
  height: auto;
  margin: 0 auto;
  padding: 5px 20px;
  text-align: center;
  text-decoration: none;
  font: bold 30px mr-eaves-sans, sans-serif;
  border: none;
  /* color information, edit this to suit the colors of your layout */
  color: #ffffff;
  background: #055dfd;
  float: left;
  outline: none;
}
.header-component .category-link {
  font-size: 1.6rem;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0;
  color: #1c3E77;
}
@media (min-width: 768px) {
  .header-component .category-link {
    color: #1c3E77;
  }
}
.header-component .category-link:hover, .header-component .category-link:active, .header-component .category-link:focus {
  color: #1c3E77;
  text-decoration: none;
  border-bottom: 3px solid #1c3E77;
}
.header-component .desktop-nav .container {
  display: flex;
  justify-content: space-between;
  margin: 2rem auto 0;
}
.header-component .desktop-nav .container .nav-content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.header-component .desktop-nav .container .nav-brand {
  width: 32rem;
  min-width: 20rem;
}
@media (min-width: 1401px) {
  .header-component .desktop-nav .container .nav-brand {
    width: 40rem;
  }
}
.header-component .desktop-nav .container .primary-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem 3.2rem;
}
.header-component .desktop-nav .container .primary-nav .nav-item .category-link {
  font-size: 1.6rem;
}
.header-component .desktop-nav .container .primary-nav .nav-item .submenu {
  display: none;
}
.header-component .desktop-nav .container .secondary-nav {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-bottom: 2.2rem;
  gap: 2rem;
}
.header-component .desktop-nav .container .secondary-nav .separator {
  margin: 0;
  display: inline-block;
}
.header-component .desktop-nav .container .secondary-nav .menu-link {
  color: #000000;
  display: flex;
  align-items: center;
}
.header-component .desktop-nav .container .secondary-nav .menu-link:active {
  color: #1c3E77 !important;
}
.header-component .desktop-nav .container .secondary-nav .menu-link:hover, .header-component .desktop-nav .container .secondary-nav .menu-link:focus {
  color: #1c3E77 !important;
}
.header-component .desktop-nav .container .secondary-nav .menu-link:hover::after, .header-component .desktop-nav .container .secondary-nav .menu-link:active::after, .header-component .desktop-nav .container .secondary-nav .menu-link:focus::after {
  display: block;
}
.header-component .desktop-nav .container .secondary-nav .menu-link:last-child {
  margin-right: 0;
}
.header-component .desktop-nav .container .secondary-nav .menu-link.icon:before {
  margin-right: 0.5rem;
  font-size: 2.6rem;
}
@media (max-width: 1240px) {
  .header-component .desktop-nav .container .secondary-nav .menu-link.icon:before {
    font-size: 2rem;
  }
}
.header-component .desktop-nav .container .secondary-nav .menu-link:after {
  display: none;
  content: " ";
  width: calc(100% - 2.4rem);
  height: 0.3rem;
  background: #1c3E77;
  position: absolute;
  bottom: 0;
  right: 0;
}
.header-component .desktop-nav .container .secondary-nav .dropdown-wrapper {
  z-index: 30;
}
.header-component .desktop-nav .container .secondary-nav .account-dropdown {
  padding: 3rem 4rem 1rem 1.5rem;
  background: #ffffff;
  position: absolute;
  left: 0;
  text-align: left;
  top: 4rem;
  box-shadow: 0.5px 0.5px 5px 0px rgb(51, 51, 51);
}
.header-component .desktop-nav .container .secondary-nav .search-bar .form-control {
  font-size: 1.6rem;
  width: 32rem;
}
.header-component .desktop-nav .container .secondary-nav .search-bar #suggestion-box {
  text-align: left;
  background: #ffffff;
  color: #999999;
  padding: 1rem 2rem 2rem;
  font-size: 1.6rem;
  font-family: "Franklin Gothic URW", sans-serif;
  position: absolute;
  left: 0;
  width: 100%;
  top: 43px;
  z-index: 42;
  box-shadow: 0 0.5px 5px 0px #333333;
}
.header-component .desktop-nav .container .secondary-nav .search-bar #suggestion-box h3 {
  color: #999999;
  font-size: 1.6rem;
  font-family: "Franklin Gothic URW", sans-serif;
  border-bottom: 1px solid #cccccc;
}
.header-component .desktop-nav .container .secondary-nav .search-bar #suggestion-box .box {
  margin-top: 1.5rem;
}
.header-component .desktop-nav .container .secondary-nav .search-bar #suggestion-box .box:nth-child(1) {
  margin-top: 0;
}
.header-component .desktop-nav .container .secondary-nav .search-bar #suggestion-box ul li {
  display: inline-block;
  margin-bottom: 2px;
  width: 100%;
}
.header-component .desktop-nav .container .secondary-nav .search-bar #suggestion-box ul li a {
  color: #999999;
  font-size: 1.6rem;
  font-family: "Franklin Gothic URW", sans-serif;
  line-height: 1;
  margin-bottom: 2px;
  display: inline-block;
  border-bottom: 2px solid transparent;
}
.header-component .desktop-nav .container .secondary-nav .search-bar #suggestion-box ul li a:hover {
  border-bottom: 2px solid #1c3E77;
}
.header-component .desktop-nav .container .secondary-nav .search-bar #suggestion-box ul li a span {
  font-size: 1.2rem;
  display: block;
}
.header-component .desktop-nav .container .secondary-nav .search-bar #suggestion-box .more-suggestions {
  font-style: italic;
  font-size: 1.6rem;
  font-family: "Franklin Gothic URW", sans-serif;
  color: #999999;
}
.header-component .desktop-nav .container .secondary-nav .search-bar #suggestion-box .more-suggestions:hover {
  color: #1c3E77;
}
@media (max-width: 767px) {
  .header-component .desktop-nav {
    display: none;
  }
}
.header-component .user-name {
  white-space: nowrap;
  margin-bottom: 1rem;
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.header-component .account-link {
  display: block;
  margin-bottom: 0.5rem;
  color: #999999;
}
.header-component .account-link:last-child {
  margin: 0;
}
.header-component .mobile-nav {
  display: none;
  padding: 1rem 0;
  position: relative;
}
@media (max-width: 767px) {
  .header-component .mobile-nav {
    display: block;
  }
  .header-component .mobile-nav > .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.header-component .mobile-nav .menu-toggler {
  padding: 0.5rem;
  outline: none;
}
.header-component .mobile-nav .menu-toggler .icon-menu:before {
  content: "\f0c9";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 2.5rem;
  color: #1c3E77;
}
.header-component .mobile-nav .nav-brand {
  width: 15rem;
  transform: translateX(2.5rem);
}
.header-component .mobile-nav .account-dropdown-mobile {
  display: none;
  position: absolute;
  padding: 2rem;
  padding: 1.4rem 2rem 2.2rem;
  left: 0;
  right: 0;
  background: #ffffff;
  border-bottom: 1px solid #999999;
  flex-direction: column;
  align-items: flex-start;
  z-index: 99;
}
.header-component .mobile-nav .account-dropdown-mobile .user-name {
  font-size: 2rem;
}
.header-component .mobile-nav .account-dropdown-mobile .close-button-account {
  position: absolute;
  right: 16px;
  margin: 0;
  z-index: 2;
  top: 0;
}
.header-component .mobile-nav .mobile-icons {
  display: flex;
}
.header-component .mobile-nav .mobile-icons li {
  margin-left: 1.5rem;
}
.header-component .mobile-nav .mobile-icons li:first-child {
  margin: 0;
}
.header-component .mobile-nav .mobile-icons .icon {
  font-size: 2.5rem;
}
.header-component .mobile-nav .mobile-icons .icon-search:before {
  content: "\f002";
}
.header-component .mobile-nav .register-dropdown {
  right: 0;
  left: auto;
  transform: translateX(0);
  width: 100%;
  display: block;
  top: 3.7rem;
  box-shadow: none;
}
.header-component .mobile-nav .secondary-nav .input-search-wrapper {
  z-index: 999;
  width: 100%;
}
.header-component .mobile-nav .secondary-nav .search-bar {
  position: relative;
}
.header-component .mobile-nav .secondary-nav .search-bar .input-search-wrapper {
  border: 1px solid #999999;
}
.header-component .mobile-nav .secondary-nav .search-bar .input-search-wrapper input {
  font-size: 1.6rem;
}
.header-component .mobile-nav .secondary-nav .search-bar .input-search-wrapper input:focus {
  background: #e5e5e5;
}
.header-component .mobile-nav .secondary-nav .search-bar #suggestion-box {
  text-align: left;
  background: #ffffff;
  color: #999999;
  padding: 1rem 2rem 2rem;
  font-size: 1.6rem;
  font-family: "Franklin Gothic URW", sans-serif;
  position: absolute;
  left: 0;
  width: 100%;
  top: 55px;
  z-index: 999;
}
.header-component .mobile-nav .secondary-nav .search-bar #suggestion-box h3 {
  color: #999999;
  font-size: 1.6rem;
  font-family: "Franklin Gothic URW", sans-serif;
  border-bottom: 1px solid #cccccc;
}
.header-component .mobile-nav .secondary-nav .search-bar #suggestion-box .box {
  margin-top: 1.5rem;
}
.header-component .mobile-nav .secondary-nav .search-bar #suggestion-box .box:nth-child(1) {
  margin-top: 0;
}
.header-component .mobile-nav .secondary-nav .search-bar #suggestion-box ul li {
  display: inline-block;
  margin-bottom: 2px;
  width: 100%;
  padding-left: 2rem;
}
.header-component .mobile-nav .secondary-nav .search-bar #suggestion-box ul li a {
  color: #999999;
  font-size: 1.6rem;
  font-family: "Franklin Gothic URW", sans-serif;
  line-height: 2.2rem;
  display: inline-block;
  border-bottom: 2px solid transparent;
}
.header-component .mobile-nav .secondary-nav .search-bar #suggestion-box ul li a:hover {
  border-bottom: 2px solid #1c3E77;
}
.header-component .mobile-nav .secondary-nav .search-bar #suggestion-box ul li a span {
  font-size: 1.2rem;
  display: block;
}
.header-component .mobile-nav .secondary-nav .search-bar #suggestion-box .more-suggestions {
  font-style: italic;
  font-size: 1.6rem;
  font-family: "Franklin Gothic URW", sans-serif;
  color: #999999;
}
.header-component .mobile-nav .secondary-nav .search-bar #suggestion-box .more-suggestions:hover {
  color: #1c3E77;
}
.header-component .register-dropdown {
  width: 22.6rem;
  position: absolute;
  left: 0;
  background: #ffffff;
  text-align: left;
  padding: 1.8rem;
  z-index: 1;
  top: 4rem;
  box-shadow: 0.5px 0.5px 5px 0px rgb(51, 51, 51);
}
@media (max-width: 1600px) {
  .header-component .register-dropdown {
    right: 0;
    left: auto;
  }
}
.header-component .register-dropdown .primary-button {
  width: 100%;
}
.header-component .register-dropdown a.account-link {
  display: block;
  border: none;
}
.header-component .register-dropdown a.account-link:hover {
  border: none;
}
.header-component .register-dropdown a {
  display: inline-block;
  border-bottom: 2px solid transparent;
}
.header-component .register-dropdown a.primary-button.outline {
  width: 100%;
  border: 2px solid #1c3E77;
}
.header-component .register-dropdown a.primary-button.outline:hover {
  border: 2px solid #1c3E77;
}
.header-component .register-dropdown a:hover {
  color: #1c3E77;
  border-bottom-color: #1c3E77;
}
.header-component .register-dropdown .form-group {
  margin-bottom: 0.25rem;
}
.header-component .register-dropdown .form-group.text-right {
  margin-bottom: 0.5rem;
}
.header-component .register-dropdown .form-control {
  width: 100%;
  border: 1px solid #000000;
  background-color: #ffffff;
  font-size: 1.6rem;
  height: 4.6rem;
}

.primary-nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 1rem 3.2rem;
}
.primary-nav .nav-item {
  margin-left: 1.4rem;
  margin-bottom: 1.2rem;
}
@media (max-width: 1240px) {
  .primary-nav .nav-item {
    margin-left: 2.4rem;
  }
}
@media (max-width: 992px) {
  .primary-nav .nav-item {
    margin-left: 1.1rem;
  }
}
.primary-nav .nav-item:first-child {
  margin-right: 0;
}
.primary-nav .nav-item .category-link {
  font-family: "Franklin Gothic URW", sans-serif;
  font-size: 1.6rem;
}
.primary-nav .nav-item .submenu {
  display: none;
}

.secondary-nav {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-bottom: 2.2rem;
  position: relative;
}
.secondary-nav .separator {
  margin: 0;
  display: inline-block;
}
.secondary-nav .primary-button {
  font-size: 1.5rem;
  padding: 1.25rem 2rem;
  text-align: center;
  max-width: 100% !important;
}
.secondary-nav .form-group {
  margin: 0;
}
.secondary-nav .form-control {
  display: inline-block;
  font-size: 1.6rem;
  width: 28rem;
}
.secondary-nav .form-control#signupEmail {
  font-size: 1.3rem;
}
.secondary-nav .menu-link {
  color: black;
  display: flex;
  position: relative;
  align-items: center;
  margin-left: 1rem;
  white-space: nowrap;
  font-family: "Franklin Gothic URW", sans-serif !important;
  z-index: 10;
  /*@media (max-width: 904px) {
              margin-top: 2rem;
          }*/
}
.secondary-nav .menu-link:active {
  color: #1c3E77;
}
.secondary-nav .menu-link:hover {
  color: #1c3E77;
}
.secondary-nav .menu-link:hover::after, .secondary-nav .menu-link:active::after, .secondary-nav .menu-link:focus::after {
  display: block;
}
.secondary-nav .menu-link:last-child {
  margin-right: 0;
}
.secondary-nav .menu-link.icon:before {
  margin-right: 0.5rem;
  font-size: 2.6rem;
}
@media (max-width: 1240px) {
  .secondary-nav .menu-link.icon:before {
    font-size: 2rem;
  }
}
.secondary-nav .menu-link:after {
  display: none;
  content: " ";
  width: calc(100% - 2.4rem);
  height: 0.3rem;
  background: #1c3E77;
  position: absolute;
  bottom: 0;
  right: 0;
}
.secondary-nav .dropdown-wrapper {
  display: inline-block;
  position: relative;
  z-index: 30;
}
.secondary-nav .account-dropdown {
  padding: 3rem 4rem 1rem 1.5rem;
  background: #ffffff;
  position: absolute;
  left: 0;
  text-align: left;
  top: 4rem;
  -webkit-box-shadow: 0.5px 0.5px 5px 0px rgb(51, 51, 51);
  -moz-box-shadow: 0.5px 0.5px 5px 0px rgb(51, 51, 51);
  box-shadow: 0.5px 0.5px 5px 0px rgb(51, 51, 51);
}
.secondary-nav .search-bar {
  position: relative;
}
.secondary-nav .search-bar #suggestion-box {
  text-align: left;
  background: #ffffff;
  color: #999999;
  padding: 1rem 2rem 2rem;
  font-size: 1.6rem;
  font-family: "Franklin Gothic URW", sans-serif;
  position: absolute;
  left: 0;
  width: 100%;
  top: 43px;
  z-index: 42;
  box-shadow: 0 0.5px 5px 0px #333333;
}
.secondary-nav .search-bar #suggestion-box h3 {
  color: #999999;
  font-size: 1.6rem;
  font-family: "Franklin Gothic URW", sans-serif;
  border-bottom: 1px solid #cccccc;
}
.secondary-nav .search-bar #suggestion-box .box {
  margin-top: 1.5rem;
}
.secondary-nav .search-bar #suggestion-box .box:nth-child(1) {
  margin-top: 0;
}
.secondary-nav .search-bar #suggestion-box ul li {
  display: inline-block;
  margin-bottom: 2px;
  width: 100%;
}
.secondary-nav .search-bar #suggestion-box ul li a {
  color: #999999;
  font-size: 1.6rem;
  font-family: "Franklin Gothic URW", sans-serif;
  line-height: 1;
  margin-bottom: 2px;
  display: inline-block;
  border-bottom: 2px solid transparent;
}
.secondary-nav .search-bar #suggestion-box ul li a:hover {
  border-bottom: 2px solid #1c3E77;
}
.secondary-nav .search-bar #suggestion-box ul li a span {
  font-size: 1.2rem;
  display: block;
}
.secondary-nav .search-bar #suggestion-box .more-suggestions {
  font-style: italic;
  font-size: 1.6rem;
  font-family: "Franklin Gothic URW", sans-serif;
  color: #999999;
}
.secondary-nav .search-bar #suggestion-box .more-suggestions:hover {
  color: #1c3E77;
}

.hero-slider-component {
  position: relative;
  z-index: 1;
}
.hero-slider-component .slick-list, .hero-slider-component .slick-slide {
  height: 280px;
}
@media (min-width: 768px) {
  .hero-slider-component .slick-list, .hero-slider-component .slick-slide {
    height: 425px;
  }
}
@media (min-width: 1024px) {
  .hero-slider-component .slick-list, .hero-slider-component .slick-slide {
    height: 525px;
  }
}
.hero-slider-component .slide {
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center center;
  cursor: pointer;
  position: relative;
}
.hero-slider-component .slide .content-slide {
  position: absolute;
  width: 100%;
}
.hero-slider-component .slide .content-slide.to-bottom {
  bottom: 5px;
}
.hero-slider-component .slide a {
  display: block;
  width: 100%;
}
.hero-slider-component .slide .link-slide {
  display: block;
  background: pink;
}
.hero-slider-component .slide .content-wrapper {
  max-width: 55%;
  line-height: 1.2;
}
.hero-slider-component .slide .content-wrapper .title {
  color: #1c3E77;
  font-size: 2.5rem;
  line-height: 1;
  margin-bottom: 1.5rem;
}
@media (min-width: 768px) {
  .hero-slider-component .slide .content-wrapper .title {
    font-size: 3.75rem;
  }
}
@media (min-width: 1024px) {
  .hero-slider-component .slide .content-wrapper .title {
    font-size: 6rem;
    margin-bottom: 2.5rem;
  }
}
@media (min-width: 1440px) {
  .hero-slider-component .slide .content-wrapper .title {
    font-size: 8rem;
    margin-bottom: 4rem;
  }
}
.hero-slider-component .slide .content-wrapper .subtitle {
  color: #ffffff;
  font-size: 1.5rem;
  margin-bottom: 2rem;
}
@media (min-width: 1024px) {
  .hero-slider-component .slide .content-wrapper .subtitle {
    font-size: 2.5rem;
    margin-bottom: 3rem;
  }
}
@media (min-width: 1440px) {
  .hero-slider-component .slide .content-wrapper .subtitle {
    margin-bottom: 4rem;
  }
}
.hero-slider-component .slide.content-right {
  text-align: right;
}
.hero-slider-component .slide.content-right .content-wrapper {
  text-align: left;
  display: inline-block;
  width: 40%;
}
.hero-slider-component .primary-button {
  width: 23rem;
  padding: 2.3rem 0;
  text-align: center;
  font-size: 2rem;
  background: #1c3E77;
  outline: none;
  font-weight: 600;
  border-radius: 0.4rem;
  text-transform: capitalize;
  line-height: 1;
}
@media (max-width: 767px) {
  .hero-slider-component .primary-button {
    padding: 1.2rem 1.7rem;
    width: auto;
  }
}
.hero-slider-component p.primary-button {
  border: 2px solid #1c3E77;
  background: #1c3E77;
  color: #ffffff;
}
.hero-slider-component p.primary-button:hover {
  background: rgba(28, 62, 119, 0.8);
}
.hero-slider-component p.primary-button.disabled {
  border: 2px solid #b2b2b2;
  background: #b2b2b2;
}
.hero-slider-component p.primary-button.outline {
  background: transparent;
  border: 2px solid #1c3E77;
  color: #1c3E77;
}
.hero-slider-component p.primary-button.outline:hover {
  background: rgba(28, 62, 119, 0.5);
}
.hero-slider-component p.primary-button.outline.disabled {
  opacity: 0.4;
}
.hero-slider-component p.primary-button.white-outline {
  background: transparent;
  border: 2px solid #ffffff;
  color: #ffffff;
}
.hero-slider-component p.primary-button.white-outline:hover {
  background: rgba(255, 255, 255, 0.5);
}
.hero-slider-component p.primary-button.white-outline.disabled {
  opacity: 0.4;
}
.hero-slider-component p.button-align-right {
  float: right;
}
.hero-slider-component .secondary-button {
  background: transparent;
}
@media (max-width: 767px) {
  .hero-slider-component .secondary-button {
    padding: 1.2rem 1.7rem;
    width: auto;
  }
}
@media (max-width: 767px) {
  .hero-slider-component .slick-prev {
    display: none;
  }
}
.hero-slider-component .slick-prev,
.hero-slider-component .slick-next {
  width: 5rem;
  height: 5rem;
  z-index: 1000;
  background-color: transparent;
}
.hero-slider-component .slick-prev::before,
.hero-slider-component .slick-next::before {
  font-size: 4rem;
  color: #606060;
  opacity: 80%;
}
@media (max-width: 1600px) {
  .hero-slider-component .slick-prev,
  .hero-slider-component .slick-next {
    display: none;
  }
}
.hero-slider-component .slick-next {
  right: 4rem;
}
.hero-slider-component .slick-prev {
  left: 4rem;
}
.hero-slider-component .slick-slider {
  margin-bottom: 0;
}
.hero-slider-component .slick-dots {
  bottom: 1rem;
}
.hero-slider-component .slick-dots li button::before {
  font-size: 3rem;
}
.hero-slider-component video {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 120%;
}

.icon-stripe-component {
  padding: 2.5rem 0;
}
.icon-stripe-component .container {
  display: flex;
}
@media (max-width: 767px) {
  .icon-stripe-component .container {
    flex-wrap: wrap;
  }
}
.icon-stripe-component .icon-element {
  display: flex;
  align-items: center;
  justify-items: center;
  width: 25%;
}
@media (max-width: 767px) {
  .icon-stripe-component .icon-element {
    width: 50%;
    margin: 1rem 0;
  }
}
.icon-stripe-component .icon-element .stripe-icon {
  width: 6rem;
  height: 6rem;
  color: #ffffff;
  margin-right: 1.5rem;
}
@media (max-width: 767px) {
  .icon-stripe-component .icon-element .stripe-icon {
    font-size: 4rem;
  }
}
.icon-stripe-component .icon-element .description {
  font-style: italic;
}
@media (max-width: 767px) {
  .icon-stripe-component .icon-element .description {
    font-size: 1.4rem;
  }
}

.megamenu {
  font-size: 1.8rem;
  font-weight: 400;
  position: absolute;
  left: 0;
  right: 0;
  text-align: left;
  padding-top: 3rem;
  z-index: 10;
  display: none;
  background-color: #ffffff;
  margin-top: 1rem;
}
.megamenu .subtitle {
  color: #000000;
  font-size: 1.8rem;
  display: block;
  margin-bottom: 1.5rem;
}
.megamenu .title {
  display: inline-block;
  font-size: 2rem;
  font-weight: 400;
  color: #1c3E77;
  text-transform: none;
  margin-bottom: 1.5rem;
}
.megamenu .title-big {
  color: #1c3E77;
  font-size: 2.5rem;
  font-weight: 400;
  text-transform: none;
  margin-bottom: 1.9rem;
}
.megamenu .menu-column.two-menu-column:first-child {
  width: 33%;
  display: inline-block;
  padding: 0 15px;
  margin-right: 0;
}
.megamenu .menu-column.two-menu-column:not(:first-child) {
  width: 66%;
  display: inline-block;
  padding: 0 15px;
}
.megamenu .menu-column.three-menu-column:first-child {
  width: 33%;
  display: inline-block;
  padding: 0 15px;
  margin-right: 0;
}
.megamenu .menu-column.three-menu-column:not(:first-child) {
  width: 33%;
  display: inline-block;
  padding: 0 15px;
}
.megamenu .menu-column.four-menu-column:first-child {
  width: 25%;
  display: inline-block;
  padding: 0 15px;
  margin-right: 0;
}
.megamenu .menu-column.four-menu-column:not(:first-child) {
  width: 25%;
  display: inline-block;
  padding: 0 15px;
}
.megamenu .menu-column.five-menu-column:first-child {
  width: 32%;
  display: inline-block;
  padding: 0 15px;
  margin-right: 0;
}
.megamenu .menu-column.five-menu-column:not(:first-child) {
  width: 17%;
  display: inline-block;
  padding: 0 15px;
}
.megamenu .submenu-content {
  padding-left: 1.5rem;
  padding-bottom: 10px;
}
@media (max-width: 767px) {
  .megamenu .submenu-content {
    margin-bottom: 2rem;
  }
  .megamenu .submenu-content:last-child {
    margin-bottom: 0;
  }
}
.megamenu .submenu-content .contact-item {
  margin-bottom: 1rem;
}
.megamenu .submenu-content .contact-item a {
  font-size: 1.8rem;
}
.megamenu .submenu-content .telephone-wrapper {
  margin-bottom: 3rem;
}
.megamenu .item-link {
  font-weight: 700;
  color: #1c3E77;
}
.megamenu .item-link:hover, .megamenu .item-link:active, .megamenu .item-link:focus {
  color: #1c3E77;
  text-decoration: none;
  border-bottom: 3px solid #1c3E77;
}
.megamenu .item-link:visited {
  color: #1c3E77;
  text-decoration: none;
}
.megamenu .list-item .description {
  font-size: 1.6rem;
  color: #8c9091;
}
.megamenu .list-item .item-link {
  font-size: 2rem;
  color: #8c9091;
  display: block;
  margin-bottom: 0.5rem;
}
.megamenu .list-item .item-link:hover, .megamenu .list-item .item-link:active, .megamenu .list-item .item-link:focus, .megamenu .list-item .item-link:visited {
  border: none;
}
.megamenu .subcategory-link, .megamenu .subcategory-link-hover {
  display: block;
  margin-bottom: 0.6rem;
  font-size: 1.6rem;
  color: #000000;
  /*pointer-events:none;*/
}
.megamenu .media-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2rem;
}
.megamenu .media-wrapper:hover, .megamenu .media-wrapper:focus, .megamenu .media-wrapper:active {
  text-decoration: none;
}
.megamenu .media-wrapper .media-img {
  min-width: 3.3rem;
  margin-right: 2rem;
  max-width: 10rem;
  max-height: 15rem;
}
.megamenu .media-wrapper .media-img.tight {
  max-width: 3.3rem;
}
.megamenu .media-wrapper .content .title-link {
  font-size: 2rem;
  max-width: 13.4rem;
  line-height: 3.1rem;
}
.megamenu .media-wrapper .content .title-link + .description {
  font-size: 1.6rem;
  line-height: 2.1rem;
  max-width: 36rem;
  margin-top: 1rem;
}
.megamenu .featured-media-wrapper .title {
  font-size: 1.8rem;
  color: #000000;
  line-height: 2.3rem;
}
.megamenu .featured-media-wrapper .description {
  font-size: 1.5rem;
  color: #000000;
  line-height: 1.9rem;
}
.megamenu .product-list {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 14px;
  gap: 1rem 2rem;
}

.close-button {
  padding: 0;
  border: none;
  margin: 1rem 3rem;
}
.close-button .close-icon {
  font-size: 3.5rem;
  color: #1c3E77;
}
.close-button .close-icon::before {
  content: "\f00d";
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
}
.close-button-account {
  position: absolute;
  right: 3rem;
  margin: 0;
  z-index: 2;
}

.megamenu-mobile {
  text-align: right;
  padding: 0;
  padding-bottom: 20rem;
  border-bottom: 1px solid #b2b2b2;
  background-color: #ffffff;
}
.megamenu-mobile .container {
  padding: 0;
  align-items: flex-end !important;
  position: relative;
  text-align: right;
  flex-direction: column;
}
.megamenu-mobile .container .nav-item {
  padding: 0 2rem;
  border-bottom: 1px solid #cccccc;
  position: relative;
}
.megamenu-mobile .container .nav-item:first-child {
  border-top: 1px solid #cccccc;
}
.megamenu-mobile .container .nav-item .product-list {
  max-width: 25rem;
  margin-left: 4rem;
}
.megamenu-mobile .container .nav-item .category-link {
  font-size: 2rem;
  padding: 1.5rem 0;
  display: inline-block;
}
.megamenu-mobile .container .nav-item .category-link:hover, .megamenu-mobile .container .nav-item .category-link:focus, .megamenu-mobile .container .nav-item .category-link:active {
  text-decoration: none;
  border: none;
}
.megamenu-mobile .container .nav-item .category-link:hover, .megamenu-mobile .container .nav-item .category-link:focus, .megamenu-mobile .container .nav-item .category-link:active {
  color: #000000;
  text-decoration: none;
}
.megamenu-mobile .container .nav-item .category-content {
  padding-bottom: 2rem;
}
.megamenu-mobile .container .nav-item .subcategory-link, .megamenu-mobile .container .nav-item .subcategory-link-hover {
  max-width: 30rem;
  line-height: 1.5;
  margin-bottom: 1rem;
  padding: 0 4rem;
  position: relative;
}
.megamenu-mobile .container .nav-item .subcategory-link.collapse-icon::after, .megamenu-mobile .container .nav-item .subcategory-link-hover.collapse-icon::after {
  content: "\f078";
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  padding-right: 1rem;
  position: absolute;
  left: 1rem;
  top: 0;
}
.megamenu-mobile .container .nav-item .subcategory-link.collapse-icon.opened::after, .megamenu-mobile .container .nav-item .subcategory-link-hover.collapse-icon.opened::after {
  content: "\f077";
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  padding-right: 1rem;
  position: absolute;
  left: 1rem;
  top: 0;
}
.megamenu-mobile .container .menu-content {
  text-align: left;
  width: 100%;
}
.megamenu-mobile .container .menu-content .submenu-content {
  padding: 0;
}
.megamenu-mobile .container .menu-content .submenu-content li {
  margin-right: 0;
  width: 50%;
}

.paragraph-block > ul li::before {
  content: "•";
  color: #1c3E77;
  display: inline-block;
  width: 1em;
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: bold;
  margin-left: 0;
}
.paragraph-block > ul li {
  color: #000000;
  line-height: 2.4rem;
  margin-bottom: 0.8rem;
}

.paragraph-block a {
  font-size: 1.6rem;
  font-weight: 700;
  color: #1c3E77;
}
.paragraph-block a a {
  font-family: "Franklin Gothic URW", sans-serif;
  font-size: 1.4rem;
  color: #1c3E77;
}
.paragraph-block a a .primary-button {
  color: #ffffff;
}
.paragraph-block a a:visited {
  color: #1c3E77;
}

.media-slider-component {
  padding: 4rem 0;
}
.media-slider-component .section-title {
  margin-bottom: 6rem;
  font-size: 2rem;
}
.media-slider-component .slider-wrapper {
  padding: 0 2rem;
}
.media-slider-component .slider-wrapper .slide-item {
  display: flex;
}
.media-slider-component .slider-wrapper .slide-item .img-wrapper {
  margin-right: 3rem;
}
.media-slider-component .slider-wrapper .slide-item .img-wrapper img {
  height: 12rem;
  max-width: 5rem;
}
.media-slider-component .slider-wrapper .slide-item .slide-content {
  padding-right: 3rem;
}
@media (max-width: 767px) {
  .media-slider-component .slider-wrapper .slide-item .slide-content {
    padding: 0;
  }
}
.media-slider-component .slider-wrapper .slide-item .slide-content .subtitle {
  font-family: "Franklin Gothic URW", sans-serif;
  font-size: 1.6rem;
  color: #000000;
  margin-bottom: 1.5rem;
  line-height: 2rem;
}
@media (max-width: 767px) {
  .media-slider-component .slider-wrapper .slide-item .slide-content .subtitle {
    font-size: 1.3rem;
    line-height: 1.8rem;
    margin: 0;
  }
}
.media-slider-component .slider-wrapper .slide-item .slide-content p {
  font-size: 1.4rem;
  line-height: 2rem;
}
@media (max-width: 767px) {
  .media-slider-component .slider-wrapper .slide-item .slide-content p {
    font-size: 1.1rem;
    line-height: 1.6rem;
  }
}
@media (max-width: 1460px) {
  .media-slider-component .slider-wrapper {
    margin: 0 3rem;
  }
}
@media (max-width: 767px) {
  .media-slider-component .slider-wrapper {
    max-width: 40rem;
    margin: 0 auto;
  }
}
@media (max-width: 453px) {
  .media-slider-component .slider-wrapper {
    max-width: 30rem;
  }
}
.media-slider-component .slick-prev:before,
.media-slider-component .slick-next:before {
  color: #000000;
}

.media-list-component {
  padding: 3rem 0 0;
  background: #ffffff;
}
.media-list-component .section-title {
  margin-bottom: 6rem;
  font-size: 2rem;
}
.media-list-component .media-item {
  display: flex;
  margin-bottom: 10rem;
  align-items: flex-start;
}
@media (max-width: 767px) {
  .media-list-component .media-item {
    max-width: 42rem;
    margin: 0 auto 3rem;
  }
}
.media-list-component .media-item .image-wrapper {
  width: 30%;
  padding-right: 6rem;
}
@media (max-width: 767px) {
  .media-list-component .media-item .image-wrapper {
    width: 40%;
    padding-right: 2rem;
  }
}
@media (max-width: 991px) {
  .media-list-component .media-item .image-wrapper {
    padding-right: 1rem;
  }
}
.media-list-component .media-item .image-wrapper img {
  width: 100%;
}
@media (max-width: 767px) {
  .media-list-component .media-item .image-wrapper img {
    max-width: 20rem;
  }
}
.media-list-component .media-item .image-wrapper .icon {
  font-size: 12rem;
  color: #1c3E77;
  display: inline-block;
  overflow-x: hidden;
}
@media (max-width: 991px) {
  .media-list-component .media-item .image-wrapper .icon {
    font-size: 10rem;
  }
}
.media-list-component .media-item .content-wrapper {
  width: 70%;
  padding-right: 10rem;
}
@media (max-width: 991px) {
  .media-list-component .media-item .content-wrapper {
    padding: 0 2rem;
  }
}
@media (max-width: 767px) {
  .media-list-component .media-item .content-wrapper {
    padding: 0 1rem;
    width: 60%;
  }
}
.media-list-component .media-item .content-wrapper .subtitle a {
  color: #999999;
}
@media (max-width: 767px) {
  .media-list-component .media-item .content-wrapper .subtitle a {
    font-size: 1.8rem;
  }
}
.media-list-component .media-item .content-wrapper .description-link:hover, .media-list-component .media-item .content-wrapper .description-link:active, .media-list-component .media-item .content-wrapper .description-link:focus,
.media-list-component .media-item .content-wrapper .subtitle-link:hover,
.media-list-component .media-item .content-wrapper .subtitle-link:active,
.media-list-component .media-item .content-wrapper .subtitle-link:focus {
  text-decoration: none;
}
@media (max-width: 767px) {
  .media-list-component .media-item .content-wrapper .description-link p,
  .media-list-component .media-item .content-wrapper .subtitle-link p {
    font-size: 1.2rem;
  }
}
.media-list-component .media-item .content-wrapper .description-link {
  font-family: "Franklin Gothic URW", sans-serif;
  color: #000;
  font-size: 1.5rem;
  line-height: 2.4rem;
}

.square-items-component {
  display: flex;
  padding: 4rem 0;
}
@media (max-width: 767px) {
  .square-items-component {
    padding: 0 3.5rem;
  }
}
.square-items-component .block-link-2 {
  display: inline-block;
  margin: 40px;
}
.square-items-component .block-link-2 h3 {
  font-size: 3rem !important;
}
@media (max-width: 767px) {
  .square-items-component .block-link-2 {
    width: 100%;
    margin: 2rem 0;
  }
}
.square-items-component .block-link-2 img {
  width: 100%;
}
.square-items-component .block-link-2 .content-wrapper-block-link {
  text-align: center;
  margin-top: 10px;
}
.square-items-component .block-link-2 .content-wrapper-block-link .title-block-link {
  color: #707070;
  font-size: 3rem;
  margin-bottom: 15px;
  margin-top: 15px;
}
.square-items-component .block-link-2 .content-wrapper-block-link .subtitle-block-link {
  color: #F15C31 !important;
  margin: auto;
  margin-bottom: 25px;
  font-size: 2rem !important;
  width: 80%;
}
.square-items-component .block-link-2 button {
  font-size: 1.5rem !important;
  font-weight: 700 !important;
  border-radius: -3.6rem !important;
  padding: 1rem 5.2rem !important;
  border-color: #F15C31 !important;
  background: white !important;
}
.square-items-component .block-link {
  width: 25%;
  display: inline-block;
  border: 1px solid #999999;
  margin: 0 1rem;
}
@media (max-width: 767px) {
  .square-items-component .block-link {
    width: 100%;
    margin: 2rem 0;
  }
}
.square-items-component .block-link img {
  width: 100%;
}
@media (max-width: 767px) {
  .square-items-component {
    display: block;
  }
}

hr.separator-large {
  border-color: #707070;
  border-width: 1px;
}

.text-stripe-component .content-wrapper {
  text-align: center;
  padding: 3rem;
}
@media (max-width: 767px) {
  .text-stripe-component .content-wrapper {
    padding: 0.5rem 1rem;
  }
}
.text-stripe-component .content-wrapper p {
  font-size: 3rem;
  margin: 0;
  color: #ffffff;
}
@media (max-width: 767px) {
  .text-stripe-component .content-wrapper p {
    font-size: 1.2rem;
  }
}

#consent-popup {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem 4rem;
  background-color: #fff;
  display: flex;
  transition: opacity 1s ease;
  border-top: 1px solid #999999;
  z-index: 99 !important;
  box-shadow: 10px -1px 5px 0 #999999;
}
#consent-popup.hidden {
  opacity: 0;
  display: none;
}
#consent-popup .consent-container {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
#consent-popup .consent-container .consent-text p {
  font-size: 1.3rem !important;
}
#consent-popup .consent-container button {
  background-color: #1c3E77;
  color: #ffffff;
  padding: 1.2rem 2.2rem;
}
@media only screen and (max-width: 900px) {
  #consent-popup .consent-container {
    display: block;
    text-align: center;
  }
}

.icons-section-component {
  text-align: center;
  width: 50%;
}
.icons-section-component .icons-content {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 767px) {
  .icons-section-component .icons-content {
    width: 14rem;
    margin: 0 auto;
    justify-content: space-between;
  }
}
.icons-section-component .icon-wrapper {
  text-align: center;
  width: 8rem;
  margin-right: 4.2rem;
  margin-bottom: 3rem;
}
@media (max-width: 767px) {
  .icons-section-component .icon-wrapper {
    width: 6rem;
    margin-right: 0;
  }
}
.icons-section-component .icon-wrapper:last-child {
  margin: 0;
}
.icons-section-component .icon-wrapper .icon-text {
  font-family: "Franklin Gothic URW", sans-serif;
  color: #000000;
  font-size: 1.2rem;
}

.int-close {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.int-modal-header {
  display: flex;
  flex-direction: row-reverse;
  padding-bottom: 8rem;
}

.int-globe-icon {
  height: 4rem;
  padding-right: 0.6rem;
}

.int-flag-icon {
  height: 5rem;
  margin-bottom: 1rem;
}
.int-flag-icon.selected {
  outline-color: #1c3E77;
  outline-width: 0.3rem;
  outline-style: solid;
  border-radius: 2px;
}

.int-modal-current-region {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 2rem;
}

.int-modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.international-modal-underline {
  width: 90%;
  border-bottom: 2px solid #1c3E77;
}

.int-modal-dialog {
  position: relative;
  width: auto;
  height: 100%;
  pointer-events: none;
}

.int-card-body {
  min-height: 1px;
  padding: 2rem;
}
.int-card-body .int-card-link {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.international-modal-language-select {
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
}

.int-span {
  color: #8c9091;
  font-size: 3rem;
}

.int-option {
  color: #8c9091;
  font-size: 1.5rem;
}

.card-component {
  display: block;
  max-width: 77.3rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.26);
}
.card-component .title-wrapper {
  padding: 1.6rem 6.3rem;
  background: #1c3E77;
  color: #ffffff;
  border-radius: 6px;
}
@media (max-width: 767px) {
  .card-component .title-wrapper {
    padding: 2.3rem 2.5rem;
  }
}
.card-component .title-wrapper .title {
  font-family: "Franklin Gothic URW", sans-serif;
  font-size: 3rem;
  text-transform: none;
  line-height: 1;
  margin-bottom: 0.2rem;
}
@media (max-width: 767px) {
  .card-component .title-wrapper .title {
    font-size: 2.5rem;
  }
}
.card-component .title-wrapper .subtitle {
  font-size: 1.5rem;
  line-height: 1;
}
.card-component .content-wrapper .form-group {
  width: 100%;
}
.card-component .content-wrapper .form-group label {
  color: #000000;
  margin-bottom: 0.8rem;
  font-size: 1.5rem;
}
.card-component .content-wrapper .form-group input {
  background: #ffffff;
  border: 1px solid #000000;
  font-size: 1.5rem;
}
.card-component .content-wrapper .form-group select {
  background: #ffffff;
  border: 1px solid #000000;
  font-size: 1.5rem;
}

.admin-block {
  background-color: rgba(28, 62, 119, 0.3);
}

.flag-icon {
  max-width: 6rem;
  max-height: 6rem;
}
.flag-icon.selected {
  outline-color: blue;
  outline-width: 0.3rem;
  outline-style: solid;
  border-radius: 2px;
}

.loading-container {
  position: fixed;
  z-index: 1050;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.25);
}
.loading-container .pLoading {
  color: #ffffff;
  font-size: 3rem;
}

.loading {
  display: block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loading > div {
  position: absolute;
  width: 6px;
  height: 6px;
  background-color: #1c3E77;
  border-radius: 50%;
  animation: loading-default 1.2s linear infinite;
}
.loading > div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.loading > div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.loading > div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.loading > div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.loading > div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.loading > div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.loading > div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.loading > div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.loading > div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.loading > div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.loading > div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.loading > div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}

.login-popup-component {
  position: relative;
  max-width: 45rem;
  margin: 20rem auto;
  padding: 5rem 2rem 1.8rem;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.26);
  z-index: 1;
}
.login-popup-component .card {
  border: none;
  box-shadow: none;
  position: static;
}
.login-popup-component .card .card-header {
  border: none;
  background: #ffffff;
}
.login-popup-component .card button:hover, .login-popup-component .card button:active, .login-popup-component .card button:focus {
  text-transform: none;
}
.login-popup-component .title {
  font-family: "Franklin Gothic URW", sans-serif;
  font-size: 2.2rem;
  color: #999999;
  text-transform: none;
}
.login-popup-component .subtitle {
  color: #1c3E77;
  font-family: "Franklin Gothic URW", sans-serif;
  font-size: 1.5rem;
  margin-bottom: 0.7rem;
}
.login-popup-component .content-wrapper .primary-button {
  margin: 0 auto;
  display: block;
}
.login-popup-component .collapse-icon-wrapper {
  position: relative;
}
.login-popup-component .collapse-icon-wrapper a {
  display: block;
}
.login-popup-component .collapse-icon-wrapper .collapse-icon:before, .login-popup-component .collapse-icon-wrapper .collapse-icon:after {
  color: #1c3E77;
  font-size: 2rem;
  top: 0;
  right: 0;
}
.login-popup-component .close-button {
  position: absolute;
  right: 0;
  top: 0;
}
.login-popup-component .close-button .close-icon {
  font-size: 3.5rem;
  color: #1c3E77;
}
.login-popup-component .close-button .close-icon::before {
  content: "\f00d";
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
}
.login-popup-component .password-tooltip {
  top: 3rem;
  right: initial;
  transform: translateY(20%);
}
.login-popup-component .password-tooltip:after, .login-popup-component .password-tooltip:before {
  bottom: 100%;
  left: 10%;
  right: initial;
  top: initial;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.login-popup-component .password-tooltip:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 15px;
  margin-left: -15px;
}
.login-popup-component .password-tooltip:before {
  border-color: rgba(194, 225, 245, 0);
  border-bottom-color: rgba(153, 153, 153, 0.8);
  border-width: 16px;
  margin-left: -16px;
}

.megamenu {
  font-size: 1.8rem;
  font-weight: 400;
  position: absolute;
  left: 0;
  right: 0;
  text-align: left;
  padding-top: 3rem;
  z-index: 10;
  display: none;
  background-color: #ffffff;
  margin-top: 1rem;
}
.megamenu .subtitle {
  color: #000000;
  font-size: 1.8rem;
  display: block;
  margin-bottom: 1.5rem;
}
.megamenu .title {
  display: inline-block;
  font-size: 2rem;
  font-weight: 400;
  color: #1c3E77;
  text-transform: none;
  margin-bottom: 1.5rem;
}
.megamenu .title-big {
  color: #1c3E77;
  font-size: 2.5rem;
  font-weight: 400;
  text-transform: none;
  margin-bottom: 1.9rem;
}
.megamenu .menu-column.two-menu-column:first-child {
  width: 33%;
  display: inline-block;
  padding: 0 15px;
  margin-right: 0;
}
.megamenu .menu-column.two-menu-column:not(:first-child) {
  width: 66%;
  display: inline-block;
  padding: 0 15px;
}
.megamenu .menu-column.three-menu-column:first-child {
  width: 33%;
  display: inline-block;
  padding: 0 15px;
  margin-right: 0;
}
.megamenu .menu-column.three-menu-column:not(:first-child) {
  width: 33%;
  display: inline-block;
  padding: 0 15px;
}
.megamenu .menu-column.four-menu-column:first-child {
  width: 25%;
  display: inline-block;
  padding: 0 15px;
  margin-right: 0;
}
.megamenu .menu-column.four-menu-column:not(:first-child) {
  width: 25%;
  display: inline-block;
  padding: 0 15px;
}
.megamenu .menu-column.five-menu-column:first-child {
  width: 32%;
  display: inline-block;
  padding: 0 15px;
  margin-right: 0;
}
.megamenu .menu-column.five-menu-column:not(:first-child) {
  width: 17%;
  display: inline-block;
  padding: 0 15px;
}
.megamenu .submenu-content {
  padding-left: 1.5rem;
  padding-bottom: 10px;
}
@media (max-width: 767px) {
  .megamenu .submenu-content {
    margin-bottom: 2rem;
  }
  .megamenu .submenu-content:last-child {
    margin-bottom: 0;
  }
}
.megamenu .submenu-content .contact-item {
  margin-bottom: 1rem;
}
.megamenu .submenu-content .contact-item a {
  font-size: 1.8rem;
}
.megamenu .submenu-content .telephone-wrapper {
  margin-bottom: 3rem;
}
.megamenu .item-link {
  font-weight: 700;
  color: #1c3E77;
}
.megamenu .item-link:hover, .megamenu .item-link:active, .megamenu .item-link:focus {
  color: #1c3E77;
  text-decoration: none;
  border-bottom: 3px solid #1c3E77;
}
.megamenu .item-link:visited {
  color: #1c3E77;
  text-decoration: none;
}
.megamenu .list-item .description {
  font-size: 1.6rem;
  color: #8c9091;
}
.megamenu .list-item .item-link {
  font-size: 2rem;
  color: #8c9091;
  display: block;
  margin-bottom: 0.5rem;
}
.megamenu .list-item .item-link:hover, .megamenu .list-item .item-link:active, .megamenu .list-item .item-link:focus, .megamenu .list-item .item-link:visited {
  border: none;
}
.megamenu .subcategory-link, .megamenu .subcategory-link-hover {
  display: block;
  margin-bottom: 0.6rem;
  font-size: 1.6rem;
  color: #000000;
  /*pointer-events:none;*/
}
.megamenu .media-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2rem;
}
.megamenu .media-wrapper:hover, .megamenu .media-wrapper:focus, .megamenu .media-wrapper:active {
  text-decoration: none;
}
.megamenu .media-wrapper .media-img {
  min-width: 3.3rem;
  margin-right: 2rem;
  max-width: 10rem;
  max-height: 15rem;
}
.megamenu .media-wrapper .media-img.tight {
  max-width: 3.3rem;
}
.megamenu .media-wrapper .content .title-link {
  font-size: 2rem;
  max-width: 13.4rem;
  line-height: 3.1rem;
}
.megamenu .media-wrapper .content .title-link + .description {
  font-size: 1.6rem;
  line-height: 2.1rem;
  max-width: 36rem;
  margin-top: 1rem;
}
.megamenu .featured-media-wrapper .title {
  font-size: 1.8rem;
  color: #000000;
  line-height: 2.3rem;
}
.megamenu .featured-media-wrapper .description {
  font-size: 1.5rem;
  color: #000000;
  line-height: 1.9rem;
}
.megamenu .product-list {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 14px;
  gap: 1rem 2rem;
}

.close-button {
  padding: 0;
  border: none;
  margin: 1rem 3rem;
}
.close-button .close-icon {
  font-size: 3.5rem;
  color: #1c3E77;
}
.close-button .close-icon::before {
  content: "\f00d";
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
}
.close-button-account {
  position: absolute;
  right: 3rem;
  margin: 0;
  z-index: 2;
}

.megamenu-mobile {
  text-align: right;
  padding: 0;
  padding-bottom: 20rem;
  border-bottom: 1px solid #b2b2b2;
  background-color: #ffffff;
}
.megamenu-mobile .container {
  padding: 0;
  align-items: flex-end !important;
  position: relative;
  text-align: right;
  flex-direction: column;
}
.megamenu-mobile .container .nav-item {
  padding: 0 2rem;
  border-bottom: 1px solid #cccccc;
  position: relative;
}
.megamenu-mobile .container .nav-item:first-child {
  border-top: 1px solid #cccccc;
}
.megamenu-mobile .container .nav-item .product-list {
  max-width: 25rem;
  margin-left: 4rem;
}
.megamenu-mobile .container .nav-item .category-link {
  font-size: 2rem;
  padding: 1.5rem 0;
  display: inline-block;
}
.megamenu-mobile .container .nav-item .category-link:hover, .megamenu-mobile .container .nav-item .category-link:focus, .megamenu-mobile .container .nav-item .category-link:active {
  text-decoration: none;
  border: none;
}
.megamenu-mobile .container .nav-item .category-link:hover, .megamenu-mobile .container .nav-item .category-link:focus, .megamenu-mobile .container .nav-item .category-link:active {
  color: #000000;
  text-decoration: none;
}
.megamenu-mobile .container .nav-item .category-content {
  padding-bottom: 2rem;
}
.megamenu-mobile .container .nav-item .subcategory-link, .megamenu-mobile .container .nav-item .subcategory-link-hover {
  max-width: 30rem;
  line-height: 1.5;
  margin-bottom: 1rem;
  padding: 0 4rem;
  position: relative;
}
.megamenu-mobile .container .nav-item .subcategory-link.collapse-icon::after, .megamenu-mobile .container .nav-item .subcategory-link-hover.collapse-icon::after {
  content: "\f078";
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  padding-right: 1rem;
  position: absolute;
  left: 1rem;
  top: 0;
}
.megamenu-mobile .container .nav-item .subcategory-link.collapse-icon.opened::after, .megamenu-mobile .container .nav-item .subcategory-link-hover.collapse-icon.opened::after {
  content: "\f077";
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  padding-right: 1rem;
  position: absolute;
  left: 1rem;
  top: 0;
}
.megamenu-mobile .container .menu-content {
  text-align: left;
  width: 100%;
}
.megamenu-mobile .container .menu-content .submenu-content {
  padding: 0;
}
.megamenu-mobile .container .menu-content .submenu-content li {
  margin-right: 0;
  width: 50%;
}

.myaccount-component .content-wrapper {
  background: #ffffff;
  padding: 4rem 0;
}
@media (max-width: 767px) {
  .myaccount-component .content-wrapper {
    padding: 2.6rem 0;
  }
}
.myaccount-component .sidenav-wrapper {
  color: #999999;
}
@media (max-width: 767px) {
  .myaccount-component .sidenav-wrapper {
    margin: 0 1rem;
  }
}
.myaccount-component .sidenav-wrapper .sidenav-title {
  font-family: "Franklin Gothic URW", sans-serif;
  font-size: 2.2rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 2.5rem;
}
@media (max-width: 767px) {
  .myaccount-component .sidenav-wrapper .sidenav-title {
    display: none;
  }
}
.myaccount-component .sidenav-wrapper .sidenav-subtitle {
  font-family: "Franklin Gothic URW", sans-serif;
  font-weight: 700;
  font-size: 2.2rem;
  margin-bottom: 2rem;
  padding-top: 6%;
}
@media (max-width: 767px) {
  .myaccount-component .sidenav-wrapper .sidenav-subtitle {
    font-size: 1.9rem;
  }
}
.myaccount-component .sidenav-wrapper .sidenav-list {
  margin-bottom: 2rem;
}
@media (max-width: 767px) {
  .myaccount-component .sidenav-wrapper .sidenav-list {
    margin-bottom: 5rem;
  }
}
.myaccount-component .sidenav-wrapper .sidenav-list .list-item {
  margin-bottom: 1rem;
}
.myaccount-component .sidenav-wrapper .sidenav-list .list-item .link-item {
  font-family: "Franklin Gothic URW", sans-serif;
  font-size: 1.8rem;
  word-wrap: break-word;
}
.myaccount-component .sidenav-wrapper .sidenav-list .list-item .link-item:hover {
  color: #1c3E77;
  text-decoration: none;
}
.myaccount-component .sidenav-wrapper .sidenav-list .list-item .link-item.active {
  color: #1c3E77;
  text-decoration: none;
  font-weight: 700;
}
@media (max-width: 767px) {
  .myaccount-component .sidenav-wrapper .sidenav-list .list-item.mobile-hide {
    display: none;
  }
}
.myaccount-component .sidenav-wrapper .user-wrapper {
  display: none;
  margin-bottom: 2rem;
}
.myaccount-component .sidenav-wrapper .user-wrapper .account-link {
  color: #1c3E77;
  font-size: 2rem;
  font-family: "Franklin Gothic URW", sans-serif;
}
@media (max-width: 767px) {
  .myaccount-component .sidenav-wrapper .user-wrapper {
    display: block;
  }
}
.myaccount-component .main-wrapper .card-title {
  font-family: "Franklin Gothic URW", sans-serif;
  font-size: 3rem;
  margin-bottom: 2.4rem;
  font-weight: 700;
}
@media (max-width: 767px) {
  .myaccount-component .main-wrapper .card-title {
    font-size: 2.5rem;
  }
}
.myaccount-component .main-wrapper .card-wide {
  max-width: 115rem;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  padding: 3.5rem 41rem 3.2rem 5rem;
  margin-bottom: 4rem;
  color: #000000;
}
@media (max-width: 1200px) {
  .myaccount-component .main-wrapper .card-wide {
    padding-right: 5rem;
  }
}
@media (max-width: 767px) {
  .myaccount-component .main-wrapper .card-wide {
    padding: 2.5rem;
    margin: 2rem 1rem;
  }
}
.myaccount-component .main-wrapper .card-wide .content-wrapper {
  padding: 0;
}
.myaccount-component .main-wrapper .card-wide .card-title-mobile {
  margin-bottom: 2.9rem;
  display: none;
  font-size: 1.8rem;
}
@media (max-width: 767px) {
  .myaccount-component .main-wrapper .card-wide .card-title-mobile {
    display: block;
  }
}
.myaccount-component .main-wrapper .card-wide .title {
  text-transform: none;
}
.myaccount-component .main-wrapper .card-wide .form-group {
  margin-bottom: 2rem;
}
.myaccount-component .main-wrapper .card-wide .form-control {
  border: 1px solid #b2b2b2;
  font-family: "Franklin Gothic URW", sans-serif;
}
.myaccount-component .main-wrapper .card-wide .form-control::placeholder {
  font-family: "Franklin Gothic URW", sans-serif;
  font-size: 1.5rem;
  color: #000000;
}
.myaccount-component .main-wrapper .card-wide label {
  font-size: 1.2rem;
  text-transform: uppercase;
}
.myaccount-component .main-wrapper .card-wide .primary-button {
  margin-top: 2.8rem;
}
@media (max-width: 372px) {
  .myaccount-component .main-wrapper .card-wide .primary-button {
    padding: 1rem 2rem;
  }
}
.myaccount-component .main-wrapper .card-wide .cancel-button {
  margin-left: 5rem;
  border: none;
  padding: 0;
  font-family: "Open Sans", sans serif;
  font-weight: 700;
  font-size: 1.5rem;
  cursor: pointer;
}
.myaccount-component .main-wrapper .card-wide .cancel-button:hover {
  color: #1c3E77;
}
@media (max-width: 372px) {
  .myaccount-component .main-wrapper .card-wide .cancel-button {
    margin-left: 1.5rem;
  }
}
.myaccount-component .main-wrapper .user-info-card {
  padding: 4.7rem 3.3rem 3.4rem;
  margin-left: 0;
  margin-right: 0;
}
@media (max-width: 767px) {
  .myaccount-component .main-wrapper .user-info-card {
    padding: 2.5rem;
    margin: 0 1rem 2rem;
  }
}
.myaccount-component .main-wrapper .user-info-card .card-col {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 991px) {
  .myaccount-component .main-wrapper .user-info-card .card-col {
    border-bottom: 1px solid #b2b2b2;
    padding: 4rem 0 1.5rem;
  }
  .myaccount-component .main-wrapper .user-info-card .card-col:last-child {
    border: none;
  }
}
.myaccount-component .main-wrapper .user-info-card .card-col:after {
  content: "";
  display: block;
  background: #999999;
  width: 1px;
  height: 15rem;
  margin-top: 5rem;
}
@media (max-width: 991px) {
  .myaccount-component .main-wrapper .user-info-card .card-col:after {
    display: none;
  }
}
.myaccount-component .main-wrapper .user-info-card .card-col:last-child:after {
  display: none;
}
@media (max-width: 767px) {
  .myaccount-component .main-wrapper .user-info-card .card-col .list-wrapper {
    padding-bottom: 5rem;
  }
}
.myaccount-component .main-wrapper .user-info-card .card-col .list-wrapper .title {
  display: block;
  font-size: 1.6rem;
  margin-bottom: 3rem;
}
.myaccount-component .main-wrapper .user-info-card .card-col .list-wrapper .list-item {
  margin-bottom: 2.5rem;
  font-size: 1.5rem;
}
.myaccount-component .main-wrapper .user-info-card .card-col .list-wrapper .user-info-address {
  font-size: 1.5rem;
  display: block;
}
.myaccount-component .main-wrapper .distributor-card {
  padding: 4rem;
}
@media (max-width: 767px) {
  .myaccount-component .main-wrapper .distributor-card {
    padding: 2.5rem;
  }
}
.myaccount-component .main-wrapper .distributor-card .content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 767px) {
  .myaccount-component .main-wrapper .distributor-card .content-wrapper {
    display: block;
  }
}
@media (max-width: 767px) {
  .myaccount-component .main-wrapper .distributor-card .content-wrapper .content {
    margin-bottom: 1rem;
  }
}
@media (max-width: 767px) {
  .myaccount-component .main-wrapper .distributor-card .desktop-button {
    display: none;
  }
}
.myaccount-component .main-wrapper .distributor-card .mobile-button {
  display: none;
}
@media (max-width: 767px) {
  .myaccount-component .main-wrapper .distributor-card .mobile-button {
    display: block;
    width: 100%;
  }
}
.myaccount-component .main-wrapper .content .distributor-name,
.myaccount-component .main-wrapper .content .description {
  font-size: 1.5rem;
  font-weight: 700;
}
.myaccount-component .main-wrapper .content .distributor-address {
  display: block;
  font-size: 1.4rem;
}
.myaccount-component .main-wrapper .map-wrapper {
  max-width: 59rem;
}
.myaccount-component .main-wrapper .map-wrapper img {
  display: block;
  height: 100%;
}
.myaccount-component .main-wrapper .mylist-card {
  padding: 4rem 4.5rem;
}
@media (max-width: 767px) {
  .myaccount-component .main-wrapper .mylist-card {
    padding: 2.5rem;
  }
}
.myaccount-component .main-wrapper .mylist-card .content {
  text-align: right;
}
.myaccount-component .main-wrapper .mylist-card .content .input-search-wrapper {
  margin-bottom: 2rem;
}
.myaccount-component .main-wrapper .mylist-card .content .form-control {
  display: inline-block;
  padding-left: 2rem;
  max-width: 33.5rem;
  font-size: 1.6rem;
  font-family: "Franklin Gothic URW", sans-serif;
  color: #999999;
}
.myaccount-component .main-wrapper .mylist-card .content .form-control::placeholder {
  font-family: "Franklin Gothic URW", sans-serif;
  color: #999999;
}
.myaccount-component .main-wrapper .mylist-card .content .saved-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 -2rem;
}
.myaccount-component .main-wrapper .mylist-card .content .saved-list .link-item {
  margin: 0 2.5rem;
}
@media (max-width: 767px) {
  .myaccount-component .main-wrapper .mylist-card .content .saved-list .link-item {
    width: 100%;
  }
}
.myaccount-component .main-wrapper .mylist-card .content .saved-list .primary-button {
  width: 20rem;
  padding: 2.6rem 0;
}
@media (max-width: 767px) {
  .myaccount-component .main-wrapper .mylist-card .content .saved-list .primary-button {
    width: 100%;
  }
}

.password-card-component {
  position: relative;
  margin: 15rem auto;
  border-radius: 6px;
}
.password-card-component .content-wrapper {
  padding: 4.3rem 6.3rem;
}
@media (max-width: 767px) {
  .password-card-component .content-wrapper {
    padding: 4.3rem 2.5rem;
    margin-bottom: 10rem;
  }
}
.password-card-component .content-wrapper .form-group {
  margin-bottom: 4.5rem;
  position: relative;
}
.password-card-component .content-wrapper .form-group input {
  width: 51rem;
}
@media (max-width: 767px) {
  .password-card-component .content-wrapper .form-group input {
    width: 100%;
  }
}
.password-card-component .content-wrapper .form-group .error-text {
  position: absolute;
  bottom: -2.3rem;
}
.password-card-component .content-wrapper .button-wrapper {
  margin-bottom: 1.5rem;
}
@media (max-width: 767px) {
  .password-card-component .content-wrapper .button-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
@media (max-width: 767px) {
  .password-card-component .content-wrapper .button-wrapper .primary-button {
    font-size: 1.3rem;
  }
}
.password-card-component .content-wrapper .button-wrapper .secondary-button {
  font-family: "Franklin Gothic URW", sans-serif;
  font-size: 1.5rem;
  color: #999999;
  text-decoration: underline;
  margin-left: 8rem;
}
@media (max-width: 767px) {
  .password-card-component .content-wrapper .button-wrapper .secondary-button {
    margin-left: 0;
    margin-right: 5rem;
  }
}
.password-card-component .content-wrapper .button-wrapper .secondary-button:hover, .password-card-component .content-wrapper .button-wrapper .secondary-button:focus, .password-card-component .content-wrapper .button-wrapper .secondary-button:active {
  text-decoration: underline;
}
.password-card-component .cancel-button {
  font-size: 1.5rem;
  font-family: "Franklin Gothic URW", sans-serif;
  text-decoration: underline;
  margin-left: 8rem;
  color: #999999;
}
.password-card-component .success-message {
  padding: 12rem 8.3rem;
  text-align: center;
}
@media (max-width: 767px) {
  .password-card-component .success-message {
    padding: 4.3rem 2.5rem;
  }
}
.password-card-component .side-card-component {
  margin: 0 auto 10rem;
  display: block;
}
.password-card-component.reset-password-card .content-wrapper .form-group:first-child {
  margin-bottom: 2.2rem;
}
@media (max-width: 767px) {
  .password-card-component.reset-password-card .content-wrapper .form-group .form-control {
    max-width: 100%;
  }
}
.password-card-component.reset-password-card .content-wrapper .form-group .form-control::placeholder {
  font-family: Arial, Helvetica, sans-serif;
  color: #b2b2b2;
  font-size: 2rem;
}

.pdp-component {
  background: #ffffff;
}
.pdp-component .container .title-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  min-height: 50px;
}
@media (min-height: 768px) {
  .pdp-component .container .title-wrapper {
    min-height: 150px;
    margin-bottom: 3.6rem;
  }
}
@media (max-width: 991px) {
  .pdp-component .container .title-wrapper .productTitle {
    font-size: 2rem;
  }
}
@media (max-width: 991px) {
  .pdp-component .container .title-wrapper .variantTitle {
    font-size: 2rem;
  }
}
@media (max-width: 991px) {
  .pdp-component .container .title-wrapper .subtitle {
    font-size: 1.4rem;
    margin-bottom: 3rem;
  }
}
.pdp-component .container .product-details-wrapper {
  display: flex;
}
@media (max-width: 991px) {
  .pdp-component .container .product-details-wrapper {
    padding: 0 2.6rem;
    display: block;
  }
}
.pdp-component .container .product-details-wrapper .product-slider {
  width: 50%;
  padding-right: 10rem;
}
.pdp-component .container .product-details-wrapper .product-slider .slick-slide img {
  display: block;
  max-width: 100% !important;
  height: auto !important;
  max-height: 100px !important;
  width: auto !important;
  margin: auto !important;
  box-shadow: 0px 0px 4px 2px #8f8f8f;
}
@media (max-width: 991px) {
  .pdp-component .container .product-details-wrapper .product-slider {
    width: 100%;
    padding: 0;
    margin-bottom: 4.7rem;
  }
}
.pdp-component .container .product-details-wrapper .product-slider .image-wrapper {
  margin-bottom: 3rem;
}
.pdp-component .container .product-details-wrapper .product-slider .slider-lightbox .slick-track {
  display: flex;
  align-items: center;
}
.pdp-component .container .product-details-wrapper .product-slider .slider-lightbox .slick-track .item {
  margin: 5px 1rem;
}
.pdp-component .container .product-details-wrapper .product-slider .slider-lightbox .slick-track .item .video:before {
  display: block;
  font-family: "Font Awesome 5 Free";
  content: "\f144";
  position: relative;
  color: #e5e5e5;
  opacity: 0.75;
  font-size: 5rem;
  transform: translate(0, 250%);
  text-align: center;
  text-shadow: 0px 0px 6px black;
}
.pdp-component .container .product-details-wrapper .product-slider .slider-lightbox .slick-track .item.video {
  position: relative;
}
.pdp-component .container .product-details-wrapper .product-slider .slider-lightbox .slick-track .item.video:before {
  display: block;
  font-family: "Font Awesome 5 Free";
  content: "\f144";
  position: absolute;
  top: 50%;
  left: 50%;
  color: #ffffff;
  font-size: 3rem;
  transform: translate(-50%, -50%);
}
.pdp-component .container .product-details-wrapper .product-slider .slider-lightbox .slick-next:before,
.pdp-component .container .product-details-wrapper .product-slider .slider-lightbox .slick-prev:before {
  color: #000000;
}
.pdp-component .container .product-details-wrapper .product-slider .slider-lightbox .slick-next {
  right: -1.5rem;
}
.pdp-component .container .product-details-wrapper .product-slider .slider-lightbox .slick-prev {
  left: -1.5rem;
}
.pdp-component .container .product-details-wrapper .product-detail {
  width: 50%;
}
@media (max-width: 991px) {
  .pdp-component .container .product-details-wrapper .product-detail {
    width: 100%;
    padding: 0 0 3rem;
  }
}
.pdp-component .container .product-details-wrapper .product-detail .title-rating-wrapper {
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
}
@media (max-width: 991px) {
  .pdp-component .container .product-details-wrapper .product-detail .title-rating-wrapper {
    margin-left: -1rem;
  }
}
.pdp-component .container .product-details-wrapper .product-detail .title-rating-wrapper span:not(.fa-icon) {
  font-family: "Franklin Gothic URW", sans-serif;
  font-size: 2.5rem;
  color: #000000;
  line-height: 3.8rem;
}
@media (max-width: 991px) {
  .pdp-component .container .product-details-wrapper .product-detail .title-rating-wrapper span:not(.fa-icon) {
    font-size: 1.7rem;
  }
}
.pdp-component .container .product-details-wrapper .product-detail .title-rating-wrapper .product-name:before {
  content: "|";
  padding: 0 0.5rem;
}
.pdp-component .container .product-details-wrapper .product-detail .title-rating-wrapper .rating-component {
  margin-left: 2rem;
  font-size: 0;
  line-height: 3.2rem;
}
@media (max-width: 991px) {
  .pdp-component .container .product-details-wrapper .product-detail .title-rating-wrapper .rating-component {
    margin-left: 1rem;
  }
}
.pdp-component .container .product-details-wrapper .product-detail .title-rating-wrapper .rating-component .back-stars {
  position: relative;
}
.pdp-component .container .product-details-wrapper .product-detail .title-rating-wrapper .rating-component .back-stars .fa-icon {
  font-size: 1.9rem;
}
.pdp-component .container .product-details-wrapper .product-detail .title-rating-wrapper .rating-component .front-stars {
  overflow: hidden;
  position: absolute;
}
.pdp-component .container .product-details-wrapper .product-detail .title-rating-wrapper .rating-component .front-stars .fa-icon {
  font-size: 1.9rem;
}
.pdp-component .container .product-details-wrapper .product-detail .title-rating-wrapper .rating-component .front-stars .fa-icon:before {
  font-weight: 700 !important;
}
.pdp-component .container .product-details-wrapper .product-detail .title-rating-wrapper .rating-component .fa-icon {
  margin-left: 0.4rem;
}
.pdp-component .container .product-details-wrapper .product-detail .content {
  line-height: 2.6rem;
}
@media (max-width: 991px) {
  .pdp-component .container .product-details-wrapper .product-detail .content {
    line-height: 2rem;
  }
}
.pdp-component .container .product-details-wrapper .product-detail .content .large {
  margin-bottom: 2rem;
}
@media (max-width: 991px) {
  .pdp-component .container .product-details-wrapper .product-detail .content .large {
    font-size: 1.4rem;
  }
}
.pdp-component .container .product-details-wrapper .product-detail .content .detail-list {
  color: #000000;
  margin-bottom: 4rem;
}
@media (max-width: 991px) {
  .pdp-component .container .product-details-wrapper .product-detail .content .detail-list {
    font-size: 1.4rem;
  }
}
.pdp-component .container .product-details-wrapper .product-detail .content .detail-list li {
  padding-left: 2rem;
}
@media (max-width: 991px) {
  .pdp-component .container .product-details-wrapper .product-detail .content .detail-list li {
    font-size: 1.4rem;
  }
}
.pdp-component .container .product-details-wrapper .product-detail .content .detail-list li::before {
  content: "•";
  color: #1c3E77;
  font-size: 2.4rem;
  position: absolute;
  margin-left: -2rem;
}
@media (max-width: 991px) {
  .pdp-component .container .product-details-wrapper .product-detail .content .detail-list li::before {
    line-height: 2rem;
  }
}
.pdp-component .container .product-details-wrapper .product-detail .content .icons-section-component {
  width: auto;
}
@media (max-width: 991px) {
  .pdp-component .container .product-details-wrapper .product-detail .content .icons-section-component .icons-content {
    justify-content: space-between;
    width: 28rem;
    margin: 0;
  }
}
@media (max-width: 991px) {
  .pdp-component .container .product-details-wrapper .product-detail .content .icons-section-component .icons-content .icon-wrapper {
    width: 6rem;
    margin: 0;
  }
}
.pdp-component .product-tabs-component .customer-review-holder {
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.pdp-component .product-tabs-component .customer-review-holder p {
  font-size: 2rem;
  font-family: "Franklin Gothic URW", sans-serif;
  color: #999999;
}
.pdp-component .product-tabs-component .customer-review-holder .primary-button {
  padding: 1.3rem 7.3rem;
  font-size: 1.4rem;
  margin-top: 1.5rem;
}
.pdp-component .product-tabs-component .customer-review-holder .review-data .primary-button {
  float: right;
}
.pdp-component .product-tabs-component .customer-review-holder .card {
  max-width: 60rem;
  margin: 0 auto;
  position: relative;
  border: 1px solid #999999;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.pdp-component .product-tabs-component .customer-review-holder .card .login-popup-component {
  margin: 0 auto;
  box-shadow: none;
  padding: 0;
}
.pdp-component .product-tabs-component .customer-review-holder .card .login-popup-component .card {
  border: none;
}
.pdp-component .product-tabs-component .customer-review-holder .card .login-popup-component .card:before {
  display: none;
}
.pdp-component .product-tabs-component .customer-review-holder .card .login-popup-component .card .content-wrapper {
  margin-top: 0;
}
.pdp-component .product-tabs-component .customer-review-holder .card:before {
  display: block;
  position: absolute;
  content: "\f0d7";
  font-size: 2rem;
  font-family: "Font Awesome 5 Free";
  top: -0.9rem;
  left: 50%;
  color: #707070;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  font-weight: 600;
}
.pdp-component .product-tabs-component .customer-review-holder .card .content-wrapper {
  text-align: left;
  margin-top: 4rem;
}
.pdp-component .product-tabs-component .customer-review-holder .card .content-wrapper label {
  text-align: left;
  color: #999999;
  font-size: 1.4rem;
}
.pdp-component .product-tabs-component .customer-review-holder .card .content-wrapper input {
  border: 1px solid #333333;
}
.pdp-component .product-tabs-component .customer-review-holder .card .content-wrapper textarea {
  background: #ffffff;
  border: 1px solid #333333;
  height: 15.7rem;
}
.pdp-component .product-tabs-component .customer-review-holder .card .content-wrapper .review-data .bta {
  display: inline-block;
  padding-right: 0;
}
.pdp-component .product-tabs-component .customer-review-holder .card .content-wrapper .review-data .bta .primary-button {
  padding: 1.1rem 6rem;
  font-size: 1.4rem;
  margin-top: 1.5rem;
}
.pdp-component .product-tabs-component .customer-review-holder .card .content-wrapper .review-data .bta span {
  font-size: 1.4rem;
}
.pdp-component .product-tabs-component .customer-review-holder .card .content-wrapper .review-data .form-group {
  width: 20%;
  padding-right: 1rem;
}
.pdp-component .product-tabs-component .customer-review-holder .card .content-wrapper .review-data .form-group:nth-child(2) {
  padding-left: 0 !important;
}
.pdp-component .product-tabs-component .customer-review-holder .card .content-wrapper .review-data .form-group:nth-child(4) {
  width: 36% !important;
  padding-right: 0 !important;
}
.pdp-component .product-tabs-component .customer-review-holder .card .content-wrapper .review-data .primary-button.disabled {
  margin: 1.5rem auto 0;
  display: inherit;
}
.pdp-component .product-tabs-component .customer-review-holder .card .content-wrapper .review-data .posted {
  margin-top: 2rem;
}
.pdp-component .product-tabs-component .customer-review-holder .card .content-wrapper .review-data .posted .primary-button {
  margin-top: 0;
}
.pdp-component .product-tabs-component .customer-review-holder .card .content-wrapper .review-data .posted .post-name {
  font-size: 1.8rem;
  font-family: "Franklin Gothic URW", sans-serif;
  margin-bottom: 0;
}
.pdp-component .product-tabs-component .customer-review-holder .card .content-wrapper .review-data .posted .post-name span {
  color: #1c3E77;
}
.pdp-component .product-tabs-component .customer-review-holder .card .content-wrapper .rating-holder .rating-component {
  align-items: center;
  justify-content: center;
}
@media (max-width: 767px) {
  .pdp-component .product-tabs-component .customer-review-holder .card .content-wrapper .rating-holder .rating-component {
    padding-left: 1.5rem;
  }
}
.pdp-component .product-tabs-component .customer-review-holder .card .content-wrapper .rating-holder .rating-component.col-md-7 {
  display: inline;
}
@media (max-width: 767px) {
  .pdp-component .product-tabs-component .customer-review-holder .card .content-wrapper .rating-holder .rating-component.col-md-7 {
    display: block;
  }
}
.pdp-component .product-tabs-component .customer-review-holder .card .content-wrapper .rating-holder .rating-component .fa-icon {
  font-size: 1.9rem;
}
.pdp-component .product-tabs-component .customer-review-holder .card .content-wrapper .rating-holder .rating-component .fa-icon:before {
  font-weight: 400 !important;
}
.pdp-component .product-tabs-component .customer-review-holder .card .content-wrapper .rating-holder .rating-component .fa-icon:hover:before {
  font-weight: 700 !important;
}
.pdp-component .product-tabs-component .customer-review-holder .card .content-wrapper .rating-holder .rating-component .fa-icon.full-star:before {
  font-weight: 700 !important;
}
.pdp-component .product-tabs-component .customer-review-holder .card .content-wrapper .rating-holder .rating-component .back-stars {
  position: relative;
}
.pdp-component .product-tabs-component .customer-review-holder .card .content-wrapper .rating-holder .rating-component .front-stars {
  color: #1c3E77;
  overflow: hidden;
  position: absolute;
  top: 0;
  font-weight: 700;
}
.pdp-component .product-tabs-component .customer-review-holder .card .content-wrapper .rating-holder .form-text {
  font-size: 1.4rem;
}
.pdp-component .product-tabs-component .customer-review-holder .card .content-wrapper .review-upload .primary-button {
  margin-top: 2.5rem;
  text-transform: none;
}
.pdp-component .product-tabs-component .customer-review-holder .card .content-wrapper .review-upload .dropzone {
  background: transparent;
  min-height: auto;
  margin-top: 2.5rem;
  border: none;
  padding: 0;
}
.pdp-component .product-tabs-component .customer-review-holder .card .content-wrapper .review-upload .dropzone .dz-message {
  margin: 0;
  border: 2px solid #1c3e77;
  color: #1c3e77;
  padding: 1rem 0.5rem;
  border-radius: 4px;
}
.pdp-component .product-tabs-component .customer-review-holder .card .content-wrapper .review-upload .dropzone .dz-message span {
  font-size: 1.4rem;
}
.pdp-component .product-tabs-component .customer-review-holder .card .content-wrapper .review-upload .dropzone .dz-preview .dz-remove:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  font-size: 2rem;
  content: "\f00d";
  color: #8f8f8f;
  display: block;
}
@media (max-width: 767px) {
  .pdp-component .product-tabs-component .customer-review-holder .card.mobile {
    border: none;
    box-shadow: none;
  }
  .pdp-component .product-tabs-component .customer-review-holder .card.mobile:before {
    display: none !important;
  }
}
.pdp-component .product-tabs-component .customer-review-holder .review-posted {
  margin-top: 4rem;
  margin-bottom: 3rem;
}
.pdp-component .product-tabs-component .customer-review-holder .review-posted .review-star .form-text {
  font-size: 3.4rem;
  font-family: "Franklin Gothic URW", sans-serif;
  color: #1c3E77;
}
.pdp-component .product-tabs-component .customer-review-holder .review-posted .review-star .review-nr {
  font-size: 1.8rem;
  font-family: "Franklin Gothic URW", sans-serif;
  color: #000000;
}
.pdp-component .product-tabs-component .customer-review-holder .review-posted .review-star .rating-component {
  margin: 0 30px;
  position: relative;
}
.pdp-component .product-tabs-component .customer-review-holder .review-posted .review-star .rating-component .back-stars {
  position: relative;
}
.pdp-component .product-tabs-component .customer-review-holder .review-posted .review-star .rating-component .front-stars {
  color: #1c3e77;
  overflow: hidden;
  position: absolute;
  top: 0;
  font-weight: 700;
}
.pdp-component .product-tabs-component .customer-review-holder .review-posted .review-star .rating-component .fa-icon {
  font-size: 2.5rem;
}
.pdp-component .product-tabs-component .customer-review-holder .review-posted .posted-reviews {
  margin-top: 5rem;
}
.pdp-component .product-tabs-component .customer-review-holder .review-posted .posted-reviews .post-details {
  border-top: 1px solid #999999;
  padding: 5rem 0;
}
@media (max-width: 767px) {
  .pdp-component .product-tabs-component .customer-review-holder .review-posted .posted-reviews .post-details {
    padding-left: 1.5rem;
  }
  .pdp-component .product-tabs-component .customer-review-holder .review-posted .posted-reviews .post-details .col-md-3 {
    margin-bottom: 2rem;
    text-align: left;
  }
}
.pdp-component .product-tabs-component .customer-review-holder .review-posted .posted-reviews .post-details p {
  margin: 0;
}
.pdp-component .product-tabs-component .customer-review-holder .review-posted .posted-reviews .post-details .post-name, .pdp-component .product-tabs-component .customer-review-holder .review-posted .posted-reviews .post-details .post-city {
  font-family: "Franklin Gothic URW", sans-serif;
  font-size: 1.8rem;
  color: #1c3E77;
  display: inline-block;
}
@media (max-width: 767px) {
  .pdp-component .product-tabs-component .customer-review-holder .review-posted .posted-reviews .post-details .post-name, .pdp-component .product-tabs-component .customer-review-holder .review-posted .posted-reviews .post-details .post-city {
    color: #000000;
  }
}
.pdp-component .product-tabs-component .customer-review-holder .review-posted .posted-reviews .post-details .post-date {
  font-family: "Franklin Gothic URW", sans-serif;
  font-size: 1.5rem;
  color: #000000;
}
.pdp-component .product-tabs-component .customer-review-holder .review-posted .posted-reviews .post-details .content-post {
  text-align: left;
}
.pdp-component .product-tabs-component .customer-review-holder .review-posted .posted-reviews .post-details .content-post .helical-motto {
  font-size: 2.5rem;
  color: #1c3E77;
  font-family: "Franklin Gothic URW", sans-serif;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}
@media (max-width: 767px) {
  .pdp-component .product-tabs-component .customer-review-holder .review-posted .posted-reviews .post-details .content-post .helical-motto {
    font-size: 2rem;
  }
}
.pdp-component .product-tabs-component .customer-review-holder .review-posted .posted-reviews .post-details .content-post .post-description {
  font-size: 1.8rem;
  color: #000000;
  margin-bottom: 3rem;
}
@media (max-width: 767px) {
  .pdp-component .product-tabs-component .customer-review-holder .review-posted .posted-reviews .post-details .content-post .fa-icon {
    font-size: 1.8rem;
  }
}
.pdp-component .product-tabs-component .customer-review-holder .review-posted .posted-reviews .post-details .content-post .rating-component .back-stars {
  position: relative;
}
.pdp-component .product-tabs-component .customer-review-holder .review-posted .posted-reviews .post-details .content-post .rating-component .front-stars {
  color: #1c3e77;
  overflow: hidden;
  position: absolute;
  top: 0;
  font-weight: 700;
}
.pdp-component .product-tabs-component .customer-review-holder .review-posted .pagination {
  padding-left: 2.1rem;
}
.pdp-component .product-tabs-component .customer-review-holder .review-posted .pagination .pag-arrow {
  align-self: center;
}
.pdp-component .product-tabs-component .customer-review-holder .review-posted .pagination .prev-arrow {
  border-top: 2px solid #000000;
  border-left: 2px solid #000000;
  transform: rotate(-45deg);
  width: 12px;
  height: 12px;
  line-height: 12px;
  display: block;
  transition: 0.2s;
}
.pdp-component .product-tabs-component .customer-review-holder .review-posted .pagination .prev-arrow:hover {
  border-top: 2px solid #1c3E77;
  border-left: 2px solid #1c3E77;
}
.pdp-component .product-tabs-component .customer-review-holder .review-posted .pagination .next-arrow {
  border-top: 2px solid #000000;
  border-right: 2px solid #000000;
  transform: rotate(45deg);
  width: 12px;
  height: 12px;
  line-height: 12px;
  display: block;
  transition: 0.2s;
}
.pdp-component .product-tabs-component .customer-review-holder .review-posted .pagination .next-arrow:hover {
  border-top: 2px solid #1c3E77;
  border-right: 2px solid #1c3E77;
}
.pdp-component .product-tabs-component .customer-review-holder .review-posted .pagination .pag-holder {
  font-size: 1.8rem;
  font-family: "Franklin Gothic URW", sans-serif;
  color: #000000;
}
.pdp-component .product-tabs-component .customer-review-holder .review-posted .pag-bta {
  border-top: 1px solid #707070;
  padding-top: 4rem;
  position: relative;
}
.pdp-component .product-tabs-component .customer-review-holder .review-posted .pag-bta .bta-holder {
  position: absolute;
  top: 3rem;
  right: 0;
}
.pdp-component .product-tabs-component .customer-review-holder .review-posted .pag-bta .bta-holder .primary-button {
  margin-top: 0;
}
.pdp-component .product-tabs-component .customer-review-holder .mobile-vs .card {
  border: none;
  box-shadow: none;
  padding-right: 0;
  padding-left: 0;
}
.pdp-component .product-tabs-component .customer-review-holder .mobile-vs .card:before {
  display: none;
}
.pdp-component .product-tabs-component .customer-review-holder .mobile-vs .card .bta {
  text-align: left;
}
.pdp-component .product-tabs-component .customer-review-holder .mobile-vs .card .bta p {
  text-align: left;
}
.pdp-component .product-tabs-component .customer-review-holder .mobile-vs .card .bta span {
  font-size: 1.4rem;
  margin-left: 3rem;
  font-family: "Franklin Gothic URW", sans-serif;
}
.pdp-component .product-tabs-component .customer-review-holder .mobile-vs .card .primary-button {
  padding: 1.3rem 3.3rem;
}
.pdp-component .product-table-component {
  margin-top: 2rem;
}
.pdp-component .product-table-component input {
  display: inline-block;
  font-size: 1.6rem;
  padding: 0 2rem;
  font-family: "Franklin Gothic URW", sans-serif;
  font-weight: 700;
}
.pdp-component .product-table-component .holder-search {
  text-align: right;
}
.pdp-component .product-table-component .holder-search .input-search-wrapper:before {
  right: 3.3rem;
}
.pdp-component .product-table-component .product-table-input {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  text-align: left;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  flex-flow: row wrap;
}
.pdp-component .product-table-component .product-table-input .form-group {
  width: 10%;
  min-width: 14rem;
  padding: 0 5px;
  text-align: left;
  margin-bottom: 0.5rem;
}
.pdp-component .product-table-component .product-table-input .form-group label {
  font-size: 1.4rem;
  font-weight: 700;
  text-align: left;
}
.pdp-component .product-table-component .product-table-input .form-group input {
  background: none;
  border: 1px solid #333333;
  padding: 0.5rem;
  font-weight: normal;
  font-size: 1.4rem;
}
.pdp-component .product-table-component .product-table-input .form-group .suggestion-box {
  text-align: left;
  background: #ffffff;
  color: #999999;
  padding: 1rem;
  font-size: 1.6rem;
  font-family: "Franklin Gothic URW", sans-serif;
  position: absolute;
  z-index: 999;
  border: 1px solid #999999;
}
.pdp-component .product-table-component .product-table-input .form-group .suggestion-box .box {
  margin-top: 1.5rem;
}
.pdp-component .product-table-component .product-table-input .form-group .suggestion-box .box:nth-child(1) {
  margin-top: 0;
}
.pdp-component .product-table-component .product-table-input .form-group .suggestion-box a {
  width: 100%;
  color: #999999;
  font-size: 1.3rem;
  font-family: "Franklin Gothic URW", sans-serif;
  line-height: 2rem;
}
.pdp-component .product-table-component .table-content-mask {
  display: none;
}
.pdp-component .product-table-component .table-search-holder {
  position: relative;
}
.pdp-component .product-table-component .show-more {
  display: none;
}
@media (max-width: 1024px) {
  .pdp-component .product-table-component .show-more {
    display: none;
  }
  .pdp-component .product-table-component .table-dots {
    text-align: center;
    margin-top: 0.5rem;
  }
  .pdp-component .product-table-component .table-dots li {
    width: 8px;
    height: 8px;
    margin: 0 0.3rem;
    font-size: 0;
    line-height: 0;
    display: inline-block;
    padding: 5px;
    color: transparent;
    border-radius: 50%;
    outline: none;
    background: #b2b2b2;
  }
  .pdp-component .product-table-component .table-dots li.active {
    background: #1c3E77;
  }
  .pdp-component .product-table-component.mobile-vs h4 {
    display: none;
  }
  .pdp-component .product-table-component.mobile-vs .holder-search {
    text-align: center;
  }
  .pdp-component .product-table-component.mobile-vs .holder-search .form-group {
    text-align: left;
    font-size: 2.6rem;
    padding: 0 2rem;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
  }
  .pdp-component .product-table-component.mobile-vs .holder-search .selectric label {
    font-size: 1.6rem !important;
  }
  .pdp-component .product-table-component.mobile-vs .holder-search .input-search-wrapper {
    float: right;
  }
  .pdp-component .product-table-component.mobile-vs .table-search-holder .show-more {
    display: none;
  }
  .pdp-component .product-table-component.mobile-vs .table-search-holder .table-content-mask {
    text-align: center;
    padding: 34px 3px 3px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background: rgba(112, 112, 112, 0.68);
    display: flex;
    flex-direction: column;
  }
  .pdp-component .product-table-component.mobile-vs .table-search-holder .table-content-mask .table-description {
    padding-top: 4rem;
  }
  .pdp-component .product-table-component.mobile-vs .table-search-holder .table-content-mask .table-description p {
    font-size: 1.8rem;
  }
  .pdp-component .product-table-component.mobile-vs .table-search-holder .table-content-mask a {
    width: 40px;
    height: 40px;
    border: 2px solid #fff;
    border-radius: 50%;
  }
  .pdp-component .product-table-component.mobile-vs .table-search-holder .table-content-mask p {
    text-align: center;
    color: #ffffff;
    font-size: 1.4rem;
    font-family: "Franklin Gothic URW", sans-serif;
    line-height: 20px;
  }
  .pdp-component .product-table-component.mobile-vs .table-search-holder .table-content-mask button {
    background: transparent;
    border: 3px solid #ffffff;
    color: #ffffff;
    font-family: "Franklin Gothic URW", sans-serif;
    font-weight: 700;
    width: 100%;
    border-radius: 0;
  }
  .pdp-component .product-table-component.mobile-vs .table-search-holder .table-content-mask .swipe {
    margin: 50px 0;
  }
}
@media (max-width: 767px) {
  .pdp-component .product-table-component .show-more {
    position: absolute;
    bottom: 0;
    background: rgba(238, 238, 236, 0.8);
    color: #333333;
    font-family: "Franklin Gothic URW", sans-serif;
    width: 100%;
    border-radius: 0;
    outline: none;
    display: block;
  }
  .pdp-component .product-table-component .table-dots {
    text-align: center;
    margin-top: 0.5rem;
  }
  .pdp-component .product-table-component .table-dots li {
    width: 8px;
    height: 8px;
    margin: 0 0.3rem;
    font-size: 0;
    line-height: 0;
    display: inline-block;
    padding: 5px;
    color: transparent;
    border-radius: 50%;
    outline: none;
    background: #b2b2b2;
  }
  .pdp-component .product-table-component .table-dots li.active {
    background: #1c3E77;
  }
  .pdp-component .product-table-component.mobile-vs h4 {
    display: none;
  }
  .pdp-component .product-table-component.mobile-vs .holder-search {
    text-align: center;
  }
  .pdp-component .product-table-component.mobile-vs .holder-search .form-group {
    text-align: left;
    font-size: 2.6rem;
    padding: 0 2rem;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
  }
  .pdp-component .product-table-component.mobile-vs .holder-search .selectric label {
    font-size: 1.6rem !important;
  }
  .pdp-component .product-table-component.mobile-vs .holder-search .input-search-wrapper {
    float: right;
  }
  .pdp-component .product-table-component.mobile-vs .table-search-holder .show-more {
    display: none;
  }
  .pdp-component .product-table-component.mobile-vs .table-search-holder .table-content-mask {
    text-align: center;
    padding: 34px 3px 3px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background: rgba(112, 112, 112, 0.68);
    display: flex;
    flex-direction: column;
  }
  .pdp-component .product-table-component.mobile-vs .table-search-holder .table-content-mask .table-description {
    padding-top: 4rem;
  }
  .pdp-component .product-table-component.mobile-vs .table-search-holder .table-content-mask .table-description p {
    font-size: 1.8rem;
  }
  .pdp-component .product-table-component.mobile-vs .table-search-holder .table-content-mask a {
    width: 40px;
    height: 40px;
    border: 2px solid #fff;
    border-radius: 50%;
  }
  .pdp-component .product-table-component.mobile-vs .table-search-holder .table-content-mask p {
    text-align: center;
    color: #ffffff;
    font-size: 1.4rem;
    font-family: "Franklin Gothic URW", sans-serif;
    line-height: 20px;
  }
  .pdp-component .product-table-component.mobile-vs .table-search-holder .table-content-mask button {
    background: transparent;
    border: 3px solid #ffffff;
    color: #ffffff;
    font-family: "Franklin Gothic URW", sans-serif;
    font-weight: 700;
    width: 100%;
    border-radius: 0;
  }
  .pdp-component .product-table-component.mobile-vs .table-search-holder .table-content-mask .swipe {
    margin: 50px 0;
  }
}

.green-bg {
  background-color: #1c3E77;
  color: #ffffff;
}

.primary-ribbon {
  background-color: #1c3E77;
  color: #ffffff;
}

.product-tabs-component {
  border-bottom: 1px solid #999999;
}
.product-tabs-component.tech-page {
  border-bottom: 1px solid transparent;
}
.product-tabs-component.tech-page .nav-pills .nav-item .nav-link {
  font-size: 2.2rem;
  font-family: "Franklin Gothic URW", sans-serif;
  background: none;
}
.product-tabs-component.tech-page .customer-review-holder .primary-button {
  margin-top: 0;
}
.product-tabs-component .nav-pills {
  padding: 2rem 0 1.4rem;
  justify-content: center;
  border-bottom: 1px solid #999999;
}
.product-tabs-component .nav-pills.title-wrapper-small {
  border-bottom: none;
}
.product-tabs-component .nav-pills .nav-item {
  margin-right: 10rem;
}
@media (max-width: 991px) {
  .product-tabs-component .nav-pills .nav-item {
    margin-right: 4.4rem;
  }
}
.product-tabs-component .nav-pills .nav-item:last-child {
  margin-right: 0;
}
.product-tabs-component .nav-pills .nav-item .icon {
  font-size: 3rem;
  vertical-align: middle;
  margin-right: 1rem;
  width: 3rem;
  height: unset;
}
.product-tabs-component .nav-pills .nav-item .icon::before {
  font-size: 3rem;
  color: #000000;
}
.product-tabs-component .nav-pills .nav-item .nav-link {
  background: #ffffff;
  font-size: 1.6rem;
  font-family: "Franklin Gothic URW", sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  color: #999999;
  padding: 0;
  display: inline-block;
}
@media (max-width: 991px) {
  .product-tabs-component .nav-pills .nav-item .nav-link {
    font-size: 1.3rem;
  }
}
.product-tabs-component .nav-pills .nav-item .nav-link.active {
  position: relative;
  color: #1c3E77;
  border-bottom: 3px solid #1c3E77;
}
.product-tabs-component .nav-pills .nav-item .nav-link.active:after {
  display: block;
  position: absolute;
  content: "\f0d8";
  font-size: 2rem;
  font-family: "Font Awesome 5 Free";
  bottom: -2.7rem;
  left: 50%;
  color: #999999;
  transform: translateX(-50%);
}
.product-tabs-component .content {
  display: flex;
}
@media (max-width: 991px) {
  .product-tabs-component .content {
    display: block;
    padding-top: 4.4rem;
  }
}
.product-tabs-component .content .media-list {
  width: 44%;
  padding-bottom: 6rem;
}
@media (max-width: 991px) {
  .product-tabs-component .content .media-list {
    width: 100%;
    padding-bottom: 5rem;
  }
}
.product-tabs-component .content .media-list .section-title-wrapper {
  padding: 4rem 2rem 1.2rem;
}
@media (max-width: 991px) {
  .product-tabs-component .content .media-list .section-title-wrapper {
    padding: 0;
    margin-bottom: 2rem;
  }
}
.product-tabs-component .content .media-list .section-title-wrapper .section-title {
  margin: 0;
  font-family: "Franklin Gothic URW", sans-serif;
  font-size: 2rem;
  line-height: 2.3rem;
}
@media (max-width: 991px) {
  .product-tabs-component .content .media-list .section-title-wrapper .section-title {
    font-size: 1.6rem;
  }
}
.product-tabs-component .content .media-list .media-list-content {
  border-right: 1px solid #999999;
  padding: 5.5rem 2rem 9rem 0;
  min-height: 57rem;
}
@media (max-width: 991px) {
  .product-tabs-component .content .media-list .media-list-content {
    border: none;
    padding: 0;
    min-height: auto;
  }
}
.product-tabs-component .content .media-list .media-list-content .media-item {
  display: flex;
  align-items: center;
  margin-bottom: 10rem;
}
@media (max-width: 991px) {
  .product-tabs-component .content .media-list .media-list-content .media-item {
    margin-bottom: 4rem;
  }
}
.product-tabs-component .content .media-list .media-list-content .media-item:last-child {
  margin-bottom: 0;
}
.product-tabs-component .content .media-list .media-list-content .media-item .image-wrapper {
  overflow: hidden;
  min-width: 10rem;
  max-width: 10rem;
  margin-right: 3.6rem;
}
@media (max-width: 991px) {
  .product-tabs-component .content .media-list .media-list-content .media-item .image-wrapper {
    margin-right: 2rem;
  }
}
.product-tabs-component .content .media-list .media-list-content .media-item .image-wrapper .icon::before {
  font-size: 10rem;
  color: #1c3E77;
}
@media (max-width: 991px) {
  .product-tabs-component .content .media-list .media-list-content .media-item .content-wrapper .subtitle-wrapper {
    display: flex;
    align-items: center;
  }
}
.product-tabs-component .content .media-list .media-list-content .media-item .content-wrapper .subtitle-wrapper .subtitle {
  font-size: 2.2rem;
  color: #999999;
  line-height: 3.4rem;
  margin-bottom: 0.7rem;
}
@media (max-width: 991px) {
  .product-tabs-component .content .media-list .media-list-content .media-item .content-wrapper .subtitle-wrapper .subtitle {
    font-size: 1.8rem;
  }
}
.product-tabs-component .content .media-list .media-list-content .media-item .content-wrapper .description {
  font-size: 1.6rem;
  line-height: 2.1rem;
  max-width: 44rem;
  font-family: "Franklin Gothic URW", sans-serif;
  color: #000000;
}
@media (max-width: 991px) {
  .product-tabs-component .content .media-list .media-list-content .media-item .content-wrapper .description {
    font-size: 1.4rem;
  }
}
.product-tabs-component .content .media-list:nth-child(2), .product-tabs-component .content .media-list:nth-child(3) {
  padding-left: 2rem;
  width: 33%;
}
@media (max-width: 991px) {
  .product-tabs-component .content .media-list:nth-child(2), .product-tabs-component .content .media-list:nth-child(3) {
    width: 100%;
  }
}
@media (max-width: 991px) {
  .product-tabs-component .content .media-list:nth-child(1) {
    padding-left: 2rem;
  }
}
@media (max-width: 991px) {
  .product-tabs-component .content .media-list:nth-child(1) .media-list-content {
    padding-right: 2rem;
  }
}
@media (max-width: 991px) {
  .product-tabs-component .content .media-list:nth-child(1) .image-wrapper.primary-img {
    display: none;
  }
}
.product-tabs-component .content .media-list:nth-child(1) .image-wrapper.mobile-img {
  display: none;
}
@media (max-width: 991px) {
  .product-tabs-component .content .media-list:nth-child(1) .image-wrapper.mobile-img {
    display: block;
    margin-right: 1.5rem;
  }
}
.product-tabs-component .content .media-list:nth-child(2) .media-list-content {
  padding-right: 2rem;
}
.product-tabs-component .content .media-list:nth-child(3) .media-list-content {
  border: none;
}
.product-tabs-component .content .media-list:nth-child(3) .media-list-content .media-item {
  margin-bottom: 6.2rem;
}
@media (max-width: 991px) {
  .product-tabs-component .content .media-list:nth-child(3) .media-list-content .media-item {
    margin-bottom: 3rem;
  }
}

.quantity-field-component {
  font-size: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.quantity-field-component span {
  font-size: 1.4rem;
  position: relative;
  color: #1c3E77;
}
.quantity-field-component button {
  background-color: #b2b2b2;
  border: none;
  color: #000000;
  font-size: 2rem;
  height: 4.7rem;
  padding: 0;
  width: 4.7rem;
}
@media (max-width: 1200px) {
  .quantity-field-component button {
    width: 4rem;
    height: 4rem;
  }
}
.quantity-field-component input {
  border: 1px solid #e5e5e5;
  color: #000000;
  font-family: "Franklin Gothic URW", sans-serif;
  font-size: 1.8rem;
  font-weight: 700;
  height: 4.7rem;
  line-height: 3rem;
  text-align: center;
  width: 4.7rem;
  margin: 0;
  background: #ffffff;
}
@media (max-width: 1200px) {
  .quantity-field-component input {
    width: 4rem;
    height: 4rem;
  }
}
.quantity-field-component input[type=number]::-webkit-inner-spin-button,
.quantity-field-component input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
}
.quantity-field-component input[type=number] {
  appearance: none;
  -moz-appearance: textfield;
}

.rating-component .fa-icon {
  color: #1c3E77;
  font-size: 1.6rem;
}
@media (max-width: 1300px) {
  .rating-component .fa-icon {
    font-size: 1.2rem;
  }
}

.side-card-component {
  margin-left: 8.2rem;
  display: inline-block;
  margin-top: 21.3rem;
}
@media (max-width: 1200px) {
  .side-card-component {
    margin-left: 3rem;
  }
}
@media (max-width: 767px) {
  .side-card-component {
    max-width: 35rem;
    display: block;
    margin: 10rem auto;
  }
}
.side-card-component .image-wrapper {
  min-width: 5rem;
  width: 100%;
  max-width: 5rem;
}
.side-card-component .image-wrapper .icon {
  color: #1c3E77;
  font-size: 5.8rem;
}
.side-card-component .image-wrapper img {
  width: 100%;
  max-height: 6rem;
}
.side-card-component .title {
  font-family: "Franklin Gothic URW", sans-serif;
  font-size: 2.2rem;
  margin-bottom: 2.9rem;
  text-transform: none;
}
@media (max-width: 1200px) {
  .side-card-component .title {
    font-size: 2rem;
  }
}
.side-card-component .list-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 6.9rem;
}
.side-card-component .list-item .list-content {
  margin-left: 2rem;
}
.side-card-component .list-item .list-content .subtitle {
  font-size: 2.1rem;
}
@media (max-width: 1200px) {
  .side-card-component .list-item .list-content .subtitle {
    font-size: 1.9rem;
  }
}
.side-card-component .list-item .list-content .description {
  color: #999999;
  font-weight: 700;
  font-size: 1.7rem;
  max-width: 25.7rem;
}
@media (max-width: 1200px) {
  .side-card-component .list-item .list-content .description {
    font-size: 1.5rem;
  }
}
.side-card-component .link-list .list-item {
  display: block;
  color: #999999;
  margin-bottom: 3rem;
  font-size: 1.7rem;
  font-weight: 700;
}
.side-card-component .link-list .link-item {
  color: #1c3E77;
  font-family: "Franklin Gothic URW", sans-serif;
  font-size: 1.7rem;
  font-weight: 700;
}
.side-card-component .contact-wrapper .subtitle {
  font-family: "Franklin Gothic URW", sans-serif;
  color: #999999;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1.4rem;
}

.social-media-component {
  display: flex;
}
.social-media-component .media-icon {
  margin-left: 0.4rem;
}
.social-media-component .media-icon:first-child {
  margin: 0;
}
.social-media-component .media-icon .media-link {
  max-width: 2.4rem;
  display: inline-block;
}
.social-media-component .media-icon .media-link .icon {
  width: 24px;
  height: auto;
}
@media (max-width: 767px) {
  .social-media-component {
    display: none;
  }
}

.invitation-grid {
  flex-wrap: wrap;
  flex-direction: row;
  display: grid;
  grid-template-columns: 47% 47%;
  gap: 10px;
  padding-top: 20px;
}

.invitation-link-desc {
  display: inline-block;
  padding-left: 3px;
  margin-bottom: 0px;
}

.invitation-delete-button {
  float: right;
}

.invitation-card {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
}

.invitation-company {
  color: #1c3E77;
  padding-bottom: 5px;
}

.tooltip-position-wrapper {
  position: relative;
}
@media (max-width: 767px) {
  .tooltip-position-wrapper {
    max-width: 100%;
  }
}

.password-tooltip {
  margin-top: 1.5rem;
}

.contact-tooltip,
.distributor-tooltip,
.compare-tooltip {
  width: 27rem;
  top: 6rem;
}

.password-tooltip,
.contact-tooltip,
.distributor-tooltip,
.compare-tooltip {
  position: absolute;
  z-index: 3;
  display: none;
  background: #ffffff;
  margin-right: -16rem;
  padding: 1.3rem;
  border: 1px solid #b2b2b2;
  /*right: initial;
  transform: translateY(20%);*/
}
.password-tooltip .title,
.contact-tooltip .title,
.distributor-tooltip .title,
.compare-tooltip .title {
  text-transform: none;
  display: block;
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 1.3rem;
}
.password-tooltip .description,
.contact-tooltip .description,
.distributor-tooltip .description,
.compare-tooltip .description {
  text-transform: none;
  display: block;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
}
.password-tooltip span,
.contact-tooltip span,
.distributor-tooltip span,
.compare-tooltip span {
  font-size: 1.5rem;
  display: block;
}
.password-tooltip span.subtitle,
.contact-tooltip span.subtitle,
.distributor-tooltip span.subtitle,
.compare-tooltip span.subtitle {
  font-weight: 700;
  font-size: 1.5rem;
  color: #000000;
  margin-bottom: 0;
}
.password-tooltip ul,
.contact-tooltip ul,
.distributor-tooltip ul,
.compare-tooltip ul {
  position: relative;
}
.password-tooltip li,
.contact-tooltip li,
.distributor-tooltip li,
.compare-tooltip li {
  font-size: 1.5rem;
  margin-left: 4rem;
  color: #000000;
}
.password-tooltip li.validated .checked-icon,
.contact-tooltip li.validated .checked-icon,
.distributor-tooltip li.validated .checked-icon,
.compare-tooltip li.validated .checked-icon {
  color: #1c3E77;
  position: absolute;
  left: 1rem;
}
.password-tooltip li.validated .checked-icon:before,
.contact-tooltip li.validated .checked-icon:before,
.distributor-tooltip li.validated .checked-icon:before,
.compare-tooltip li.validated .checked-icon:before {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  padding-right: 1rem;
  font-size: 1.5rem;
}
.password-tooltip:after, .password-tooltip:before,
.contact-tooltip:after,
.contact-tooltip:before,
.distributor-tooltip:after,
.distributor-tooltip:before,
.compare-tooltip:after,
.compare-tooltip:before {
  bottom: 100%;
  left: 14%;
  /*right: initial;
  top: initial;*/
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.password-tooltip:after,
.contact-tooltip:after,
.distributor-tooltip:after,
.compare-tooltip:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
  border-width: 14px;
  margin-left: -15px;
}
.password-tooltip:before,
.contact-tooltip:before,
.distributor-tooltip:before,
.compare-tooltip:before {
  border-color: rgba(178, 178, 178, 0);
  border-bottom-color: #b2b2b2;
  border-width: 16px;
  margin-left: -17px;
}
.password-tooltip.arrow-bottom:after, .password-tooltip.arrow-bottom:before,
.contact-tooltip.arrow-bottom:after,
.contact-tooltip.arrow-bottom:before,
.distributor-tooltip.arrow-bottom:after,
.distributor-tooltip.arrow-bottom:before,
.compare-tooltip.arrow-bottom:after,
.compare-tooltip.arrow-bottom:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.password-tooltip.arrow-bottom:after,
.contact-tooltip.arrow-bottom:after,
.distributor-tooltip.arrow-bottom:after,
.compare-tooltip.arrow-bottom:after {
  border-color: rgba(136, 183, 213, 0);
  border-top-color: #ffffff;
  border-width: 15px;
  margin-left: -15px;
}
.password-tooltip.arrow-bottom:before,
.contact-tooltip.arrow-bottom:before,
.distributor-tooltip.arrow-bottom:before,
.compare-tooltip.arrow-bottom:before {
  border-color: rgba(79, 69, 63, 0);
  border-top-color: #b2b2b2;
  border-width: 16px;
  margin-left: -16px;
}

.account-shipping-form-success-modal {
  background: white;
  pointer-events: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 4rem;
}

.register-card-component,
.register-card-modal {
  margin: 10rem 0;
}
.register-card-component h5,
.register-card-modal h5 {
  color: #ffffff;
}
.register-card-component h1,
.register-card-modal h1 {
  color: #ffffff;
}
@media (max-width: 767px) {
  .register-card-component,
  .register-card-modal {
    margin: 0 auto 10rem;
  }
}
.register-card-component .content-wrapper,
.register-card-modal .content-wrapper {
  padding: 3rem 13rem;
}
@media (max-width: 1200px) {
  .register-card-component .content-wrapper,
  .register-card-modal .content-wrapper {
    padding: 3rem 5rem;
  }
}
.register-card-component .content-wrapper .form-group,
.register-card-modal .content-wrapper .form-group {
  margin-bottom: 1.7rem;
}
.register-card-component .content-wrapper .primary-button,
.register-card-modal .content-wrapper .primary-button {
  margin-top: 2rem;
}
.register-card-component .media-wrapper,
.register-card-modal .media-wrapper {
  padding: 9rem 0;
  text-align: center;
}
.register-card-component .media-wrapper .icon-wrapper,
.register-card-modal .media-wrapper .icon-wrapper {
  margin-bottom: 5.5rem;
  position: relative;
}
.register-card-component .media-wrapper .icon-wrapper .icon,
.register-card-modal .media-wrapper .icon-wrapper .icon {
  font-size: 10rem;
}
.register-card-component .media-wrapper .email-icon::after,
.register-card-modal .media-wrapper .email-icon::after {
  color: #1c3E77;
  font-size: 2.6rem;
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  border: 3px solid #1c3E77;
  border-radius: 50%;
  padding: 1rem;
  background: #ffffff;
  position: absolute;
  bottom: -1.2rem;
  transform: translateX(-2rem);
  height: 5rem;
}
.register-card-component .media-wrapper .description,
.register-card-modal .media-wrapper .description {
  font-size: 1.5rem;
  color: #999999;
}
.register-card-component .media-wrapper .description a,
.register-card-modal .media-wrapper .description a {
  color: #1c3E77;
}
.register-card-component .EmailSpam,
.register-card-modal .EmailSpam {
  margin-top: 15px;
}
.register-card-component .EmailSpam p,
.register-card-modal .EmailSpam p {
  line-height: 2rem;
}

.register-card-modal .card {
  border-top-left-radius: 1.3rem;
  border-top-right-radius: 1.3rem;
}
.register-card-modal .modal-content {
  border-radius: 1.3rem;
  border: none;
}
.register-card-modal .modal-content .contact {
  color: #ffffff;
}
.register-card-modal .modal-content .content-wrapper {
  padding: 0 1.3rem;
}
.register-card-modal .title-wrapper {
  position: relative;
  display: block;
  padding: 1.6rem 6.3rem;
  background: #1c3E77;
  color: #ffffff;
  border-radius: 6px;
}
.register-card-modal .title-wrapper .title {
  margin-bottom: 1rem;
  color: #ffffff !important;
}
.register-card-modal .title-wrapper span {
  display: block;
}
.register-card-modal .title-wrapper .close {
  position: absolute;
  top: 50%;
  right: 2rem;
  transform: translateY(-50%);
  margin: 0;
}
.register-card-modal .title-wrapper .close span {
  font-size: 2rem;
  color: #ffffff;
}

.modal-title {
  padding: 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #ffffff;
}

.button-container {
  margin: auto;
}

.tool-information-component {
  padding-bottom: 4rem;
  background: #ffffff;
}
.tool-information-component input::-webkit-outer-spin-button,
.tool-information-component input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.tool-information-component input[type=number] {
  -moz-appearance: textfield;
}
.tool-information-component .title-wrapper {
  padding: 12rem 0 3rem;
}
@media (max-width: 767px) {
  .tool-information-component .title-wrapper {
    padding: 2rem 0 1.7rem;
  }
}
@media (max-width: 767px) {
  .tool-information-component .title-wrapper .title {
    font-size: 2rem;
  }
}
@media (max-width: 767px) {
  .tool-information-component .mobile-hide {
    display: none;
  }
}
.tool-information-component .form-section {
  margin-bottom: 5rem;
}
.tool-information-component .form-section:last-child {
  margin-bottom: 2rem;
}
.tool-information-component .form-section .subtitle {
  font-size: 1.8rem;
  font-family: "Franklin Gothic URW", sans-serif;
  font-weight: 400;
  color: #000000;
  margin-bottom: 2.8rem;
}
.tool-information-component .form-section .subtitle select {
  color: #738087;
  cursor: text;
  font-family: "Arial";
  font-size: 13.33px;
  font-stretch: normal;
  font-weight: 400;
}
.tool-information-component .form-section .subtitle + .description {
  margin-top: -2.8rem;
  margin-bottom: 2.8rem;
}
.tool-information-component .form-section .subtitle + .description:last-child {
  margin-left: 5rem;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .tool-information-component .form-section .subtitle {
    font-size: 1.8rem;
  }
}
@media (max-width: 570px) {
  .tool-information-component .form-section .subtitle {
    width: 100%;
  }
}
.tool-information-component .form-section .form-wrapper {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 767px) {
  .tool-information-component .form-section .form-wrapper {
    margin-left: -2rem;
  }
}
.tool-information-component .form-section .form-wrapper > .subtitle {
  margin-bottom: 0;
  margin-right: 2rem;
}
.tool-information-component .form-section .form-wrapper .form-group {
  padding-left: 5rem;
}
@media (max-width: 1200px) {
  .tool-information-component .form-section .form-wrapper .form-group {
    width: auto;
    padding-left: 2rem;
  }
}
@media (max-width: 360px) {
  .tool-information-component .form-section .form-wrapper .form-group {
    width: 100%;
    padding-left: 2rem;
  }
}
@media (max-width: 360px) {
  .tool-information-component .form-section .form-wrapper .form-group.pl-4 {
    padding-left: 0 !important;
  }
}
.tool-information-component .form-section .form-wrapper .form-group.update-btn a {
  padding: 1.1rem 3rem;
}
.tool-information-component .form-section .form-wrapper .form-fluid {
  width: 100%;
}
.tool-information-component .form-section .form-wrapper .half-fluid {
  width: 40%;
}
@media (max-width: 767px) {
  .tool-information-component .form-section .form-wrapper .half-fluid {
    width: 100%;
  }
}
.tool-information-component .form-section .form-wrapper label {
  text-transform: uppercase;
  font-size: 1.2rem;
  color: #000000;
  white-space: nowrap;
}
@media (max-width: 767px) {
  .tool-information-component .form-section .form-wrapper label {
    font-size: 1.1rem;
    text-transform: none;
  }
}
.tool-information-component .form-section .form-wrapper .form-control {
  background: #ffffff;
  border: 1px solid #b2b2b2;
  font-size: 1.5rem;
}
.tool-information-component .form-section .form-wrapper textarea {
  height: 17rem;
}
.tool-information-component .form-section .form-wrapper .etch {
  display: none;
}
.tool-information-component .form-section .form-wrapper.rn-tool-form .r,
.tool-information-component .form-section .form-wrapper.rn-tool-form .a, .tool-information-component .form-section .form-wrapper.s-rn-tool-form .r,
.tool-information-component .form-section .form-wrapper.s-rn-tool-form .a {
  display: none;
}
.tool-information-component .form-section .form-wrapper.cr-rn-tool .a {
  display: none;
}
.tool-information-component .form-section .form-wrapper.cr-tool-form .l3,
.tool-information-component .form-section .form-wrapper.cr-tool-form .d3,
.tool-information-component .form-section .form-wrapper.cr-tool-form .a {
  display: none;
}
.tool-information-component .form-section .form-wrapper.s-tool-form .l3,
.tool-information-component .form-section .form-wrapper.s-tool-form .d3,
.tool-information-component .form-section .form-wrapper.s-tool-form .r,
.tool-information-component .form-section .form-wrapper.s-tool-form .a, .tool-information-component .form-section .form-wrapper.b-tool-form .l3,
.tool-information-component .form-section .form-wrapper.b-tool-form .d3,
.tool-information-component .form-section .form-wrapper.b-tool-form .r,
.tool-information-component .form-section .form-wrapper.b-tool-form .a {
  display: none;
}
.tool-information-component .form-section .form-wrapper.hpcm-tool-form .l3,
.tool-information-component .form-section .form-wrapper.hpcm-tool-form .d3,
.tool-information-component .form-section .form-wrapper.hpcm-tool-form .r,
.tool-information-component .form-section .form-wrapper.hpcm-tool-form .co,
.tool-information-component .form-section .form-wrapper.hpcm-tool-form .chp {
  display: none;
}
.tool-information-component .form-section .form-wrapper.hpcm-tool-form .etch {
  display: inline-block;
}
.tool-information-component .form-section .form-wrapper.hcm-tool-form .l3,
.tool-information-component .form-section .form-wrapper.hcm-tool-form .d1,
.tool-information-component .form-section .form-wrapper.hcm-tool-form .d3,
.tool-information-component .form-section .form-wrapper.hcm-tool-form .r,
.tool-information-component .form-section .form-wrapper.hcm-tool-form .co,
.tool-information-component .form-section .form-wrapper.hcm-tool-form .chp {
  display: none;
}
.tool-information-component .form-section .form-wrapper.hcm-tool-form .etch {
  display: inline-block;
}
.tool-information-component .form-section .form-wrapper .error-text .update-error {
  margin-left: 10rem;
}
.tool-information-component .form-section .form-inline .form-control {
  margin-left: 1.6rem;
  max-width: 15rem;
}
@media (max-width: 767px) {
  .tool-information-component .form-section .form-inline .form-control {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .tool-information-component .form-section .form-inline {
    margin-left: 0;
  }
}
.tool-information-component .form-section .input-fluid {
  width: 100%;
  max-width: 100% !important;
}
.tool-information-component .form-section .input-fluid::placeholder {
  color: red;
}
.tool-information-component .dropzone {
  max-width: 30rem;
  margin-left: 5rem;
  border: 1px dashed #b2b2b2;
  text-align: center;
}
@media (max-width: 767px) {
  .tool-information-component .dropzone {
    margin: 0 auto;
    margin-bottom: 2rem;
  }
}
.tool-information-component .dropzone .icon-upload {
  color: #1c3E77;
  font-size: 4rem;
  pointer-events: none;
}
.tool-information-component .dropzone .dz-message {
  display: none;
}
.tool-information-component .dropzone .subtitle {
  font-size: 2rem;
  pointer-events: none;
}
.tool-information-component .dropzone .primary-button {
  margin-bottom: 2rem;
  padding: 1rem 4rem;
  cursor: pointer;
}
.tool-information-component .dropzone .dz-preview {
  position: relative;
}
.tool-information-component .dropzone .dz-preview .dz-remove:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  font-size: 3rem;
  content: "\f00d";
  color: #8f8f8f;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -27px;
  margin-top: -27px;
  z-index: 999;
  right: 0;
  background: #ffffff;
  width: 54px;
  height: 54px;
  line-height: 54px;
  border-radius: 100%;
  opacity: 0.9;
  border: 1px solid #8f8f8f;
}
.tool-information-component .button-wrapper {
  text-align: center;
  margin-bottom: 5rem;
}
.tool-information-component .button-wrapper .g-recaptcha {
  margin-left: 5rem;
}
.tool-information-component .button-wrapper .primary-button {
  width: 16.4rem;
  padding: 1.5rem 0;
}
.tool-information-component .success-message {
  text-align: center;
  margin: 3rem 0;
}
.tool-information-component .success-message .fa-check-circle {
  font-size: 5rem;
  color: #1c3E77;
  margin-bottom: 3rem;
}
.tool-information-component .success-message p {
  font-size: 3rem;
  font-family: "Franklin Gothic URW", sans-serif;
  color: #1c3E77;
}
.tool-information-component .form-wrapper.measure .input-measure-wrapper {
  position: relative;
}
.tool-information-component .form-wrapper.measure .input-measure-wrapper input {
  font-size: 1.6rem;
}
.tool-information-component .form-wrapper.measure .input-measure-wrapper input:focus {
  background: #ffffff;
}
.tool-information-component .form-wrapper.measure #measure-box {
  text-align: left;
  background: #ffffff;
  color: #999999;
  padding: 1rem;
  font-size: 1.6rem;
  font-family: "Franklin Gothic URW", sans-serif;
  position: absolute;
  left: 0;
  width: 100%;
  top: 40px;
  z-index: 999;
  box-shadow: rgb(51, 51, 51) 0px 0.5px 5px 0px;
}
.tool-information-component .form-wrapper.measure #measure-box .box {
  margin-top: 1.5rem;
}
.tool-information-component .form-wrapper.measure #measure-box .box:nth-child(1) {
  margin-top: 0;
}
.tool-information-component .form-wrapper.measure #measure-box a {
  width: 100%;
  color: #999999;
  font-size: 1.3rem;
  font-family: "Franklin Gothic URW", sans-serif;
  line-height: 2rem;
  display: block;
}

.drawing-measurements {
  padding: 2.7rem 0 2rem;
  background: #ffffff;
  margin-bottom: 5rem;
}
.drawing-measurements .title {
  font-family: "Franklin Gothic URW", sans-serif;
  font-weight: 400;
  font-size: 2.2rem;
  text-transform: none;
  color: #000000;
  margin-bottom: 2rem;
}
@media (max-width: 767px) {
  .drawing-measurements .title {
    font-size: 1.8rem;
  }
}
.drawing-measurements .custom-form {
  text-align: center;
  width: 104.8rem;
  position: relative;
  margin: 0 auto;
}
@media (max-width: 1100px) {
  .drawing-measurements .custom-form {
    width: 74rem;
  }
}
@media (max-width: 767px) {
  .drawing-measurements .custom-form {
    width: auto;
  }
}
.drawing-measurements .custom-form img {
  width: 100%;
}
@media (max-width: 767px) {
  .drawing-measurements .custom-form .tool-image {
    display: none;
  }
}
.drawing-measurements .custom-form .mobile-tool-image {
  display: none;
}
@media (max-width: 767px) {
  .drawing-measurements .custom-form .mobile-tool-image {
    display: block;
  }
}
.drawing-measurements .custom-form .form-control {
  width: 15rem;
  background: #ffffff;
  border: 1px solid #b2b2b2;
  font-size: 1.4rem;
  position: absolute;
  padding: 0 0 0 0.4rem;
}
@media (max-width: 1100px) {
  .drawing-measurements .custom-form .form-control {
    width: 9rem;
  }
}
@media (max-width: 767px) {
  .drawing-measurements .custom-form .form-control {
    display: none;
  }
}
.drawing-measurements .custom-form .form-control::placeholder {
  color: #999999;
  font-size: 1.2rem;
}
.drawing-measurements .custom-form input[type=number].form-control:disabled {
  background: #F5F5F5;
}
.drawing-measurements .custom-form.rn-tool .l1, .drawing-measurements .custom-form.cr-rn-tool .l1, .drawing-measurements .custom-form.s-rn-tool .l1 {
  top: 13.4rem;
  left: 65.2rem;
}
@media (max-width: 1100px) {
  .drawing-measurements .custom-form.rn-tool .l1, .drawing-measurements .custom-form.cr-rn-tool .l1, .drawing-measurements .custom-form.s-rn-tool .l1 {
    top: 8.8rem;
    left: 46rem;
  }
}
.drawing-measurements .custom-form.rn-tool .l2, .drawing-measurements .custom-form.cr-rn-tool .l2, .drawing-measurements .custom-form.s-rn-tool .l2 {
  top: 5.8rem;
  left: 7.2rem;
}
@media (max-width: 1100px) {
  .drawing-measurements .custom-form.rn-tool .l2, .drawing-measurements .custom-form.cr-rn-tool .l2, .drawing-measurements .custom-form.s-rn-tool .l2 {
    top: 4rem;
    left: 4.2rem;
  }
}
.drawing-measurements .custom-form.rn-tool .l3, .drawing-measurements .custom-form.cr-rn-tool .l3, .drawing-measurements .custom-form.s-rn-tool .l3 {
  top: 9.8rem;
  left: 41.2rem;
}
@media (max-width: 1100px) {
  .drawing-measurements .custom-form.rn-tool .l3, .drawing-measurements .custom-form.cr-rn-tool .l3, .drawing-measurements .custom-form.s-rn-tool .l3 {
    top: 7rem;
    left: 30.2rem;
  }
}
.drawing-measurements .custom-form.rn-tool .d1, .drawing-measurements .custom-form.cr-rn-tool .d1, .drawing-measurements .custom-form.s-rn-tool .d1 {
  top: 21.8rem;
  left: 87.2rem;
}
@media (max-width: 1100px) {
  .drawing-measurements .custom-form.rn-tool .d1, .drawing-measurements .custom-form.cr-rn-tool .d1, .drawing-measurements .custom-form.s-rn-tool .d1 {
    top: 15rem;
    left: 61.6rem;
  }
}
.drawing-measurements .custom-form.rn-tool .d2, .drawing-measurements .custom-form.cr-rn-tool .d2, .drawing-measurements .custom-form.s-rn-tool .d2 {
  top: 12.8rem;
  left: 12.2rem;
}
@media (max-width: 1100px) {
  .drawing-measurements .custom-form.rn-tool .d2, .drawing-measurements .custom-form.cr-rn-tool .d2, .drawing-measurements .custom-form.s-rn-tool .d2 {
    top: 9rem;
    left: 9.2rem;
  }
}
.drawing-measurements .custom-form.rn-tool .d3, .drawing-measurements .custom-form.cr-rn-tool .d3, .drawing-measurements .custom-form.s-rn-tool .d3 {
  top: 31rem;
  left: 36rem;
}
@media (max-width: 1100px) {
  .drawing-measurements .custom-form.rn-tool .d3, .drawing-measurements .custom-form.cr-rn-tool .d3, .drawing-measurements .custom-form.s-rn-tool .d3 {
    top: 22rem;
    left: 26rem;
  }
}
.drawing-measurements .custom-form.rn-tool .r, .drawing-measurements .custom-form.cr-rn-tool .r, .drawing-measurements .custom-form.s-rn-tool .r {
  display: none;
}
.drawing-measurements .custom-form.rn-tool .a, .drawing-measurements .custom-form.cr-rn-tool .a, .drawing-measurements .custom-form.s-rn-tool .a {
  display: none;
}
.drawing-measurements .custom-form.cr-rn-tool .l1, .drawing-measurements .custom-form.s-rn-tool .l1 {
  top: 14.4rem;
}
@media (max-width: 1100px) {
  .drawing-measurements .custom-form.cr-rn-tool .l1, .drawing-measurements .custom-form.s-rn-tool .l1 {
    top: 9.8rem;
  }
}
.drawing-measurements .custom-form.cr-rn-tool .l2, .drawing-measurements .custom-form.s-rn-tool .l2 {
  top: 7.4rem;
}
@media (max-width: 1100px) {
  .drawing-measurements .custom-form.cr-rn-tool .l2, .drawing-measurements .custom-form.s-rn-tool .l2 {
    top: 5rem;
  }
}
.drawing-measurements .custom-form.cr-rn-tool .l3, .drawing-measurements .custom-form.s-rn-tool .l3 {
  top: 11.4rem;
}
@media (max-width: 1100px) {
  .drawing-measurements .custom-form.cr-rn-tool .l3, .drawing-measurements .custom-form.s-rn-tool .l3 {
    top: 7.4rem;
  }
}
.drawing-measurements .custom-form.cr-rn-tool .d1, .drawing-measurements .custom-form.s-rn-tool .d1 {
  top: 22.2rem;
  left: 88.2rem;
}
@media (max-width: 1100px) {
  .drawing-measurements .custom-form.cr-rn-tool .d1, .drawing-measurements .custom-form.s-rn-tool .d1 {
    left: 62.6rem;
  }
}
.drawing-measurements .custom-form.cr-rn-tool .d2, .drawing-measurements .custom-form.s-rn-tool .d2 {
  top: 32rem;
}
@media (max-width: 1100px) {
  .drawing-measurements .custom-form.cr-rn-tool .d2, .drawing-measurements .custom-form.s-rn-tool .d2 {
    top: 23rem;
  }
}
.drawing-measurements .custom-form.cr-rn-tool .d3, .drawing-measurements .custom-form.s-rn-tool .d3 {
  left: 40rem;
}
@media (max-width: 1100px) {
  .drawing-measurements .custom-form.cr-rn-tool .d3, .drawing-measurements .custom-form.s-rn-tool .d3 {
    left: 29rem;
  }
}
.drawing-measurements .custom-form.cr-rn-tool .r, .drawing-measurements .custom-form.s-rn-tool .r {
  display: block;
  top: 29.8rem;
  left: 60.2rem;
}
@media (max-width: 1100px) {
  .drawing-measurements .custom-form.cr-rn-tool .r, .drawing-measurements .custom-form.s-rn-tool .r {
    top: 21rem;
    left: 42rem;
  }
}
@media (max-width: 767px) {
  .drawing-measurements .custom-form.cr-rn-tool .r, .drawing-measurements .custom-form.s-rn-tool .r {
    display: none;
  }
}
.drawing-measurements .custom-form.cr-rn-tool .a, .drawing-measurements .custom-form.s-rn-tool .a {
  display: none;
}
.drawing-measurements .custom-form.s-rn-tool .l1 {
  top: 14rem;
  left: 66.2rem;
}
@media (max-width: 1100px) {
  .drawing-measurements .custom-form.s-rn-tool .l1 {
    top: 9.6rem;
    left: 46.6rem;
  }
}
.drawing-measurements .custom-form.s-rn-tool .l3 {
  top: 10.4rem;
}
@media (max-width: 1100px) {
  .drawing-measurements .custom-form.s-rn-tool .l3 {
    top: 7.4rem;
  }
}
.drawing-measurements .custom-form.s-rn-tool .d1 {
  left: 88.8rem;
}
@media (max-width: 1100px) {
  .drawing-measurements .custom-form.s-rn-tool .d1 {
    left: 61.6rem;
  }
}
.drawing-measurements .custom-form.s-rn-tool .r {
  display: none;
}
.drawing-measurements .custom-form.cr-tool .l1, .drawing-measurements .custom-form.s-tool .l1, .drawing-measurements .custom-form.b-tool .l1 {
  top: 12.4rem;
  left: 63.4rem;
}
@media (max-width: 1100px) {
  .drawing-measurements .custom-form.cr-tool .l1, .drawing-measurements .custom-form.s-tool .l1, .drawing-measurements .custom-form.b-tool .l1 {
    top: 8.2rem;
    left: 44.4rem;
  }
}
.drawing-measurements .custom-form.cr-tool .l2, .drawing-measurements .custom-form.s-tool .l2, .drawing-measurements .custom-form.b-tool .l2 {
  top: 5.4rem;
  left: 17.2rem;
}
@media (max-width: 1100px) {
  .drawing-measurements .custom-form.cr-tool .l2, .drawing-measurements .custom-form.s-tool .l2, .drawing-measurements .custom-form.b-tool .l2 {
    top: 3.4rem;
    left: 13.2rem;
  }
}
.drawing-measurements .custom-form.cr-tool .l3, .drawing-measurements .custom-form.s-tool .l3, .drawing-measurements .custom-form.b-tool .l3 {
  display: none;
}
.drawing-measurements .custom-form.cr-tool .d1, .drawing-measurements .custom-form.s-tool .d1, .drawing-measurements .custom-form.b-tool .d1 {
  top: 25.8rem;
  left: 92.6rem;
}
@media (max-width: 1100px) {
  .drawing-measurements .custom-form.cr-tool .d1, .drawing-measurements .custom-form.s-tool .d1, .drawing-measurements .custom-form.b-tool .d1 {
    top: 18rem;
    left: 64.6rem;
  }
}
.drawing-measurements .custom-form.cr-tool .d2, .drawing-measurements .custom-form.s-tool .d2, .drawing-measurements .custom-form.b-tool .d2 {
  top: 25.4rem;
  left: 31.4rem;
}
@media (max-width: 1100px) {
  .drawing-measurements .custom-form.cr-tool .d2, .drawing-measurements .custom-form.s-tool .d2, .drawing-measurements .custom-form.b-tool .d2 {
    top: 18rem;
    left: 22.2rem;
  }
}
.drawing-measurements .custom-form.cr-tool .d3, .drawing-measurements .custom-form.s-tool .d3, .drawing-measurements .custom-form.b-tool .d3 {
  display: none;
}
.drawing-measurements .custom-form.cr-tool .r, .drawing-measurements .custom-form.s-tool .r, .drawing-measurements .custom-form.b-tool .r {
  top: 34.4rem;
  left: 62.2rem;
}
@media (max-width: 1100px) {
  .drawing-measurements .custom-form.cr-tool .r, .drawing-measurements .custom-form.s-tool .r, .drawing-measurements .custom-form.b-tool .r {
    top: 24rem;
    left: 44.2rem;
  }
}
.drawing-measurements .custom-form.cr-tool .a, .drawing-measurements .custom-form.s-tool .a, .drawing-measurements .custom-form.b-tool .a {
  display: none;
}
.drawing-measurements .custom-form.s-tool .l1 {
  left: 61.4rem;
}
@media (max-width: 1100px) {
  .drawing-measurements .custom-form.s-tool .l1 {
    top: 8.4rem;
    left: 44rem;
  }
}
.drawing-measurements .custom-form.s-tool .l2 {
  top: 5rem;
  left: 16.2rem;
}
@media (max-width: 1100px) {
  .drawing-measurements .custom-form.s-tool .l2 {
    top: 2.8rem;
    left: 13.2rem;
  }
}
.drawing-measurements .custom-form.s-tool .d1 {
  top: 26.6rem;
  left: 91.8rem;
}
@media (max-width: 1100px) {
  .drawing-measurements .custom-form.s-tool .d1 {
    top: 18rem;
    left: 64.8rem;
  }
}
.drawing-measurements .custom-form.s-tool .d2 {
  top: 26.4rem;
  left: 29.2rem;
}
@media (max-width: 1100px) {
  .drawing-measurements .custom-form.s-tool .d2 {
    top: 18rem;
    left: 20.8rem;
  }
}
.drawing-measurements .custom-form.s-tool .r {
  display: none;
}
.drawing-measurements .custom-form.b-tool .l1 {
  top: 13.6rem;
  left: 62.4rem;
}
@media (max-width: 1100px) {
  .drawing-measurements .custom-form.b-tool .l1 {
    top: 9.2rem;
    left: 43.4rem;
  }
}
.drawing-measurements .custom-form.b-tool .l2 {
  top: 9rem;
  left: 16.2rem;
}
@media (max-width: 1100px) {
  .drawing-measurements .custom-form.b-tool .l2 {
    top: 5.8rem;
    left: 13.2rem;
  }
}
.drawing-measurements .custom-form.b-tool .d1 {
  top: 25.8rem;
  left: 91.4rem;
}
@media (max-width: 1100px) {
  .drawing-measurements .custom-form.b-tool .d1 {
    top: 17.6rem;
    left: 64.6rem;
  }
}
.drawing-measurements .custom-form.b-tool .d2 {
  top: 26.4rem;
  left: 31.2rem;
}
@media (max-width: 1100px) {
  .drawing-measurements .custom-form.b-tool .d2 {
    top: 18rem;
    left: 22.2rem;
  }
}
.drawing-measurements .custom-form.b-tool .r {
  display: none;
}
.drawing-measurements .custom-form.hpcm-tool .l1, .drawing-measurements .custom-form.hcm-tool .l1 {
  top: 12.2rem;
  left: 49.2rem;
}
@media (max-width: 1100px) {
  .drawing-measurements .custom-form.hpcm-tool .l1, .drawing-measurements .custom-form.hcm-tool .l1 {
    top: 8.2rem;
    left: 34.4rem;
  }
}
.drawing-measurements .custom-form.hpcm-tool .l2, .drawing-measurements .custom-form.hcm-tool .l2 {
  top: 6.6rem;
  left: 12.2rem;
}
@media (max-width: 1100px) {
  .drawing-measurements .custom-form.hpcm-tool .l2, .drawing-measurements .custom-form.hcm-tool .l2 {
    top: 4.4rem;
    left: 9.2rem;
  }
}
.drawing-measurements .custom-form.hpcm-tool .l3, .drawing-measurements .custom-form.hcm-tool .l3 {
  display: none;
}
.drawing-measurements .custom-form.hpcm-tool .d1, .drawing-measurements .custom-form.hcm-tool .d1 {
  top: 34.4rem;
  left: 67rem;
}
@media (max-width: 1100px) {
  .drawing-measurements .custom-form.hpcm-tool .d1, .drawing-measurements .custom-form.hcm-tool .d1 {
    top: 24.4rem;
    left: 47.6rem;
  }
}
.drawing-measurements .custom-form.hpcm-tool .d2, .drawing-measurements .custom-form.hcm-tool .d2 {
  top: 25.4rem;
  left: 31.8rem;
}
@media (max-width: 1100px) {
  .drawing-measurements .custom-form.hpcm-tool .d2, .drawing-measurements .custom-form.hcm-tool .d2 {
    top: 17rem;
    left: 22.8rem;
  }
}
.drawing-measurements .custom-form.hpcm-tool .d3, .drawing-measurements .custom-form.hcm-tool .d3 {
  display: none;
}
.drawing-measurements .custom-form.hpcm-tool .r, .drawing-measurements .custom-form.hcm-tool .r {
  display: none;
}
.drawing-measurements .custom-form.hpcm-tool .a, .drawing-measurements .custom-form.hcm-tool .a {
  top: 25.2rem;
  left: 84rem;
}
@media (max-width: 1100px) {
  .drawing-measurements .custom-form.hpcm-tool .a, .drawing-measurements .custom-form.hcm-tool .a {
    top: 17.2rem;
    left: 59.2rem;
  }
}
.drawing-measurements .custom-form.hcm-tool .l1 {
  top: 11rem;
  left: 51.8rem;
}
@media (max-width: 1100px) {
  .drawing-measurements .custom-form.hcm-tool .l1 {
    top: 7.2rem;
    left: 36rem;
  }
}
@media (max-width: 1100px) {
  .drawing-measurements .custom-form.hcm-tool .l2 {
    top: 4.2rem;
  }
}
.drawing-measurements .custom-form.hcm-tool .d1 {
  display: none;
}
.drawing-measurements .custom-form.hcm-tool .d2 {
  top: 25.6rem;
  left: 28.8rem;
}
@media (max-width: 1100px) {
  .drawing-measurements .custom-form.hcm-tool .d2 {
    top: 17.4rem;
    left: 20.8rem;
  }
}
.drawing-measurements .custom-form.hcm-tool .a {
  top: 25.4rem;
  left: 84.8rem;
}
@media (max-width: 1100px) {
  .drawing-measurements .custom-form.hcm-tool .a {
    top: 17.2rem;
    left: 59.2rem;
  }
}
.drawing-measurements .form-wrapper.measure .input-measure-wrapper {
  position: relative;
}
.drawing-measurements .form-wrapper.measure .input-measure-wrapper input {
  font-size: 1.6rem;
}
.drawing-measurements .form-wrapper.measure .input-measure-wrapper input:focus {
  background: #ffffff;
}
.drawing-measurements .form-wrapper.measure #measure-box {
  text-align: left;
  background: #ffffff;
  color: #999999;
  padding: 1rem;
  font-size: 1.6rem;
  font-family: "Franklin Gothic URW", sans-serif;
  position: absolute;
  left: 0;
  width: 100%;
  top: 40px;
  z-index: 999;
  box-shadow: rgb(51, 51, 51) 0px 0.5px 5px 0px;
}
.drawing-measurements .form-wrapper.measure #measure-box .box {
  margin-top: 1.5rem;
}
.drawing-measurements .form-wrapper.measure #measure-box .box:nth-child(1) {
  margin-top: 0;
}
.drawing-measurements .form-wrapper.measure #measure-box a {
  width: 100%;
  color: #999999;
  font-size: 1.3rem;
  font-family: "Franklin Gothic URW", sans-serif;
  line-height: 2rem;
  display: block;
}

.contact-component {
  background: #e5e5e5;
  padding: 3.9rem 0 7.1rem;
  text-align: center;
}
@media (max-width: 767px) {
  .contact-component {
    text-align: left;
  }
}
@media (max-width: 767px) {
  .contact-component .container {
    padding: 0 3rem;
  }
}
.contact-component .title {
  font-family: "Franklin Gothic URW", sans-serif;
  text-transform: none;
}
@media (max-width: 767px) {
  .contact-component .title {
    font-size: 1.9rem;
    margin-bottom: 1rem;
  }
}
.contact-component .subtitle {
  margin-bottom: 6.7rem;
}
@media (max-width: 767px) {
  .contact-component .subtitle {
    font-size: 1.6rem;
    margin-bottom: 3.5rem;
  }
}
.contact-component .icon-row {
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  max-width: 40rem;
}
@media (max-width: 767px) {
  .contact-component .icon-row {
    display: block;
  }
}
.contact-component .icon-row .icon-wrapper {
  display: flex;
  flex-direction: column;
  width: 16rem;
}
@media (max-width: 767px) {
  .contact-component .icon-row .icon-wrapper {
    text-align: center;
    width: 100%;
    margin-bottom: 3rem;
  }
}
.contact-component .icon-row .icon-wrapper .icon {
  font-size: 10rem;
  margin-bottom: 2.7rem;
}
.contact-component .icon-row .icon-wrapper .primary-button {
  width: 100%;
}

.tool-description-component {
  background: #ffffff;
}
.tool-description-component .title-wrapper {
  padding: 11rem 0 4rem;
  border-bottom: 1px solid #999999;
}
@media (max-width: 767px) {
  .tool-description-component .title-wrapper {
    padding: 2rem 1.5rem 2.4rem;
    border: none;
  }
}
@media (max-width: 767px) {
  .tool-description-component .title-wrapper .title {
    font-size: 2rem;
  }
}
@media (max-width: 767px) {
  .tool-description-component .title-wrapper .subtitle {
    font-size: 1.5rem;
  }
}
.tool-description-component .content-wrapper {
  padding: 6rem 0;
}
@media (max-width: 767px) {
  .tool-description-component .content-wrapper {
    padding: 0;
  }
}
.tool-description-component .content-wrapper .tool-row {
  position: relative;
}
.tool-description-component .content-wrapper .tool-row .subtitle {
  position: absolute;
  font-weight: 400;
  padding-right: 14rem;
  font-family: "Franklin Gothic URW", sans-serif;
  text-transform: none;
  font-size: 2rem;
  background: #ffffff;
  transform: translateY(-50%);
}
@media (max-width: 767px) {
  .tool-description-component .content-wrapper .tool-row .subtitle {
    position: static;
    transform: translateY(0);
    margin-bottom: 1.2rem;
  }
}
.tool-description-component .content-wrapper .tool-row .content {
  display: flex;
  flex-wrap: wrap;
  padding: 5rem 5rem;
  border-top: 1px solid #999999;
}
@media (max-width: 767px) {
  .tool-description-component .content-wrapper .tool-row .content {
    padding: 3rem;
    margin: 0 -1.5rem;
  }
}
.tool-description-component .content-wrapper .tool-row .tool-item {
  width: 25%;
  margin-bottom: 3.7rem;
}
@media (max-width: 767px) {
  .tool-description-component .content-wrapper .tool-row .tool-item {
    width: 100%;
  }
}
.tool-description-component .content-wrapper .tool-row .tool-item .item-link {
  display: block;
  max-width: 30rem;
}
.tool-description-component .content-wrapper .tool-row .tool-item .img-wrapper {
  margin-bottom: 2rem;
  max-width: 20rem;
}
.tool-description-component .content-wrapper .tool-row .tool-item .tool-title {
  display: inline-block;
  font-size: 1.8rem;
  font-family: "Franklin Gothic URW", sans-serif;
  color: #000000;
  margin: 0;
}
.tool-description-component .content-wrapper .tool-row .tool-item .tool-decription {
  font-size: 1.5rem;
  max-width: 30rem;
  line-height: 2rem;
  font-family: "Franklin Gothic URW", sans-serif;
}

.media-hero-component {
  border-bottom: 1px solid #b2b2b2;
  background: #ffffff;
}
.media-hero-component .content-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 1300px) {
  .media-hero-component .content-wrapper {
    padding-left: 4rem;
  }
}
.media-hero-component .content-wrapper .content {
  max-width: 42rem;
}
@media (max-width: 767px) {
  .media-hero-component .content-wrapper .content {
    padding: 3.4rem 3.6rem 3.1rem;
  }
}
@media (max-width: 767px) {
  .media-hero-component .content-wrapper .content .title {
    font-size: 2rem;
  }
}
.media-hero-component .content-wrapper .content .subtitle {
  margin-bottom: 1.5rem;
}
@media (max-width: 767px) {
  .media-hero-component .content-wrapper .content .subtitle {
    font-size: 1.3rem;
  }
}
@media (max-width: 767px) {
  .media-hero-component .content-wrapper .content .large {
    font-size: 1.4rem;
  }
}
.media-hero-component .icon-wrapper {
  display: flex;
}
.media-hero-component .icon-wrapper .icon {
  margin-right: 2rem;
  text-align: center;
  max-width: 8.8rem;
}
.media-hero-component .icon-wrapper .icon img {
  display: inline-block;
  width: 100%;
  margin-bottom: 1rem;
}
.media-hero-component .icon-wrapper .icon .icon-description {
  color: #000000;
  font-size: 1.2rem;
  font-family: "Arimo", sans-serif;
}
.media-hero-component .media-wrapper {
  padding: 0;
}
.media-hero-component .media-wrapper img {
  width: 100%;
}
.media-hero-component .media-wrapper.media-wide {
  display: flex;
  align-items: center;
  margin: 8rem 0 8rem;
}
@media (max-width: 767px) {
  .media-hero-component .media-wrapper.media-wide {
    padding-left: 2rem;
    order: 1;
    margin: 10rem 0 4rem;
  }
}
.media-hero-component .media-wrapper.media-wide img {
  object-fit: cover;
  object-position: left;
  height: 38rem;
}
@media (max-width: 767px) {
  .media-hero-component .media-wrapper.media-wide img {
    height: 20rem;
  }
}
@media (max-width: 767px) {
  .media-hero-component .content-wrapper {
    order: 2;
  }
  .media-hero-component .media-wrapper {
    order: 1;
    margin-bottom: 3rem;
  }
}

.catalog-grid-component {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-around;
  padding: 8.3rem 0 0;
}
@media (max-width: 767px) {
  .catalog-grid-component {
    padding: 2.4rem 0 0;
  }
}
.catalog-grid-component .catalog-item {
  padding: 0 2rem;
  margin-bottom: 9.5rem;
  text-align: center;
  width: 33.33%;
  max-width: 45rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 767px) {
  .catalog-grid-component .catalog-item {
    width: 100%;
    margin-bottom: 3.4rem;
  }
}
.catalog-grid-component .catalog-item .img-wrapper {
  max-width: 30rem;
}
@media (max-width: 767px) {
  .catalog-grid-component .catalog-item .img-wrapper {
    max-width: 20rem;
  }
}
.catalog-grid-component .catalog-item .subtitle {
  text-transform: uppercase;
  margin-bottom: 2rem;
  font-size: 2rem;
  font-weight: 500;
}
@media (max-width: 767px) {
  .catalog-grid-component .catalog-item .subtitle {
    font-size: 1.5rem;
  }
}
.catalog-grid-component .catalog-item .catalog-image {
  margin-bottom: 1.4rem;
}
@media (max-width: 767px) {
  .catalog-grid-component .catalog-item p {
    font-size: 1.2rem;
    max-width: 45rem;
    margin: 0 auto;
    line-height: 1.7;
  }
}

.product-grid-component {
  padding: 10rem 0;
  background: #ffffff;
}
.product-grid-component .item-wrapper {
  text-align: center;
}
@media (max-width: 767px) {
  .product-grid-component .item-wrapper {
    max-width: 45rem;
    margin: 0 auto 3rem;
  }
}
.product-grid-component .item-wrapper .img-wrapper {
  /*margin-right: 4.2rem;*/
  max-width: 6.4rem;
  margin: 0 auto;
  float: left !important;
  margin-right: 15px;
  /*flex-basis: 100%;*/
}
@media (max-width: 1020px) {
  .product-grid-component .item-wrapper .img-wrapper {
    margin-right: 2.4rem;
    min-width: 4rem;
  }
}
@media (max-width: 767px) {
  .product-grid-component .item-wrapper .img-wrapper {
    max-width: 5.2rem;
    margin-right: 25px;
  }
}
.product-grid-component .item-wrapper .img-wrapper img {
  width: 100%;
  width: auto;
}
.product-grid-component .item-wrapper .content-wrapper {
  text-align: left;
}
.product-grid-component .item-wrapper .content-wrapper .subtitle {
  margin-bottom: 1.5rem;
  padding-top: 6%;
}
@media (max-width: 767px) {
  .product-grid-component .item-wrapper .content-wrapper .subtitle {
    font-size: 1.6rem;
  }
}
.product-grid-component .item-wrapper .content-wrapper .item-description {
  font-size: 1.5rem;
  color: #000000;
  padding-left: 75px;
}
@media (max-width: 1020px) {
  .product-grid-component .item-wrapper .content-wrapper .item-description {
    font-size: 1.4rem;
    padding-left: 104px;
  }
}
@media (max-width: 767px) {
  .product-grid-component .item-wrapper .content-wrapper .item-description {
    font-size: 1.2rem;
    padding-left: 76px !important;
  }
}
@media (max-width: 767px) {
  .product-grid-component .item-wrapper {
    align-items: flex-start;
    margin-bottom: 3rem;
  }
  .product-grid-component .item-wrapper:last-child {
    margin-bottom: 0;
  }
}

.media-link-list-component, .product-resources-section {
  background-color: #1c3E77;
  color: #ffffff;
  margin-top: 3rem;
}
.media-link-list-component a, .product-resources-section a {
  color: #ffffff;
}
.media-link-list-component .icon::before, .product-resources-section .icon::before {
  color: #ffffff;
}

.product-table-component .product-table {
  font-size: 1.2rem !important;
  overflow: auto;
  color: #3D3C3C;
  text-align: center;
  border-collapse: collapse;
}
.product-table-component .icon:hover {
  color: #1c3E77;
  cursor: pointer;
}
.product-table-component .cartSelect:hover {
  color: #EB642D;
  cursor: pointer;
}
.product-table-component a:hover {
  color: #1c3E77;
}
.product-table-component tr:nth-child(odd) {
  background-color: white;
}
.product-table-component tr:nth-child(even) {
  background-color: #e5e5e5;
}

.product-table-subheader {
  background-color: #e5e5e5;
}

.product-table-thead {
  text-align: center;
}
.product-table-thead .sorting {
  position: relative;
}
.product-table-thead .sorting:after {
  content: "\f0dc";
  color: #000;
  right: 5px;
  font-size: 1.5rem;
  top: 35%;
  position: absolute;
  font-family: "Font Awesome 5 Free";
}
.product-table-thead .sorting_asc {
  position: relative;
}
.product-table-thead .sorting_asc:after {
  content: "\f0de";
  color: #000;
  right: 5px;
  font-size: 1.5rem;
  top: 35%;
  position: absolute;
  font-family: "Font Awesome 5 Free";
}
.product-table-thead .sorting_desc {
  position: relative;
}
.product-table-thead .sorting_desc:after {
  content: "\f0dd";
  color: #000;
  right: 5px;
  font-size: 1.5rem;
  top: 35%;
  position: absolute;
  font-family: "Font Awesome 5 Free";
}

.dataTables_scrollBody .sorting:after,
.dataTables_scrollBody .sorting_asc:after,
.dataTables_scrollBody .sorting_desc:after {
  display: none;
}

.dataTables_wrapper {
  width: 100%;
}

.dataTables_wrapper .dataTables_scroll {
  overflow-x: auto;
  overflow-y: auto;
  width: 100%;
}

.dataTables_empty {
  display: none;
}

div.dataTables_scrollFootInner .dataTables_filter {
  display: none;
}

.product-table-tbody td {
  text-align: center;
  border-right: 1px solid #999999;
  border-bottom: 1px solid #999999;
}
.product-table-tbody td:last-child {
  border-right: 0px;
}
.product-table-tbody td > a {
  text-decoration: underline;
  font-size: 1.5rem;
  font-weight: 500;
}
.product-table-tbody .dropdown-wrapper .new-list-dropdown {
  width: 232px;
}
@media (max-width: 767px) {
  .product-table-tbody .dropdown-wrapper .new-list-dropdown {
    left: auto;
    right: 0;
  }
}
.product-table-tbody .dropdown-wrapper .new-list-dropdown .close-button .close-icon {
  font-size: 2rem;
}
.product-table-tbody .dropdown-wrapper .new-list-dropdown form .form-control {
  width: 100%;
  max-width: 100%;
  margin: 4px 0;
  display: block;
}
.product-table-tbody .dropdown-wrapper .new-list-dropdown form .quantity-wrapper input {
  padding: 0;
}

.product-table-datum {
  min-width: 75px;
}

.dataTable tbody th,
.dataTable tbody td {
  padding: 4px 5px;
}

table.dataTable thead th,
table.dataTable thead td {
  border: 0;
  padding: 5px 15px;
}

.bold {
  font-weight: bold;
}

table.dataTable thead th.white-text {
  color: #ffffff !important;
}

.product-table-td-note {
  background: white;
  font-size: 12px;
  text-align: left;
  position: relative;
  font-style: italic;
}

.dataTables_info {
  display: none;
}

.hpc-inline {
  display: inline-block;
  vertical-align: middle;
}

.hpc-block {
  display: block;
  margin-left: 4px;
}

.hpc-top {
  font-size: 75%;
  font-weight: bold;
  margin-bottom: -5px;
}

.hpc-bottom {
  font-size: 75%;
  font-weight: bold;
  display: block;
  vertical-align: top;
  margin-top: -10px;
}

.t {
  border-top: 1px solid #999999 !important;
}

.b {
  border-bottom: 1px solid #999999 !important;
}

.l {
  border-left: 1px solid #999999 !important;
}

.r {
  border-right: 1px solid #999999 !important;
}

@media (min-width: 768px) {
  .dataTables_filter {
    display: none;
  }
}
@media (max-width: 767px) {
  #product-table-advanced-search {
    display: none;
  }
  .dataTables_scrollFoot {
    width: 100% !important;
  }
  .dataTables_wrapper .dataTables_filter {
    margin-bottom: 1.5rem;
    float: right;
    margin-top: 0px;
    position: relative;
  }
  .dataTables_wrapper .dataTables_filter input {
    display: inline-block;
    font-size: 1.6rem;
    padding: 0 4rem 0 2rem;
    font-family: "Franklin Gothic URW", sans-serif;
    font-weight: 400;
    border: none;
    background: #e5e5e5;
    line-height: 3rem;
    height: 4rem;
    max-width: 200px;
    outline: none;
  }
  .dataTables_wrapper .dataTables_filter input::-webkit-search-decoration, .dataTables_wrapper .dataTables_filter input::-webkit-search-cancel-button, .dataTables_wrapper .dataTables_filter input::-webkit-search-results-button, .dataTables_wrapper .dataTables_filter input::-webkit-search-results-decoration {
    display: none;
  }
  .dataTables_wrapper .dataTables_filter:before {
    display: block;
    position: absolute;
    z-index: 0;
    right: 1rem;
    font-family: "helical-icons";
    content: "\e93f";
    top: 50%;
    font-size: 2.8rem;
    transform: translateY(-50%);
    cursor: pointer;
  }
  .dataTables_wrapper .dataTables_length {
    float: left;
    background: #e5e5e5;
    height: 4rem;
    font-family: "Franklin Gothic URW", sans-serif;
    font-size: 1.6rem;
    padding: 1rem;
    color: #999999;
  }
  .dataTables_wrapper .dataTables_length select {
    border: none;
    background: #e5e5e5;
    color: #999999;
    outline: none;
  }
  .dataTables_wrapper .dataTables_paginate {
    margin-top: 0;
    padding-top: 0;
    display: none;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button {
    color: #1c3E77 !important;
    outline: none;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    opacity: 0.5;
    border: 0px !important;
    background: #ffffff;
    color: #1c3E77 !important;
    box-shadow: none;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button.disabled {
    color: #b2b2b2 !important;
    pointer-events: none;
  }
}
/*.dataTables_scrollHead,
.dataTables_scrollBody,
.dataTables_scrollFoot {
overflow:unset!important;
}
.dataTables_scroll {
    overflow:scroll!important;
}*/
.media-link-list-component .list-wrapper {
  padding: 1.3rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}
@media (max-width: 768px) {
  .media-link-list-component .list-wrapper {
    display: none !important;
  }
}
.media-link-list-component .list-wrapper .list-item {
  font-size: 0;
  margin-right: 1.2rem;
  margin-bottom: 1rem;
}
@media (max-width: 991px) {
  .media-link-list-component .list-wrapper .list-item {
    display: inline-block;
    margin-bottom: 2.3rem;
  }
  .media-link-list-component .list-wrapper .list-item:last-child {
    margin: 0;
  }
}
@media (max-width: 480px) {
  .media-link-list-component .list-wrapper .list-item {
    display: block;
  }
}
.media-link-list-component .list-wrapper .list-item .icon-wrapper {
  display: inline-block;
  overflow-x: hidden;
  vertical-align: middle;
  line-height: 1;
}
.media-link-list-component .list-wrapper .list-item .icon-wrapper .icon::before {
  font-size: 3.8rem;
}
@media (max-width: 991px) {
  .media-link-list-component .list-wrapper .list-item .icon-wrapper .icon::before {
    font-size: 3.2rem;
  }
}
.media-link-list-component .list-wrapper .list-item .info-item {
  margin-left: 1.2rem;
  text-transform: uppercase;
  display: inline-block;
  font-family: "Franklin Gothic URW", sans-serif;
  font-size: 1.4rem;
  font-weight: 700;
  vertical-align: middle;
}

.variant-top-details-component {
  background: #ffffff;
  padding: 7.6rem 0 5.2rem;
}
@media (max-width: 767px) {
  .variant-top-details-component {
    padding: 2rem 0 2.2rem;
  }
}
.variant-top-details-component .content-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 767px) {
  .variant-top-details-component .content-row {
    display: block;
  }
}
.variant-top-details-component .content-row .image-col {
  max-width: 40%;
}
@media (max-width: 767px) {
  .variant-top-details-component .content-row .image-col {
    width: 90%;
    max-width: 100%;
    margin: 0 auto 2.6rem;
  }
}
.variant-top-details-component .content-row .image-col .main-image-wrapper {
  max-width: 65rem;
  margin-bottom: 1.8rem;
}
.variant-top-details-component .content-row .image-col .main-image-wrapper img {
  width: 100%;
}
.variant-top-details-component .content-row .image-col .dimension-image-wrapper {
  max-width: 23rem;
  margin-bottom: 2.1rem;
  display: inline-block;
}
@media (max-width: 767px) {
  .variant-top-details-component .content-row .image-col .dimension-image-wrapper {
    width: 11.2rem;
    margin-bottom: 2.8rem;
  }
}
.variant-top-details-component .content-row .image-col .dimension-image-wrapper img {
  width: 100%;
}
.variant-top-details-component .content-row .image-col .info {
  font-size: 1.1rem;
}
.variant-top-details-component .content-row .dimension-col {
  width: 32.8rem;
}
@media (max-width: 1300px) {
  .variant-top-details-component .content-row .dimension-col {
    width: 23.4rem;
  }
}
@media (max-width: 767px) {
  .variant-top-details-component .content-row .dimension-col {
    margin: 0 auto 4rem;
  }
}
.variant-top-details-component .content-row .dimension-col .dimension-list li {
  line-height: 3.6rem;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1300px) {
  .variant-top-details-component .content-row .dimension-col .dimension-list li {
    line-height: 2.3rem;
  }
}
@media (max-width: 1300px) {
  .variant-top-details-component .content-row .dimension-col .dimension-list li span {
    font-size: 1.4rem;
  }
}
.variant-top-details-component .content-row .dimension-col .dimension-list li span.dimension-text {
  font-weight: 700;
  text-align: left;
}
.variant-top-details-component .content-row .dimension-col .dimension-list li span.dimension-value {
  text-align: right;
}
.variant-top-details-component .content-row .dimension-col .cta-link {
  font-family: "Franklin Gothic URW", sans-serif;
  font-size: 1.8rem;
  color: #999999;
}
@media (max-width: 1300px) {
  .variant-top-details-component .content-row .dimension-col .cta-link {
    font-size: 1.1rem;
  }
}
.variant-top-details-component .content-row .tool-info-col {
  width: 40.4rem;
  background-color: #e5e5e5;
  padding: 4.2rem 2rem;
}
@media (max-width: 1300px) {
  .variant-top-details-component .content-row .tool-info-col {
    width: 26.4rem;
    padding: 2.7rem 1.5rem;
  }
}
@media (max-width: 767px) {
  .variant-top-details-component .content-row .tool-info-col {
    margin: 0 auto;
    width: 90%;
  }
}
.variant-top-details-component .content-row .tool-info-col .info-list {
  margin-bottom: 3.5rem;
  color: #222222;
}
.variant-top-details-component .content-row .tool-info-col .info-list li {
  line-height: 3.6rem;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1300px) {
  .variant-top-details-component .content-row .tool-info-col .info-list li {
    line-height: 2.3rem;
  }
}
@media (max-width: 1300px) {
  .variant-top-details-component .content-row .tool-info-col .info-list li span {
    font-size: 1.4rem;
  }
}
.variant-top-details-component .content-row .tool-info-col .info-list li .info-text {
  font-weight: 700;
}
.variant-top-details-component .content-row .tool-info-col .cta-wrapper {
  flex-direction: column;
  gap: 1.5rem;
}
.variant-top-details-component .content-row .tool-info-col .rating-component {
  padding-left: 7.5rem;
  align-items: center;
  justify-content: center;
}
@media (max-width: 767px) {
  .variant-top-details-component .content-row .tool-info-col .rating-component {
    padding-left: 1.5rem;
  }
}
.variant-top-details-component .content-row .tool-info-col .rating-component.col-md-7 {
  display: inline;
}
@media (max-width: 767px) {
  .variant-top-details-component .content-row .tool-info-col .rating-component.col-md-7 {
    display: block;
  }
}
.variant-top-details-component .content-row .tool-info-col .rating-component .fa-icon {
  font-size: 1.9rem;
}
.variant-top-details-component .content-row .tool-info-col .rating-component .fa-icon:before {
  font-weight: 400;
}
.variant-top-details-component .content-row .tool-info-col .rating-component .back-stars {
  position: relative;
}
.variant-top-details-component .content-row .tool-info-col .rating-component .front-stars {
  color: #1c3E77;
  overflow: hidden;
  position: absolute;
  top: 0;
}
.variant-top-details-component .content-row .tool-info-col .rating-component .front-stars .fa-icon:before {
  font-weight: 700;
}
.variant-top-details-component .main-image-wrapper .modal,
.variant-top-details-component .dimension-image-wrapper .modal {
  background: rgba(54, 54, 54, 0.7);
}
.variant-top-details-component .main-image-wrapper .modal .modal-dialog,
.variant-top-details-component .dimension-image-wrapper .modal .modal-dialog {
  max-width: 80%;
  max-height: 80%;
  margin: 0 auto;
}
.variant-top-details-component .main-image-wrapper .modal .modal-dialog img,
.variant-top-details-component .dimension-image-wrapper .modal .modal-dialog img {
  width: auto;
}
.variant-top-details-component .main-image-wrapper .modal .modal-dialog .modal-content,
.variant-top-details-component .dimension-image-wrapper .modal .modal-dialog .modal-content {
  width: auto;
  margin: 0 auto;
}

.tech-dimension-component {
  background: #ffffff;
}
.tech-dimension-component .content {
  padding: 6.6rem 0 8.5rem;
}
@media (max-width: 991px) {
  .tech-dimension-component .content {
    padding: 2rem 0 2.4rem;
  }
}
.tech-dimension-component .content .dimension-list {
  padding: 0 2rem;
}
@media (max-width: 991px) {
  .tech-dimension-component .content .dimension-list {
    max-width: 26.4rem;
    margin: 0 auto 3.6rem;
  }
}
@media (max-width: 767px) {
  .tech-dimension-component .content .dimension-list {
    /*max-width: 26.4rem;*/
  }
}
.tech-dimension-component .content .dimension-list li {
  line-height: 3.6rem;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1200px) {
  .tech-dimension-component .content .dimension-list li {
    line-height: 2.3rem;
  }
}
@media (max-width: 120px) {
  .tech-dimension-component .content .dimension-list li span {
    font-size: 1.6rem;
  }
}
@media (max-width: 767px) {
  .tech-dimension-component .content .dimension-list li span {
    font-size: 1.4rem;
  }
}
.tech-dimension-component .content .dimension-list li span.dimension-text {
  font-weight: 700;
  font-size: 1.7rem;
  text-align: left;
}
.tech-dimension-component .content .dimension-list li span.dimension-value {
  font-size: 1.5rem;
  text-align: right;
}

.tech-info-component {
  background: #ffffff;
}
.tech-info-component .content {
  padding: 4.3rem 0;
  display: table;
  width: 100%;
}
.tech-info-component .content .icons-section-component {
  display: table-cell;
}
.tech-info-component .content .icons-section-component.full-width {
  width: 100%;
  display: inline-block;
}
.tech-info-component .content .icons-section-component.full-width .icons-content {
  display: inline-flex;
}
@media (max-width: 720px) {
  .tech-info-component .content .icons-section-component {
    display: block !important;
    width: 100%;
  }
}
.tech-info-component .content .subtitle {
  font-size: 2rem;
  font-family: "Franklin Gothic URW", sans-serif;
  margin-bottom: 2rem;
}
.tech-info-component .content .option-section {
  text-align: center;
  width: 50%;
  position: relative;
  display: table-cell;
}
.tech-info-component .content .option-section.full-width {
  width: 100%;
  display: inline-block;
}
.tech-info-component .content .option-section.full-width .option-content {
  display: inline-block;
  position: relative;
}
@media (max-width: 991px) {
  .tech-info-component .content .option-section.full-width .option-content {
    display: flex;
  }
}
@media (max-width: 720px) {
  .tech-info-component .content .option-section {
    display: block;
    width: 100%;
  }
}
.tech-info-component .content .option-section .option-content {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
}
@media (max-width: 991px) {
  .tech-info-component .content .option-section .option-content {
    position: static;
    justify-content: center;
    flex-direction: row;
    margin: 0;
    padding: 0;
    transform: none;
  }
}
.tech-info-component .content .option-section .option-content .progress-bar {
  display: block;
  transition: none;
  height: 1rem;
  width: 100%;
  background: #b2b2b2;
  margin-bottom: 2rem;
}
@media (max-width: 991px) {
  .tech-info-component .content .option-section .option-content .progress-bar {
    width: 1rem;
    height: auto;
    margin-bottom: 0;
    order: 2;
  }
}
.tech-info-component .content .option-section .option-content .option-list {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  justify-content: flex-start;
  padding-left: 12%;
}
@media (max-width: 720px) {
  .tech-info-component .content .option-section .option-content .option-list {
    padding-left: 18%;
    order: 1;
  }
}
.tech-info-component .content .option-section .option-content .option-list .material-option {
  position: relative;
  font-size: 1.4rem;
  max-width: 12rem;
  font-weight: 700;
  line-height: 1;
  margin-right: 34px;
  width: 100%;
  margin-top: 20px;
}
@media (max-width: 991px) {
  .tech-info-component .content .option-section .option-content .option-list .material-option {
    margin-bottom: 2rem;
  }
  .tech-info-component .content .option-section .option-content .option-list .material-option:last-child {
    margin-bottom: 0;
  }
}
.tech-info-component .content .option-section .option-content .option-list .material-option.active {
  color: #1c3E77;
}
.tech-info-component .content .option-section .option-content .option-list .material-option.active:before {
  display: block;
  background: #1c3E77;
  width: 100%;
  height: 1rem;
  position: absolute;
  top: -3rem;
}
@media (max-width: 991px) {
  .tech-info-component .content .option-section .option-content .option-list .material-option.active:before {
    width: 1rem;
    height: 100%;
    position: absolute;
    top: auto;
    right: -3rem;
  }
}

.tech-resources-component {
  background: #ffffff;
}
.tech-resources-component .new-list-dropdown {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.26);
  padding: 1rem 2.6rem 3rem;
  background: #ffffff;
  position: absolute;
  z-index: 10;
  right: 2.9rem;
  text-align: left;
}
.tech-resources-component .new-list-dropdown h4 {
  color: #1c3E77;
}
.tech-resources-component .new-list-dropdown .close-button {
  float: right;
  margin-right: 0px;
  margin-top: -5px;
  margin-bottom: 5px;
}
.tech-resources-component .content .row {
  padding: 5rem 0;
}
@media (max-width: 767px) {
  .tech-resources-component .content .row {
    padding: 2rem 0;
  }
}
.tech-resources-component .content .row .subtitle {
  font-family: "Franklin Gothic URW", sans-serif;
  font-size: 2rem;
  line-height: 2.3rem;
  margin-bottom: 3rem;
}
@media (max-width: 767px) {
  .tech-resources-component .content .row .subtitle {
    /*font-size: 1.4rem;*/
    margin-bottom: 1.5rem;
  }
}
@media (max-width: 767px) {
  .tech-resources-component .content .row .link-list {
    max-width: 40rem;
    margin: 0 auto;
  }
}
.tech-resources-component .content .row .link-item {
  display: flex;
  align-items: center;
  margin-bottom: 3.6rem;
}
@media (max-width: 767px) {
  .tech-resources-component .content .row .link-item {
    margin-left: 1rem;
    margin-bottom: 2.5rem;
  }
}
.tech-resources-component .content .row .link-item .icon-wrapper {
  max-width: 4rem;
  min-width: 3rem;
  overflow: hidden;
  margin-right: 1.8rem;
}
.tech-resources-component .content .row .link-item .icon-wrapper .icon {
  font-size: 3rem;
  color: #1c3E77;
}
.tech-resources-component .content .row .link-item .link {
  font-size: 2.2rem;
  font-family: "Franklin Gothic URW", sans-serif;
  font-weight: bold;
  color: #999999;
  line-height: 1;
  vertical-align: middle;
  cursor: pointer;
}
@media (max-width: 1024px) {
  .tech-resources-component .content .row .link-item .link {
    font-size: 1.8rem;
  }
}

.check-stock-component h3 {
  padding-top: 0.8rem;
  padding-bottom: 0.4rem;
}
.check-stock-component h5.netPrice.netPrice {
  font-weight: 700;
  color: #1c3E77;
}
.check-stock-component span.info.info {
  font-weight: 700;
  color: #1c3E77;
}
.check-stock-component span.customer-tool {
  color: #1c3E77;
  font-size: 1rem;
}
.check-stock-component span.customer-tool.customer-tool {
  color: #1c3E77;
  font-size: 1rem;
}
.check-stock-component .modal-content .modal-body {
  padding: 2rem 5rem 5rem 5rem !important;
}
.check-stock-component .modal-content .modal-body-add-cart {
  padding: 2rem 2rem 2rem 2rem !important;
  width: 300px;
}
.check-stock-component .modal-content.check-stock-add-to-cart-multi {
  align-items: center;
  background-color: transparent;
}
.check-stock-component .clear-button {
  margin-top: 2rem;
  float: right;
  margin-right: 0;
  width: 15rem;
  padding: 1.8rem 0;
}
.check-stock-component .buttons-wrapper {
  margin-bottom: 10rem;
}
.check-stock-component .buttons-wrapper .container {
  display: flex;
  justify-content: space-between;
}
.check-stock-component .buttons-wrapper .container .button-link {
  color: #999999;
  font-weight: 400;
}
.check-stock-component .buttons-wrapper .container .side-buttons {
  display: flex;
  position: relative;
  justify-content: flex-end;
}
.check-stock-component .buttons-wrapper .container .side-buttons .modal-header button {
  margin-left: auto !important;
}
.check-stock-component .buttons-wrapper .container .side-buttons button {
  width: 15rem;
  display: flex;
  align-items: center;
  padding: 1.8rem 0;
  justify-content: center;
}
.check-stock-component .buttons-wrapper .container .side-buttons button:last-child {
  margin-left: 3rem;
}
.check-stock-component .buttons-wrapper .container .side-buttons button span {
  margin-right: 0.5rem;
}
@media (max-width: 767px) {
  .check-stock-component .buttons-wrapper .container .side-buttons div {
    display: none;
  }
}
.check-stock-component .buttons-wrapper .container .side-buttons .compare-tooltip {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  top: -21rem;
  left: -6rem;
  padding: 3rem;
  text-align: center;
}
.check-stock-component .buttons-wrapper .container .side-buttons .compare-tooltip .title {
  font-size: 1.6rem;
}
.check-stock-component .buttons-wrapper .container .side-buttons .compare-tooltip .info {
  font-size: 1.4rem;
  color: #000000;
}

.check-stock-item span.info.info {
  font-weight: 700;
  color: #1c3E77;
}
.check-stock-item span.customer-tool {
  color: #1c3E77;
  font-size: 1rem;
}
.check-stock-item span.customer-tool.customer-tool {
  color: #1c3E77;
  font-size: 1rem;
}

form.multi-add-to-cart-form {
  align-items: center;
  display: flex;
  /* align-content: space-between; */
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.multi-add-to-cart-formgroup {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.check-stock-multi-add-to-cart-button {
  margin-left: 0px !important;
  width: 100%;
}

#loginPopupSubmit {
  margin: auto !important;
}

.add-to-list-dropdown-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dropdown-wrapper .quantity-wrapper {
  display: flex;
  flex-direction: row;
}
.dropdown-wrapper .quantity-wrapper label {
  margin-right: 1rem;
  padding-right: 0rem;
}
.dropdown-wrapper .quantity-wrapper input {
  border: 1px solid #ddd;
  max-width: 5rem;
  border-radius: 0.5rem;
  height: 4.7rem;
  font-size: 1.5rem;
  text-align: center;
}

.dropdown-wrapper .check-stock-add-to-cart-multi .new-list-dropdown .multi-add-to-cart-form button.small {
  padding: 1rem 2.2rem !important;
  font-weight: 700 !important;
  text-align: center;
  font-size: 1.2rem !important;
}

.item-details-component {
  padding: 1rem 0 0;
}
.item-details-component .item-row {
  position: relative;
  margin-bottom: 2rem;
  display: flex;
  padding: 2rem 0;
  align-items: center;
  min-height: 16rem;
  border: 1px solid #e5e5e5;
}
@media (max-width: 991px) {
  .item-details-component .item-row {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }
}
.item-details-component .item-row:first-child {
  min-height: auto;
  margin: 0;
  border: 0;
}
@media (max-width: 991px) {
  .item-details-component .item-row:first-child {
    display: none;
  }
}
.item-details-component .item-row .title {
  font-size: 1.8rem;
  color: #000000;
  font-weight: 400;
  font-family: "Franklin Gothic URW", sans-serif;
  text-transform: none;
}
.item-details-component .item-row .row-content {
  display: flex;
  align-items: center;
  margin: 0;
  width: 100%;
}
@media (max-width: 991px) {
  .item-details-component .item-row .row-content {
    flex-wrap: wrap;
    flex-direction: column;
    padding: 1rem;
  }
}
.item-details-component .checkbox-wrapper {
  position: absolute;
  left: 2rem;
}
@media (max-width: 991px) {
  .item-details-component .checkbox-wrapper {
    display: none;
  }
}
.item-details-component .checkbox-wrapper .input-wrapper {
  display: flex;
  width: 3rem;
  height: 3rem;
  align-items: center;
}
.item-details-component .checkbox-wrapper .input-wrapper input[type=checkbox] {
  background-color: #ffffff;
}
.item-details-component .checkbox-wrapper .input-wrapper label {
  margin: 0 !important;
  margin-left: -2rem !important;
}
.item-details-component .tool-number-wrapper {
  width: 20%;
  margin-left: 7rem;
  position: relative;
}
.item-details-component .tool-number-wrapper.check-stock .input-check-wrapper {
  position: relative;
}
.item-details-component .tool-number-wrapper.check-stock .input-check-wrapper input {
  font-size: 1.6rem;
}
.item-details-component .tool-number-wrapper.check-stock .input-check-wrapper input:focus {
  background: #ffffff;
}
.item-details-component .tool-number-wrapper.check-stock #check-box {
  text-align: left;
  background: #ffffff;
  color: #999999;
  padding: 1rem;
  font-size: 1.6rem;
  font-family: "Franklin Gothic URW", sans-serif;
  position: absolute;
  left: 0;
  width: 100%;
  top: 40px;
  z-index: 999;
}
.item-details-component .tool-number-wrapper.check-stock #check-box .box {
  margin-top: 1.5rem;
}
.item-details-component .tool-number-wrapper.check-stock #check-box .box:nth-child(1) {
  margin-top: 0;
}
.item-details-component .tool-number-wrapper.check-stock #check-box a {
  width: 100%;
  color: #999999;
  font-size: 1.3rem;
  font-family: "Franklin Gothic URW", sans-serif;
  line-height: 2rem;
}
.item-details-component .tool-number-wrapper .error-message-wrapper {
  position: absolute;
  white-space: nowrap;
  left: 20rem;
  top: 50%;
  font-size: 1.6rem;
  transform: translateY(-50%);
}
@media (max-width: 991px) {
  .item-details-component .tool-number-wrapper .error-message-wrapper {
    white-space: normal;
    font-size: 1.3rem;
  }
}
@media (max-width: 991px) {
  .item-details-component .tool-number-wrapper {
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: space-between;
    max-width: 30rem;
  }
}
@media (max-width: 767px) {
  .item-details-component .tool-number-wrapper {
    max-width: 100%;
  }
}
.item-details-component .tool-number-wrapper .form-group {
  margin: 0;
}
.item-details-component .tool-number-wrapper .form-group .form-control {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  font-size: 1.5rem;
  width: 14rem;
}
@media (max-width: 991px) {
  .item-details-component .tool-number-wrapper .form-group .form-control {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.4rem;
  }
}
.item-details-component .tool-number-wrapper .form-group .info {
  display: none;
  font-size: 1.3rem;
  color: #1c3E77;
  font-weight: 700;
}
@media (max-width: 991px) {
  .item-details-component .tool-number-wrapper .form-group .info {
    display: block;
  }
}
.item-details-component .tool-number-wrapper .price-wrapper {
  display: none;
  text-align: center;
  color: #000000;
  font-size: 1.3rem;
  font-weight: 700;
}
@media (max-width: 991px) {
  .item-details-component .tool-number-wrapper .price-wrapper {
    display: flex;
    flex-direction: column;
  }
}
.item-details-component .product-wrapper {
  display: flex;
  width: 30%;
}
@media (max-width: 991px) {
  .item-details-component .product-wrapper {
    width: 100%;
    max-width: 30rem;
    margin-bottom: 2rem;
    margin-top: 1rem;
  }
}
@media (max-width: 767px) {
  .item-details-component .product-wrapper {
    max-width: 100%;
  }
}
.item-details-component .product-wrapper .img-wrapper {
  max-width: 3rem;
  margin-right: 2rem;
}
.item-details-component .product-wrapper .img-wrapper img {
  width: 100%;
}
.item-details-component .product-wrapper .content-wrapper {
  max-width: 40rem;
}
.item-details-component .product-wrapper .content-wrapper .subtitle {
  font-size: 1.4rem;
  color: #000000;
  font-weight: 700;
}
.item-details-component .product-wrapper .content-wrapper .item-description {
  font-size: 1.2rem;
  margin: 0;
}
.item-details-component .product-wrapper .content-wrapper .substitutes {
  font-size: 1.2rem;
  font-family: "Franklin Gothic URW", sans-serif;
  color: #ff0000;
}
.item-details-component .info-wrapper {
  width: 10%;
  justify-content: center;
  text-align: center;
}
@media (max-width: 991px) {
  .item-details-component .info-wrapper {
    display: none;
  }
}
.item-details-component .info-wrapper span {
  font-weight: 700;
}
.item-details-component .button-wrapper {
  width: 30%;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
@media (max-width: 1500px) {
  .item-details-component .button-wrapper {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
@media (max-width: 991px) {
  .item-details-component .button-wrapper {
    width: 100%;
    flex-direction: row;
  }
}
@media (max-width: 767px) {
  .item-details-component .button-wrapper {
    justify-content: space-between;
  }
}
.item-details-component .button-wrapper > button, .item-details-component .button-wrapper .dropdown-wrapper > button {
  margin-right: 3rem;
  padding: 1.2rem;
  white-space: nowrap;
  display: flex;
  width: 16rem;
  align-items: center;
  justify-content: center;
}
.item-details-component .button-wrapper > button span, .item-details-component .button-wrapper .dropdown-wrapper > button span {
  margin-right: 0.5rem;
  font-size: 2rem;
}
@media (max-width: 767px) {
  .item-details-component .button-wrapper > button span, .item-details-component .button-wrapper .dropdown-wrapper > button span {
    margin-right: 1rem;
  }
}
@media (max-width: 1500px) {
  .item-details-component .button-wrapper > button, .item-details-component .button-wrapper .dropdown-wrapper > button {
    margin-right: 0;
    margin-bottom: 1rem;
  }
  .item-details-component .button-wrapper > button:last-child, .item-details-component .button-wrapper .dropdown-wrapper > button:last-child {
    margin-right: 0;
  }
}
@media (max-width: 991px) {
  .item-details-component .button-wrapper > button, .item-details-component .button-wrapper .dropdown-wrapper > button {
    width: 14rem;
    margin-bottom: 1rem;
  }
  .item-details-component .button-wrapper > button:first-child, .item-details-component .button-wrapper .dropdown-wrapper > button:first-child {
    order: 2;
    margin-right: 0;
  }
  .item-details-component .button-wrapper > button:last-child, .item-details-component .button-wrapper .dropdown-wrapper > button:last-child {
    margin-right: 2rem;
    order: 1;
  }
}
@media (max-width: 767px) {
  .item-details-component .button-wrapper > button, .item-details-component .button-wrapper .dropdown-wrapper > button {
    font-size: 1.1rem;
    height: 3.2rem !important;
    padding: 0 !important;
    width: 14.7rem;
  }
}
.item-details-component .button-wrapper .dropdown-wrapper {
  margin-right: 2rem;
}
@media (max-width: 1500px) {
  .item-details-component .button-wrapper .dropdown-wrapper {
    margin-right: 0;
  }
}
@media (max-width: 991px) {
  .item-details-component .button-wrapper .dropdown-wrapper {
    margin-right: 2rem;
    order: 1;
  }
}
@media (max-width: 767px) {
  .item-details-component .button-wrapper .dropdown-wrapper {
    margin-right: 0;
  }
}
.item-details-component .button-wrapper .dropdown-wrapper .title {
  text-transform: none;
  font-size: 1.6rem;
  color: #000000;
  margin-bottom: 1rem;
  font-weight: 700;
}
.item-details-component .distributor-button-wrapper {
  width: 30%;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media (max-width: 991px) {
  .item-details-component .distributor-button-wrapper {
    width: 100%;
    flex-direction: row;
  }
}
@media (max-width: 767px) {
  .item-details-component .distributor-button-wrapper {
    justify-content: space-between;
  }
}
.item-details-component .distributor-button-wrapper > button, .item-details-component .distributor-button-wrapper .dropdown-wrapper > button {
  padding: 1.2rem;
  white-space: nowrap;
  display: flex;
  width: 16rem;
  align-items: center;
  justify-content: center;
  margin-right: 0;
  margin-bottom: 1rem;
}
.item-details-component .distributor-button-wrapper > button:last-child, .item-details-component .distributor-button-wrapper .dropdown-wrapper > button:last-child {
  margin-right: 0;
}
.item-details-component .distributor-button-wrapper > button span, .item-details-component .distributor-button-wrapper .dropdown-wrapper > button span {
  margin-right: 0.5rem;
  font-size: 2rem;
}
@media (max-width: 767px) {
  .item-details-component .distributor-button-wrapper > button span, .item-details-component .distributor-button-wrapper .dropdown-wrapper > button span {
    margin-right: 1rem;
  }
}
@media (max-width: 991px) {
  .item-details-component .distributor-button-wrapper > button, .item-details-component .distributor-button-wrapper .dropdown-wrapper > button {
    width: 14rem;
    margin-bottom: 1rem;
  }
  .item-details-component .distributor-button-wrapper > button:first-child, .item-details-component .distributor-button-wrapper .dropdown-wrapper > button:first-child {
    order: 2;
    margin-right: 0;
  }
  .item-details-component .distributor-button-wrapper > button:last-child, .item-details-component .distributor-button-wrapper .dropdown-wrapper > button:last-child {
    margin-right: 2rem;
    order: 1;
  }
}
@media (max-width: 767px) {
  .item-details-component .distributor-button-wrapper > button, .item-details-component .distributor-button-wrapper .dropdown-wrapper > button {
    font-size: 1.1rem;
    height: 3.2rem !important;
    padding: 0 !important;
    width: 14.7rem;
  }
}
.item-details-component .distributor-button-wrapper .dropdown-wrapper {
  margin-right: 0;
}
@media (max-width: 991px) {
  .item-details-component .distributor-button-wrapper .dropdown-wrapper {
    margin-right: 2rem;
    order: 1;
  }
}
@media (max-width: 767px) {
  .item-details-component .distributor-button-wrapper .dropdown-wrapper {
    margin-right: 0;
  }
}
.item-details-component .distributor-button-wrapper .dropdown-wrapper .title {
  text-transform: none;
  font-size: 1.6rem;
  color: #000000;
  margin-bottom: 1rem;
  font-weight: 700;
}
.item-details-component .close-wrapper {
  position: absolute;
  right: 2rem;
}
.item-details-component .close-wrapper .close-button {
  margin: 0;
}
.item-details-component .close-wrapper span {
  font-size: 1.8rem;
}
@media (max-width: 991px) {
  .item-details-component .close-wrapper {
    display: none;
  }
}

.contact-page-component {
  background: #ffffff;
}
.contact-page-component .title-wrapper {
  background: #ffffff;
}
.contact-page-component .contact-subtitle {
  color: #1c3E77;
  font-size: 2.5rem;
  font-family: "Franklin Gothic URW", sans-serif;
  text-align: center;
  padding-top: 5rem;
  padding-bottom: 2.5rem;
  border-bottom: 1px solid #999999;
  margin-bottom: 4.5rem;
  display: block;
}
.contact-page-component .contact-subtitle span {
  font-weight: 600;
}
.contact-page-component .info-holder .email, .contact-page-component .info-holder .phone {
  font-size: 2rem;
  color: #1c3E77;
  font-family: "Franklin Gothic URW", sans-serif;
}
.contact-page-component .info-holder .phone-item,
.contact-page-component .info-holder .fax-item,
.contact-page-component .info-holder .sales-item {
  font-size: 1.6rem;
  color: #000000;
  font-family: "Franklin Gothic URW", sans-serif;
}
.contact-page-component .info-holder .email-link, .contact-page-component .info-holder .telephone {
  font-size: 1.6rem;
  font-family: "Franklin Gothic URW", sans-serif;
}
.contact-page-component .contact-wrapper .title-form {
  background: #1c3E77;
  border-radius: 6px;
}
.contact-page-component .contact-wrapper .title-form .title {
  margin-bottom: 0;
  color: #ffffff;
  text-transform: none;
  font-size: 2.2rem;
  padding: 1.2rem 3.5rem;
}
.contact-page-component .contact-wrapper .card-wide {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  padding: 3.5rem 3.5rem;
  margin-bottom: 4rem;
  color: #000000;
}
.contact-page-component .contact-wrapper .card-wide .form-group {
  margin-bottom: 2rem;
}
.contact-page-component .contact-wrapper .card-wide .form-group .form-control {
  background: #fff;
  border: 1px solid #e6e6e6;
  font-size: 1.5rem;
}
.contact-page-component .contact-wrapper .form-fluid .form-control {
  height: 17rem;
}
.contact-page-component .main-wrapper .contact-mail {
  padding-left: 40px;
}
@media (max-width: 767px) {
  .contact-page-component .main-wrapper .contact-mail {
    padding-left: 15px;
    padding-bottom: 20px;
  }
}
.contact-page-component .main-wrapper .email, .contact-page-component .main-wrapper .phone {
  font-size: 2rem;
  color: #999999;
  font-family: "Franklin Gothic URW", sans-serif;
}
.contact-page-component .main-wrapper .phone-item,
.contact-page-component .main-wrapper .fax-item,
.contact-page-component .main-wrapper .sales-item,
.contact-page-component .main-wrapper .tech-item {
  font-size: 1.4rem;
  color: #000000;
}
.contact-page-component .main-wrapper .email-link, .contact-page-component .main-wrapper .telephone {
  font-size: 1.4rem;
}
.contact-page-component .main-wrapper .contact-map {
  margin-top: 3rem;
}
.contact-page-component .error-message-wrapper {
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}
.contact-page-component .error-message-wrapper .info {
  color: #1c3E77;
  font-size: 2rem;
}

.error-page .content-wrapper {
  padding: 10rem 0 5.4rem;
  text-align: center;
}

.error-page .content-wrapper .title {
  color: #1c3E77;
  font-family: "Titillium Web", sans-serif;
  font-size: 30rem;
  font-weight: 400;
  line-height: 1;
  margin: 0;
}

@media (max-width: 991px) {
  .error-page .content-wrapper .title {
    font-size: 20rem;
  }
}
@media (max-width: 767px) {
  .error-page .content-wrapper .title {
    font-size: 15rem;
  }
}
.error-page .content-wrapper .title-small {
  color: #1c3E77;
  font-family: "Titillium Web", sans-serif;
  font-size: 6rem;
  line-height: 1;
  font-weight: 400;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 3rem;
}

@media (max-width: 991px) {
  .error-page .content-wrapper .title-small {
    font-size: 4rem;
  }
}
@media (max-width: 767px) {
  .error-page .content-wrapper .title-small {
    font-size: 3rem;
  }
}
.error-page .content-wrapper .subtitle {
  font-size: 2.5rem;
  color: #707070;
  font-weight: 400;
  font-family: "Arimo", sans-serif;
  margin-bottom: 1.5rem;
}

@media (max-width: 991px) {
  .error-page .content-wrapper .subtitle {
    font-size: 1.8rem;
  }
}
@media (max-width: 767px) {
  .error-page .content-wrapper .large {
    font-size: 1.5rem;
  }
}
.error-page .content-wrapper .link-item {
  font-family: "Arimo", sans-serif;
  font-size: 1.8rem;
  color: #1c3E77;
}

.error-page .description-wrapper {
  text-align: center;
  background: #fff;
  padding: 6.6rem 0 20rem;
}

.error-page .description-wrapper .description {
  line-height: 4.1rem;
  font-family: "Roboto", sans-serif;
  font-size: 2.9rem;
  color: #707070;
  margin-bottom: 2.8rem;
}

@media (max-width: 991px) {
  .error-page .description-wrapper .description {
    font-size: 1.5rem;
  }
}
@media (max-width: 767px) {
  .error-page .description-wrapper .description {
    font-size: 1.3rem;
  }
}
.error-page .description-wrapper .form-inline {
  display: inline-block;
  margin-bottom: 7.7rem;
  text-align: center;
}

.error-page .description-wrapper .form-inline .form-control {
  width: 30rem;
}

.error-page .description-wrapper .form-inline .separator {
  margin: 0 1rem;
}

@media (max-width: 991px) {
  .error-page .description-wrapper .form-inline .separator {
    display: block;
    margin: 1rem;
    font-size: 1.4rem;
  }
}
@media (max-width: 991px) {
  .error-page .description-wrapper .form-inline .primary-button {
    display: block;
    margin: 0 auto;
  }
}
.error-page .description-wrapper .return-link {
  display: block;
  font-weight: 700;
  font-family: "Arimo", sans-serif;
  text-decoration: underline;
  font-size: 1.8rem;
  color: #1c3E77;
}

.error-page .content-wrapper {
  background: #e5e5e5;
  padding: 10rem 4rem 5.4rem;
  text-align: center;
}
.error-page .content-wrapper .title {
  color: #1c3E77 !important;
  font-family: "Franklin Gothic URW", sans-serif;
  font-size: 16rem;
  font-weight: 400;
  line-height: 1;
}
@media (max-width: 991px) {
  .error-page .content-wrapper .title {
    font-size: 20rem;
  }
}
@media (max-width: 767px) {
  .error-page .content-wrapper .title {
    font-size: 15rem;
  }
}
.error-page .content-wrapper .title-small {
  color: #1c3E77 !important;
  font-family: "Franklin Gothic URW", sans-serif;
  font-size: 6rem;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 2rem;
}
@media (max-width: 991px) {
  .error-page .content-wrapper .title-small {
    font-size: 4rem;
  }
}
@media (max-width: 767px) {
  .error-page .content-wrapper .title-small {
    font-size: 3rem;
  }
}
.error-page .content-wrapper .subtitle {
  font-size: 2.5rem;
  color: #999999;
  font-family: "Franklin Gothic URW", sans-serif;
  margin-bottom: 1.5rem;
}
@media (max-width: 991px) {
  .error-page .content-wrapper .subtitle {
    font-size: 1.8rem;
  }
}
.error-page .content-wrapper .large {
  margin: 0 auto;
  max-width: 96.6rem;
}
@media (max-width: 767px) {
  .error-page .content-wrapper .large {
    font-size: 1.5rem;
  }
}
.error-page .content-wrapper .link-item {
  font-family: "Franklin Gothic URW", sans-serif;
  font-size: 1.8rem;
  color: #1c3E77;
}
.error-page .description-wrapper {
  text-align: center;
  background: #ffffff;
  padding: 6.6rem 4rem 20rem;
}
.error-page .description-wrapper .description {
  max-width: 60.2rem;
  margin: 0 auto;
  line-height: 4.1rem;
  font-family: "Franklin Gothic URW", sans-serif;
  font-size: 2.9rem;
  color: #999999;
  margin-bottom: 2.8rem;
}
@media (max-width: 991px) {
  .error-page .description-wrapper .description {
    font-size: 1.5rem;
    line-height: 2;
  }
}
@media (max-width: 767px) {
  .error-page .description-wrapper .description {
    font-size: 1.3rem;
  }
}
.error-page .description-wrapper .form-inline {
  margin-bottom: 7.7rem;
  text-align: center;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 2.2rem;
  position: relative;
}
.error-page .description-wrapper .form-inline .form-control {
  width: 30rem;
  font-size: 1.4rem;
}
.error-page .description-wrapper .separator {
  margin: 0 1rem;
}
@media (max-width: 991px) {
  .error-page .description-wrapper .separator {
    display: block;
    margin: 1rem;
    font-size: 1.4rem;
  }
}
.error-page .description-wrapper .primary-button {
  padding: 1rem 1.7rem;
}
@media (max-width: 991px) {
  .error-page .description-wrapper .primary-button {
    display: block;
    margin: 0 auto;
  }
}
.error-page .return-link {
  margin-top: 7rem;
  display: block;
  font-weight: 700;
  font-family: "Franklin Gothic URW", sans-serif;
  text-decoration: underline;
  font-size: 1.8rem;
  color: #1c3E77;
}

.search-bar {
  position: relative;
}
.search-bar input {
  font-size: 1.4rem;
}
.search-bar #suggestion-box {
  text-align: left;
  background: #ffffff;
  color: #999999;
  padding: 1rem 2rem 2rem;
  font-size: 1.6rem;
  font-family: "Franklin Gothic URW", sans-serif;
  position: absolute;
  left: 0;
  width: 100%;
  top: 43px;
  z-index: 42;
  box-shadow: 0 0.5px 5px 0px #333333;
}
.search-bar #suggestion-box h3 {
  color: #999999;
  font-size: 1.6rem;
  font-family: "Franklin Gothic URW", sans-serif;
  border-bottom: 1px solid #cccccc;
}
.search-bar #suggestion-box .box {
  margin-top: 1.5rem;
}
.search-bar #suggestion-box .box:nth-child(1) {
  margin-top: 0;
}
.search-bar #suggestion-box ul li {
  display: inline-block;
  margin-bottom: 2px;
  width: 100%;
}
.search-bar #suggestion-box ul li a {
  color: #999999;
  font-size: 1.6rem;
  font-family: "Franklin Gothic URW", sans-serif;
  line-height: 1;
  margin-bottom: 2px;
  display: block;
  border-bottom: 2px solid transparent;
}
.search-bar #suggestion-box ul li a:hover {
  border-bottom: 2px solid #1c3E77;
}
.search-bar #suggestion-box ul li a span {
  font-size: 1.2rem;
  display: block;
}
.search-bar #suggestion-box .more-suggestions {
  font-style: italic;
  font-size: 1.6rem;
  font-family: "Franklin Gothic URW", sans-serif;
  color: #999999;
}
.search-bar #suggestion-box .more-suggestions:hover {
  color: #1c3E77;
}

.card-wrapper.last .distributor-card-component .distributor-tooltip {
  width: 50rem;
  top: 5.5rem;
  transform: translateY(0) !important;
  padding: 3.4rem;
  position: absolute;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  right: -100px;
  left: auto;
}
.card-wrapper.last .distributor-card-component .distributor-tooltip:before {
  margin-left: 116px;
}
.card-wrapper.last .distributor-card-component .distributor-tooltip:after {
  margin-left: 118px;
}
.card-wrapper.last .distributor-card-component .distributor-tooltip.arrow-bottom {
  top: -6.5rem;
  left: -11.5rem;
  width: auto;
  padding: 1rem 2rem;
}
.card-wrapper.last .distributor-card-component .distributor-tooltip.arrow-bottom::before, .card-wrapper.last .distributor-card-component .distributor-tooltip.arrow-bottom::after {
  left: 75%;
}
@media (max-width: 1024px) {
  .card-wrapper.last .distributor-card-component .distributor-tooltip {
    max-width: 42rem;
    left: -16rem;
    padding: 2rem 2.7rem;
  }
  .card-wrapper.last .distributor-card-component .distributor-tooltip::before, .card-wrapper.last .distributor-card-component .distributor-tooltip::after {
    left: 15%;
  }
}
@media (max-width: 991px) {
  .card-wrapper.last .distributor-card-component .distributor-tooltip {
    max-width: 37rem;
    left: -6rem;
    padding: 2rem 2.7rem;
    right: auto;
  }
  .card-wrapper.last .distributor-card-component .distributor-tooltip::before, .card-wrapper.last .distributor-card-component .distributor-tooltip::after {
    left: -12%;
  }
}
@media (max-width: 480px) {
  .card-wrapper.last .distributor-card-component .distributor-tooltip {
    width: auto;
  }
}
.card-wrapper.last .distributor-card-component .distributor-tooltip .close-wrapper {
  position: absolute;
  top: 1.4rem;
  right: 2rem;
}
.card-wrapper.last .distributor-card-component .distributor-tooltip .close-wrapper span {
  font-size: 3rem;
  color: #999999;
}
.card-wrapper.last .distributor-card-component .distributor-tooltip .content-wrapper .subtitle {
  margin: 0;
  line-height: 1.2;
}
.card-wrapper.last .distributor-card-component .distributor-tooltip .content-wrapper .location {
  color: #000000;
  font-weight: 700;
  margin-bottom: 3.2rem;
}
.card-wrapper.last .distributor-card-component .distributor-tooltip .contact-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.8rem;
}
.card-wrapper.last .distributor-card-component .distributor-tooltip .contact-wrapper .contact span {
  display: inline;
  color: #000000;
}
@media (max-width: 991px) {
  .card-wrapper.last .distributor-card-component .distributor-tooltip .contact-wrapper .contact span {
    font-size: 1.3rem;
  }
}
.card-wrapper.last .distributor-card-component .distributor-tooltip .contact-wrapper .contact .info {
  font-weight: 700;
}
.card-wrapper.last .distributor-card-component .distributor-tooltip .contact-wrapper .primary-button {
  max-height: 5rem;
  padding: 1.5rem 3rem;
}
@media (max-width: 991px) {
  .card-wrapper.last .distributor-card-component .distributor-tooltip .contact-wrapper .primary-button {
    padding: 1.3rem 2rem;
    white-space: nowrap;
    font-size: 1.3rem;
    max-height: 4rem;
  }
}
.card-wrapper.last .distributor-card-component .distributor-tooltip .map-wrapper {
  width: 100%;
}
.card-wrapper.last .distributor-card-component .distributor-tooltip .map-wrapper .address {
  font-size: 1.8rem;
  margin-bottom: 1.2rem;
}
@media (max-width: 991px) {
  .card-wrapper.last .distributor-card-component .distributor-tooltip .map-wrapper .address {
    font-size: 1.4rem;
  }
}

.distributor-card-component {
  border: 1px solid #999999;
  background: #ffffff;
  padding: 6.6rem 6rem;
  position: relative;
}
@media (max-width: 1200px) {
  .distributor-card-component {
    padding: 6.6rem 3rem;
  }
}
@media (max-width: 991px) {
  .distributor-card-component {
    max-width: 45rem;
    margin: 0 auto;
  }
}
.distributor-card-component .h5.subtitle {
  min-height: 55px;
}
.distributor-card-component .icon-wrapper {
  position: absolute;
  top: 1rem;
  right: 2rem;
}
.distributor-card-component .icon-wrapper .icon {
  font-size: 3rem;
  color: #000000;
}
.distributor-card-component .icon-wrapper .icon.icon-my-list.favorite {
  background: #1c3E77;
  padding: 5px 10px 1px;
  border-radius: 0.4rem;
}
.distributor-card-component .icon-wrapper .icon.icon-my-list.favorite:before {
  color: #ffffff;
}
.distributor-card-component .content-wrapper {
  padding: 0;
}
.distributor-card-component .content-wrapper .subtitle {
  font-size: 2.2rem;
  margin-bottom: 2.6rem;
}
.distributor-card-component .content-wrapper .info-wrapper {
  margin-bottom: 5.4rem;
}
.distributor-card-component .content-wrapper .info-wrapper span {
  font-size: 1.6rem;
  font-weight: 700;
  display: block;
}
.distributor-card-component .content-wrapper hr {
  margin-bottom: 2rem;
  border-color: #999999;
}
.distributor-card-component .content-wrapper .button-wrapper {
  height: 4.4rem;
  display: flex;
  align-items: center;
  padding-right: 2rem;
  justify-content: space-between;
}
@media (max-width: 1300px) {
  .distributor-card-component .content-wrapper .button-wrapper {
    padding: 0;
  }
}
.distributor-card-component .content-wrapper .button-wrapper .info-link {
  font-family: "Franklin Gothic URW", sans-serif;
  font-size: 1.5rem;
  color: #1c3E77;
}
.distributor-card-component .content-wrapper .button-wrapper .primary-button {
  padding: 1.2rem 2rem;
}
.distributor-card-component .distributor-tooltip {
  width: 50rem;
  top: 5.5rem;
  transform: translateY(0) !important;
  padding: 3.4rem;
  position: absolute;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  left: -40px;
}
.distributor-card-component .distributor-tooltip.arrow-bottom {
  top: -6.5rem;
  left: -11.5rem;
  width: auto;
  padding: 1rem 2rem;
}
.distributor-card-component .distributor-tooltip.arrow-bottom::before, .distributor-card-component .distributor-tooltip.arrow-bottom::after {
  left: 75%;
}
@media (max-width: 991px) {
  .distributor-card-component .distributor-tooltip {
    max-width: 37rem;
    left: -6rem;
    padding: 2rem 2.7rem;
  }
  .distributor-card-component .distributor-tooltip::before, .distributor-card-component .distributor-tooltip::after {
    left: 25%;
  }
}
@media (max-width: 480px) {
  .distributor-card-component .distributor-tooltip {
    width: auto;
  }
}
.distributor-card-component .distributor-tooltip .close-wrapper {
  position: absolute;
  top: 1.4rem;
  right: 2rem;
}
.distributor-card-component .distributor-tooltip .close-wrapper span {
  font-size: 3rem;
  color: #999999;
}
.distributor-card-component .distributor-tooltip .content-wrapper .subtitle {
  margin: 0;
  line-height: 1.2;
}
.distributor-card-component .distributor-tooltip .content-wrapper .location {
  color: #000000;
  font-weight: 700;
  margin-bottom: 3.2rem;
}
.distributor-card-component .distributor-tooltip .contact-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.8rem;
}
.distributor-card-component .distributor-tooltip .contact-wrapper .contact span {
  display: inline;
  color: #000000;
}
@media (max-width: 991px) {
  .distributor-card-component .distributor-tooltip .contact-wrapper .contact span {
    font-size: 1.3rem;
  }
}
.distributor-card-component .distributor-tooltip .contact-wrapper .contact .info {
  font-weight: 700;
}
.distributor-card-component .distributor-tooltip .contact-wrapper .primary-button {
  max-height: 5rem;
  padding: 1.5rem 3rem;
}
@media (max-width: 991px) {
  .distributor-card-component .distributor-tooltip .contact-wrapper .primary-button {
    padding: 1.3rem 2rem;
    white-space: nowrap;
    font-size: 1.3rem;
    max-height: 4rem;
  }
}
.distributor-card-component .distributor-tooltip .map-wrapper {
  width: 100%;
}
.distributor-card-component .distributor-tooltip .map-wrapper .address {
  font-size: 1.8rem;
  margin-bottom: 1.2rem;
}
@media (max-width: 991px) {
  .distributor-card-component .distributor-tooltip .map-wrapper .address {
    font-size: 1.4rem;
  }
}

.find-distributor-component .content-wrapper {
  background-color: #ffffff;
  padding: 1.8rem 0 0;
}
.find-distributor-component .content-wrapper .description {
  max-width: 60rem;
}
.find-distributor-component .content-wrapper .local-distributor-wrapper {
  border-bottom: 1px solid #999999;
  padding: 1.4rem 0;
}
.find-distributor-component .content-wrapper .local-distributor-wrapper .selectric {
  width: 12.6rem;
}
@media (max-width: 767px) {
  .find-distributor-component .content-wrapper .local-distributor-wrapper {
    padding: 1.8rem 0 2.2rem;
  }
}
.find-distributor-component .content-wrapper .local-distributor-wrapper .container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .find-distributor-component .content-wrapper .local-distributor-wrapper .container {
    display: block;
    text-align: center;
  }
}
.find-distributor-component .content-wrapper .local-distributor-wrapper .title {
  display: inline-block;
  margin: 0;
  font-size: 2.2rem;
}
@media (max-width: 767px) {
  .find-distributor-component .content-wrapper .local-distributor-wrapper .title {
    font-size: 1.8rem;
    margin-bottom: 1.6rem;
  }
}
.find-distributor-component .content-wrapper .local-distributor-wrapper .form-group {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}
.find-distributor-component .content-wrapper .local-distributor-wrapper label {
  white-space: nowrap;
  margin: 0 2rem 0;
  font-weight: 700;
  font-family: "Franklin Gothic URW", sans-serif;
  font-size: 1.6rem;
  color: #000000;
}
@media (max-width: 767px) {
  .find-distributor-component .content-wrapper .local-distributor-wrapper label {
    font-size: 1.4rem;
  }
}
.find-distributor-component .content-wrapper .local-distributor-wrapper select {
  width: 23rem;
  border: 1px solid #e5e5e5;
}
@media (max-width: 767px) {
  .find-distributor-component .content-wrapper .local-distributor-wrapper select {
    width: 14rem;
  }
}
.find-distributor-component .content-wrapper .distributor-results-wrapper {
  display: flex;
  align-items: center;
  background-color: #e5e5e5;
  min-height: 150px;
}
.find-distributor-component .content-wrapper .distributor-results-wrapper .loader {
  display: flex;
  padding-top: 20px;
}
.find-distributor-component .content-wrapper .distributor-results-wrapper .loader .text {
  font-size: 1.8rem;
}
.find-distributor-component .content-wrapper .distributor-results-wrapper .loader .dots {
  display: flex;
  position: relative;
  top: 12px;
  left: -10px;
  width: 66px;
  animation: dots 4s ease infinite 1s;
}
.find-distributor-component .content-wrapper .distributor-results-wrapper .loader .dots div {
  position: relative;
  width: 4px;
  height: 4px;
  margin-right: 6px;
  border-radius: 100%;
  background-color: #999999;
}
.find-distributor-component .content-wrapper .distributor-results-wrapper .loader .dots div:nth-child(1) {
  width: 4px;
  height: 4px;
  margin: 5px;
  margin-right: 6px;
  animation: show-dot 4s ease-out infinite 1s;
}
.find-distributor-component .content-wrapper .distributor-results-wrapper .loader .dots div:nth-child(4) {
  background-color: transparent;
  animation: dot-fall-left 4s linear infinite 1s;
}
.find-distributor-component .content-wrapper .distributor-results-wrapper .loader .dots div:nth-child(4):before {
  position: absolute;
  width: 4px;
  height: 4px;
  margin-right: 6px;
  border-radius: 100%;
  background-color: #999999;
  content: "";
  animation: dot-fall-top 4s cubic-bezier(0.46, 0.02, 0.94, 0.54) infinite 1s;
}
@keyframes dots {
  0% {
    left: -10px;
  }
  20%, 100% {
    left: 10px;
  }
}
@keyframes show-dot {
  0%, 20% {
    width: 0px;
    height: 0px;
    margin: 5px;
    margin-right: 6px;
  }
  30%, 100% {
    width: 4px;
    height: 4px;
    margin: 0px;
    margin-right: 6px;
  }
}
@keyframes dot-fall-left {
  0%, 5% {
    left: 0px;
  }
  100% {
    left: 200px;
  }
}
@keyframes dot-fall-top {
  0%, 5% {
    top: 0px;
  }
  30%, 100% {
    top: 50vh;
  }
}
.find-distributor-component .content-wrapper .distributor-results-wrapper .cards-list-wrapper {
  display: grid;
  gap: 2.5rem;
  margin: 2rem auto;
}
@media (min-width: 768px) {
  .find-distributor-component .content-wrapper .distributor-results-wrapper .cards-list-wrapper {
    gap: 5rem;
    grid-template-columns: repeat(3, 1fr);
    margin: 2rem auto;
  }
}
.find-distributor-component .content-wrapper .distributor-results-wrapper .no-matches p {
  color: #999999;
}
.find-distributor-component .content-wrapper .section-title {
  position: relative;
}
@media (max-width: 991px) {
  .find-distributor-component .content-wrapper .section-title {
    margin: 2rem 0 4rem;
  }
}
.find-distributor-component .content-wrapper .section-title hr {
  margin: 0;
}
.find-distributor-component .content-wrapper .section-title .subtitle {
  padding: 1rem 5rem;
  background: #e5e5e5;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.find-distributor-component .search-distributor-component {
  border-bottom: 1px solid #999999;
}
.find-distributor-component .search-distributor-component .container {
  display: flex;
}
.find-distributor-component .search-distributor-component .search-location-wrapper {
  padding: 4rem 7rem;
  width: 40%;
}
@media (max-width: 991px) {
  .find-distributor-component .search-distributor-component .search-location-wrapper {
    width: 100%;
    padding: 4rem 2rem;
  }
}
.find-distributor-component .search-distributor-component .search-location-wrapper .subtitle {
  margin-bottom: 1.8rem;
  margin-left: -1rem;
}
.find-distributor-component .search-distributor-component .search-location-wrapper .primary-button {
  margin-bottom: 4.8rem;
  padding: 1rem 1.5rem;
}
.find-distributor-component .search-distributor-component .search-location-wrapper .primary-button .icon {
  font-family: "Franklin Gothic URW", sans-serif !important;
}
.find-distributor-component .search-distributor-component .search-location-wrapper .primary-button .icon.icon-location:before {
  margin-right: 1rem;
}
.find-distributor-component .search-distributor-component .search-location-wrapper .primary-button:last-child {
  margin: 0;
}
.find-distributor-component .search-distributor-component .search-location-wrapper form .form-row {
  max-width: 32rem;
  margin-bottom: 1.8rem;
}
.find-distributor-component .search-distributor-component .search-location-wrapper form .form-row input,
.find-distributor-component .search-distributor-component .search-location-wrapper form .form-row select {
  font-family: "Franklin Gothic URW", sans-serif;
  border: 1px solid #e5e5e5;
}
.find-distributor-component .search-distributor-component .search-location-wrapper form .form-row input {
  padding: 0 2rem;
}
.find-distributor-component .search-distributor-component .search-location-wrapper form .form-row .selectric {
  max-width: 12rem;
}
.find-distributor-component .search-distributor-component .search-location-wrapper form .primary-button {
  padding: 1rem 3rem;
}
.find-distributor-component .search-distributor-component .search-location-wrapper .province {
  margin-bottom: 0;
  margin-right: 3rem;
}
.find-distributor-component .search-distributor-component .search-state-wrapper {
  width: 60%;
}
@media (max-width: 991px) {
  .find-distributor-component .search-distributor-component .search-state-wrapper {
    display: none;
  }
}
.find-distributor-component .search-distributor-component .search-state-wrapper .subtitle {
  margin-bottom: 7rem;
}
.find-distributor-component .search-distributor-component .search-state-wrapper .maps-wrapper img {
  transform: scale(1.5);
  transform-origin: 0% 0%;
}
.find-distributor-component .search-distributor-component .search-state-wrapper .maps-wrapper img:last-child {
  margin-left: 20rem;
}
@media (max-width: 1400px) {
  .find-distributor-component .search-distributor-component .search-state-wrapper .maps-wrapper img {
    transform: scale(1);
  }
  .find-distributor-component .search-distributor-component .search-state-wrapper .maps-wrapper img:last-child {
    margin-left: 5rem;
  }
}
.find-distributor-component .distributor-modal .modal {
  background: rgba(54, 54, 54, 0.7);
}
.find-distributor-component .distributor-modal .modal-content {
  border: none;
  background: none;
}
.find-distributor-component .distributor-modal .modal-content .modal-body {
  padding: 0;
}
.find-distributor-component .distributor-modal .modal-content .modal-body .card {
  padding: 15px 20px 30px;
}
.find-distributor-component .distributor-modal .modal-content .modal-body .card-header {
  background: none;
  border-bottom: none;
}
.find-distributor-component .distributor-modal .modal-content .modal-body .card-header .close-button {
  float: right;
  margin: 0;
}
.find-distributor-component .distributor-modal .modal-content .modal-body .card-header .subtitle {
  font-family: "Franklin Gothic URW", sans-serif;
  font-size: 2rem;
  line-height: 3.1rem;
  color: #1c3E77;
  text-align: center;
  clear: both;
}
.find-distributor-component .distributor-modal .modal-content .modal-body .card-header .title {
  text-transform: none;
  text-align: center;
}
.find-distributor-component .distributor-modal .modal-content .modal-body .card-body .primary-button {
  margin: 0 auto;
  display: block;
  padding: 1.2rem 7rem;
}
.find-distributor-component .distributor-modal .modal-content .modal-body .create-btn {
  text-align: center;
  display: block;
  transition: all 0.2s ease-in-out;
}
.find-distributor-component .distributor-modal .modal-content .modal-body .create-btn:hover {
  color: #1c3E77;
  transform: scale(1.1);
  transition: all 0.2s ease-in-out;
}

.iframe-container {
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
}

.fat-iframe {
  width: 100%;
  overflow: auto;
  height: 786px;
}

@media (min-width: 576px) {
  #fat-component.container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  #fat-component.container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  #fat-component.container {
    max-width: 1140px;
  }
}
@media (min-width: 960px) {
  #fat-component.container {
    max-width: 1140px;
  }
}

.find-a-tool-header {
  background-color: #1c3E77;
  text-align: center;
  margin-bottom: 10px;
}
.find-a-tool-header h2 {
  color: #ffffff;
  padding: 0.5rem 0;
}
.find-a-tool-secondary-header, .find-a-tool-secondary-filter-radio {
  margin-bottom: 1.6rem;
}
.find-a-tool-secondary-header .form-check, .find-a-tool-secondary-filter-radio .form-check {
  margin-bottom: 0.8rem;
}
.find-a-tool-secondary-header {
  margin-top: 50px;
}
.find-a-tool-subheader {
  background-color: #e5e5e5;
  text-align: center;
  margin-bottom: 10px;
}
.find-a-tool-subheader h3 {
  color: #7f7f7f;
  padding: 0.25rem 0;
}

.fat-h1 {
  color: #999999;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  font-size: 3.2rem;
  font-weight: 900;
  margin-top: 30px;
}

.fat-section {
  margin-left: -10px;
  margin-right: -10px;
}

.fat-group {
  padding-right: 10px;
  padding-left: 10px;
}

.fat-group-row {
  margin-top: 20px;
  margin-left: -5px;
  margin-right: -5px;
}

.find-a-tool-subsection .find-a-tool-subsection-grouping {
  padding-left: 5px;
  padding-right: 5px;
}
.find-a-tool-subsection .find-a-tool-subsection-grouping a {
  min-height: 175px;
}
.find-a-tool-subsection a {
  display: block;
}
.find-a-tool-subsection .find-a-tool-item-label {
  margin: 0.5rem auto 2rem;
  font-size: 1.28rem;
  font-family: Roboto, sans-serif;
  color: black;
  text-align: center;
  display: block;
  min-height: 50px;
  line-height: 1.6rem;
}

.fat-material-link {
  color: #222;
  border-bottom: 2px solid #222;
  font-weight: 700;
  display: inline;
}

.fat-material-div {
  padding-bottom: 10px;
}

.find-a-tool-component {
  margin-bottom: 20px;
}

.find-a-tool-secondary-filter-radio .form-radio-button {
  border: 0 solid, transparent;
}

button.fat-reset-button {
  padding: 10px 15px;
  margin: 25px auto;
  color: white;
  background-color: #d2232a;
  border: 1px solid #707070;
  display: block;
  min-width: 120px;
  cursor: pointer;
  border-radius: 0;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
}

h3.fat-results-header {
  color: #1c3E77;
  font-size: 2.4rem;
  font-weight: 700;
}
h3.fat-results-header span {
  color: black;
}

.fat-compare-button {
  background-color: transparent;
  color: #1c3E77;
  max-height: 2rem;
  border: none;
  border-bottom: 2px solid #1c3E77;
  display: inline;
  padding: 0 0 14px 0;
  border-radius: 0;
}
.fat-compare-button:disabled {
  color: black;
  border: none;
}

input.fat-primary-filter {
  background-color: white;
  opacity: 1;
  border-color: transparent;
  border-bottom: solid 2px #707070;
  max-height: 2.5rem;
  color: #000000;
}
input.fat-primary-filter:disabled {
  background-color: white;
  opacity: 1;
  border-color: transparent;
  border-bottom: solid 2px #707070;
  max-height: 2.5rem;
}
input.fat-primary-filter:focus {
  outline: none;
  box-shadow: none;
  border-color: transparent;
  border-bottom: solid 2px #707070;
}

select.fat-primary-filter {
  background-color: #e5e5e5;
  border-color: transparent;
  color: #000000;
  overflow-y: auto;
}

input.fat-secondary-filter {
  background-color: white;
  opacity: 1;
  border-color: transparent;
  border-bottom: solid 2px #707070;
  max-height: 2.5rem;
  color: #000000;
}
input.fat-secondary-filter:disabled {
  background-color: white;
  opacity: 1;
  border-color: transparent;
  border-bottom: solid 2px #707070;
  max-height: 2.5rem;
}
input.fat-secondary-filter[readonly] {
  background-color: white;
  opacity: 1;
  border-color: transparent;
  border-bottom: solid 2px #707070;
  max-height: 2.5rem;
}

div.fat-secondary-filter {
  background-color: white;
  opacity: 1;
  border-color: transparent;
  border-bottom: solid 2px #707070;
}
div.fat-secondary-filter.multi-popup {
  background-color: #e5e5e5;
}
div.fat-secondary-filter.multi-popup label {
  color: black;
}

.find-a-tool-results {
  margin: 2.6rem auto 1.6rem;
  max-width: 1100px;
}
.find-a-tool-results .combo-input:focus {
  outline: none;
}
.find-a-tool-results .find-a-tool-secondary-filter input:focus {
  box-shadow: none;
}
.find-a-tool-results .form-group label {
  color: #707070;
}
.find-a-tool-results .right-icon {
  float: right;
  padding: 0;
}
.find-a-tool-results .fat-user-options {
  margin-bottom: 15px;
  margin-top: 20px;
}
.find-a-tool-results .fat-user-options p {
  color: #707070;
  font-weight: 700;
  font-size: 1.6rem;
  margin-bottom: -5px;
}
.find-a-tool-results .fat-user-options .x-button {
  padding: 0;
  padding-left: 2px;
  color: red;
  font-size: 1.92rem;
  font-weight: 400;
}
.find-a-tool-results .fat-message {
  padding: 100px 0;
  color: #707070;
  text-align: center;
  width: 400px;
  margin: auto;
  font-size: 2.4rem;
  font-weight: 700;
}
.find-a-tool-results .align-bottom {
  align-items: flex-end;
}
.find-a-tool-results .form-check {
  padding-left: 2rem;
}
.find-a-tool-results .form-check-input {
  margin-left: -2rem;
}
.find-a-tool-results span.primary {
  color: #1c3E77;
}
.find-a-tool-results .fat-sort {
  border: none;
  border-bottom: 2px solid #707070;
  max-height: 2.8rem;
  color: #1c3E77;
}
.find-a-tool-results .fat-sort:focus > option {
  background: #e5e5e5;
}
.find-a-tool-results .fat-breadcrumb {
  display: inline-block;
  padding: 1rem 1.5rem;
  color: #1c3E77;
  font-weight: 700;
}
.find-a-tool-results .fat-breadcrumb:hover {
  border-radius: 4px;
  outline: 1px solid red;
}
.find-a-tool-results .fat-breadcrumb .x-button {
  padding: 0;
  color: red;
  font-size: 1.92rem;
  font-weight: 400;
}
.find-a-tool-results .primary-button {
  padding: 1.8rem 3.2rem;
}
.find-a-tool-results .p-autocomplete {
  display: flex;
}
.find-a-tool-results .p-autocomplete-multiple-container {
  flex: 1 1 100%;
}
.find-a-tool-results .variants-comparison {
  display: flex;
  /*justify-content: space-around;*/
}
.find-a-tool-results .variants-comparison .find-a-tool-comparison {
  flex: 1 1 auto;
  border-top: 2px solid #ccd1cf;
}
.find-a-tool-results .variants-comparison .find-a-tool-comparison .dim-diff {
  background-color: yellow;
}
.find-a-tool-results .variants-comparison .variant-dimension-output {
  border-bottom: 2px solid #ccd1cf;
  padding-left: 4rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.find-a-tool-results-card-container {
  display: flex;
  flex-wrap: wrap;
}
.modal .find-a-tool-results-card-container {
  justify-content: space-evenly;
}
.find-a-tool-results .tool-header {
  width: 100%;
  text-align: center;
  padding-bottom: 10px;
  padding-top: 10px;
}
.find-a-tool-results .tool-header h3 {
  color: white;
  font-size: 1.92rem;
  font-weight: 700;
  margin: 0 15px;
  background-color: #1c3E77;
  padding: 10px;
}
.find-a-tool-results-compare-card-container {
  display: flex;
}
.modal .find-a-tool-results-compare-card-container {
  justify-content: center;
}
.find-a-tool-results-card {
  margin: 15px 25px;
  border: 1px solid #ccd1cf;
  border-radius: 8px;
  padding: 10px 10px 45px 65px;
  position: relative;
  width: 217px;
  height: 257px;
}
@media (max-width: 1200px) {
  .find-a-tool-results-card {
    margin: 15px 5px;
  }
}
.find-a-tool-results-card .card-content h6.primary {
  font-size: 1.4rem;
  height: 8.6rem;
  margin: 0;
}
.find-a-tool-results-card .card-content a > .name-wrapper {
  overflow: hidden;
  height: 3.6em;
}
.find-a-tool-results-card .card-content a > .name-wrapper h6.name {
  line-height: 1.2em;
}
.find-a-tool-results-card .card-content .detail-text-wrapper {
  height: 2rem;
  overflow: hidden;
}
.find-a-tool-results-card .card-content .detail-text {
  font-size: 1rem;
  margin: 10px 0;
  position: relative;
  line-height: 1.2em;
}
.find-a-tool-results-card .card-content .product-id {
  margin: 5px 0;
}
.find-a-tool-results-card .card-content .product-id a {
  font-size: 1.6rem;
  font-weight: 700;
  text-decoration: underline;
  color: #5b6665;
}
.find-a-tool-results-card .card-content .product-id a:hover {
  text-decoration: none;
}
.find-a-tool-results-card .card-content .numbers {
  font-size: 1rem;
  padding: 5px 5px 5px 10px;
  background-color: #e5e5e5;
  margin: 15px 0 10px 0;
  color: #000000;
}
.find-a-tool-results-card .card-content .numbers p {
  margin: 0;
}
.find-a-tool-results-card .card-content .numbers .right {
  float: right;
}
.find-a-tool-results-card .card-content .form-group input ~ label {
  padding-left: 0rem;
}
.find-a-tool-results-card .card-content .compare-check {
  text-align: right;
  padding-right: 5px;
}
.find-a-tool-results-card .card-content .compare-check .form-check-label {
  font-size: 1rem;
}
.find-a-tool-results-card .card-content .compare-check input[type=checkbox]:checked + label::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 5px;
  background: rgba(0, 0, 0, 0);
  top: 0.9ex;
  left: 0.4ex;
  border: 3px solid #D2232A;
  border-top: none;
  border-right: none;
  transform: rotate(-45deg);
}
.find-a-tool-results-card .card-content .compare-check input[type=checkbox] {
  line-height: 2.1ex;
}
.find-a-tool-results-card .card-content .compare-check input[type=radio],
.find-a-tool-results-card .card-content .compare-check input[type=checkbox] {
  position: absolute;
  left: -999em;
}
.find-a-tool-results-card .card-content .compare-check input[type=checkbox] + label {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  line-height: 1.35;
}
.find-a-tool-results-card .card-content .compare-check input [type=checkbox][disabled] + label {
  color: #e5e5e5;
}
.find-a-tool-results-card .card-content .compare-check input[type=checkbox] + label::before {
  content: "";
  display: inline-block;
  vertical-align: -25%;
  height: 14px;
  width: 14px;
  background-color: white;
  border: 1px solid rgb(166, 166, 166);
  border-radius: 1px;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.25);
  margin-right: 0.5em;
}
.find-a-tool-results-card .card-content .compare-check input[type=checkbox]:checked + label::before {
  background: white !important;
}
.find-a-tool-results-card .card-content .compare-check input[type=checkbox][disabled] + label::before {
  background-color: #b2b2b2;
}
.find-a-tool-results-card .left-img {
  position: absolute;
  left: 0;
  bottom: 35px;
  width: 56px;
  border-top-left-radius: 8px;
  display: block;
  overflow: hidden;
}
.find-a-tool-results-card .left-img img {
  width: 100%;
  height: auto;
}
.find-a-tool-results-card .bottom-links {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 35px;
  color: white;
}
.find-a-tool-results-card .bottom-links a {
  color: white;
  font-size: 1rem;
  text-decoration: underline;
}
.find-a-tool-results-card .bottom-links a:hover {
  text-decoration: none;
}
.find-a-tool-results-card .bottom-links .left-link {
  width: 65%;
  height: 100%;
  background-color: #7f7f7f;
  text-align: right;
  padding-right: 10px;
  padding-top: 5px;
  float: left;
  border-bottom-left-radius: 8px;
}
.find-a-tool-results-card .bottom-links .right-link {
  width: 35%;
  height: 100%;
  padding-left: 10px;
  padding-top: 5px;
  float: right;
  border-bottom-right-radius: 8px;
}
.find-a-tool-results-card .bottom-links .right-link.primary {
  background-color: #1c3E77;
}
.find-a-tool-results-card .bottom-links .right-link.secondary {
  background-color: green;
}

.floating-fat-image {
  position: fixed;
  height: 45px;
  bottom: 50px;
  right: 26vw;
  z-index: 5;
}

@media (max-width: 992px) {
  .find-a-tool-results-card .card-content .compare-check {
    display: none;
  }
}
@media (max-width: 540px) {
  .find-a-tool-results-card {
    width: 180px;
    margin: 3px auto;
  }
  .find-a-tool-results-card .card-content .product-id > a {
    font-size: 0.7rem;
  }
  .find-a-tool-results-card .card-content .detail-text {
    margin: 5px 5px 5px 0;
  }
  .find-a-tool-results-card .card-content .numbers {
    padding: 5px 3px;
  }
}
/*
#fat-results {
.fat-user-options {
    h2 {
        display: inline-block;
    }
}
}

.find-a-tool-header {
background-color: $accent;
text-align: center;

h2 {
    color: $white;
    padding: 0.5rem 0;
}
}

.find-a-tool-subheader {
background-color: $grey90;
text-align: center;

h3 {
    color: $grey50;
    padding: 0.25rem 0;
}
}

.find-a-tool-subsection {
.find-a-tool-subsection-grouping {
    a {
        min-height: 175px;
    }
}

a {
    display: block;
}

h4 {
    margin: 0.5rem auto 2rem;
    font-size: 1.4rem;
    color: black;
    text-align: center;
}
}

.find-a-tool-results {
.primary-button {
}

&-component {
    .button-container {
        padding-bottom: 1rem;
    }
}


&-card-container {
    display: flex;
    flex-wrap: wrap;
}

&-card {
    margin: 15px 25px;
    border: 1px solid $grey90;
    border-radius: 8px;
    padding: 10px 10px 45px 65px;
    position: relative;
    width: 217px;
    height: 257px;

    #modal-compare & {
        margin: 15px;
    }

    @media (max-width: 1200px) {
        margin: 15px 5px;
    }

    .card-content {

        h6.primary {
            font-size: 1.4rem;
        }

        a > .name-wrapper {
            overflow: hidden;
            height: 3.6em;

            h6.name {
                line-height: 1.2em;
            }
        }

        .detail-text-wrapper {
            height: 2.4em;
            overflow: hidden;
        }

        .detail-text {
            font-size: 1rem;
            margin: 10px 0;
            position: relative;
            line-height: 1.2em;
        }

        .product-id {
            margin: 5px 0;

            a {
                font-size: 1.4rem;
                font-weight: 700;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        .numbers {
            font-size: 1rem;
            padding: 5px 5px 5px 10px;
            background-color: $grey90; //$bg-light-gray;
            margin: 15px 0 10px 0;
            color: $black;

            p {
                margin: 0;
            }

            .right {
                float: right;
            }
        }

        .compare-check {
            text-align: right;
            padding-right: 5px;

            .form-check-label {
                font-size: 1rem;
                //color: $grey90; //$info-gray;
            }

            input[type="checkbox"]:checked + label::after {
                content: '';
                position: absolute;
                width: 10px;
                height: 5px;
                background: rgba(0, 0, 0, 0);
                top: 0.9ex;
                left: 0.4ex;
                border: 3px solid #D2232A;
                border-top: none;
                border-right: none;
                transform: rotate(-45deg);
            }

            input[type="checkbox"] {
                line-height: 2.1ex;
            }

            input[type="radio"],
            input[type="checkbox"] {
                position: absolute;
                left: -999em;
            }

            input[type="checkbox"] + label {
                position: relative;
                overflow: hidden;
                cursor: pointer;
            }

            input[type="checkbox"] + label::before {
                content: "";
                display: inline-block;
                vertical-align: -25%;
                height: 14px;
                width: 14px;
                background-color: white;
                border: 1px solid rgb(166, 166, 166);
                border-radius: 1px;
                box-shadow: inset 0 2px 5px rgba(0,0,0,0.25);
                margin-right: 0.5em;
            }

            input[type="checkbox"]:checked + label::before {
                background: white !important;
            }

            .maxCompareExceeded input[type="checkbox"] + label::before {
                background-color: $grey90; //$checkbox-gray;
            }
        }
    }

    .left-img {
        position: absolute;
        left: 0;
        bottom: 35px;
        width: 56px;
        border-top-left-radius: 8px;
        display: block;
        overflow: hidden;

        img {
            width: 100%;
            height: auto;
        }
    }

    .bottom-links {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 35px;
        color: white;

        a {
            color: white;
            font-size: 1rem;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }

        .left-link {
            width: 65%;
            height: 100%;
            background-color: $grey50;
            text-align: right;
            padding-right: 10px;
            padding-top: 5px;
            float: left;
            border-bottom-left-radius: 8px;
        }

        .right-link {
            width: 35%;
            height: 100%;
            padding-left: 10px;
            padding-top: 5px;
            float: right;
            border-bottom-right-radius: 8px;

            &.primary {
                background-color: $accent;
            }

            &.secondary {
                background-color: green;
            }
        }
    }
}
}*/
.gmg-table-holder {
  width: 100%;
}
.gmg-table-holder h2 {
  text-align: center;
  color: #8C9091;
}
.gmg-table-holder table {
  width: 100%;
  margin-bottom: 50px;
  margin-top: 30px;
  border: 2px solid black;
}
.gmg-table-holder th {
  background-color: #1c3E77;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  color: white;
  line-height: 3rem;
}
.gmg-table-holder tr.bold td {
  font-weight: bold;
  color: black;
}
.gmg-table-holder tr:nth-child(odd) {
  background-color: white;
}
.gmg-table-holder tr:nth-child(even) {
  background-color: #e5e5e5;
}
.gmg-table-holder span {
  margin-left: 20px;
}

.subscribe-form .form-check-emailSignUp {
  display: flex !important;
}
.subscribe-form #emailSignUpCheckbox {
  margin-right: 10px;
  align-self: flex-start;
  margin-top: 7px;
}

button.newsletter-button {
  font-family: "Franklin Gothic URW", sans-serif;
  font-weight: 700;
  text-align: center;
  border: 2px solid #1c3E77;
  background-color: #1c3E77;
  color: #ffffff;
  padding: 1.3rem 5rem;
}
button.newsletter-button:active {
  background-color: #1c3E77;
  border-color: #1c3E77;
  color: #ffffff;
}
button.newsletter-button:hover {
  border-color: rgba(28, 62, 119, 0.8);
  background-color: rgba(28, 62, 119, 0.8);
}
button.newsletter-button:hover:active {
  background-color: #1c3E77;
  border-color: #1c3E77;
  color: #ffffff;
}

#newsletter-block {
  padding: 5.2rem 0;
  background-color: #1c3E77;
}
#newsletter-block .content-wrapper {
  text-align: center;
}
#newsletter-block .content-wrapper .signed-up-wrapper {
  display: block;
}
#newsletter-block .content-wrapper .signed-up-wrapper .title-news {
  color: #ffffff;
  font-size: 1.7em;
  text-transform: none;
}
#newsletter-block .content-wrapper .signed-up-wrapper .subtitle {
  color: #ffffff;
}
#newsletter-block .content-wrapper .signed-up-wrapper .link-item {
  color: #ffffff;
  display: block;
}
#newsletter-block .container {
  display: flex;
}
#newsletter-block .container .title-news {
  color: #ffffff;
  font-size: 1.7em;
  text-transform: none;
}
#newsletter-block .container .form-group {
  width: 100%;
}
#newsletter-block .container .form-group .form-control {
  font-size: 2rem;
  background: #ffffff;
  padding-left: 2rem;
  font-family: "Lato", sans-serif;
  color: black;
  border: 1px solid #ffffff;
  width: 51.5rem;
  height: 5.5rem;
}
@media screen and (max-width: 1100px) {
  #newsletter-block .container .form-group .form-control {
    width: 100%;
    margin-bottom: 20px !important;
  }
}
#newsletter-block .container .form-group .form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1; /* Firefox */
}
#newsletter-block .container .form-group .form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: black;
}
#newsletter-block .container .form-group .form-control::-ms-input-placeholder { /* Microsoft Edge */
  color: black;
}
#newsletter-block .container .form-group button {
  border: 2px solid #ffffff;
  margin-left: 4rem;
}
#newsletter-block .container .form-group button:hover {
  opacity: 0.8;
}

.newsletter-modal {
  font-size: 1.6rem;
}
.newsletter-modal .title {
  text-align: center;
}
.newsletter-modal .modal-content {
  background: #e5e5e5;
  padding: 2rem;
}
.newsletter-modal .modal-content .subtitle {
  font-size: 1.6rem;
}
.newsletter-modal .button-wrapper {
  text-align: center;
}

/**************************************************************************/
/**************************************************************************/
#cart-component h2.title {
  font-size: 2.4rem;
  text-transform: none;
  margin-bottom: 1rem;
}
#cart-component h3 {
  padding-top: 0;
  padding-bottom: 0;
}
#cart-component h4 {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 10px;
  color: #1c3E77;
}
#cart-component h4.title {
  color: grey;
}
#cart-component button.wide {
  width: 100%;
}
#cart-component .disabled-button {
  background-color: grey;
  border-color: grey;
}
#cart-component .disabled-button:hover {
  background-color: grey;
  border-color: grey;
}
#cart-component a.wide {
  width: 100%;
}
#cart-component a.transparent-button {
  background-color: white;
  border-left: none;
  border-right: none;
  border-radius: 0px;
  color: #1c3E77;
}
#cart-component a.transparent-button:hover {
  color: white;
  background-color: #1c3E77;
}
#cart-component .row {
  width: 100%;
}
#cart-component .checkout-description-row {
  border-top: 1px solid #b2b2b2;
  border-bottom: 1px solid #b2b2b2;
}
#cart-component .checkout-description-row .title {
  font-family: "Franklin Gothic URW", sans-serif;
  color: #000000;
}
#cart-component .bordered {
  border: 1px solid black;
}

/**************************************************************************/
/**************************************************************************/
#bill-component {
  width: 100%;
}

.billing-component {
  width: 100%;
  padding-bottom: 1rem;
}
.billing-component h2.title {
  font-size: 2.4rem;
  text-transform: none;
  margin-bottom: 1rem;
}
.billing-component h3 {
  padding-top: 0;
  padding-bottom: 0;
}
.billing-component h4 {
  padding-top: 1.6rem;
  padding-bottom: 0;
  color: #1c3E77;
}
.billing-component input {
  width: 100%;
}
.billing-component select {
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
}
.billing-component button.wide {
  width: 100%;
}
.billing-component .disabled-button {
  background-color: grey;
  border-color: grey;
}
.billing-component .disabled-button:hover {
  background-color: grey;
  border-color: grey;
}
.billing-component p {
  margin-bottom: 0;
  font-size: 1.3rem;
}
.billing-component .bordered {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  margin-bottom: 15px;
  padding-bottom: 15px;
}
.billing-component .row {
  font-size: 1.5rem;
  padding-bottom: 1rem;
}

/**************************************************************************/
/**************************************************************************/
#dest-component {
  width: 100%;
}

.shipto-component h2.title {
  font-size: 2.4rem;
  text-transform: none;
  margin-bottom: 1rem;
}
.shipto-component h3 {
  padding-top: 0;
  padding-bottom: 0;
}
.shipto-component h4 {
  padding-top: 0;
  padding-bottom: 0;
  color: #1c3E77;
}
.shipto-component input[type=text] {
  width: 100%;
}
.shipto-component select {
  margin-top: 0;
  margin-bottom: 0rem;
  width: 100%;
}
.shipto-component button.wide {
  margin: 1rem 0;
  width: 100%;
}
.shipto-component .disabled-button {
  background-color: grey;
  border-color: grey;
}
.shipto-component .disabled-button:hover {
  background-color: grey;
  border-color: grey;
}
.shipto-component .bordered {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  margin-bottom: 15px;
  padding: 15px;
}
.shipto-component .p {
  color: #333333;
}
.shipto-component .row {
  margin-top: 1.2rem;
}
.shipto-component small {
  padding-left: 1.6rem;
}

/**************************************************************************/
/**************************************************************************/
#ship-method-component {
  width: 100%;
}

.ship-method-component h2.title {
  font-size: 2.4rem;
  text-transform: none;
  margin-bottom: 1rem;
}
.ship-method-component h3 {
  padding-top: 0;
  padding-bottom: 0;
}
.ship-method-component h4 {
  padding-top: 0;
  padding-bottom: 0;
  color: #1c3E77;
}
.ship-method-component label {
  width: 100%;
  color: #f8f8f8;
}
.ship-method-component input[type=text] {
  width: 100%;
}
.ship-method-component input[type=checkbox] {
  width: 20px;
}
.ship-method-component select {
  margin-top: 0;
  margin-bottom: 0rem;
  width: 100%;
}
.ship-method-component button.wide {
  margin: 1rem 0;
  width: 100%;
}
.ship-method-component .disabled-button {
  background-color: grey;
  border-color: grey;
}
.ship-method-component .disabled-button:hover {
  background-color: grey;
  border-color: grey;
}
.ship-method-component .bordered {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  margin-bottom: 15px;
  padding: 15px;
}
.ship-method-component .p {
  color: #333333;
}

/**************************************************************************/
/**************************************************************************/
#ship-instructions {
  vertical-align: text-top;
  min-height: 100px;
  width: 100%;
}

.shipping-searchbar {
  display: flex;
  flex-direction: row-reverse;
}

.modal-content .modal-header {
  background-color: #1c3E77;
}
.modal-content button.close {
  color: #ffffff;
}
.modal-content h3.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  color: #ffffff;
}
.modal-content .shipping-grid {
  flex-wrap: wrap;
  flex-direction: row;
  display: grid;
  grid-template-columns: 30% 30% 30%;
  gap: 10px;
  padding-top: 20px;
}
.modal-content .shipping-link-desc {
  display: inline-block;
  padding-left: 3px;
  margin-bottom: 0px;
}
.modal-content .shipping-card {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  height: 20rem;
}
.modal-content p.shipping-title {
  font-size: 1.6rem;
}
.modal-content p.shipping-desc {
  font-size: 1.2rem;
  margin-bottom: 0;
}
.modal-content p.shipping-desc:last-child {
  margin-bottom: 1.4rem;
}
.modal-content p.shipping-desc:last-of-type {
  margin-bottom: 1.4rem;
}
.modal-content .shipping-select {
  margin-top: auto;
  display: flex;
  flex-direction: row-reverse;
}
.modal-content .shipping-search-container {
  width: 100%;
}
.modal-content .shipping-search-subheader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/**************************************************************************/
/**************************************************************************/
.confirm-component a.wide {
  width: 100%;
}
.confirm-component p {
  margin-bottom: 0;
  font-size: 1.3rem;
}

/**************************************************************************/
/**************************************************************************/
.confirm-cart-component {
  padding-left: 0;
}
.confirm-cart-component h2.title {
  font-size: 2.4rem;
  text-transform: none;
  margin-bottom: 1rem;
}
.confirm-cart-component h3 {
  padding-top: 0;
  padding-bottom: 0;
}
.confirm-cart-component h4 {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 10px;
  color: #1c3E77;
}
.confirm-cart-component h4.title {
  color: grey;
}
.confirm-cart-component button.wide {
  width: 100%;
}
.confirm-cart-component p {
  margin-bottom: 0;
  font-size: 1.3rem;
}
.confirm-cart-component .disabled-button {
  background-color: grey;
  border-color: grey;
}
.confirm-cart-component .disabled-button:hover {
  background-color: grey;
  border-color: grey;
}
.confirm-cart-component a.wide {
  width: 100%;
}
.confirm-cart-component a.transparent-button {
  background-color: white;
  border-left: none;
  border-right: none;
  border-radius: 0px;
  color: #1c3E77;
}
.confirm-cart-component a.transparent-button:hover {
  color: white;
  background-color: #1c3E77;
}
.confirm-cart-component .row {
  width: 100%;
}
.confirm-cart-component .checkout-description-row {
  border-top: 1px solid #b2b2b2;
  border-bottom: 1px solid #b2b2b2;
}
.confirm-cart-component .checkout-description-row .title {
  font-family: "Franklin Gothic URW", sans-serif;
  color: #000000;
}
.confirm-cart-component .bordered {
  padding-top: 1rem;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  margin-bottom: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
}
.confirm-cart-component .confirm-total {
  font-weight: 600;
  font-size: 1.6rem;
  color: black;
}

/**************************************************************************/
/**************************************************************************/
#billing-component {
  width: 100%;
}

.confirm-billing-component {
  width: 100%;
  min-height: 260px;
}
.confirm-billing-component h2.title {
  font-size: 2.4rem;
  text-transform: none;
  margin-bottom: 1rem;
}
.confirm-billing-component h3 {
  padding-top: 0;
  padding-bottom: 0;
}
.confirm-billing-component h4 {
  padding-top: 1.6rem;
  padding-bottom: 0;
  color: #1c3E77;
}
.confirm-billing-component input {
  width: 100%;
}
.confirm-billing-component select {
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
}
.confirm-billing-component button.wide {
  width: 100%;
}
.confirm-billing-component .disabled-button {
  background-color: grey;
  border-color: grey;
}
.confirm-billing-component .disabled-button:hover {
  background-color: grey;
  border-color: grey;
}
.confirm-billing-component p {
  margin-bottom: 0;
  font-size: 1.3rem;
}
.confirm-billing-component .billing-order p {
  min-height: 20px;
}
.confirm-billing-component .bordered {
  padding-top: 1rem;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  margin-bottom: 15px;
  padding-bottom: 15px;
}
.confirm-billing-component .row {
  font-size: 1.5rem;
  padding-bottom: 1rem;
}

/**************************************************************************/
/**************************************************************************/
#dest-component {
  width: 100%;
}

.confirm-dest-component {
  min-height: 260px;
}
.confirm-dest-component h2.title {
  font-size: 2.4rem;
  text-transform: none;
  margin-bottom: 1rem;
}
.confirm-dest-component h3 {
  padding-top: 0;
  padding-bottom: 0;
}
.confirm-dest-component h4 {
  padding-top: 0;
  padding-bottom: 0;
  color: #1c3E77;
}
.confirm-dest-component input[type=text] {
  width: 100%;
}
.confirm-dest-component select {
  margin-top: 0;
  margin-bottom: 0rem;
  width: 100%;
}
.confirm-dest-component button.wide {
  margin: 1rem 0;
  width: 100%;
}
.confirm-dest-component .disabled-button {
  background-color: grey;
  border-color: grey;
}
.confirm-dest-component .disabled-button:hover {
  background-color: grey;
  border-color: grey;
}
.confirm-dest-component .bordered {
  padding-top: 1rem;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  margin-bottom: 15px;
  padding-bottom: 15px;
}
.confirm-dest-component .p {
  color: #333333;
}
.confirm-dest-component .row {
  width: 100%;
}
.confirm-dest-component small {
  padding-left: 1.6rem;
}

/**************************************************************************/
/**************************************************************************/
#ship-method-component {
  width: 100%;
}

.confirm-ship-component {
  min-height: 260px;
}
.confirm-ship-component h2.title {
  font-size: 2.4rem;
  text-transform: none;
  margin-bottom: 1rem;
}
.confirm-ship-component h3 {
  padding-top: 0;
  padding-bottom: 0;
}
.confirm-ship-component h4 {
  padding-top: 0;
  padding-bottom: 0;
  color: #1c3E77;
}
.confirm-ship-component label {
  width: 100%;
  color: #f8f8f8;
}
.confirm-ship-component input[type=text] {
  width: 100%;
}
.confirm-ship-component input[type=checkbox] {
  width: 20px;
}
.confirm-ship-component select {
  margin-top: 0;
  margin-bottom: 0rem;
  width: 100%;
}
.confirm-ship-component button.wide {
  margin: 1rem 0;
  width: 100%;
}
.confirm-ship-component .disabled-button {
  background-color: grey;
  border-color: grey;
}
.confirm-ship-component .disabled-button:hover {
  background-color: grey;
  border-color: grey;
}
.confirm-ship-component .bordered {
  padding-top: 1rem;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  margin-bottom: 15px;
  padding: 15px;
}
.confirm-ship-component .p {
  color: #333333;
}

/**************************************************************************/
/**************************************************************************/
#ship-instructions {
  vertical-align: text-top;
  min-height: 100px;
  width: 100%;
}

.modal-content .modal-header {
  background-color: #1c3E77;
}
.modal-content button.close {
  color: #ffffff;
}
.modal-content h3.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  color: #ffffff;
}
.modal-content .shipping-grid {
  flex-wrap: wrap;
  flex-direction: row;
  display: grid;
  grid-template-columns: 30% 30% 30%;
  gap: 10px;
  padding-top: 20px;
}
.modal-content .shipping-link-desc {
  display: inline-block;
  padding-left: 3px;
  margin-bottom: 0px;
}
.modal-content .shipping-card {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  height: 20rem;
}
.modal-content p.shipping-title {
  font-size: 1.6rem;
}
.modal-content p.shipping-desc {
  font-size: 1.2rem;
  margin-bottom: 0;
}
.modal-content p.shipping-desc:last-child {
  margin-bottom: 1.4rem;
}
.modal-content p.shipping-desc:last-of-type {
  margin-bottom: 1.4rem;
}
.modal-content .shipping-select {
  margin-top: auto;
  display: flex;
  flex-direction: row-reverse;
}
.modal-content .shipping-search-container {
  width: 100%;
}
.modal-content .shipping-search-subheader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.backlogged-order-details {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  gap: 0.75rem;
  padding: 0.75rem 0;
}

.backlogged-items-view {
  color: #000000;
}

.trackingColumn {
  display: flex;
  flex-direction: column;
}

.order-history-component {
  font-size: 1.3rem;
}
.order-history-component .order-history-view {
  color: #000000;
  background-color: #707070;
  padding-top: 0.5rem;
}
.order-history-component .order-component {
  padding-bottom: 0.5rem;
}
.order-history-component .order-header {
  margin: 0;
  background-color: #ffffff;
}
.order-history-component .order-header.active {
  background-color: #cbedf4;
}
.order-history-component .order-header .order-header-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.order-history-component .order-header .show-more-container {
  padding-bottom: 0.5rem;
}
.order-history-component .order-header .show-more {
  color: #f8f8f8;
  cursor: pointer;
}
.order-history-component .order-header .repeat-order-link {
  font-weight: 600;
}
.order-history-component .order-header .order-tracking a {
  font-size: inherit;
}
.order-history-component .order-header .order-header-details {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 0.75rem;
  padding: 0.75rem 0;
}
.order-history-component .order-address {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  min-height: 10rem;
}
.order-history-component .order-address h3 {
  padding: 0;
  font-size: 1.6rem;
}
.order-history-component .order-history-details-component {
  padding: 1.5rem;
  background-color: lightgray;
}
.order-history-component .order-details {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 0 0.75rem;
}

@media (min-width: 480px) {
  .safety-data-search form span {
    float: right;
  }
}
@media (max-width: 480px) {
  .safety-data-search form span {
    text-align: center;
  }
  .safety-data-search form .col-md-4 {
    text-align: center;
  }
}
.safety-data-search form {
  margin-bottom: 15px;
}
.safety-data-search form span {
  font-size: 16px;
}
.safety-data-search form input {
  padding-top: 25px;
  padding-bottom: 25px;
  font-size: 16px;
}
.safety-data-search form button.wide {
  width: 100% !important;
}
.safety-data-search form button, .safety-data-search form input {
  position: relative;
  display: block;
  margin: 5px auto;
}
.safety-data-search h2 {
  text-align: center;
  padding-top: 1.5rem;
  padding-bottom: 2rem;
  color: #1c3E77;
}

.sds-search-card {
  padding: 20px 5% 20px 5%;
  background-color: #ffffff;
}
.sds-search-card .container {
  padding: 0 5% 0 5%;
  background-color: #f9f9f9;
}

.sds-card {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.sds-card h2 {
  text-align: center;
  padding-top: 1.5rem;
  font-weight: 900;
  color: #999999;
}
.sds-card h3 {
  padding-bottom: 0rem;
  Padding-top: 0rem;
}
.sds-card h4 {
  margin-bottom: 1.5rem;
  margin-top: 2.5rem;
}
.sds-card hr {
  margin-top: 1.5rem;
  margin-bottom: 4rem;
}
.sds-card a {
  font-family: "Franklin Gothic URW", sans-serif;
  font-size: 1.4rem;
  color: #1c3E77 !important;
  font-weight: 600;
  text-decoration-line: underline;
}

.search-page .content-wrapper {
  padding-top: 0;
}

.search-results-wrapper {
  margin-top: 4.5rem;
}
.search-results-wrapper h5 {
  color: #000000;
}
.search-results-wrapper h5 .subtitle {
  font-family: "Franklin Gothic URW", sans-serif;
  font-size: 2.5rem;
  position: relative;
  outline: none;
}
.search-results-wrapper h5 .subtitle:focus {
  outline: none;
}
.search-results-wrapper .card {
  box-shadow: none;
  border: none;
  max-width: 100%;
}
.search-results-wrapper .card .card-header {
  background: none;
  border-bottom: none;
  padding-left: 0;
  padding-right: 0;
}
.search-results-wrapper .card .card-header .search-title {
  font-size: 2rem;
  color: #999999;
  font-family: "Franklin Gothic URW", sans-serif;
  position: relative;
}
.search-results-wrapper .card .card-header .collapse-icon:after {
  right: -4rem;
  top: 0.6rem;
  cursor: pointer;
  font-size: 1.6rem;
}
.search-results-wrapper .card .card-header .collapse-icon.no-arrow-collapse:after {
  display: none;
}
.search-results-wrapper .card .card-body {
  padding: 0;
}
.search-results-wrapper .card .card-body .input-group-text {
  border: none;
  background: none;
  white-space: normal;
  text-align: left;
  width: 100%;
}
.search-results-wrapper .card .card-body .input-group-text.full-width {
  width: 100%;
}
.search-results-wrapper .card .card-body .input-group-text span {
  font-size: 1.5rem;
  font-family: "Franklin Gothic URW", sans-serif;
  margin-left: 5px;
  color: #000000;
}
.search-results-wrapper .card .card-body .input-group-text span .product-quantity {
  color: #999999;
  margin-left: 0;
}
.search-results-wrapper .card .card-body .input-group-text span.checkbox {
  max-width: 70%;
}
.search-results-wrapper .card .card-body .input-group-text input[type=checkbox] {
  display: none;
}
.search-results-wrapper .card .card-body .input-group-text input[type=checkbox] + label {
  display: block;
  cursor: pointer;
  margin-bottom: 0;
}
.search-results-wrapper .card .card-body .input-group-text input[type=checkbox] + label:before {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  border: 1px solid #cccccc;
  border-radius: 0.2em;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: text-top;
  color: transparent;
  transition: 0.2s;
  background: #cccccc;
  margin-top: -1px;
  font-size: 0.9rem;
  line-height: 0.8rem;
  font-weight: 700;
}
.search-results-wrapper .card .card-body .input-group-text input[type=checkbox] + label:active:before {
  transform: scale(0);
}
.search-results-wrapper .card .card-body .input-group-text input[type=checkbox]:checked + label:before {
  background-color: #1c3E77;
  border-color: #1c3E77;
  color: #fff;
}
.search-results-wrapper .card .card-body .input-group-text input[type=checkbox]:disabled + label:before {
  transform: scale(1);
  border-color: #aaa;
}
.search-results-wrapper .card .card-body .input-group-text input[type=checkbox]:checked:disabled + label:before {
  transform: scale(1);
  background-color: #bfb;
  border-color: #bfb;
}
.search-results-wrapper .content-wrapper {
  padding: 0;
}
.search-results-wrapper .content-wrapper .form-group {
  max-width: 253px;
}
.search-results-wrapper .content-wrapper .form-group .form-control {
  background: rgba(161, 161, 161, 0.27);
  border-radius: 0;
  box-shadow: none;
  border: 1px solid transparent;
  color: #999999;
  font-size: 1.6rem;
  font-family: "Franklin Gothic URW", sans-serif;
}
.search-results-wrapper .content-wrapper .form-group ::-webkit-input-placeholder { /* Edge */
  color: #999999;
  font-size: 1.6rem;
  font-family: "Franklin Gothic URW", sans-serif;
}
.search-results-wrapper .content-wrapper .form-group :-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #999999;
  font-size: 1.6rem;
  font-family: "Franklin Gothic URW", sans-serif;
}
.search-results-wrapper .content-wrapper .form-group ::placeholder {
  color: #999999;
  font-size: 1.6rem;
  font-family: "Franklin Gothic URW", sans-serif;
}

@media (max-width: 767px) {
  .search-results-wrapper {
    box-shadow: 2px 0 8px #888888;
    margin-bottom: 20px;
  }
  .search-results-wrapper .card .card-body .input-group-text span {
    font-size: 1.3rem;
  }
  .search-results-wrapper .card .card-header .search-title {
    font-size: 1.8rem;
  }
  .search-results-wrapper .card .card-header .collapse-icon:after {
    top: 0.6rem;
  }
  .search-results-wrapper .card .card-header .subtitle {
    position: relative;
  }
  .search-results-wrapper .card .card-header .subtitle:after {
    top: 0.4rem;
    right: 3.8rem;
    color: #999999;
  }
  .search-results-wrapper .primary-button.filters {
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
    margin-top: 25px;
  }
  .search-results-wrapper .holder-filters {
    padding-bottom: 2rem;
  }
}
.search-tabs-holder {
  border-left: 1px solid #bfbfbf;
}
.search-tabs-holder .search-product,
.search-tabs-holder .search-resources {
  font-size: 2.5rem;
  font-family: "Franklin Gothic URW", sans-serif;
  border-radius: 4px !important;
  padding: 0rem 5.3rem 2.2rem 1rem;
  margin-left: 10px;
  color: #1c3E77;
}

.search-page .content-wrapper {
  padding-top: 3rem;
}

.search-results-holder {
  padding-bottom: 0 !important;
  border-bottom: 2px solid #999999 !important;
}
.search-results-holder .results-showed {
  color: #999999;
  font-size: 1.8rem;
  font-family: "Franklin Gothic URW", sans-serif;
}

.search-tabs-holder .search-pane {
  padding-top: 3rem;
}
.search-tabs-holder .search-pane:first-child {
  border-bottom: 1px solid #bfbfbf;
  padding-bottom: 4.5rem;
}
.search-tabs-holder .search-pane .pagination {
  padding-left: 2.1rem;
}
.search-tabs-holder .search-pane .pagination .pag-arrow {
  align-self: center;
}
.search-tabs-holder .search-pane .pagination .prev-arrow {
  border-top: 2px solid #1c3E77;
  border-left: 2px solid #1c3E77;
  transform: rotate(-45deg);
  width: 9px;
  height: 9px;
  line-height: 9px;
  display: block;
  transition: 0.2s;
}
.search-tabs-holder .search-pane .pagination .prev-arrow:hover {
  border-top: 2px solid rgba(28, 62, 119, 0.8);
  border-left: 2px solid rgba(28, 62, 119, 0.8);
}
.search-tabs-holder .search-pane .pagination .prev-arrow.disabled {
  border-top: 2px solid #cccccc;
  border-left: 2px solid #cccccc;
}
.search-tabs-holder .search-pane .pagination .next-arrow {
  border-top: 2px solid #1c3E77;
  border-right: 2px solid #1c3E77;
  transform: rotate(45deg);
  width: 9px;
  height: 9px;
  line-height: 9px;
  display: block;
  transition: 0.2s;
}
.search-tabs-holder .search-pane .pagination .next-arrow:hover {
  border-top: 2px solid rgba(28, 62, 119, 0.8);
  border-right: 2px solid rgba(28, 62, 119, 0.8);
}
.search-tabs-holder .search-pane .pagination .next-arrow.disabled {
  border-top: 2px solid #cccccc;
  border-right: 2px solid #cccccc;
}
.search-tabs-holder .search-pane .pagination .pag-holder {
  font-size: 2.2rem;
  font-family: "Franklin Gothic URW", sans-serif;
}
.search-tabs-holder .resources-item {
  background: #e5e5e5;
  margin-bottom: 1.5rem;
  padding: 0.5rem;
  text-align: center;
}
.search-tabs-holder .resources-item .img-fluid {
  max-width: 14rem;
}
.search-tabs-holder .products-item {
  background: #e5e5e5;
  margin-bottom: 1.5rem;
  padding: 34px 0;
  text-align: left;
}
.search-tabs-holder .products-item .img-fluid {
  width: 100%;
}
.search-tabs-holder .products-item:last-child {
  margin-bottom: 0;
}
.search-tabs-holder .search-listing-title {
  font-size: 2.2rem;
  font-family: "Franklin Gothic URW", sans-serif;
  color: #999999;
  text-align: left;
}
.search-tabs-holder .search-listing-text {
  font-size: 1.8rem;
  font-family: "Franklin Gothic URW", sans-serif;
  color: #000000;
  margin-bottom: 0;
  text-align: left;
}
@media (max-width: 480px) {
  .search-tabs-holder .search-results-wrapper {
    margin-bottom: 2rem;
  }
  .search-tabs-holder .products-item {
    display: block !important;
  }
  .search-tabs-holder .resources-item {
    display: block !important;
    padding-bottom: 2rem;
    padding-top: 2rem;
  }
  .search-tabs-holder .search-listing-title {
    font-size: 1.8rem;
    margin-top: 1.5rem;
  }
  .search-tabs-holder .search-listing-text {
    font-size: 1.4rem;
    line-height: 2rem;
  }
}

.shipping-address-root {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3.2rem;
}
.shipping-address-root .shipping-address-container {
  min-height: 225px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1607843137);
  padding: 1.6rem 3.2rem;
}
.shipping-address-root .shipping-address-container .branch-label span {
  font-weight: bold;
}
.shipping-address-root .shipping-address-container > h3 {
  padding: 0;
}
.shipping-address-root .shipping-address-container .buttons-holder {
  text-align: right;
}
.shipping-address-root .shipping-address-container .buttons-holder > button {
  font-size: 1.6rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.shipping-address-root .shipping-address-container .buttons-holder > button:last-child {
  padding-right: 0;
}
.shipping-address-root .shipping-address-container .buttons-holder > button.primary-link {
  color: #1c3E77;
  text-decoration: underline;
}
.shipping-address-root .shipping-address-container .buttons-holder > button.secondary-link {
  color: #707070;
  text-decoration: underline;
}
.shipping-address-root .add-shipping-address-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.shipping-address-root .add-shipping-address-container button {
  width: 100%;
  height: 100%;
}

@media (min-width: 480px) {
  .sim-file-search form span {
    float: right;
  }
}
@media (max-width: 480px) {
  .sim-file-search form span {
    text-align: center;
  }
  .sim-file-search form .col-md-4 {
    text-align: center;
  }
}
.sim-file-search form {
  margin-bottom: 15px;
}
.sim-file-search form span {
  font-size: 16px;
}
.sim-file-search form .form-control {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
  font-size: 16px;
}
.sim-file-search form button.wide {
  width: 100% !important;
}
.sim-file-search form button, .sim-file-search form input {
  position: relative;
  display: block;
  margin: 5px auto;
}
.sim-file-search h2 {
  text-align: center;
  padding-top: 1.5rem;
  padding-bottom: 2rem;
  color: #1c3E77;
}

.sim-file-results.container {
  padding-left: 6%;
  padding-right: 6%;
  padding-top: 40px;
  padding-bottom: 50px;
}
.sim-file-results.container .sim-file {
  background-color: #f9f9f9;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 15px;
  padding-right: 15px;
}
.sim-file-results.container .sim-file span.h3 {
  text-align: center;
  padding-top: 1.5rem;
  padding-bottom: 2rem;
  padding-left: 2rem;
  color: #1c3E77;
  font-weight: 700;
  border: 1px solid #f9f9f9;
}
.sim-file-results.container .sim-file span.h4 {
  font-size: 1.6rem;
  padding-left: 2rem;
}
.sim-file-results.container .sim-file a {
  margin-left: 10px;
  text-align: center;
  font-size: 1.6rem;
  text-decoration: underline;
  font-weight: 600;
}

.sim-modal-component .modal-dialog {
  max-width: 90rem;
}
.sim-modal-component .modal-dialog .modal-content {
  padding-bottom: 3rem;
}
.sim-modal-component .modal-dialog .modal-content .modal-header {
  position: relative;
  padding: 4rem 4rem 2rem;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .sim-modal-component .modal-dialog .modal-content .modal-header {
    display: block;
  }
}
.sim-modal-component .modal-dialog .modal-content .modal-header .modal-title-wrapper .title {
  display: block;
  font-size: 2.2rem;
  line-height: 2.9rem;
  color: #ffffff;
}
.sim-modal-component .modal-dialog .modal-content .modal-header .modal-title-wrapper .subtitle {
  font-size: 1.8rem;
  line-height: 2.7rem;
  color: #ffffff;
}
.sim-modal-component .modal-dialog .modal-content .modal-header .input-search-wrapper {
  width: 44rem;
}
@media (max-width: 767px) {
  .sim-modal-component .modal-dialog .modal-content .modal-header .input-search-wrapper {
    max-width: 100%;
  }
}
.sim-modal-component .modal-dialog .modal-content .modal-header .input-search-wrapper input {
  width: 100%;
  font-size: 1.8rem;
}
.sim-modal-component .modal-dialog .modal-content .modal-header .close {
  position: absolute;
  right: 1rem;
  top: 1rem;
}
.sim-modal-component .modal-dialog .modal-content .modal-body {
  display: flex;
  flex-wrap: wrap;
  padding: 3rem;
  overflow: auto;
  max-height: 35rem;
}
.sim-modal-component .modal-dialog .modal-content .modal-body .link-item {
  font-size: 1.6rem;
  width: 16.66%;
  padding: 0 0.5rem;
  text-align: center;
  color: #999999;
  line-height: 2.7rem;
  min-width: 4.8rem;
}
@media (max-width: 767px) {
  .sim-modal-component .modal-dialog .modal-content .modal-body .link-item {
    font-size: 1.6rem;
    width: 25%;
  }
}

@media (min-width: 480px) {
  .speeds-feeds-search form span {
    float: right;
  }
}
@media (max-width: 480px) {
  .speeds-feeds-search form span {
    text-align: center;
  }
  .speeds-feeds-search form .col-md-4 {
    text-align: center;
  }
}
.speeds-feeds-search form {
  margin-bottom: 15px;
}
.speeds-feeds-search form span {
  font-size: 16px;
}
.speeds-feeds-search form .form-control {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
  font-size: 16px;
}
.speeds-feeds-search form button.wide {
  width: 100% !important;
}
.speeds-feeds-search form button, .speeds-feeds-search form input {
  position: relative;
  display: block;
  margin: 5px auto;
}
.speeds-feeds-search h2 {
  text-align: center;
  padding-top: 1.5rem;
  padding-bottom: 2rem;
  color: #1c3E77;
}

.speed-feed-results {
  padding-left: 6%;
  padding-right: 6%;
  padding-top: 40px;
  padding-bottom: 50px;
}
.speed-feed-results .sandf-file {
  background-color: #f9f9f9;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 15px;
  padding-right: 15px;
}
.speed-feed-results .sandf-file span.h3 {
  text-align: center;
  padding-top: 1.5rem;
  padding-bottom: 2rem;
  padding-left: 2rem;
  color: #1c3E77;
  font-weight: 700;
  border: 1px solid #f9f9f9;
}
.speed-feed-results .sandf-file span.h4 {
  font-size: 1.6rem;
  padding-left: 2rem;
}
.speed-feed-results .sandf-file a {
  margin-left: 10px;
  text-align: center;
  font-size: 1.6rem;
  text-decoration: underline;
  font-weight: 600;
}

.speeds-feeds-tree-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.speeds-feeds-tree-container h2 {
  text-align: center;
  padding-top: 1.5rem;
  font-weight: 900;
  color: #999999;
}
.speeds-feeds-tree-container h3 {
  padding-bottom: 0rem;
  Padding-top: 0rem;
}
.speeds-feeds-tree-container h4 {
  margin-bottom: 1.5rem;
  margin-top: 2.5rem;
}
.speeds-feeds-tree-container hr {
  margin-top: 1.5rem;
  margin-bottom: 4rem;
}
.speeds-feeds-tree-container a {
  font-family: "Franklin Gothic URW", sans-serif;
  font-size: 1.4rem;
  color: #1c3E77 !important;
  font-weight: 600;
  text-decoration-line: underline;
}
.speeds-feeds-tree-container .speed-feed-section {
  margin-bottom: 0px;
  margin-top: 20px;
}
.speeds-feeds-tree-container .speed-feed-label {
  padding-left: 0px;
}

.speedsfeeds-modal-component .modal-dialog {
  max-width: 90rem;
}
.speedsfeeds-modal-component .modal-dialog .modal-content {
  padding-bottom: 3rem;
}
.speedsfeeds-modal-component .modal-dialog .modal-content .modal-header {
  position: relative;
  padding: 4rem 4rem 2rem;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .speedsfeeds-modal-component .modal-dialog .modal-content .modal-header {
    display: block;
  }
}
.speedsfeeds-modal-component .modal-dialog .modal-content .modal-header .modal-title-wrapper .title {
  display: block;
  font-size: 2.2rem;
  line-height: 2.9rem;
  color: #ffffff;
}
.speedsfeeds-modal-component .modal-dialog .modal-content .modal-header .modal-title-wrapper .subtitle {
  font-size: 1.8rem;
  line-height: 2.7rem;
  color: #ffffff;
}
.speedsfeeds-modal-component .modal-dialog .modal-content .modal-header .input-search-wrapper {
  width: 44rem;
}
@media (max-width: 767px) {
  .speedsfeeds-modal-component .modal-dialog .modal-content .modal-header .input-search-wrapper {
    max-width: 100%;
  }
}
.speedsfeeds-modal-component .modal-dialog .modal-content .modal-header .input-search-wrapper input {
  width: 100%;
  font-size: 1.8rem;
}
.speedsfeeds-modal-component .modal-dialog .modal-content .modal-header .close {
  position: absolute;
  right: 1rem;
  top: 1rem;
}
.speedsfeeds-modal-component .modal-dialog .modal-content .modal-body {
  display: flex;
  flex-wrap: wrap;
  padding: 3rem;
  overflow: auto;
  max-height: 35rem;
}
.speedsfeeds-modal-component .modal-dialog .modal-content .modal-body .link-item {
  font-size: 1.8rem;
  width: 50%;
  padding: 0 0.5rem;
  text-align: center;
  color: #999999;
  line-height: 3.5rem;
  min-width: 4.8rem;
}
@media (max-width: 767px) {
  .speedsfeeds-modal-component .modal-dialog .modal-content .modal-body .link-item {
    font-size: 1.6rem;
    width: 25%;
  }
}

.tabs-component .nav-tabs {
  list-style-type: none;
  padding-left: 25px;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
}
.tabs-component .nav-tabs li {
  display: inline-block;
  padding: 10px;
  margin-right: 1rem;
  flex-basis: 50px;
  z-index: 5;
  text-align: center;
}
.tabs-component .nav-tabs li.stacked {
  position: absolute;
  right: 20px;
  bottom: 0;
  top: 0;
}
.tabs-component nav {
  border-bottom: 1px solid #b2b2b2;
  background: transparent;
}
@media (max-width: 1600px) {
  .tabs-component nav {
    padding-left: 3rem;
  }
}
@media (max-width: 767px) {
  .tabs-component nav {
    padding-left: 2rem;
  }
}
.tabs-component nav .nav-tabs {
  background: transparent;
  border-bottom: none;
  width: 90rem;
  margin: 0 0 -1px -2.2rem;
  padding-left: 2.2rem;
}
@media (max-width: 1200px) {
  .tabs-component nav .nav-tabs {
    width: 70rem;
  }
}
@media (max-width: 991px) {
  .tabs-component nav .nav-tabs {
    width: 36rem;
  }
}
@media (max-width: 767px) {
  .tabs-component nav .nav-tabs {
    width: 28rem;
  }
}
.tabs-component nav .nav-tabs li {
  padding: 0;
  background: #ffffff;
  text-align: center;
  display: flex;
  justify-content: center;
}
.tabs-component nav .nav-tabs li .nav-item {
  cursor: pointer;
  text-align: center;
  font-family: "Franklin Gothic URW", sans-serif;
  margin: 0 0 -1px;
  font-weight: 700;
  color: #999999;
  border: 1px solid #b2b2b2;
  padding: 2.5rem 1rem;
  word-wrap: break-word;
}
.tabs-component nav .nav-tabs li .nav-item.active {
  background: #ffffff;
  border-bottom-color: #ffffff;
}
.tabs-component nav .fixed {
  border: 1px solid #b2b2b2;
  margin-bottom: -1px;
  background: #ffffff;
  font-family: "Franklin Gothic URW", sans-serif;
  color: #999999;
  font-size: 1.6rem;
  font-weight: 700;
  display: inline-block;
}
@media (max-width: 991px) {
  .tabs-component nav .fixed {
    font-size: 1.1rem;
  }
}
.tabs-component .nav-tabs-component {
  position: relative;
}
.tabs-component .nav-tabs-component nav {
  padding: 0 5rem;
}
@media (max-width: 991px) {
  .tabs-component .nav-tabs-component .next-btn,
  .tabs-component .nav-tabs-component .prev-btn {
    padding: 0.5rem;
  }
}
.tabs-component .nav-tabs-component .prev-btn {
  position: absolute;
  background: transparent;
  left: 0;
  top: 0;
  bottom: 0;
  transform: translateX(-100%);
  z-index: 6;
}
@media (max-width: 991px) {
  .tabs-component .nav-tabs-component .prev-btn {
    left: -1.8rem;
  }
}
.tabs-component .nav-tabs-component .prev-btn .fa-icon::before {
  content: "\f053";
  font-family: "Font Awesome 5 Free";
  color: #999999;
  font-size: 2rem;
}
.tabs-component .nav-tabs-component .next-btn {
  background: transparent;
  position: absolute;
  right: -3rem;
  top: 0;
  bottom: 0;
  z-index: 6;
}
@media (max-width: 991px) {
  .tabs-component .nav-tabs-component .next-btn {
    right: 0.5rem;
  }
}
.tabs-component .nav-tabs-component .next-btn .fa-icon::before {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  color: #999999;
  font-size: 2rem;
}
.tabs-component .nav-item {
  margin: 0;
  padding: 2.5rem;
  width: 20rem;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  text-align: center;
}
@media (max-width: 1200px) {
  .tabs-component .nav-item {
    width: 15rem;
  }
}
@media (max-width: 991px) {
  .tabs-component .nav-item {
    padding: 1rem 0;
    width: 6.6rem;
    line-height: 1.5rem;
    font-size: 1.1rem;
  }
}
.tabs-component .nav-tabs li.stacked-left {
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
}
.tabs-component .nav-tabs li.stacked.shift-1 {
  margin-right: 15px;
  z-index: 4;
}
.tabs-component .nav-tabs li.stacked.shift-2 {
  margin-right: 10px;
  z-index: 3;
}
.tabs-component .nav-tabs li.stacked.shift-3 {
  margin-right: 5px;
  z-index: 2;
}
.tabs-component .nav-tabs li.stacked-left.shift-1 {
  margin-left: 0;
  z-index: 2;
}
.tabs-component .nav-tabs li.stacked-left.shift-2 {
  margin-left: 5px;
  z-index: 3;
}
.tabs-component .nav-tabs li.stacked-left.shift-3 {
  margin-left: 10px;
  z-index: 4;
}

#wish-list-component h3 {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

.disabled-button {
  background-color: grey !important;
  border-color: grey !important;
  /*display: inline-block;
  border: none;
  background-color: $white;
  outline: none;
  font-size: 1.5rem;
  font-weight: 600;
  border-radius: .4rem;
  text-transform: capitalize;
  line-height: 1;*/
}
.disabled-button:hover {
  background-color: grey !important;
  border-color: grey !important;
}

.checkout-button {
  margin-top: 2rem !important;
  font-size: 2rem !important;
  padding: 2.3rem 5rem !important;
}

.subtitle-cart-option {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 2.2rem;
  text-align: center;
  padding-top: 3rem;
}

.totalsRow {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: flex-end;
}

.allTotalText {
  color: #000000;
  font-size: 1.7rem;
  font-weight: 700;
  margin: 1.5rem 0 1rem 0;
}

.allNetTotalText {
  font-size: 1.7rem;
  font-weight: 700;
  margin: 0 0 1rem 0;
  color: #1c3E77;
}

.dropdown-wrapper form .form-group .selectric,
.add-list-modal form .form-group .selectric {
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  background: #ffffff;
}
.dropdown-wrapper form .form-group label,
.add-list-modal form .form-group label {
  font-size: 1.4rem;
  margin-top: 1.4rem;
}
.dropdown-wrapper form .selectric-items li,
.add-list-modal form .selectric-items li {
  line-height: 1rem;
  padding-left: 4rem;
}
.dropdown-wrapper form .selectric-items li.icon,
.add-list-modal form .selectric-items li.icon {
  font-size: 1.2rem;
}

.dropdown-wrapper {
  position: relative;
}
.dropdown-wrapper .new-list-dropdown {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.26);
  background-color: #ffffff;
  position: absolute;
  z-index: 1050;
  padding: 0px !important;
}
.dropdown-wrapper .new-list-dropdown .primary-button {
  display: flex;
  height: auto !important;
  width: 100%;
  padding: 15px 20px !important;
  font-size: 1.8rem !important;
  margin: 3rem auto 0 !important;
  justify-content: center;
}
.dropdown-wrapper .title {
  font-size: 1.6rem;
  color: #ffffff;
}
.dropdown-wrapper .subtitle {
  font-size: 1.6rem;
}
.dropdown-wrapper .quantity-wrapper {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.dropdown-wrapper .quantity-wrapper .availability {
  font-size: 1.2rem;
  display: inline-block;
  font-weight: 700;
  color: #1c3E77;
}
.dropdown-wrapper .quantity-wrapper label {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.dropdown-wrapper .quantity-wrapper input {
  border: 1px solid #DDD;
  max-width: 5rem;
  border-radius: 0.5rem;
  height: 4.7rem;
  font-size: 1.5rem;
}

.add-list-modal .modal-dialog {
  top: 40%;
  transform: translateY(-50%) !important;
}
.add-list-modal .modal-dialog .modal-header {
  border: none;
}
.add-list-modal .modal-dialog .modal-header .close span {
  font-size: 2.5rem;
}
.add-list-modal .modal-dialog .modal-body {
  padding: 0 3rem 6rem;
}
.add-list-modal .modal-dialog .modal-body .title {
  text-transform: none;
  font-size: 1.8rem;
}
.add-list-modal .modal-dialog .modal-body .subtitle {
  font-size: 1.6rem;
  margin-bottom: 2rem;
}
.add-list-modal .modal-dialog .modal-body form {
  /*max-width: 36rem;*/
  margin: 0 auto;
}
.add-list-modal .modal-dialog .modal-body form .quantity-wrapper {
  margin-bottom: 2rem;
}
.add-list-modal .modal-dialog .modal-body form .quantity-wrapper label {
  margin-right: 2rem;
}
.add-list-modal .modal-dialog .modal-body .selectric {
  border: 1px solid #DDD !important;
}
.add-list-modal .modal-dialog .modal-body .selectric .button {
  top: 0.1rem;
}

.share-list-modal .modal-dialog, .send-list-modal .modal-dialog {
  max-width: 106.1rem !important;
}
.share-list-modal .modal-content .modal-header, .send-list-modal .modal-content .modal-header {
  padding: 2rem 8rem;
  background: #ffffff;
}
.share-list-modal .modal-content .modal-header .title, .send-list-modal .modal-content .modal-header .title {
  font-size: 2.4rem;
  margin: 0;
}
.share-list-modal .modal-content .modal-header .close, .send-list-modal .modal-content .modal-header .close {
  color: #1c3E77;
}
.share-list-modal .modal-content .modal-header .close span, .send-list-modal .modal-content .modal-header .close span {
  font-size: 2.4rem;
}
.share-list-modal .modal-content .modal-body, .send-list-modal .modal-content .modal-body {
  padding: 3.5rem 8rem;
  background: #ffffff;
  text-align: left;
}
.share-list-modal .modal-content .modal-body .title, .send-list-modal .modal-content .modal-body .title {
  font-size: 2rem;
  text-transform: none;
  margin-bottom: 2rem;
}
.share-list-modal .modal-content .modal-body form, .send-list-modal .modal-content .modal-body form {
  margin-bottom: 3rem;
}
.share-list-modal .modal-content .modal-body form .info-wrapper, .send-list-modal .modal-content .modal-body form .info-wrapper {
  width: 36rem;
}
.share-list-modal .modal-content .modal-body form .form-group, .send-list-modal .modal-content .modal-body form .form-group {
  width: 80%;
}
.share-list-modal .modal-content .modal-body form .form-group input,
.share-list-modal .modal-content .modal-body form .form-group textarea, .send-list-modal .modal-content .modal-body form .form-group input,
.send-list-modal .modal-content .modal-body form .form-group textarea {
  font-size: 1.4rem;
  padding-left: 2.2rem;
  background: #ffffff;
  font-family: "Franklin Gothic URW", sans-serif;
  border: 1px solid #b2b2b2;
}
.share-list-modal .modal-content .modal-body form .form-group label, .send-list-modal .modal-content .modal-body form .form-group label {
  font-family: "Franklin Gothic URW", sans-serif;
  font-size: 1.4rem;
}
.share-list-modal .modal-content .modal-body form .form-group label.send-list-message, .send-list-modal .modal-content .modal-body form .form-group label.send-list-message {
  padding-top: 20px !important;
  color: black;
}
.share-list-modal .modal-content .modal-body form .form-check, .send-list-modal .modal-content .modal-body form .form-check {
  margin-bottom: 2rem;
}
.share-list-modal .modal-content .modal-body form .form-check input, .send-list-modal .modal-content .modal-body form .form-check input {
  vertical-align: middle;
  width: 2.4rem;
  height: 2.4rem;
  margin-top: -0.3rem;
}
.share-list-modal .modal-content .modal-body form .form-check label, .send-list-modal .modal-content .modal-body form .form-check label {
  font-family: "Franklin Gothic URW", sans-serif;
  font-size: 1.4rem;
}
.share-list-modal .modal-content .modal-body form .form-check label::before, .send-list-modal .modal-content .modal-body form .form-check label::before {
  margin-right: 1rem;
  vertical-align: middle;
}
.share-list-modal .modal-content .modal-body form .primary-button, .send-list-modal .modal-content .modal-body form .primary-button {
  width: 16.4rem;
  padding: 1.5rem 0;
}
.share-list-modal .modal-content .modal-body .items-wrapper .row, .send-list-modal .modal-content .modal-body .items-wrapper .row {
  border-bottom: 1px solid #999999;
  margin: 0;
  padding: 2rem 0;
}
.share-list-modal .modal-content .modal-body .items-wrapper .row:last-child, .send-list-modal .modal-content .modal-body .items-wrapper .row:last-child {
  border: none;
}
.share-list-modal .modal-content .modal-body .items-wrapper .row .title, .send-list-modal .modal-content .modal-body .items-wrapper .row .title {
  font-family: "Franklin Gothic URW", sans-serif;
  margin: 0;
  font-size: 1.6rem;
  color: #000000;
}
.share-list-modal .modal-content .modal-body .items-wrapper .row .col-md-2.col-sm-4, .send-list-modal .modal-content .modal-body .items-wrapper .row .col-md-2.col-sm-4 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.share-list-modal .modal-content .modal-body .items-wrapper .row .col-md-2.col-sm-4 span, .send-list-modal .modal-content .modal-body .items-wrapper .row .col-md-2.col-sm-4 span {
  font-family: "Franklin Gothic URW", sans-serif;
  color: #000000;
  font-weight: 700;
}
.share-list-modal .modal-content .modal-body .items-wrapper .item-row .product-wrapper, .send-list-modal .modal-content .modal-body .items-wrapper .item-row .product-wrapper {
  display: flex;
}
.share-list-modal .modal-content .modal-body .items-wrapper .item-row .product-wrapper .img-wrapper, .send-list-modal .modal-content .modal-body .items-wrapper .item-row .product-wrapper .img-wrapper {
  max-width: 3rem;
}
.share-list-modal .modal-content .modal-body .items-wrapper .item-row .product-wrapper .img-wrapper img, .send-list-modal .modal-content .modal-body .items-wrapper .item-row .product-wrapper .img-wrapper img {
  width: 100%;
}
.share-list-modal .modal-content .modal-body .items-wrapper .item-row .product-wrapper .content-wrapper, .send-list-modal .modal-content .modal-body .items-wrapper .item-row .product-wrapper .content-wrapper {
  margin-left: 2rem;
}
.share-list-modal .modal-content .modal-body .items-wrapper .item-row .product-wrapper .content-wrapper .title, .send-list-modal .modal-content .modal-body .items-wrapper .item-row .product-wrapper .content-wrapper .title {
  font-size: 1.8rem;
  color: #000000;
  margin: 0;
}
.share-list-modal .modal-content .modal-body .items-wrapper .item-row .product-wrapper .content-wrapper .subtitle, .send-list-modal .modal-content .modal-body .items-wrapper .item-row .product-wrapper .content-wrapper .subtitle {
  font-size: 1.4rem;
  color: #000000;
  font-weight: 700;
  line-height: 1.7rem;
  font-family: "Franklin Gothic URW", sans-serif;
}
.share-list-modal .modal-content .modal-body .items-wrapper .item-row .product-wrapper .content-wrapper .item-description, .send-list-modal .modal-content .modal-body .items-wrapper .item-row .product-wrapper .content-wrapper .item-description {
  line-height: 1.5;
}
.share-list-modal .modal-content .modal-body .description-wrapper, .send-list-modal .modal-content .modal-body .description-wrapper {
  margin: 10rem auto;
  text-align: center;
}

.empty-list-component {
  max-width: 40rem;
  text-align: center;
  margin: 10rem auto;
}
.empty-list-component .title {
  margin-bottom: 1rem !important;
  text-transform: capitalize !important;
  font-size: 1.8rem !important;
}
.empty-list-component .info {
  line-height: 2;
}
.empty-list-component .info-link {
  font-family: "Franklin Gothic URW", sans-serif;
  color: #1c3E77;
}

.history-list-component {
  padding: 5.4rem 0 20rem;
  background: #e5e5e5;
}
@media (max-width: 767px) {
  .history-list-component:last-child {
    border: none;
  }
}
.history-list-component .title {
  font-family: "Franklin Gothic URW", sans-serif;
  text-transform: none;
  font-size: 2.2rem;
  margin-bottom: 3.9rem;
}
.history-list-component .list-item {
  margin-bottom: 2rem;
  font-size: 1.4rem;
  color: #000000;
}

.saved-list-component {
  background: #ffffff;
  padding-top: 1.8rem;
}
.saved-list-component .icon {
  color: #000000;
  font-family: "Franklin Gothic URW", sans-serif;
  font-size: 2.2rem;
  font-weight: 700;
  position: relative;
  vertical-align: middle;
}
.saved-list-component .icon.icon-my-list {
  font-weight: 400;
}
.saved-list-component .tab-pane {
  display: none;
}
.saved-list-component .tab-pane.active {
  display: block;
}
.saved-list-component .new-list-modal .form-group .form-control {
  font-size: 1.4rem;
}
.saved-list-component .saved-tab-wrapper {
  margin: 0;
}
.saved-list-component .tab-content {
  padding: 1rem 1.5rem;
  margin-bottom: 3rem;
}
.saved-list-component .tab-content .row {
  margin-right: 0;
  margin-top: 2rem;
}
.saved-list-component .tab-content .col-md-3, .saved-list-component .tab-content .col-md-9 {
  padding: 0;
}
@media (max-width: 767px) {
  .saved-list-component .tab-content {
    padding: 0;
  }
  .saved-list-component .tab-content:last-child {
    padding-bottom: 0;
  }
}
.saved-list-component .tab-content a.wide {
  width: 100%;
}
.saved-list-component .tab-content #quickOrderCollapse {
  border: 1px solid black;
  background-color: #e5e5e5;
  padding: 15px;
}
.saved-list-component .tab-content #quickOrderCollapse .indent {
  margin-left: 20px;
}
.saved-list-component .tab-content #quickOrderCollapse .row {
  margin: 0;
  width: 100%;
  padding: 0;
}
.saved-list-component .tab-content #quickOrderCollapse .row p {
  margin: 0;
}
.saved-list-component .tab-content #quickOrderCollapse .row input[type=text] {
  max-width: 90%;
  margin: 0;
  padding: 0;
}
.saved-list-component .tab-content #quickOrderCollapse .row input[type=number] {
  max-width: 50px;
  padding: 0 1px;
}
.saved-list-component .tab-content #quickOrderCollapse .row button.wide {
  width: 100%;
  font-size: 1.5rem;
}
.saved-list-component .tab-content .list-wrapper {
  width: 100%;
}
.saved-list-component .tab-content .list-wrapper .row {
  margin: 0;
  padding: 1.5rem 0;
}
@media (max-width: 767px) {
  .saved-list-component .tab-content .list-wrapper .row:first-child {
    border-bottom: 1px solid #b2b2b2;
  }
  .saved-list-component .tab-content .list-wrapper .row:last-child {
    border-top: 1px solid #b2b2b2;
  }
}
@media (max-width: 767px) {
  .saved-list-component .tab-content .row:first-child {
    padding: 0;
  }
}
.saved-list-component .tab-content .title {
  font-size: 1.6rem;
  text-transform: none;
  margin: 0;
}
.saved-list-component .tab-content div[class*=col-md] {
  text-align: center;
}
.saved-list-component .tab-content div[class*=col-md].product-wrapper {
  text-align: left;
}
.saved-list-component .tab-content .col-md-5 {
  text-align: left !important;
}
.saved-list-component .tab-content .product-wrapper {
  display: flex;
  align-items: center;
}
.saved-list-component .tab-content .product-wrapper .img-wrapper {
  max-width: 2.4rem;
  margin-right: 3.2rem;
  min-width: 2.4rem;
}
.saved-list-component .tab-content .product-wrapper .img-wrapper img {
  width: 100%;
}
.saved-list-component .tab-content .product-wrapper .content-wrapper .title {
  text-transform: uppercase;
  line-height: 1;
  color: #000000;
}
.saved-list-component .tab-content .product-wrapper .content-wrapper .customer-tool {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: #1c3E77;
}
.saved-list-component .tab-content .product-wrapper .content-wrapper .subtitle {
  font-family: "Franklin Gothic URW", sans-serif;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.7rem;
  color: #000000;
}
.saved-list-component .tab-content .product-wrapper .content-wrapper .item-description {
  font-size: 1.3rem;
  line-height: 1.5rem;
  margin-bottom: 1.6rem;
}
.saved-list-component .tab-content .product-wrapper .content-wrapper .remove-link {
  line-height: 1;
}
@media (max-width: 767px) {
  .saved-list-component .tab-content .product-wrapper .content-wrapper .remove-link {
    display: none;
  }
}
.saved-list-component .tab-content .price-wrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}
.saved-list-component .tab-content .price-wrapper span {
  font-weight: 700;
  color: #000000;
}
@media (max-width: 767px) {
  .saved-list-component .tab-content .price-wrapper span {
    font-size: 1.3rem;
  }
}
@media (max-width: 767px) {
  .saved-list-component .tab-content .price-wrapper span.item-price, .saved-list-component .tab-content .price-wrapper span.total-price {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
}
.saved-list-component .tab-content .price-wrapper span.item-netprice {
  font-size: 1.3rem;
  margin-bottom: 1rem;
  color: #1c3E77;
}
@media (max-width: 767px) {
  .saved-list-component .tab-content .price-wrapper span.item-netprice {
    font-size: 1.3rem;
    margin-bottom: 1rem;
    color: #1c3E77;
  }
}
.saved-list-component .tab-content .price-wrapper span.item-discountpercent {
  font-size: 1rem;
  margin-bottom: 1rem;
  color: #1c3E77;
}
@media (max-width: 767px) {
  .saved-list-component .tab-content .price-wrapper span.item-discountpercent {
    font-size: 1rem;
    margin-bottom: 1rem;
    color: #1c3E77;
  }
}
.saved-list-component .tab-content .quantity-wrapper {
  flex-direction: column;
  position: relative;
  padding-top: 10px;
}
.saved-list-component .tab-content .quantity-wrapper .quantity-field-component {
  margin-bottom: 1.3rem;
}
.saved-list-component .tab-content .quantity-wrapper span {
  display: block;
  font-size: 1.3rem;
}
@media (max-width: 767px) {
  .saved-list-component .tab-content .quantity-wrapper span {
    position: relative;
    bottom: auto;
  }
}
.saved-list-component .tab-content .quantity-wrapper span.info {
  font-weight: 700;
  color: #1c3E77;
}
.saved-list-component .tab-content .quantity-wrapper span.error-text {
  font-weight: 700;
  white-space: nowrap;
}
.saved-list-component .tab-content .col-md-1,
.saved-list-component .tab-content .col-md-2 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.saved-list-component .tab-content .col-md-1 .form-check,
.saved-list-component .tab-content .col-md-2 .form-check {
  padding: 0;
}
.saved-list-component .tab-content .resources-wrapper {
  display: flex;
  justify-content: space-around;
}
@media (max-width: 767px) {
  .saved-list-component .tab-content .resources-wrapper {
    display: none;
  }
}
.saved-list-component .tab-content .resources-wrapper .icon-wrapper {
  overflow: hidden;
}
.saved-list-component .tab-content .resources-wrapper .icon-wrapper .icon {
  color: #1c3E77;
  font-size: 3.5rem;
}
@media (max-width: 767px) {
  .saved-list-component .tab-content .checkbox-wrapper {
    display: none;
  }
}
@media (min-width: 767px) {
  .saved-list-component .tab-content .price-title,
  .saved-list-component .tab-content .remove-link-mobile {
    display: none;
  }
}
.saved-list-component .tab-content .net-price-title,
.saved-list-component .tab-content .remove-link-mobile {
  font-family: "Franklin Gothic URW", sans-serif;
}
@media (min-width: 767px) {
  .saved-list-component .tab-content .net-price-title,
  .saved-list-component .tab-content .remove-link-mobile {
    display: none;
  }
}
.saved-list-component .tab-content .description-row {
  border-top: 1px solid #b2b2b2;
  border-bottom: 1px solid #b2b2b2;
}
.saved-list-component .tab-content .description-row .title {
  font-family: "Franklin Gothic URW", sans-serif;
  color: #000000;
}
@media (max-width: 767px) {
  .saved-list-component .tab-content .description-row {
    display: none;
  }
}
.saved-list-component .tab-content .button-wrapper {
  display: flex;
  padding: 1.5rem;
  width: 100%;
  justify-content: flex-end;
}
@media (max-width: 767px) {
  .saved-list-component .tab-content .button-wrapper {
    display: none;
  }
}
.saved-list-component .tab-content .button-wrapper button {
  padding: 1.7rem 0;
  width: 16.4rem;
  margin-left: 2rem;
}
.saved-list-component .tab-content .button-wrapper button:first-child {
  margin: 0;
}

hr {
  margin-top: 1.5rem !important;
  margin-bottom: 1rem !important;
}

.list-option-component {
  flex: 0 0 23%;
  max-width: 23%;
  margin-left: 1.2rem;
}
.list-option-component .list-wrapper {
  background: #ffffff;
  padding: 0.5rem 0;
}
.list-option-component .list-wrapper .subtitle {
  text-transform: uppercase;
  font-family: "Franklin Gothic URW", sans-serif;
  line-height: 1.2;
  font-weight: 700;
  margin: 0 3.2rem 1rem;
}
.list-option-component .list-wrapper .underline-hr {
  max-width: 70%;
  margin-left: 3rem;
  margin-top: 1.5rem !important;
  margin-bottom: 1rem !important;
}
.list-option-component .list-wrapper .title {
  font-size: 1.8rem;
  font-weight: 400;
}
.list-option-component .list-wrapper .card-body {
  background: #e5e5e5;
  padding: 1rem;
}
.list-option-component .list-wrapper .card-body .description {
  font-size: 1.5rem;
  margin-bottom: 1.9rem;
}
.list-option-component .list-wrapper .card-body .update-link {
  font-family: "Franklin Gothic URW", sans-serif;
  color: #1c3E77;
  font-weight: 700;
}
.list-option-component .list-wrapper .card-body .box {
  background-color: #ffffff;
  z-index: 3;
  position: absolute;
  border: solid black 1px;
  text-align: left;
  right: 20%;
}
.list-option-component .list-wrapper .card-body form .form-group {
  width: 100%;
  margin-bottom: 3.6rem;
}
.list-option-component .list-wrapper .card-body form label {
  font-size: 1.5rem;
  color: #000000;
}
.list-option-component .list-wrapper .card-body form input {
  background: #ffffff;
  width: 100%;
  border: 1px solid #999999;
  font-size: 1.4rem;
}
.list-option-component .list-wrapper .card-body form .primary-button {
  padding: 1rem;
}
.list-option-component .collapse-icon-wrapper {
  position: relative;
  padding: 2rem 3.2rem;
}
.list-option-component .collapse-icon-wrapper .collapse-icon:before, .list-option-component .collapse-icon-wrapper .collapse-icon:after {
  color: #1c3E77;
  font-size: 2rem;
  top: 2rem;
  right: 2rem;
}

.modal-inside-container {
  position: relative;
  margin-right: 0;
  margin-left: 0;
}
.modal-inside-container .modal {
  position: relative;
}
.modal-inside-container .modal-dialog .modal-header {
  border: 0;
}
.modal-inside-container .modal-dialog .modal-body {
  padding: 2.2rem 2rem 5.4rem 4.8rem;
}
.modal-inside-container .modal-dialog .modal-body .new-list-modal .title {
  font-size: 2.2rem;
  margin-bottom: 1rem;
  color: #999999;
}
.modal-inside-container .modal-dialog .modal-body .new-list-modal .description {
  font-size: 1.5rem;
  color: #999999;
  margin-bottom: 2rem;
}
.modal-inside-container .modal-dialog .modal-body .new-list-modal .form-group {
  width: 70%;
  margin-bottom: 2rem;
  display: block;
}
.modal-inside-container .modal-dialog .modal-body .new-list-modal .form-group .form-control {
  border: 1px solid #000000;
  background: #ffffff;
  font-size: 1.5rem;
}
.modal-inside-container .modal-dialog .modal-body .new-list-modal .form-group .form-control::placeholder {
  font-family: "Franklin Gothic URW", sans-serif;
  color: #000000;
  font-size: 1.5rem;
}
.modal-inside-container .modal-dialog .modal-body .new-list-modal .primary-button {
  display: block;
  float: left;
}

.modal-backdrop {
  background: transparent;
}

.primary-button .fa {
  margin-left: 10px !important;
}

.desktop {
  display: inherit;
}

.mobile {
  display: inherit;
}

@media screen and (max-width: 575px) {
  .desktop {
    display: none !important;
  }
}
@media screen and (min-width: 576px) {
  .mobile {
    display: none !important;
  }
}