//SHARED by the login-popup-component.vue (available on just about all pages)
.login-popup-component {
    position: relative;
    max-width: 45rem;
    margin: 20rem auto;
    padding: 5rem 2rem 1.8rem;
    background: $white;
    box-shadow: 0px 3px 6px rgba($black, .26);
    z-index: 1;

    .card {
        border: none;
        box-shadow: none;
        position: static;

        .card-header {
            border: none;
            background: $white;
        }

        button {
            &:hover,
            &:active,
            &:focus {
                text-transform: none;
            }
        }
    }

    .title {
        font-family: $secondaryFont;
        font-size: 2.2rem;
        color: $grey60;
        text-transform: none;
    }

    .subtitle {
        color: $accent;
        font-family: $primaryFont;
        font-size: 1.5rem;
        margin-bottom: .7rem;
    }

    .content-wrapper {
        .primary-button {
            margin: 0 auto;
            display: block;
        }
    }

    .collapse-icon-wrapper {
        position: relative;

        a {
            display: block;
        }

        .collapse-icon {
            &:before,
            &:after {
                color: $accent;
                font-size: 2rem;
                top: 0;
                right: 0;
            }
        }
    }

    .close-button {
        position: absolute;
        right: 0;
        top: 0;

        .close-icon {
            font-size: 3.5rem;
            color: $accent;

            &::before {
                content: "\f00d";
                font-family: "Font Awesome 5 Free";
                font-weight: 700;
            }
        }
    }

    .password-tooltip {
        top: 3rem;
        right: initial;
        transform: translateY(20%);

        &:after,
        &:before {
            bottom: 100%;
            left: 10%;
            right: initial;
            top: initial;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }

        &:after {
            border-color: rgba(255, 255, 255, 0);
            border-bottom-color: #fff;
            border-width: 15px;
            margin-left: -15px;
        }

        &:before {
            border-color: rgba(194, 225, 245, 0);
            border-bottom-color: rgba($grey60, .8);
            border-width: 16px;
            margin-left: -16px;
        }
    }
}
