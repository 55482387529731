﻿//SHARED on all pages (from the layout)
#consent-popup {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem 4rem;
    background-color: #fff;
    display: flex;
    transition: opacity 1s ease;
    border-top: 1px solid $grey60;
    //border-radius: 10px;
    z-index: 99 !important;
    box-shadow: 10px -1px 5px 0 $grey60;

    &.hidden {
        opacity: 0;
        display: none;
    }

    .consent-container {
        display: flex;
        align-items: baseline;
        justify-content: space-between;

        .consent-text {
            p {
                font-size: 1.3rem !important;
            }
        }

        button {
            background-color: $accent;
            color: $white;
            padding: 1.2rem 2.2rem;
        }

        @media only screen and (max-width: 900px) {
            display: block;
            text-align: center;
        }
    }
}
