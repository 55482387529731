//SHARED by team invite block and (not sure why) international homepage popup.
.invitation-grid {
    flex-wrap: wrap;
    flex-direction: row;
    display: grid;
    grid-template-columns: 47% 47%;
    gap: 10px;
    padding-top:20px;
}

.invitation-link-desc {
    display: inline-block;
    padding-left: 3px;
    margin-bottom: 0px;
}

.invitation-delete-button {
    float: right;
    //block-size: 30%;
    //padding-top: 10px;
    //border-radius: 0px;
    //text-align: left;
    //text-anchor: start;
}

.invitation-card {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
}

.invitation-company {
    color: $accent;
    padding-bottom: 5px;
}