.tabs-component {
    .nav-tabs {
        list-style-type: none;
        padding-left: 25px;
        //overflow: hidden;
        display: flex;
        flex-wrap: nowrap;
        position: relative;
    }

    .nav-tabs li {
        display: inline-block;
        padding: 10px;
        margin-right: 1rem;
        flex-basis: 50px;
        z-index: 5;
        text-align: center;
    }

    .nav-tabs li.stacked {
        position: absolute;
        right: 20px;
        bottom: 0;
        top: 0;
    }

    nav {
        border-bottom: 1px solid $grey70;
        background: transparent;

        @media (max-width: 1600px) {
            padding-left: 3rem;
        }

        @media (max-width: 767px) {
            padding-left: 2rem;
        }

        .nav-tabs {
            background: transparent;
            border-bottom: none;
            width: 90rem;
            margin: 0 0 -1px -2.2rem;
            padding-left: 2.2rem;

            @media (max-width: 1200px) {
                width: 70rem;
            }

            @media (max-width: 991px) {
                width: 36rem;
            }

            @media (max-width: 767px) {
                width: 28rem;
            }

            li {
                padding: 0;
                background: $background;
                text-align: center;
                display: flex;
                justify-content: center;

                .nav-item {
                    cursor: pointer;
                    text-align: center;
                    font-family: $primaryFont;
                    margin: 0 0 -1px;
                    font-weight: 700;
                    color: $grey60;
                    border: 1px solid $grey70;
                    padding: 2.5rem 1rem;
                    word-wrap: break-word;

                    &.active {
                        background: $white;
                        border-bottom-color: $white;
                    }
                }
            }
        }

        .fixed {
            border: 1px solid $grey70;
            margin-bottom: -1px;
            background: $background;
            font-family: $primaryFont;
            color: $grey60;
            font-size: 1.6rem;
            font-weight: 700;
            display: inline-block;

            @media (max-width: 991px) {
                font-size: 1.1rem;
            }
        }
    }

    .nav-tabs-component {
        position: relative;

        nav {
            padding: 0 5rem;
        }

        .next-btn,
        .prev-btn {
            @media (max-width: 991px) {
                padding: .5rem
            }
        }

        .prev-btn {
            position: absolute;
            background: transparent;
            left: 0;
            top: 0;
            bottom: 0;
            transform: translateX(-100%);
            z-index: 6;

            @media (max-width: 991px) {
                left: -1.8rem;
            }

            .fa-icon {
                &::before {
                    content: '\f053';
                    font-family: $fontAwesome;
                    color: $grey60;
                    font-size: 2rem;
                }
            }
        }

        .next-btn {
            background: transparent;
            position: absolute;
            right: -3rem;
            top: 0;
            bottom: 0;
            z-index: 6;

            @media (max-width: 991px) {
                right: .5rem;
            }

            .fa-icon {
                &::before {
                    content: '\f054';
                    font-family: $fontAwesome;
                    color: $grey60;
                    font-size: 2rem;
                }
            }
        }
    }

    .nav-item {
        margin: 0;
        padding: 2.5rem;
        width: 20rem;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        text-align: center;

        @media (max-width: 1200px) {
            width: 15rem;
        }

        @media (max-width: 991px) {
            padding: 1rem 0;
            width: 6.6rem;
            line-height: 1.5rem;
            font-size: 1.1rem;
        }
    }

    .nav-tabs li.stacked-left {
        position: absolute;
        left: 0;
        bottom: 0;
        top: 0;
    }

    .nav-tabs li.stacked.shift-1 {
        margin-right: 15px;
        z-index: 4;
    }

    .nav-tabs li.stacked.shift-2 {
        margin-right: 10px;
        z-index: 3;
    }

    .nav-tabs li.stacked.shift-3 {
        margin-right: 5px;
        z-index: 2
    }

    .nav-tabs li.stacked-left.shift-1 {
        margin-left: 0;
        z-index: 2;
    }

    .nav-tabs li.stacked-left.shift-2 {
        margin-left: 5px;
        z-index: 3;
    }

    .nav-tabs li.stacked-left.shift-3 {
        margin-left: 10px;
        z-index: 4;
    }
}

//dropdown wrapper should be its own entity

#wish-list-component {
    h3 {
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;
    }
}

.disabled-button {
    background-color: grey !important;
    border-color: grey !important;
    /*display: inline-block;
    border: none;
    background-color: $white;
    outline: none;
    font-size: 1.5rem;
    font-weight: 600;
    border-radius: .4rem;
    text-transform: capitalize;
    line-height: 1;*/
    &:hover {
        background-color: grey !important;
        border-color: grey !important;
    }
}

.checkout-button {
    margin-top: 2rem !important;
    font-size: 2rem !important;
    padding: 2.3rem 5rem !important;
}

.subtitle-cart-option {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 2.2rem;
    text-align: center;
    padding-top: 3rem;
}

.totalsRow {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-items: flex-end;
}

.allTotalText {
    color: $black;
    font-size: 1.7rem;
    font-weight: 700;
    margin: 1.5rem 0 1rem 0;
}

.allNetTotalText {
    font-size: 1.7rem;
    font-weight: 700;
    margin: 0 0 1rem 0;
    color: $accent;
}

.dropdown-wrapper,
.add-list-modal {
    form {
        .form-group {
            
            .selectric {
                border-radius: .5rem;
                margin-bottom: 1rem;
                background: $white;
            }

            label {
                font-size: 1.4rem;
                margin-top: 1.4rem;
            }
        }

        .selectric-items {
            li {
                line-height: 1rem;
                padding-left: 4rem;

                &.icon {
                    font-size: 1.2rem;
                }
            }
        }
    }
}

.dropdown-wrapper {
    position: relative;

    .new-list-dropdown {
        box-shadow: 0px 3px 6px rgba($black, .26);
        background-color: $white;
        position: absolute;
        z-index: 1050;
        padding: 0px !important;

        .primary-button {
            display: flex;
            height: auto !important;
            width: 100%;
            padding: 15px 20px !important;
            font-size: 1.8rem !important;
            margin: 3rem auto 0 !important;
            justify-content: center;
        }
    }

    .title {
        //text-transform: none;
        font-size: 1.6rem;
        color: $white;
        //margin-bottom: 2rem;
    }

    .subtitle {
        font-size: 1.6rem;
    }

    .quantity-wrapper {
        text-align: center;
        margin-top: 1rem;
        margin-bottom: 1rem;

        .availability {
            font-size: 1.2rem;
            display: inline-block;
            font-weight: 700;
            color: $accent;
        }

        label {
            //margin-right: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
        }

        input {
            border: 1px solid #DDD;
            max-width: 5rem;
            border-radius: .5rem;
            height: 4.7rem;
            font-size: 1.5rem;
        }
    }
}

.add-list-modal {
    .modal-dialog {
        top: 40%;
        transform: translateY(-50%) !important;

        .modal-header {
            border: none;

            .close {
                span {
                    font-size: 2.5rem;
                }
            }
        }

        .modal-body {
            padding: 0 3rem 6rem;

            .title {
                text-transform: none;
                font-size: 1.8rem;
            }

            .subtitle {
                font-size: 1.6rem;
                margin-bottom: 2rem;
            }

            form {
                /*max-width: 36rem;*/
                margin: 0 auto;

                .quantity-wrapper {
                    margin-bottom: 2rem;

                    label {
                        margin-right: 2rem;
                    }
                }
            }

            .selectric {
                border: 1px solid #DDD !important;

                .button {
                    top: .1rem;
                }
            }
        }
    }
}

.share-list-modal, .send-list-modal {
    .modal-dialog {
        max-width: 106.1rem !important;
    }

    .modal-content {
        .modal-header {
            padding: 2rem 8rem;
            background: $background;

            .title {
                font-size: 2.4rem;
                margin: 0;
            }

            .close {
                color: $accent;

                span {
                    font-size: 2.4rem;
                }
            }
        }

        .modal-body {
            padding: 3.5rem 8rem;
            background: $white;
            text-align: left;

            .title {
                font-size: 2rem;
                text-transform: none;
                margin-bottom: 2rem;
            }

            form {
                margin-bottom: 3rem;

                .info-wrapper {
                    width: 36rem;
                }

                .form-group {
                    width: 80%;

                    input,
                    textarea {
                        font-size: 1.4rem;
                        padding-left: 2.2rem;
                        background: $white;
                        font-family: $primaryFont;
                        border: 1px solid $grey70;
                    }

                    label {
                        font-family: $primaryFont;
                        font-size: 1.4rem;
                    }

                    label.send-list-message {
                        padding-top: 20px !important;
                        color: black;
                    }
                }

                .form-check {
                    margin-bottom: 2rem;

                    input {
                        vertical-align: middle;
                        width: 2.4rem;
                        height: 2.4rem;
                        margin-top: -.3rem;
                    }

                    label {
                        font-family: $primaryFont;
                        font-size: 1.4rem;

                        &::before {
                            margin-right: 1rem;
                            vertical-align: middle;
                        }
                    }
                }

                .primary-button {
                    width: 16.4rem;
                    padding: 1.5rem 0;
                }
            }

            .items-wrapper {
                .row {
                    border-bottom: 1px solid $grey60;
                    margin: 0;
                    padding: 2rem 0;

                    &:last-child {
                        border: none;
                    }

                    .title {
                        font-family: $primaryFont;
                        margin: 0;
                        font-size: 1.6rem;
                        color: $black;
                    }

                    .col-md-2.col-sm-4 {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        span {
                            font-family: $primaryFont;
                            color: $black;
                            font-weight: 700;
                        }
                    }
                }

                .item-row {
                    .product-wrapper {
                        display: flex;

                        .img-wrapper {
                            max-width: 3rem;

                            img {
                                width: 100%;
                            }
                        }

                        .content-wrapper {
                            margin-left: 2rem;

                            .title {
                                font-size: 1.8rem;
                                color: $black;
                                margin: 0;
                            }

                            .subtitle {
                                font-size: 1.4rem;
                                color: $black;
                                font-weight: 700;
                                line-height: 1.7rem;
                                font-family: $primaryFont;
                            }

                            .item-description {
                                line-height: 1.5;
                            }
                        }
                    }
                }
            }

            .description-wrapper {
                margin: 10rem auto;
                text-align: center;
            }
        }
    }
}

.empty-list-component {
    max-width: 40rem;
    text-align: center;
    margin: 10rem auto;

    .title {
        margin-bottom: 1rem !important;
        text-transform: capitalize !important;
        font-size: 1.8rem !important;
    }

    .info {
        line-height: 2;
    }

    .info-link {
        font-family: $primaryFont;
        color: $accent;
    }
}

.history-list-component {
    padding: 5.4rem 0 20rem;
    background: $grey90;

    @media (max-width: 767px) {
        &:last-child {
            border: none;
        }
    }

    .title {
        font-family: $secondaryFont;
        text-transform: none;
        font-size: 2.2rem;
        margin-bottom: 3.9rem;
    }

    .list-item {
        margin-bottom: 2rem;
        font-size: 1.4rem;
        color: $black;
    }
}

.saved-list-component {
    background: $white;
    padding-top: 1.8rem;


    .icon {
        color: $black;
        font-family: $primaryFont;
        font-size: 2.2rem;
        font-weight: 700;
        position: relative;
        vertical-align: middle;

        &.icon-my-list {
            font-weight: 400;
        }
    }

    .tab-pane {
        display: none;

        &.active {
            display: block;
        }
    }

    .new-list-modal {
        .form-group {
            .form-control {
                font-size: 1.4rem;
            }
        }
    }

    .saved-tab-wrapper {
        margin: 0;
    }

    .tab-content {
        padding: 1rem 1.5rem;
        margin-bottom: 3rem;

        .row {
            margin-right: 0;
            margin-top: 2rem;
        }

        .col-md-3, .col-md-9 {
            padding: 0;
        }

        @media (max-width: 767px) {
            padding: 0;

            &:last-child {
                padding-bottom: 0;
            }
        }

        a {
            &.wide {
                width: 100%;
            }
        }

        #quickOrderCollapse {
            border: 1px solid black;
            background-color: $grey90;
            padding: 15px;

            .indent {
                margin-left: 20px;
            }

            .row {
                margin: 0;
                width: 100%;
                padding: 0;

                p {
                    margin: 0;
                }

                input[type="text"] {
                    max-width: 90%;
                    margin: 0;
                    padding: 0;
                }

                input[type="number"] {
                    max-width: 50px;
                    padding: 0 1px;
                }

                button {
                    &.wide {
                        width: 100%;
                        font-size: 1.5rem;
                    }
                }
            }
        }

        .list-wrapper {
            width: 100%;

            .row {
                margin: 0;
                padding: 1.5rem 0;

                @media (max-width: 767px) {
                    &:first-child {
                        border-bottom: 1px solid $grey70;
                    }

                    &:last-child {
                        border-top: 1px solid $grey70;
                    }
                }
            }
        }

        .row {

            @media (max-width: 767px) {
                &:first-child {
                    padding: 0;
                }
            }
        }

        .title {
            font-size: 1.6rem;
            text-transform: none;
            margin: 0;
        }

        div[class*="col-md"] {
            text-align: center;

            &.product-wrapper {
                text-align: left;
            }
        }

        .col-md-5 {
            text-align: left !important;
        }

        .product-wrapper {
            display: flex;
            align-items: center;

            .img-wrapper {
                max-width: 2.4rem;
                margin-right: 3.2rem;
                min-width: 2.4rem;

                img {
                    width: 100%;
                }
            }

            .content-wrapper {
                .title {
                    text-transform: uppercase;
                    line-height: 1;
                    color: $black;
                }

                .customer-tool {
                    font-size: 1.2rem;
                    margin-bottom: 1rem;
                    color: $accent;
                }

                .subtitle {
                    font-family: $primaryFont;
                    font-size: 1.4rem;
                    font-weight: 700;
                    line-height: 1.7rem;
                    color: $black;
                }

                .item-description {
                    font-size: 1.3rem;
                    line-height: 1.5rem;
                    margin-bottom: 1.6rem;
                }

                .remove-link {
                    line-height: 1;

                    @media (max-width: 767px) {
                        display: none;
                    }
                }
            }
        }

        .price-wrapper {
            text-align: center;
            display: flex;
            flex-direction: column;
            padding-top:20px;

            span {
                font-weight: 700;
                color: $black;

                @media (max-width: 767px) {
                    font-size: 1.3rem;
                }

                &.item-price,
                &.total-price {
                    @media (max-width: 767px) {
                        font-size: 1.5rem;
                        margin-bottom: 1rem;
                    }
                }

                &.item-netprice {
                    font-size: 1.3rem;
                    margin-bottom: 1rem;
                    color: $accent;

                    @media (max-width: 767px) {
                        font-size: 1.3rem;
                        margin-bottom: 1rem;
                        color: $accent;
                    }
                }

                &.item-discountpercent {
                    font-size: 1.0rem;
                    margin-bottom: 1rem;
                    color: $accent;

                    @media (max-width: 767px) {
                        font-size: 1.0rem;
                        margin-bottom: 1rem;
                        color: $accent;
                    }
                }
            }
        }

        .quantity-wrapper {
            flex-direction: column;
            position: relative;
            padding-top:10px;
            .quantity-field-component {
            margin-bottom:1.3rem;
            }
            span {
                //position: absolute;
                display: block;
//                bottom: -1rem;
                font-size: 1.3rem;

                @media (max-width: 767px) {
                    position: relative;
                    bottom: auto;
                }

                &.info {
                    font-weight: 700;
                    color: $accent;
                }

                &.error-text {
                    font-weight: 700;
                    white-space: nowrap;
                }
            }
        }

        .col-md-1,
        .col-md-2 {
            display: flex;
            justify-content: center;
            align-items: center;

            .form-check {
                padding: 0;
            }
        }

        .resources-wrapper {
            display: flex;
            justify-content: space-around;

            @media (max-width: 767px) {
                display: none;
            }

            .icon-wrapper {
                overflow: hidden;

                .icon {
                    color: $accent;
                    font-size: 3.5rem;
                }
            }
        }

        .checkbox-wrapper {
            @media (max-width: 767px) {
                display: none;
            }
        }

        .price-title,
        .remove-link-mobile {
            @media (min-width: 767px) {
                display: none;
            }
        }

        .net-price-title,
        .remove-link-mobile {
            font-family: $secondaryFont;

            @media (min-width: 767px) {
                display: none;
            }
        }

        .description-row {
            border-top: 1px solid $grey70;
            border-bottom: 1px solid $grey70;

            .title {
                font-family: $primaryFont;
                color: $black;
            }

            @media (max-width: 767px) {
                display: none;
            }
        }

        .button-wrapper {
            display: flex;
            padding: 1.5rem;
            width: 100%;
            justify-content: flex-end;

            @media (max-width: 767px) {
                display: none;
            }

            button {
                padding: 1.7rem 0;
                width: 16.4rem;
                margin-left: 2rem;

                &:first-child {
                    margin: 0;
                }
            }
        }
    }
}

hr {
    margin-top: 1.5rem !important;
    margin-bottom: 1rem !important;
}

.list-option-component {
    flex: 0 0 23%;
    max-width: 23%;
    margin-left: 1.2rem;

    .list-wrapper {
        background: $background;
        //height: 100%;
        padding: 0.5rem 0;

        .subtitle {
            text-transform: uppercase;
            font-family: $primaryFont;
            line-height: 1.2;
            font-weight: 700;
            margin: 0 3.2rem 1rem;
        }

        .underline-hr {
            max-width: 70%;
            margin-left: 3rem;
            margin-top: 1.5rem !important;
            margin-bottom: 1rem !important;
        }

        .title {
            font-size: 1.8rem;
            font-weight: 400;
        }

        .card-body {
            background: $grey90;
            padding: 1rem;

            .description {
                font-size: 1.5rem;
                margin-bottom: 1.9rem;
            }

            .update-link {
                font-family: $primaryFont;
                color: $accent;
                font-weight: 700;
            }

            .box {
                background-color: $white;
                z-index: 3;
                position: absolute;
                border: solid black 1px;
                text-align: left;
                right: 20%;
            }

            form {
                .form-group {
                    width: 100%;
                    margin-bottom: 3.6rem;
                }

                label {
                    font-size: 1.5rem;
                    color: $black;
                }

                input {
                    background: $white;
                    width: 100%;
                    border: 1px solid $grey60;
                    font-size: 1.4rem;
                }

                .primary-button {
                    padding: 1rem;
                }
            }
        }
    }

    .collapse-icon-wrapper {
        position: relative;
        padding: 2rem 3.2rem;

        .collapse-icon {
            &:before,
            &:after {
                color: $accent;
                font-size: 2rem;
                top: 2rem;
                right: 2rem;
            }
        }
    }
}
//new list
.modal-inside-container {
    position: relative;
    margin-right: 0;
    margin-left: 0;

    .modal {
        position: relative;
    }

    .modal-content {
    }

    .modal-dialog {

        .modal-header {
            border: 0;
        }

        .modal-body {
            padding: 2.2rem 2rem 5.4rem 4.8rem;

            .new-list-modal {
                .title {
                    font-size: 2.2rem;
                    margin-bottom: 1rem;
                    color: $grey60;
                }

                .description {
                    font-size: 1.5rem;
                    color: $grey60;
                    margin-bottom: 2rem;
                }

                .form-group {
                    width: 70%;
                    margin-bottom: 2rem;
                    display: block;

                    .form-control {
                        border: 1px solid $black;
                        background: $white;
                        font-size: 1.5rem;

                        &::placeholder {
                            font-family: $primaryFont;
                            color: $black;
                            font-size: 1.5rem;
                        }
                    }
                }

                .primary-button {
                    display: block;
                    float: left;
                }
            }
        }
    }
}
//is this used anywhere?
.modal-backdrop {
    background: transparent;
}
//is this used anywhere?
.primary-button {
    .fa {
        margin-left: 10px !important;
    }
}
