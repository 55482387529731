hr.separator-large {
    border-color: #707070;
    border-width: 1px;
}

.text-stripe-component {
    .content-wrapper {
        text-align: center;
        padding: 3rem;

        @media (max-width: 767px) {
            padding: 0.5rem 1rem;
        }

        p {
            font-size: 3rem;
            margin: 0;
            color: $white;

            @media (max-width: 767px) {
                font-size: 1.2rem;
            }
        }
    }
}
