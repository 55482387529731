.media-hero-component {
    border-bottom: 1px solid $grey70;
    background: $white;

    .content-wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        @media (max-width: 1300px) {
            padding-left: 4rem;
        }

        .content {
            max-width: 42rem;

            @media (max-width: 767px) {
                padding: 3.4rem 3.6rem 3.1rem;
            }

            .title {
                @media (max-width: 767px) {
                    font-size: 2rem;
                }
            }

            .subtitle {
                margin-bottom: 1.5rem;

                @media (max-width: 767px) {
                    font-size: 1.3rem;
                }
            }

            .large {
                @media (max-width: 767px) {
                    font-size: 1.4rem;
                }
            }
        }
    }

    .icon-wrapper {
        display: flex;

        .icon {
            margin-right: 2rem;
            text-align: center;
            max-width: 8.8rem;

            img {
                display: inline-block;
                width: 100%;
                margin-bottom: 1rem;
            }

            .icon-description {
                color: $black;
                font-size: 1.2rem;
                font-family: "Arimo", sans-serif;
            }
        }
    }

    .media-wrapper {
        padding: 0;

        img {
            width: 100%;
        }

        &.media-wide {
            display: flex;
            align-items: center;
            margin: 8rem 0 8rem;

            @media (max-width: 767px) {
                padding-left: 2rem;
                order: 1;
                margin: 10rem 0 4rem;
            }

            img {
                object-fit: cover;
                object-position: left;
                height: 38rem;

                @media (max-width: 767px) {
                    height: 20rem;
                }
            }
        }
    }

    @media (max-width: 767px) {
        .content-wrapper {
            order: 2;
        }

        .media-wrapper {
            order: 1;
            margin-bottom: 3rem;
        }
    }
}

.catalog-grid-component {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-around;
    padding: 8.3rem 0 0;

    @media (max-width: 767px) {
        padding: 2.4rem 0 0;
    }

    .catalog-item {
        padding: 0 2rem;
        margin-bottom: 9.5rem;
        text-align: center;
        width: 33.33%;
        max-width: 45rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: 767px) {
            width: 100%;
            margin-bottom: 3.4rem;
        }

        .img-wrapper {
            max-width: 30rem;

            @media (max-width: 767px) {
                max-width: 20rem;
            }
        }

        .subtitle {
            text-transform: uppercase;
            margin-bottom: 2rem;
            font-size: 2rem;
            font-weight: 500;

            @media (max-width: 767px) {
                font-size: 1.5rem;
            }
        }

        .catalog-image {
            margin-bottom: 1.4rem;
        }

        p {
            @media (max-width: 767px) {
                font-size: 1.2rem;
                max-width: 45rem;
                margin: 0 auto;
                line-height: 1.7;
            }
        }
    }
}

.product-grid-component {
    padding: 10rem 0;
    background: $white;

    .item-wrapper {
        text-align: center;

        @media (max-width: 767px) {
            max-width: 45rem;
            margin: 0 auto 3rem;
        }

        .img-wrapper {
            /*margin-right: 4.2rem;*/
            max-width: 6.4rem;
            margin: 0 auto;
            float: left !important;
            margin-right: 15px;
            /*flex-basis: 100%;*/
            @media (max-width: 1020px) {
                margin-right: 2.4rem;
                min-width: 4rem;
            }

            @media (max-width: 767px) {
                max-width: 5.2rem;
                margin-right: 25px;
            }

            img {
                width: 100%;
                width: auto;
            }
        }

        .content-wrapper {
            text-align: left;

            .subtitle {
                margin-bottom: 1.5rem;
                padding-top: 6%;

                @media (max-width: 767px) {
                    font-size: 1.6rem;
                }
            }

            .item-description {
                font-size: 1.5rem;
                color: $black;
                padding-left: 75px;

                @media (max-width: 1020px) {
                    font-size: 1.4rem;
                    padding-left: 104px;
                }

                @media (max-width: 767px) {
                    font-size: 1.2rem;
                    padding-left: 76px !important;
                }
            }
        }

        @media (max-width: 767px) {
            align-items: flex-start;
            margin-bottom: 3rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
