.search-page {
    .content-wrapper{
        padding-top:0;
    }
}

.search-results-wrapper {
    margin-top: 4.5rem;

    h5 {
        color: $black;

        .subtitle {
            font-family: $tertiaryFont;
            font-size: 2.5rem;
            position: relative;
            outline:none;
            &:focus {
                outline:none;
            }
        }
    }

    .card {
        box-shadow: none;
        border: none;
        max-width: 100%;

        .card-header {
            background: none;
            border-bottom: none;
            padding-left: 0;
            padding-right: 0;

            .search-title {
                font-size: 2rem;
                color: $grey60;
                font-family: $tertiaryFont;
                position: relative;
            }

            .collapse-icon {
                &:after {
                    right: -4rem;
                    top: 0.6rem;
                    cursor: pointer;
                    font-size: 1.6rem;
                }

                &.no-arrow-collapse {
                    &:after {
                        display: none;
                    }
                }
            }
        }

        .card-body {
            padding: 0;

            .input-group-text {
                border: none;
                background: none;
                white-space: normal;
                text-align: left;
                width: 100%;

                &.full-width {
                    width: 100%;
                }

                span {
                    font-size: 1.5rem;
                    font-family: $primaryFont;
                    margin-left: 5px;
                    color: $black;

                    .product-quantity {
                        color: $grey60;
                        margin-left: 0;
                    }

                    &.checkbox {
                        max-width: 70%;
                    }
                }


                input[type=checkbox] {
                    display: none;

                    + label {
                        display: block;
                        cursor: pointer;
                        margin-bottom: 0;

                        &:before {
                            content: "\f00c";
                            font-family: "Font Awesome 5 Free";
                            border: 1px solid $grey80;
                            border-radius: 0.2em;
                            display: inline-block;
                            width: 1rem;
                            height: 1rem;
                            vertical-align: text-top;
                            color: transparent;
                            transition: .2s;
                            background: $grey80;
                            margin-top: -1px;
                            font-size: 0.9rem;
                            line-height: 0.8rem;
                            font-weight:700;
                        }

                        &:active {
                            &:before {
                                transform: scale(0);
                            }
                        }
                    }
                }

                input[type=checkbox]:checked {
                    + label {
                        &:before {
                            background-color: $accent;
                            border-color: $accent;
                            color: #fff;
                        }
                    }
                }

                input[type=checkbox]:disabled {
                    + label {
                        &:before {
                            transform: scale(1);
                            border-color: #aaa;
                        }
                    }
                }

                input[type=checkbox]:checked:disabled {
                    + label {
                        &:before {
                            transform: scale(1);
                            background-color: #bfb;
                            border-color: #bfb;
                        }
                    }
                }
            }
        }
    }

    .content-wrapper {
        padding: 0;

        .form-group {
            max-width: 253px;

            .form-control {
                background: rgba(161,161,161,0.27);
                border-radius: 0;
                box-shadow: none;
                border: 1px solid transparent;
                color: $grey60;
                font-size: 1.6rem;
                font-family: $tertiaryFont;
            }

            ::-webkit-input-placeholder { /* Edge */
                color: $grey60;
                font-size: 1.6rem;
                font-family: $tertiaryFont;
            }

            :-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: $grey60;
                font-size: 1.6rem;
                font-family: $tertiaryFont;
            }

            ::placeholder {
                color: $grey60;
                font-size: 1.6rem;
                font-family: $tertiaryFont;
            }
        }
    }
}

    @media (max-width: 767px) {
        .search-results-wrapper {
            box-shadow: 2px 0 8px #888888;
            margin-bottom: 20px;

            .card {
                .card-body {
                    .input-group-text {
                        span {
                            font-size: 1.3rem;
                        }
                    }
                }

                .card-header {
                    .search-title {
                        font-size: 1.8rem;
                    }

                    .collapse-icon {
                        &:after {
                            top: 0.6rem;
                        }
                    }

                    .subtitle {
                        position: relative;

                        &:after {
                            top: 0.4rem;
                            right: 3.8rem;
                            color: $grey60;
                        }
                    }
                }
            }

            .primary-button {
                &.filters {
                    padding-top: 1.4rem;
                    padding-bottom: 1.4rem;
                    margin-top: 25px;
                }
            }

            .holder-filters {
                padding-bottom: 2rem;
            }
        }
    }

.search-tabs-holder {
    border-left: 1px solid $border;


    .search-product,
    .search-resources {
        font-size: 2.5rem;
        font-family: $tertiaryFont;
        border-radius: 4px !important;
        padding: 0rem 5.3rem 2.2rem 1rem;
        margin-left: 10px;
        color: $accent;
    }
}

.search-page {
    .content-wrapper {
        padding-top: 3rem;
    }
}

.search-results-holder {
    padding-bottom: 0 !important;
    border-bottom: 2px solid $grey60 !important;

    .results-showed {
        color: $grey60;
        font-size: 1.8rem;
        font-family: $primaryFont;
    }
}

.search-tabs-holder {
    .search-pane {
        padding-top: 3rem;

        &:first-child {
            border-bottom: 1px solid $border;
            padding-bottom: 4.5rem;
        }

        .pagination {
            padding-left: 2.1rem;

            .pag-arrow {
                align-self: center;
            }

            .prev-arrow {
                border-top: 2px solid $accent;
                border-left: 2px solid $accent;
                transform: rotate(-45deg);
                width: 9px;
                height: 9px;
                line-height: 9px;
                display: block;
                transition: 0.2s;

                &:hover {
                    border-top: 2px solid rgba($accent, .8);
                    border-left: 2px solid rgba($accent, .8);
                }

                &.disabled {
                    border-top: 2px solid $grey80;
                    border-left: 2px solid $grey80;
                }
            }

            .next-arrow {
                border-top: 2px solid $accent;
                border-right: 2px solid $accent;
                transform: rotate(45deg);
                width: 9px;
                height: 9px;
                line-height: 9px;
                display: block;
                transition: 0.2s;

                &:hover {
                    border-top: 2px solid rgba($accent, .8);
                    border-right: 2px solid rgba($accent, .8);
                }

                &.disabled {
                    border-top: 2px solid $grey80;
                    border-right: 2px solid $grey80;
                }
            }

            .pag-holder {
                font-size: 2.2rem;
                font-family: $secondaryFont;
            }
        }
    }

    .resources-item {
        background: $grey90;
        margin-bottom: 1.5rem;
        padding: 0.5rem;
        text-align: center;

        .img-fluid {
            max-width: 14rem;
        }
    }

    .products-item {
        background: $grey90;
        margin-bottom: 1.5rem;
        padding: 34px 0;
        text-align: left;

        .img-fluid {
            width: 100%;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .search-listing-title {
        font-size: 2.2rem;
        font-family: $tertiaryFont;
        color: $grey60;
        text-align: left;
    }

    .search-listing-text {
        font-size: 1.8rem;
        font-family: $primaryFont;
        color: $black;
        margin-bottom: 0;
        text-align: left;
    }

    @media (max-width: 480px) {
        .search-results-wrapper {
            margin-bottom: 2rem;
        }

        .products-item {
            display: block !important;
        }

        .resources-item {
            display: block !important;
            padding-bottom: 2rem;
            padding-top: 2rem;
        }

        .search-listing-title {
            font-size: 1.8rem;
            margin-top: 1.5rem;
        }

        .search-listing-text {
            font-size: 1.4rem;
            line-height: 2rem;
        }
    }
}
