//SHARED by product table and variant details pages
.product-tabs-component {
    border-bottom: 1px solid $grey60;

    &.tech-page {
        border-bottom: 1px solid transparent;

        .nav-pills {
            .nav-item {
                .nav-link {
                    font-size: 2.2rem;
                    font-family: $secondaryFont;
                    background: none;
                }
            }
        }

        .customer-review-holder {
            .primary-button{
                margin-top:0;
            }
        }
    }

    .nav-pills {
        padding: 2rem 0 1.4rem;
        justify-content: center;
        border-bottom: 1px solid $grey60;

        &.title-wrapper-small {
            border-bottom: none;
        }

        .nav-item {
            margin-right: 10rem;

            @media (max-width: 991px) {
                margin-right: 4.4rem;
            }

            &:last-child {
                margin-right: 0;
            }

            .icon {
                font-size: 3rem;
                vertical-align: middle;
                margin-right: 1rem;
                width: 3rem;
                height:unset;

                &::before {
                    font-size: 3rem;
                    color: $black;
                }
            }

            .nav-link {
                background: $white;
                font-size: 1.6rem;
                font-family: $primaryFont;
                text-transform: uppercase;
                font-weight: 700;
                color: $grey60;
                padding: 0;
                display: inline-block;

                @media (max-width: 991px) {
                    font-size: 1.3rem;
                }

                &.active {
                    position: relative;
                    color: $accent;
                    border-bottom: 3px solid $accent;

                    &:after {
                        display: block;
                        position: absolute;
                        content: "\f0d8";
                        font-size: 2rem;
                        font-family: $fontAwesome;
                        bottom: -2.7rem;
                        left: 50%;
                        color: $grey60;
                        transform: translateX(-50%);
                    }
                }
            }
        }
    }

    .content {
        display: flex;

        @media (max-width: 991px) {
            display: block;
            padding-top: 4.4rem;
        }

        .media-list {
            width: 44%;
            padding-bottom: 6rem;

            @media (max-width: 991px) {
                width: 100%;
                padding-bottom: 5rem;
            }

            .section-title-wrapper {
                padding: 4rem 2rem 1.2rem;

                @media (max-width: 991px) {
                    padding: 0;
                    margin-bottom: 2rem;
                }

                .section-title {
                    margin: 0;
                    font-family: $primaryFont;
                    font-size: 2rem;
                    line-height: 2.3rem;

                    @media (max-width: 991px) {
                        font-size: 1.6rem;
                    }
                }
            }

            .media-list-content {
                border-right: 1px solid $grey60;
                padding: 5.5rem 2rem 9rem 0;
                min-height: 57rem;

                @media (max-width: 991px) {
                    border: none;
                    padding: 0;
                    min-height: auto;
                }

                .media-item {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10rem;

                    @media (max-width: 991px) {
                        margin-bottom: 4rem;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .image-wrapper {
                        overflow: hidden;
                        min-width: 10rem;
                        max-width: 10rem;
                        margin-right: 3.6rem;

                        @media (max-width: 991px) {
                            margin-right: 2rem;
                        }

                        .icon {
                            &::before {
                                font-size: 10rem;
                                color: $accent;
                            }
                        }
                    }

                    .content-wrapper {
                        .subtitle-wrapper {
                            @media (max-width: 991px) {
                                display: flex;
                                align-items: center;
                            }

                            .subtitle {
                                font-size: 2.2rem;
                                color: $grey60;
                                line-height: 3.4rem;
                                margin-bottom: .7rem;

                                @media (max-width: 991px) {
                                    font-size: 1.8rem;
                                }
                            }
                        }

                        .description {
                            font-size: 1.6rem;
                            line-height: 2.1rem;
                            max-width: 44rem;
                            font-family: $primaryFont;
                            color: $black;

                            @media (max-width: 991px) {
                                font-size: 1.4rem;
                            }
                        }
                    }
                }
            }

            &:nth-child(2),
            &:nth-child(3) {
                padding-left: 2rem;
                width: 33%;

                @media (max-width: 991px) {
                    width: 100%;
                }
            }

            &:nth-child(1) {
                @media (max-width: 991px) {
                    padding-left: 2rem;
                }

                .media-list-content {
                    @media (max-width: 991px) {
                        padding-right: 2rem;
                    }
                }

                .image-wrapper {
                    &.primary-img {
                        @media (max-width: 991px) {
                            display: none;
                        }
                    }

                    &.mobile-img {
                        display: none;

                        @media (max-width: 991px) {
                            display: block;
                            margin-right: 1.5rem;
                        }
                    }
                }
            }

            &:nth-child(2) {
                .media-list-content {
                    padding-right: 2rem;
                }
            }

            &:nth-child(3) {
                .media-list-content {
                    border: none;

                    .media-item {
                        margin-bottom: 6.2rem;

                        @media (max-width: 991px) {
                            margin-bottom: 3rem;
                        }
                    }
                }
            }
        }
    }
}
