.media-list-component {
    padding: 3rem 0 0;
    background: $white;
    .section-title {
        margin-bottom: 6rem;
        font-size: 2rem;
    }
    .media-item {
        display: flex;
        margin-bottom: 10rem;
        align-items: flex-start;
        @media (max-width: 767px) {
            max-width: 42rem;
            margin: 0 auto 3rem;
        }
        .image-wrapper {
            width: 30%;
            padding-right: 6rem;
            @media (max-width: 767px) {
                width: 40%;
                padding-right: 2rem;
            }
            @media (max-width: 991px) {
                padding-right: 1rem;
            }
            img {
                width: 100%;
                @media (max-width: 767px) {
                    max-width: 20rem;
                }
            }
            .icon {
                font-size: 12rem;
                color: $accent;
                display: inline-block;
                overflow-x: hidden;
                @media (max-width: 991px) {
                    font-size: 10rem;
                }
            }
        }
        .content-wrapper {
            width: 70%;
            padding-right: 10rem;
            @media (max-width: 991px) {
                padding: 0 2rem;
            }
            @media (max-width: 767px) {
                padding: 0 1rem;
                width: 60%;
            }
            .subtitle {
                a {
                    color: $grey60;
                    @media (max-width: 767px) {
                        font-size: 1.8rem;
                    }
                }
            }
            .description-link,
            .subtitle-link {
                &:hover,
                &:active,
                &:focus {
                    text-decoration: none;
                }
                p {
                    @media (max-width: 767px) {
                        font-size: 1.2rem;
                    }
                }
            }
            .description-link {
                font-family: $primaryFont;
                color: #000;
                font-size: 1.5rem;
                line-height: 2.4rem;
            }
        }
    }
}