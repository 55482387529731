/// <reference path="variables.scss" />

/*************************************
Buttons 
*************************************/

button {
    border: none;
    background-color: $white;
    outline: none;
    font-size: 1.5rem;
    font-weight: 700;
    border-radius: .4rem;
    padding: 1.8rem 3.2rem;
    text-transform: capitalize;
    line-height: 1;

    &.small {
        padding: 1rem 2.2rem;
        font-weight: 700;
        text-align: center;
    }

    &.wide {
        padding: 1.5rem 0;
        width: 16.4rem;
        font-size: 1.6rem;
    }
}

a {
    &.primary-button,
    &.secondary-button,
    &:disabled {
        display: inline-block;
        border: none;
        background-color: $white;
        outline: none;
        font-size: 1.5rem;
        font-weight: 600;
        border-radius: .4rem;
        text-transform: capitalize;
        line-height: 1;
    }

    &:hover {
        text-decoration: none;
    }

    &.wide {
        padding: 1.5rem 0;
        width: 16.4rem;
        font-size: 1.6rem;
    }
}

button,
a {
    &.primary-button, &.secondary-button {
        font-family: $secondaryFont;
        font-weight: 700;
        text-align: center;
    }

    &.primary-button {
        border: 2px solid $accent;
        background-color: $primary-button-background-color;
        border-color: $primary-button-border-color;
        color: $primary-button-text-color;
        padding: 1.3rem 5rem;


        &.wide {
            padding: 1.5rem 0;
            width: 16.4rem;
            font-size: 1.6rem;
        }

        &:active {
            background-color: $primary-button-background-pressed-color;
            border-color: $primary-button-border-pressed-color;
            color: $primary-button-text-pressed-color;
        }

        &:hover {
            border-color: rgba($accent, .8);
            background-color: rgba($accent, .8);

            &:active {
                background-color: $primary-button-background-pressed-color;
                border-color: $primary-button-border-pressed-color;
                color: $primary-button-text-pressed-color;
            }
        }


        &:disabled {
            border: 2px solid $grey70;
            background-color: $grey70;
        }

        &.outline {
            background-color: transparent;
            border: 2px solid $primary-button-border-pressed-color;
            color: $primary-button-border-color;

            &:hover {
                background-color: rgba($accent, .5);
            }

            &:disabled {
                opacity: 0.4;
            }
        }

        &.white-outline {
            background-color: transparent;
            border: 2px solid $white;
            color: $white;

            &:hover {
                background-color: rgba($white, .5);
            }

            &:disabled {
                opacity: 0.4;
            }
        }
    }

    &.secondary-button {
        border: 2px solid $grey60;
        background-color: $secondary-button-background-color;
        border-color: $secondary-button-border-color;
        color: $secondary-button-text-color;

        &:active {
            background-color: $secondary-button-background-pressed-color;
            border-color: $secondary-button-border-pressed-color;
            color: $secondary-button-text-pressed-color;
        }

        &:hover {
            background-color: $secondary-button-background-hover-color;
            color: $secondary-button-text-hover-color;
        }

        &:disabled {
            opacity: 0.4;

            &:hover {
                background-color: transparent;
                color: $grey60;
            }
        }

        &.outline {
            background-color: transparent;
            border: 2px solid $secondary-button-border-pressed-color;
            color: $black;

            &:hover {
                background-color: rgba($accent, .5);
            }

            &:disabled {
                opacity: 0.4;

                &:hover {
                    background-color: transparent;
                    color: $black;
                }
            }
        }
    }

    &:disabled {
        &:hover {
            pointer-events: none;
            cursor: none;
        }
    }

    &.link-button {
        font-size: 1.3rem;
        font-weight: 400;
        font-family: $primaryFont;
        padding: 0;
    }
}

a:hover {
    h3 {
        color: $accent;
    }
}
//a h3:hover { color:$accent;}

.button-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    min-width: 250px;
    padding: 5% 0;
}


//--- Slick Arrows---.
.slick-prev,
.slick-next {
    &:before {
        color: $black;
    }
}

.slick-next {
    &:before {
        font-family: "Font Awesome 5 Free";
        content: '\f054';
    }
}

.slick-prev {
    &:before {
        font-family: "Font Awesome 5 Free";
        content: '\f053';
    }
}
