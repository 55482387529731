.modal-branding {
    // Removed "pulse" at Marketing's request
    &.modal.modal-static .modal-dialog {
        transform: none;
    }

    .modal-header {
        //background-color: $titan-red;

        .modal-title {
            position: absolute;
            left: 0;
            width: 100%;
            text-align: center;
            font-size: 2rem;
            color: $white;
        }

        .close {
            color: $white;
        }
    }
}
