.check-stock-component {
    h3 {
        padding-top: 0.8rem;
        padding-bottom: 0.4rem;
    }

    h5.netPrice {
        &.netPrice {
            font-weight: 700;
            color: $accent;
        }
    }

    span.info {
        &.info {
            font-weight: 700;
            color: $accent;
        }
    }

    span.customer-tool {
        color: $accent;
        font-size: 1.0rem;

        &.customer-tool {
            color: $accent;
            font-size: 1.0rem;
        }
    }
    // adds padding to create new cart pop up
    .modal-content {
        .modal-body {
            padding: 2rem 5rem 5rem 5rem !important;
        }

        .modal-body-add-cart {
            padding: 2rem 2rem 2rem 2rem !important;
            width: 300px;
        }

        &.check-stock-add-to-cart-multi {
            align-items: center;
            background-color: transparent;
        }
    }

    .clear-button {
        margin-top: 2rem;
        float: right;
        margin-right: 0;
        width: 15rem;
        padding: 1.8rem 0;
    }

    .buttons-wrapper {
        margin-bottom: 10rem;


        .container {
            display: flex;
            justify-content: space-between;

            .button-link {
                color: $grey60;
                font-weight: 400;
            }

            .side-buttons {
                display: flex;
                position: relative;
                justify-content: flex-end;

                .modal-header {
                    button {
                        margin-left: auto !important;
                    }
                }

                button {
                    width: 15rem;
                    display: flex;
                    align-items: center;
                    padding: 1.8rem 0;
                    justify-content: center;

                    &:last-child {
                        margin-left: 3rem;
                    }

                    span {
                        margin-right: .5rem;
                    }
                }

                div {
                    @media (max-width: 767px) {
                        display: none;
                    }
                }

                .compare-tooltip {
                    box-shadow: $shadow-box;
                    top: -21rem;
                    left: -6rem;
                    padding: 3rem;
                    text-align: center;

                    .title {
                        font-size: 1.6rem;
                    }

                    .info {
                        font-size: 1.4rem;
                        color: $black;
                    }
                }
            }
        }
    }
}

.check-stock-item {
    span.info {
        &.info {
            font-weight: 700;
            color: $accent;
        }
    }

    span.customer-tool {
        color: $accent;
        font-size: 1.0rem;

        &.customer-tool {
            color: $accent;
            font-size: 1.0rem;
        }
    }
}

form {
    &.multi-add-to-cart-form {
        align-items: center;
        display: flex;
        /* align-content: space-between; */
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
}

.multi-add-to-cart-formgroup {
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.check-stock-multi-add-to-cart-button {
    margin-left: 0px !important;
    width: 100%;
}

#loginPopupSubmit{
    margin: auto !important;
}

.add-to-list-dropdown-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dropdown-wrapper .quantity-wrapper {
    display: flex;
    flex-direction: row;

    label {
        margin-right: 1rem;
        padding-right: 0rem;
    }

    input {
        border: 1px solid #ddd;
        max-width: 5rem;
        border-radius: 0.5rem;
        height: 4.7rem;
        font-size: 1.5rem;
        text-align: center;
    }

    
}

.dropdown-wrapper .check-stock-add-to-cart-multi .new-list-dropdown .multi-add-to-cart-form {

    button.small {
        padding: 1rem 2.2rem !important;
        font-weight: 700 !important;
        text-align: center;
        font-size: 1.2rem !important;
    }
}

.item-details-component {
    padding: 1rem 0 0;

    .item-row {
        position: relative;
        margin-bottom: 2rem;
        display: flex;
        padding: 2rem 0;
        align-items: center;
        min-height: 16rem;
        border: 1px solid $grey90;

        @media (max-width: 991px) {
            margin-right: -1.5rem;
            margin-left: -1.5rem;
        }

        &:first-child {
            min-height: auto;
            margin: 0;
            border: 0;

            @media (max-width: 991px) {
                display: none;
            }
        }

        .title {
            font-size: 1.8rem;
            color: $black;
            font-weight: 400;
            font-family: $primaryFont;
            text-transform: none;
        }

        .row-content {
            display: flex; //display: none;
            align-items: center;
            margin: 0;
            width: 100%;

            @media (max-width: 991px) {
                flex-wrap: wrap;
                flex-direction: column;
                padding: 1rem;
            }
        }
    }

    .checkbox-wrapper {
        position: absolute;
        left: 2rem;

        @media (max-width: 991px) {
            display: none;
        }

        .input-wrapper {
            display: flex;
            width: 3rem;
            height: 3rem;
            align-items: center;

            input[type=checkbox] {
                background-color: $white;
            }


            label {
                margin: 0 !important;
                margin-left: -2rem !important;
            }
        }
    }

    .tool-number-wrapper {
        width: 20%;
        margin-left: 7rem;
        position: relative;


        &.check-stock {


            .input-check-wrapper {
                position: relative;

                input {
                    font-size: 1.6rem;

                    &:focus {
                        background: $white;
                    }
                }
            }

            #check-box {
                text-align: left;
                background: $white;
                color: $grey60;
                padding: 1rem;
                font-size: 1.6rem;
                font-family: $tertiaryFont;
                position: absolute;
                left: 0;
                width: 100%;
                top: 40px;
                z-index: 999;

                .box {
                    margin-top: 1.5rem;

                    &:nth-child(1) {
                        margin-top: 0;
                    }
                }

                a {
                    width: 100%;
                    color: $grey60;
                    font-size: 1.3rem;
                    font-family: $tertiaryFont;
                    line-height: 2rem;
                }
            }
        }


        .error-message-wrapper {
            position: absolute;
            white-space: nowrap;
            left: 20rem;
            top: 50%;
            font-size: 1.6rem;
            transform: translateY(-50%);

            @media (max-width: 991px) {
                white-space: normal;
                font-size: 1.3rem;
            }
        }

        @media (max-width: 991px) {
            width: 100%;
            margin: 0;
            display: flex;
            justify-content: space-between;
            max-width: 30rem;
        }

        @media (max-width: 767px) {
            max-width: 100%;
        }

        .form-group {
            margin: 0;

            .form-control {
                background: $white;
                border: 1px solid $grey90;
                font-size: 1.5rem;
                width: 14rem;

                @media (max-width: 991px) {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: .4rem;
                }
            }

            .info {
                display: none;
                font-size: 1.3rem;
                color: $accent;
                font-weight: 700;

                @media (max-width: 991px) {
                    display: block;
                }
            }
        }

        .price-wrapper {
            display: none;
            text-align: center;
            color: $black;
            font-size: 1.3rem;
            font-weight: 700;

            @media (max-width: 991px) {
                display: flex;
                flex-direction: column;
            }
        }
    }

    .product-wrapper {
        display: flex;
        width: 30%;

        @media (max-width: 991px) {
            width: 100%;
            max-width: 30rem;
            margin-bottom: 2rem;
            margin-top: 1rem;
        }

        @media (max-width: 767px) {
            max-width: 100%;
        }

        .img-wrapper {
            max-width: 3rem;
            margin-right: 2rem;

            img {
                width: 100%;
            }
        }

        .content-wrapper {
            max-width: 40rem;

            .subtitle {
                font-size: 1.4rem;
                color: $black;
                font-weight: 700;
            }

            .item-description {
                font-size: 1.2rem;
                margin: 0;
            }

            .substitutes {
                font-size: 1.2rem;
                font-family: $primaryFont;
                color: $error;
            }
        }
    }

    .info-wrapper {
        width: 10%;
        justify-content: center;
        text-align: center;

        @media (max-width: 991px) {
            display: none;
        }

        span {
            font-weight: 700;
        }
    }

    .button-wrapper {
        width: 30%;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;

        @media (max-width: 1500px) {
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        @media (max-width: 991px) {
            width: 100%;
            flex-direction: row;
        }

        @media (max-width: 767px) {
            justify-content: space-between;
        }

        > button, .dropdown-wrapper > button {
            margin-right: 3rem;
            padding: 1.2rem;
            white-space: nowrap;
            display: flex;
            width: 16rem;
            align-items: center;
            justify-content: center;

            span {
                margin-right: .5rem;
                font-size: 2rem;

                @media (max-width: 767px) {
                    margin-right: 1rem;
                }
            }

            @media (max-width: 1500px) {
                margin-right: 0;
                margin-bottom: 1rem;

                &:last-child {
                    margin-right: 0;
                }
            }

            @media (max-width: 991px) {
                width: 14rem;
                margin-bottom: 1rem;

                &:first-child {
                    order: 2;
                    margin-right: 0;
                }

                &:last-child {
                    margin-right: 2rem;
                    order: 1;
                }
            }

            @media (max-width: 767px) {
                font-size: 1.1rem;
                height: 3.2rem !important;
                padding: 0 !important;
                width: 14.7rem;
            }
        }

        .dropdown-wrapper {
            margin-right: 2rem;

            @media (max-width: 1500px) {
                margin-right: 0;
            }

            @media (max-width: 991px) {
                margin-right: 2rem;
                order: 1;
            }

            @media (max-width: 767px) {
                margin-right: 0;
            }

            .title {
                text-transform: none;
                font-size: 1.6rem;
                color: $black;
                margin-bottom: 1rem;
                font-weight: 700;
            }
        }
    }

    .distributor-button-wrapper {
        width: 30%;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        //justify-content: flex-end;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @media (max-width: 991px) {
            width: 100%;
            flex-direction: row;
        }

        @media (max-width: 767px) {
            justify-content: space-between;
        }

        > button, .dropdown-wrapper > button {
            //margin-right: 3rem;
            padding: 1.2rem;
            white-space: nowrap;
            display: flex;
            width: 16rem;
            align-items: center;
            justify-content: center;
            margin-right: 0;
            margin-bottom: 1rem;

            &:last-child {
                margin-right: 0;
            }

            span {
                margin-right: .5rem;
                font-size: 2rem;

                @media (max-width: 767px) {
                    margin-right: 1rem;
                }
            }

            @media (max-width: 991px) {
                width: 14rem;
                margin-bottom: 1rem;

                &:first-child {
                    order: 2;
                    margin-right: 0;
                }

                &:last-child {
                    margin-right: 2rem;
                    order: 1;
                }
            }

            @media (max-width: 767px) {
                font-size: 1.1rem;
                height: 3.2rem !important;
                padding: 0 !important;
                width: 14.7rem;
            }
        }

        .dropdown-wrapper {
            margin-right: 0;

            @media (max-width: 991px) {
                margin-right: 2rem;
                order: 1;
            }

            @media (max-width: 767px) {
                margin-right: 0;
            }

            .title {
                text-transform: none;
                font-size: 1.6rem;
                color: $black;
                margin-bottom: 1rem;
                font-weight: 700;
            }
        }
    }

    .close-wrapper {
        position: absolute;
        right: 2rem;

        .close-button {
            margin: 0;
        }

        span {
            font-size: 1.8rem;
        }

        @media (max-width: 991px) {
            display: none;
        }
    }
}
