﻿.backlogged-order-details {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    gap: 0.75rem;
    padding: 0.75rem 0;
}

.backlogged-items-view{
    color: #000000;
}

.trackingColumn{
    display: flex;
    flex-direction: column;
}


.order-history-component {
    font-size: 1.3rem;

    .order-history-view {
        color: $black;
        background-color: #707070;
        padding-top: 0.5rem;
    }

    .order-component {
        padding-bottom: 0.5rem;
    }

    .order-header {
        margin: 0;
        background-color: $white;

        &.active {
            background-color: #cbedf4;
        }

        .order-header-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .show-more-container {
            padding-bottom: 0.5rem;
        }

        .show-more {
            color: $main;
            cursor: pointer;
        }

        .repeat-order-link {
            font-weight: 600;
        }

        .order-tracking {
            a {
                font-size: inherit;
            }
        }

        .order-header-details {
            display: grid;
            //grid-template-columns: repeat(6, fit-content(20%));
            grid-template-columns: repeat(6, 1fr);
            gap: 0.75rem;
            padding: 0.75rem 0;
        }
    }

    .order-address {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        min-height: 10rem;

        h3 {
            padding: 0;
            font-size: 1.6rem;
        }
    }

    .order-history-details-component {
        padding: 1.5rem;
        background-color: lightgray;
    }

    .order-details {
        display: grid;
        //grid-template-columns: repeat(7, fit-content(20%));
        grid-template-columns: repeat(7, 1fr);
        gap: 0 0.75rem;
    }
}
