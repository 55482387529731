.gmg-table-holder {
    width: 100%;

    h2 {
        text-align: center;
        color: #8C9091;
    }

    table {
        width: 100%;
        margin-bottom: 50px;
        margin-top: 30px;
        border: 2px solid black;
    }

    th {
        background-color: $accent;
        text-align: center;
        font-size: 2rem;
        font-weight: bold;
        color: white;
        line-height: 3rem;
    }

    tr.bold td {
        font-weight: bold;
        color: black;
    }

    tr:nth-child(odd) {
        background-color: white;
    }

    tr:nth-child(even) {
        background-color: $grey90;
    }

    span {
        margin-left: 20px;
    }
}
