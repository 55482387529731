//SHARED by product table and variant details pages
.pdp-component {
    background: $white;

    .container {
        .title-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-end;
            min-height: 50px;

            @media (min-height: 768px) {
                min-height: 150px;
                margin-bottom: 3.6rem;
            }

            .productTitle {
                @media (max-width: 991px) {
                    font-size: 2rem;
                }
            }

            .variantTitle {
                @media (max-width: 991px) {
                    font-size: 2rem;
                }
            }

            .subtitle {
                @media (max-width: 991px) {
                    font-size: 1.4rem;
                    margin-bottom: 3rem;
                }
            }
        }

        .product-details-wrapper {
            display: flex;

            @media (max-width: 991px) {
                padding: 0 2.6rem;
                display: block;
            }

            .product-slider {
                width: 50%;
                padding-right: 10rem;

                .slick-slide img {
                    //display: block;
                    display: block;
                    //max-height: 100px;
                    max-width: 100% !important;
                    height: auto !important;
                    max-height: 100px !important;
                    width: auto !important;
                    margin: auto !important;
                    box-shadow: 0px 0px 4px 2px #8f8f8f;
                }

                @media (max-width: 991px) {
                    width: 100%;
                    padding: 0;
                    margin-bottom: 4.7rem;
                }

                .image-wrapper {
                    margin-bottom: 3rem;
                }

                .slider-lightbox {
                    .slick-track {
                        display: flex;
                        align-items: center;

                        .item {
                            margin: 5px 1rem;

                            .video {
                                &:before {
                                    display: block;
                                    font-family: "Font Awesome 5 Free";
                                    content: "\F144";
                                    position: relative;
                                    color: $grey90;
                                    opacity: 0.75;
                                    font-size: 5rem;
                                    transform: translate(0, 250%);
                                    text-align: center;
                                    text-shadow: 0px 0px 6px black;
                                }
                            }

                            &.video {
                                position: relative;

                                &:before {
                                    display: block;
                                    font-family: $fontAwesome;
                                    content: "\f144";
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    color: $white;
                                    font-size: 3rem;
                                    transform: translate(-50%, -50%);
                                }
                            }
                        }
                    }

                    .slick-next,
                    .slick-prev {
                        &:before {
                            color: $black;
                        }
                    }

                    .slick-next {
                        right: -1.5rem;
                    }

                    .slick-prev {
                        left: -1.5rem;
                    }
                }
            }

            .product-detail {
                width: 50%;

                @media (max-width: 991px) {
                    width: 100%;
                    padding: 0 0 3rem;
                }

                .title-rating-wrapper {
                    margin-bottom: 1.5rem;
                    display: flex;
                    align-items: center;

                    @media (max-width: 991px) {
                        margin-left: -1rem;
                    }

                    span:not(.fa-icon) {
                        font-family: $tertiaryFont;
                        font-size: 2.5rem;
                        color: $black;
                        line-height: 3.8rem;

                        @media (max-width: 991px) {
                            font-size: 1.7rem;
                        }
                    }

                    .product-name {
                        &:before {
                            content: "|";
                            padding: 0 .5rem;
                        }
                    }

                    .rating-component {
                        margin-left: 2rem;
                        font-size: 0;
                        line-height: 3.2rem;

                        @media (max-width: 991px) {
                            margin-left: 1rem;
                        }

                        .back-stars {
                            position: relative;

                            .fa-icon {
                                font-size: 1.9rem;
                            }
                        }

                        .front-stars {
                            overflow: hidden;
                            position: absolute;

                            .fa-icon {
                                font-size: 1.9rem;

                                &:before {
                                    font-weight: 700 !important;
                                }
                            }
                        }

                        .fa-icon {
                            margin-left: .4rem;
                        }
                    }
                }

                .content {
                    line-height: 2.6rem;

                    @media (max-width: 991px) {
                        line-height: 2rem;
                    }

                    .large {
                        margin-bottom: 2rem;

                        @media (max-width: 991px) {
                            font-size: 1.4rem;
                        }
                    }

                    .detail-list {
                        color: $black;
                        margin-bottom: 4rem;

                        @media (max-width: 991px) {
                            font-size: 1.4rem;
                        }

                        li {
                            padding-left: 2rem;

                            @media (max-width: 991px) {
                                font-size: 1.4rem;
                            }

                            &::before {
                                content: '•';
                                color: $accent;
                                font-size: 2.4rem;
                                position: absolute;
                                margin-left: -2rem;

                                @media (max-width: 991px) {
                                    line-height: 2rem;
                                }
                            }
                        }
                    }

                    .icons-section-component {
                        width: auto;

                        .icons-content {
                            @media (max-width: 991px) {
                                justify-content: space-between;
                                width: 28rem;
                                margin: 0;
                            }

                            .icon-wrapper {
                                @media (max-width: 991px) {
                                    width: 6rem;
                                    margin: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .product-tabs-component {
        .customer-review-holder {
            text-align: center;
            margin-top: 4rem;
            margin-bottom: 4rem;

            p {
                font-size: 2rem;
                font-family: $primaryFont;
                color: $grey60;
            }

            .primary-button {
                padding: 1.3rem 7.3rem;
                font-size: 1.4rem;
                margin-top: 1.5rem;
            }

            .review-data {
                .primary-button {
                    float: right;
                }
            }

            .card {
                max-width: 60rem;
                margin: 0 auto;
                position: relative;
                border: 1px solid $grey60;
                padding-top: 3rem;
                padding-bottom: 3rem;

                .login-popup-component {
                    margin: 0 auto;
                    box-shadow: none;
                    padding: 0;

                    .card {
                        border: none;

                        &:before {
                            display: none;
                        }

                        .content-wrapper {
                            margin-top: 0;
                        }
                    }
                }


                &:before {
                    display: block;
                    position: absolute;
                    content: "\f0d7";
                    font-size: 2rem;
                    font-family: $fontAwesome;
                    top: -.9rem;
                    left: 50%;
                    color: #707070;
                    -webkit-transform: translateX(-50%);
                    transform: translateX(-50%);
                    font-weight: 600;
                }

                .content-wrapper {
                    text-align: left;
                    margin-top: 4rem;

                    label {
                        text-align: left;
                        color: $grey60;
                        font-size: 1.4rem;
                    }

                    input {
                        border: 1px solid $grey20;
                    }

                    textarea {
                        background: $white;
                        border: 1px solid $grey20;
                        height: 15.7rem;
                    }




                    .review-data {
                        .bta {
                            display: inline-block;
                            padding-right: 0;

                            .primary-button {
                                padding: 1.1rem 6rem;
                                font-size: 1.4rem;
                                margin-top: 1.5rem;
                            }

                            span {
                                font-size: 1.4rem;
                            }
                        }

                        .form-group {
                            width: 20%;
                            padding-right: 1rem;

                            &:nth-child(2) {
                                padding-left: 0 !important;
                            }

                            &:nth-child(4) {
                                width: 36% !important;
                                padding-right: 0 !important;
                            }
                        }

                        .primary-button {
                            &.disabled {
                                margin: 1.5rem auto 0;
                                display: inherit;
                            }
                        }

                        .posted {
                            margin-top: 2rem;

                            .primary-button {
                                margin-top: 0;
                            }

                            .post-name {
                                font-size: 1.8rem;
                                font-family: $primaryFont;
                                margin-bottom: 0;

                                span {
                                    color: $accent;
                                }
                            }
                        }
                    }

                    .rating-holder {

                        .rating-component {
                            align-items: center;
                            justify-content: center;


                            @media (max-width: 767px) {
                                padding-left: 1.5rem;
                            }

                            &.col-md-7 {
                                display: inline;

                                @media (max-width: 767px) {
                                    display: block;
                                }
                            }

                            .fa-icon {
                                font-size: 1.9rem;

                                &:before {
                                    font-weight: 400 !important;
                                }

                                &:hover {

                                    &:before {
                                        font-weight: 700 !important;
                                    }
                                }

                                &.full-star {
                                    &:before {
                                        font-weight: 700 !important;
                                    }
                                }
                            }

                            .back-stars {
                                position: relative;
                            }

                            .front-stars {
                                color: $accent;
                                overflow: hidden;
                                position: absolute;
                                top: 0;
                                font-weight: 700;
                            }
                        }

                        .form-text {
                            font-size: 1.4rem;
                        }
                    }

                    .review-upload {
                        .primary-button {
                            margin-top: 2.5rem;
                            text-transform: none;
                        }

                        .dropzone {
                            background: transparent;
                            min-height: auto;
                            margin-top: 2.5rem;
                            border: none;
                            padding: 0;

                            .dz-message {
                                margin: 0;
                                border: 2px solid #1c3e77;
                                color: #1c3e77;
                                padding: 1rem 0.5rem;
                                border-radius: 4px;

                                span {
                                    font-size: 1.4rem;
                                }
                            }

                            .dz-preview {
                                .dz-remove {
                                    &:after {
                                        font-family: 'Font Awesome 5 Free';
                                        font-weight: 700;
                                        font-size: 2rem;
                                        content: "\f00d";
                                        color: #8f8f8f;
                                        display: block;
                                    }
                                }
                            }
                        }
                    }
                }

                &.mobile {
                    @media (max-width: 767px) {
                        border: none;
                        box-shadow: none;

                        &:before {
                            display: none !important;
                        }
                    }
                }
            }

            .review-posted {
                margin-top: 4rem;
                margin-bottom: 3rem;

                .review-star {
                    .form-text {
                        font-size: 3.4rem;
                        font-family: $tertiaryFont;
                        color: $accent;
                    }

                    .review-nr {
                        font-size: 1.8rem;
                        font-family: $primaryFont;
                        color: $black;
                    }

                    .rating-component {
                        margin: 0 30px;
                        position: relative;

                        .back-stars {
                            position: relative;
                        }

                        .front-stars {
                            color: #1c3e77;
                            overflow: hidden;
                            position: absolute;
                            top: 0;
                            font-weight: 700;
                        }

                        .fa-icon {
                            font-size: 2.5rem;
                        }
                    }
                }

                .posted-reviews {
                    margin-top: 5rem;

                    .post-details {
                        border-top: 1px solid $grey60;
                        padding: 5rem 0;

                        @media (max-width: 767px) {
                            padding-left: 1.5rem;

                            .col-md-3 {
                                margin-bottom: 2rem;
                                text-align: left;
                            }
                        }


                        p {
                            margin: 0;
                        }

                        .post-name, .post-city {
                            font-family: $primaryFont;
                            font-size: 1.8rem;
                            color: $accent;
                            display: inline-block;

                            @media (max-width: 767px) {
                                color: $black;
                            }
                        }

                        .post-date {
                            font-family: $primaryFont;
                            font-size: 1.5rem;
                            color: $black;
                        }

                        .content-post {
                            text-align: left;

                            .helical-motto {
                                font-size: 2.5rem;
                                color: $accent;
                                font-family: $tertiaryFont;
                                margin-bottom: 1.5rem;
                                margin-top: 1rem;

                                @media (max-width: 767px) {
                                    font-size: 2rem;
                                }
                            }

                            .post-description {
                                font-size: 1.8rem;
                                color: $black;
                                margin-bottom: 3rem;
                            }

                            @media (max-width: 767px) {
                                .fa-icon {
                                    font-size: 1.8rem;
                                }
                            }

                            .rating-component {
                                .back-stars {
                                    position: relative;
                                }

                                .front-stars {
                                    color: #1c3e77;
                                    overflow: hidden;
                                    position: absolute;
                                    top: 0;
                                    font-weight: 700;
                                }
                            }
                        }
                    }
                }

                .pagination {
                    padding-left: 2.1rem;

                    .pag-arrow {
                        align-self: center;
                    }

                    .prev-arrow {
                        border-top: 2px solid $black;
                        border-left: 2px solid $black;
                        transform: rotate(-45deg);
                        width: 12px;
                        height: 12px;
                        line-height: 12px;
                        display: block;
                        transition: 0.2s;

                        &:hover {
                            border-top: 2px solid $accent;
                            border-left: 2px solid $accent;
                        }
                    }

                    .next-arrow {
                        border-top: 2px solid $black;
                        border-right: 2px solid $black;
                        transform: rotate(45deg);
                        width: 12px;
                        height: 12px;
                        line-height: 12px;
                        display: block;
                        transition: 0.2s;

                        &:hover {
                            border-top: 2px solid $accent;
                            border-right: 2px solid $accent;
                        }
                    }

                    .pag-holder {
                        font-size: 1.8rem;
                        font-family: $secondaryFont;
                        color: $black;
                    }
                }

                .pag-bta {
                    border-top: 1px solid #707070;
                    padding-top: 4rem;
                    position: relative;

                    .bta-holder {
                        position: absolute;
                        top: 3rem;
                        right: 0;

                        .primary-button {
                            margin-top: 0;
                        }
                    }
                }
            }

            .mobile-vs {
                .card {
                    border: none;
                    box-shadow: none;
                    padding-right: 0;
                    padding-left: 0;

                    &:before {
                        display: none;
                    }

                    .bta {
                        text-align: left;

                        p {
                            text-align: left;
                        }

                        span {
                            font-size: 1.4rem;
                            margin-left: 3rem;
                            font-family: $primaryFont;
                        }
                    }

                    .primary-button {
                        padding: 1.3rem 3.3rem;
                    }
                }
            }
        }
    }

    .product-table-component {
        margin-top: 2rem;

        input {
            display: inline-block;
            font-size: 1.6rem;
            padding: 0 2rem;
            font-family: $secondaryFont;
            font-weight: 700;
        }

        .holder-search {
            text-align: right;

            .input-search-wrapper {
                &:before {
                    right: 3.3rem;
                }
            }
        }


        .product-table-input {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            text-align: left;
            margin-top: 2rem;
            margin-bottom: 1.5rem;
            flex-flow: row wrap;

            .form-group {
                width: 10%;
                min-width: 14rem;
                padding: 0 5px;
                text-align: left;
                margin-bottom: 0.5rem;

                label {
                    font-size: 1.4rem;
                    font-weight: 700;
                    text-align: left;
                }

                input {
                    background: none;
                    border: 1px solid $grey20;
                    padding: 0.5rem;
                    font-weight: normal;
                    font-size: 1.4rem;
                }

                .suggestion-box {
                    text-align: left;
                    background: $white;
                    color: $grey60;
                    padding: 1rem;
                    font-size: 1.6rem;
                    font-family: $tertiaryFont;
                    position: absolute;
                    //left: 0;
                    //width: 100%;
                    //top: 40px;
                    z-index: 999;
                    border: 1px solid $grey60;

                    .box {
                        margin-top: 1.5rem;

                        &:nth-child(1) {
                            margin-top: 0;
                        }
                    }

                    a {
                        width: 100%;
                        color: $grey60;
                        font-size: 1.3rem;
                        font-family: $tertiaryFont;
                        line-height: 2rem;
                    }
                }
            }
        }

        .table-content-mask {
            display: none;
        }

        .table-search-holder {
            position: relative;
        }

        .show-more {
            display: none;
        }

        @media (max-width: 1024px) {
            .show-more {
                display: none;
            }

            .table-dots {
                text-align: center;
                margin-top: 0.5rem;

                li {
                    width: 8px;
                    height: 8px;
                    margin: 0 0.3rem;
                    font-size: 0;
                    line-height: 0;
                    display: inline-block;
                    padding: 5px;
                    color: transparent;
                    border-radius: 50%;
                    outline: none;
                    background: $grey70;

                    &.active {
                        background: $accent;
                    }
                }
            }

            &.mobile-vs {

                h4 {
                    display: none;
                }

                .holder-search {
                    text-align: center;

                    .form-group {
                        text-align: left;
                        font-size: 2.6rem;
                        padding: 0 2rem;
                        font-family: "Roboto",sans-serif;
                        font-weight: 700;
                    }

                    .selectric {
                        label {
                            font-size: 1.6rem !important;
                        }
                    }

                    .input-search-wrapper {
                        float: right;
                    }
                }

                .table-search-holder {
                    .show-more {
                        display: none;
                    }

                    .table-content-mask {
                        text-align: center;
                        padding: 34px 3px 3px;
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        height: 100%;
                        width: 100%;
                        background: rgba(112,112,112,0.68);
                        display: flex;
                        flex-direction: column;

                        .table-description {
                            padding-top: 4rem;

                            p {
                                font-size: 1.8rem;
                            }
                        }

                        a {
                            width: 40px;
                            height: 40px;
                            border: 2px solid #fff;
                            border-radius: 50%;
                        }

                        p {
                            text-align: center;
                            color: $white;
                            font-size: 1.4rem;
                            font-family: $primaryFont;
                            line-height: 20px;
                        }

                        button {
                            background: transparent;
                            border: 3px solid $white;
                            color: $white;
                            font-family: $primaryFont;
                            font-weight: 700;
                            width: 100%;
                            border-radius: 0;
                        }

                        .swipe {
                            margin: 50px 0;
                        }
                    }
                }
            }
        }

        @media (max-width: 767px) {
            .show-more {
                position: absolute;
                bottom: 0;
                background: rgba(238,238,236,0.8);
                color: $grey20;
                font-family: $primaryFont;
                width: 100%;
                border-radius: 0;
                outline: none;
                display: block;
            }

            .table-dots {
                text-align: center;
                margin-top: 0.5rem;

                li {
                    width: 8px;
                    height: 8px;
                    margin: 0 0.3rem;
                    font-size: 0;
                    line-height: 0;
                    display: inline-block;
                    padding: 5px;
                    color: transparent;
                    border-radius: 50%;
                    outline: none;
                    background: $grey70;

                    &.active {
                        background: $accent;
                    }
                }
            }

            &.mobile-vs {

                h4 {
                    display: none;
                }

                .holder-search {
                    text-align: center;

                    .form-group {
                        text-align: left;
                        font-size: 2.6rem;
                        padding: 0 2rem;
                        font-family: "Roboto",sans-serif;
                        font-weight: 700;
                    }

                    .selectric {
                        label {
                            font-size: 1.6rem !important;
                        }
                    }

                    .input-search-wrapper {
                        float: right;
                    }
                }

                .table-search-holder {
                    .show-more {
                        display: none;
                    }

                    .table-content-mask {
                        text-align: center;
                        padding: 34px 3px 3px;
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        height: 100%;
                        width: 100%;
                        background: rgba(112,112,112,0.68);
                        display: flex;
                        flex-direction: column;

                        .table-description {
                            padding-top: 4rem;

                            p {
                                font-size: 1.8rem;
                            }
                        }

                        a {
                            width: 40px;
                            height: 40px;
                            border: 2px solid #fff;
                            border-radius: 50%;
                        }

                        p {
                            text-align: center;
                            color: $white;
                            font-size: 1.4rem;
                            font-family: $primaryFont;
                            line-height: 20px;
                        }

                        button {
                            background: transparent;
                            border: 3px solid $white;
                            color: $white;
                            font-family: $primaryFont;
                            font-weight: 700;
                            width: 100%;
                            border-radius: 0;
                        }

                        .swipe {
                            margin: 50px 0;
                        }
                    }
                }
            }
        }
    }
}
