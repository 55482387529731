﻿@media (min-width: 480px) {
    .speeds-feeds-search {
        form {
            span {
                float: right;
            }
        }
    }
}
@media (max-width: 480px) {
    .speeds-feeds-search {
        form {
            span {
                text-align: center;
            }
            .col-md-4 {
                text-align:center;
            }
        }
    }
}

.speeds-feeds-search {
    form {
        margin-bottom: 15px;

        span {
            font-size: 16px;
        }

        .form-control {
            padding-top: 25px !important;
            padding-bottom: 25px !important;
            font-size: 16px;
        }

        button.wide {
            width: 100% !important;
        }

        button, input {
            position: relative;
            display: block;
            margin: 5px auto;
        }
    }

    h2 {
        text-align: center;
        padding-top: 1.5rem;
        padding-bottom: 2rem;
        color: $accent;
    }
}

.speed-feed-results {
    padding-left: 6%;
    padding-right: 6%;
    padding-top: 40px;
    padding-bottom: 50px;

    .sandf-file {
        background-color: #f9f9f9;
        padding-top: 40px;
        padding-bottom: 40px;
        padding-left: 15px;
        padding-right: 15px;

        span.h3 {
            text-align: center;
            padding-top: 1.5rem;
            padding-bottom: 2rem;
            padding-left: 2rem;
            color: $accent;
            font-weight: 700;
            border: 1px solid #f9f9f9;
        }

        span.h4 {
            font-size: 1.6rem;
            padding-left: 2rem;
        }

        a {
            margin-left: 10px;
            text-align: center;
            font-size: 1.6rem;
            text-decoration: underline;
            font-weight: 600;
        }
    }
}


.speeds-feeds-tree-container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

    h2 {
        text-align: center;
        padding-top: 1.5rem;
        font-weight: 900;
        color: $grey60;
    }

    h3 {
        padding-bottom: 0rem;
        Padding-top: 0rem;
    }

    h4 {
        margin-bottom: 1.5rem;
        margin-top: 2.5rem;
    }

    hr {
        margin-top: 1.5rem;
        margin-bottom: 4rem;
    }

    a {
        font-family: $secondaryFont;
        font-size: 1.4rem;
        color: $accent !important;
        font-weight: 600;
        text-decoration-line: underline;
    }

    .speed-feed-series {
    }

    .speed-feed-section {
        margin-bottom: 0px;
        margin-top: 20px;
    }

    .speed-feed-label {
        padding-left: 0px;
    }
}

.speedsfeeds-modal-component {
    .modal-dialog {
        max-width: 90rem;

        .modal-content {
            padding-bottom: 3rem;

            .modal-header {
                position: relative;
                padding: 4rem 4rem 2rem;
                display: flex;
                justify-content: space-between;

                @media (max-width: 767px) {
                    display: block;
                }

                .modal-title-wrapper {
                    .title {
                        display: block;
                        font-size: 2.2rem;
                        line-height: 2.9rem;
                        color: $white;
                    }

                    .subtitle {
                        font-size: 1.8rem;
                        line-height: 2.7rem;
                        color: $white;
                    }
                }

                .input-search-wrapper {
                    width: 44rem;

                    @media (max-width: 767px) {
                        max-width: 100%;
                    }

                    input {
                        width: 100%;
                        font-size: 1.8rem;
                    }
                }

                .close {
                    position: absolute;
                    right: 1rem;
                    top: 1rem;
                }
            }

            .modal-body {
                display: flex;
                flex-wrap: wrap;
                padding: 3rem;
                overflow: auto;
                max-height: 35rem;

                .link-item {
                    font-size: 1.8rem;
                    width: 50%;
                    padding: 0 0.5rem;
                    text-align: center;
                    color: $grey60;
                    line-height: 3.5rem;
                    min-width: 4.8rem;

                    @media (max-width: 767px) {
                        font-size: 1.6rem;
                        width: 25%;
                    }
                }
            }
        }
    }
}
