﻿.form-check {
    margin-bottom: 0.8rem;
    margin-top: 0.8rem;

    .form-check-label {
        font-size: 1.2rem;
        color: $black;
        margin-left: 1rem;
    }

    .form-check-label-accent {
        color: $accent;
    }
}

/// <reference path="variables" />

.form-control {
    font-size: 1.6rem;
    height: 4.6rem;

    &::placeholder {
        font-family: $secondaryFont;
        font-weight: 600;
        font-size: 1.6rem;
    }
}

.form-radio-button {
    border: 1px solid black;
    padding: 1rem 2rem 0.25rem;
    border-radius:0.25rem;
}

.multiselect-container {
    position: relative;
}

.multiselect-popover {
    position: absolute;
    z-index: 100;
    border: 1px solid black;
    background-color: $bodyBackgroundColor;
    max-height:250px;
    overflow-y:scroll;
}

input.multiselect-input {
    background-color: transparent !important;
    width: 90%;
    z-index: 3;
    display: inline;
    position: relative;
    border: 1px solid transparent !important;
    font-size: 1.6rem;
    outline: none;
}

.multiselect-suggestion {
    background-color: white;
    position: absolute;
    display:flex;
    align-items:center;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 0;
    height: 4.6rem;
    padding-left: 0.75rem;
    border: 1px solid #000000;
    font-size: 1.6rem;
}

a.multiselect-icon {
  padding-top: 1.6rem;
  right:0;
  position: absolute;
  font-size: 1.1rem;
  margin-right: -2.4rem;
}

.g-recaptcha {
    padding: 1rem 0;
}

.form-fields {
    display: grid;

    &.columns-2 {
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
    }
}

.form-group {
    label {
        color: $black;
        padding-right: 10px;
    }

    .vertical_center {
        margin: 0;
        position: relative;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    input {
        background: $white;
        border: 1px solid $black;
    }

    .suggestion {
        background: $white;
        border: 1px solid $black;
    }

    select {
        background: $white;
        border: 1px solid $black;
    }
}

.selectric {
    border-radius: .5rem;
    margin-bottom: 1rem;
    background: $white;
    border: 1px solid $grey70;

    .button {
        font-size: 0;
        background: $white;

        &::after {
            font-family: "Font Awesome 5 Free";
            content: "\f078" !important;
            font-weight: 700;
            font-size: 1rem;
            border: none;
            color: $grey60;
        }
    }

    .selectric-items li {
        background: $white;
    }
}

// Contact Form
.form-check-emailSignUp {
    display: flex !important;
}

#emailSignUpCheckbox {
    //font-size: 16px;
    //width: 120px;
    //height: max-content;
    margin-right: 10px;
    //align-content: end;
    align-self: flex-start;
    margin-top: 7px;
}

/*Checkbox and radio*/

.input-wrapper {
    font-size: 0px;
    line-height: 1;
}

.input-wrapper input[type=checkbox] {
    width: 20px;
    height: 20px;
    margin: 0;
    padding: 0;
    opacity: 0;
}

.input-wrapper input[type=checkbox] + label {
    display: inline-block;
    margin-left: -25px;
    margin-bottom: -1.5rem;
}

.input-wrapper input[type=checkbox] + label::before {
    font-family: "Font Awesome 5 Free";
    font-weight: 700;
    display: inline-block;
    min-width: 1em;
}

.input-wrapper input[type=checkbox] + label::before {
    content: "\f0c8";
    font-size: 3rem;
    font-weight: 400;
    color: $grey60;
}

.input-wrapper input[type=checkbox]:checked + label::before {
    content: "\f14a";
    font-size: 3rem;
    color: $accent;
}

.content-wrapper {
    .form-group {
        width: 100%;

        input ~ label {
            padding-left: 0.5rem;
        }
    }
}