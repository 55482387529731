/// <reference path="variables.scss" />

/*************************************
TITAN ---- Font faces
*************************************/

body {
    font-family: $primaryFont;
    font-size: $bodyFontSize;
}

hr {
    margin-top: 1.5rem;
    margin-bottom: 4rem;
    border: 0;
    border-top: 1px solid rgba(0,0,0,.1);
}

.page-title {
    color: white !important;
}

.h1 {
    color: $h1FontColor;
    font-family: $secondaryFont;
    font-size: 3rem;
    font-weight: 900;
}

.h2 {
    font-family: $primaryFont;
    font-weight: 700;
    font-style: normal;
    Font-size: 2.8rem;
    //color: $accent;

    a {
        color: $accent !important;
    }
}

.h3 {
    font-family: $secondaryFont;
    font-weight: 600;
    font-style: normal;
    font-size: 2rem;
    color: $h3FontColor;
    padding-bottom: 2rem;
    padding-top: 2rem;
}

.h4 {
    font-family: $secondaryFont;
    font-weight: 600;
    font-size: 1.8rem;
    color: $h4FontColor;
}

p {
    color: $black;
    font-size: 1.6rem;

    &.small {
        color: $grey60;
        font-size: 1.6rem;
    }

    &.large {
        font-size: 1.6rem;
    }
}

/*a {
    font-weight: 600;
    text-decoration-line: underline;
    color: $accent;
}*/

.title {
    font-family: $secondaryFont;
    font-size: $titleFontSize;
    font-weight: $titleFontWeight;
    color: $titleColor;
    text-transform: uppercase;
}

.productTitle {
    font-family: $secondaryFont;
    font-size: $productTitleFontSize;
    font-weight: $productTitleFontWeight;
    text-transform: uppercase;
    color: $productTitleColor;
}

.variantTitle {
    font-family: $secondaryFont;
    font-size: 3.4rem;
    font-weight: 700;
    text-transform: uppercase;
    color: $variantTitleColor;
}

.resourcePDP {
    font-family: $secondaryFont;
    font-size: 2.5rem;
    font-weight: 900;
    text-transform: uppercase;
}

.subtitle {
    font-family: $subTitleFont;
    font-size: $subTitleFontSize;
    font-weight: $subTitleFontWeight;
}

.quote {
    font-family: $tertiaryFont;
    font-style: italic;
    font-size: 2rem;
}

a {
    font-family: $primaryFont;
    font-weight: 400;
    font-size: 1.4rem;
    color: $linkColor;
}

ul {
    list-style: none;
}

.devider {
    background: $grey70;
    margin: 0;
}

.error-text {
    color: $error;
    font-size: 1.2rem;
}

.collapse-icon {
    &[aria-expanded="true"] {
        &::after {
            content: "\f077";
            font-family: "Font Awesome 5 Free";
            font-weight: 700;
            padding-right: 1rem;
            position: absolute;
            right: 2rem;
        }
    }

    &[aria-expanded="false"] {
        &::after {
            content: "\f078";
            font-family: "Font Awesome 5 Free";
            font-weight: 700;
            padding-right: 1rem;
            position: absolute;
            right: 2rem;
        }
    }
}

.title-wrapper {
    padding: 7.7rem 0 1.6rem;
    border-bottom: 1px solid $grey90;
}

.title-wrapper-small {
    text-align: center;
    padding: 1.8rem 0;

    .title {
        font-size: 2.2rem;
        line-height: 2.9rem;
        margin-bottom: 0;
    }
}

/*************************************
Icons
*************************************/

@font-face {
    font-family: 'helical-icons';
    src: url('/Content/fonts/vendor/icomoon/fonts/helical-icons.eot?xr9k2');
    src: url('/Content/fonts/vendor/icomoon/fonts/helical-icons.eot?xr9k2') format('embedded-opentype'), url('/Content/fonts/vendor/icomoon/fonts/helical-icons.ttf?xr9k2') format('truetype'), url('/Content/fonts/vendor/icomoon/fonts/helical-icons.woff?xr9k2') format('woff'), url('/Content/fonts/vendor/icomoon/fonts/helical-icons.svg?xr9k2') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'international-icons';
    src: url('/Content/images/countryIcons/global.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

.icon {
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1;

    &:before,
    &:after {
        font-family: 'helical-icons';
    }
}

.fa-icon {
    &::before {
        font-family: $fontAwesome;
    }
}


.input-search-wrapper {
    position: relative;

    &::before {
        display: block;
        position: absolute;
        z-index: 0;
        right: 1rem;
        font-family: 'helical-icons';
        content: "\e93f";
        top: 50%;
        font-size: 2rem;
        font-weight: 700;
        transform: translateY(-50%);
        cursor: pointer;
    }

    &.hasCleard {
        &::before {
            display: none;
        }
    }
}

.clearable {
    position: relative;

    .clear {
        display: none;
        background: transparent;
        position: absolute;
        color: $grey60;
        padding: 0;
        font-family: $primaryFont;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }
}

.icon-location:before {
    content: "\e900";
}

.icon-user:before {
    content: "\e902";
}

.icon-my-list:before {
    content: "\e903";
}



.icon-cam:before {
    content: "\e939";
}

.icon-customize:before {
    content: "\e93a";
}

.icon-email:before {
    content: "\e93e";
}

.icon-find-tool:before {
    content: "\e93f";
}

.icon-loupe:before {
    content: "\e940";
}

.icon-map:before {
    content: "\e941";
}

.icon-pdf:before {
    content: "\e942";
}

.icon-phone:before {
    content: "\e943";
}

.icon-sim:before {
    content: "\e944";
}

.icon-speed-feeds:before {
    content: "\e945";
}

.icon-star:before {
    content: "\e94e";
}

.icon-table:before {
    content: "\e94f";
}

.icon-phone1:before {
    content: "\e950";
}

.icon-phone-one:before {
    content: "\e903";
}

.icon-email-one:before {
    content: "\e904";
}

.icon-upload:before {
    content: "\e905";
}

.icon-shopping-cart:before {
    content: "\e62";
    padding-right: 5px;
}

.icon-global:before {
    content: url('/../images/countryIcons/global.svg');
    padding-right: 5px;
}

.icon-Helical_Icons_Location-01:before {
    content: "\e904";
}

.icon-Helical_Icons_Location-011:before {
    content: "\e900";
}

/*************************************
ADA Compliant Focus
*************************************/
.tab-user {
    a, .form-control, button {
        &:focus {
            outline: 1px dashed black;
            border-color: none;
            box-shadow: black;
        }
    }
}
