//SHARED by account profile (a shared account part) AND (why?) search layout???
.myaccount-component {

    .content-wrapper {
        background: $white;
        padding: 4rem 0;

        @media (max-width: 767px) {
            padding: 2.6rem 0;
        }
    }

    .sidenav-wrapper {
        color: $grey60;

        @media (max-width: 767px) {
            margin: 0 1rem;
        }

        .sidenav-title {
            font-family: $secondaryFont;
            font-size: 2.2rem;
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 2.5rem;

            @media (max-width: 767px) {
                display: none;
            }
        }

        .sidenav-subtitle {
            font-family: $secondaryFont;
            font-weight: 700;
            font-size: 2.2rem;
            margin-bottom: 2rem;
            padding-top: 6%;

            @media (max-width: 767px) {
                font-size: 1.9rem;
            }
        }

        .sidenav-list {
            margin-bottom: 2rem;

            @media (max-width: 767px) {
                margin-bottom: 5rem;
            }

            .list-item {
                margin-bottom: 1rem;

                .link-item {
                    font-family: $primaryFont;
                    font-size: 1.8rem;
                    word-wrap: break-word;

                    &:hover {
                        color: $accent;
                        text-decoration: none;
                    }

                    &.active {
                        color: $accent;
                        text-decoration: none;
                        font-weight: 700;
                    }
                }

                &.mobile-hide {
                    @media (max-width: 767px) {
                        display: none;
                    }
                }
            }
        }

        .user-wrapper {
            display: none;
            margin-bottom: 2rem;

            .account-link {
                color: $accent;
                font-size: 2rem;
                font-family: $tertiaryFont;
            }

            @media (max-width: 767px) {
                display: block;
            }
        }
    }

    .main-wrapper {
        .card-title {
            font-family: $secondaryFont;
            font-size: 3rem;
            margin-bottom: 2.4rem;
            font-weight: 700;

            @media (max-width: 767px) {
                font-size: 2.5rem;
            }
        }

        .card-wide {
            max-width: 115rem;
            box-shadow: 0px 2px 6px rgba($black, .16);
            padding: 3.5rem 41rem 3.2rem 5rem;
            margin-bottom: 4rem;
            color: $black;

            @media (max-width: 1200px) {
                padding-right: 5rem;
            }

            @media (max-width: 767px) {
                padding: 2.5rem;
                margin: 2rem 1rem;
            }

            .content-wrapper {
                padding: 0;
            }

            .card-title-mobile {
                margin-bottom: 2.9rem;
                display: none;
                font-size: 1.8rem;

                @media (max-width: 767px) {
                    display: block;
                }
            }

            .title {
                text-transform: none;
            }

            .form-group {
                margin-bottom: 2rem;
            }

            .form-control {
                border: 1px solid $grey70;
                font-family: $primaryFont;

                &::placeholder {
                    font-family: $primaryFont;
                    font-size: 1.5rem;
                    color: $black;
                }
            }

            label {
                font-size: 1.2rem;
                text-transform: uppercase;
            }

            .primary-button {
                margin-top: 2.8rem;

                @media (max-width: 372px) {
                    padding: 1rem 2rem;
                }
            }

            .cancel-button {
                margin-left: 5rem;
                border: none;
                padding: 0;
                font-family: "Open Sans", sans serif;
                font-weight: 700;
                font-size: 1.5rem;
                cursor: pointer;

                &:hover {
                    color: $accent;
                }

                @media (max-width: 372px) {
                    margin-left: 1.5rem;
                }
            }
        }

        .user-info-card {
            padding: 4.7rem 3.3rem 3.4rem;
            margin-left: 0;
            margin-right: 0;

            @media (max-width: 767px) {
                padding: 2.5rem;
                margin: 0 1rem 2rem;
            }

            .card-col {
                display: flex;
                justify-content: space-between;

                @media (max-width: 991px) {
                    border-bottom: 1px solid $grey70;
                    padding: 4rem 0 1.5rem;

                    &:last-child {
                        border: none;
                    }

                    &:first-child {
                    }
                }

                &:after {
                    content: "";
                    display: block;
                    background: $grey60;
                    width: 1px;
                    height: 15rem;
                    margin-top: 5rem;

                    @media (max-width: 991px) {
                        display: none;
                    }
                }

                &:last-child {
                    &:after {
                        display: none;
                    }
                }

                .list-wrapper {
                    @media (max-width: 767px) {
                        padding-bottom: 5rem;
                    }

                    .title {
                        display: block;
                        font-size: 1.6rem;
                        margin-bottom: 3rem;
                    }

                    .list-item {
                        margin-bottom: 2.5rem;
                        font-size: 1.5rem;
                    }

                    .user-info-address {
                        font-size: 1.5rem;
                        display: block;
                    }
                }
            }
        }

        .distributor-card {
            padding: 4rem;

            @media (max-width: 767px) {
                padding: 2.5rem;
            }

            .content-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;

                @media (max-width: 767px) {
                    display: block;
                }

                .content {
                    @media (max-width: 767px) {
                        margin-bottom: 1rem;
                    }
                }
            }

            .desktop-button {
                @media (max-width: 767px) {
                    display: none;
                }
            }

            .mobile-button {
                display: none;

                @media (max-width: 767px) {
                    display: block;
                    width: 100%;
                }
            }
        }

        .content {
            .distributor-name,
            .description {
                font-size: 1.5rem;
                font-weight: 700;
            }

            .distributor-address {
                display: block;
                font-size: 1.4rem;
            }
        }

        .map-wrapper {
            max-width: 59rem;

            img {
                display: block;
                height: 100%;
            }
        }

        .mylist-card {
            padding: 4rem 4.5rem;

            @media (max-width: 767px) {
                padding: 2.5rem;
            }

            .content {
                text-align: right;

                .input-search-wrapper {
                    margin-bottom: 2rem;
                }

                .form-control {
                    display: inline-block;
                    padding-left: 2rem;
                    max-width: 33.5rem;
                    font-size: 1.6rem;
                    font-family: $tertiaryFont;
                    color: $grey60;

                    &::placeholder {
                        font-family: $tertiaryFont;
                        color: $grey60;
                    }
                }

                .saved-list {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    margin: 0 -2rem;

                    .link-item {
                        margin: 0 2.5rem;

                        @media (max-width: 767px) {
                            width: 100%;
                        }
                    }

                    .primary-button {
                        width: 20rem;
                        padding: 2.6rem 0;

                        @media (max-width: 767px) {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}
