//SHARED by a lot of stuff
.tooltip-position-wrapper {
    position: relative;
    @media (max-width: 767px) {
        max-width: 100%;
    }
}

.password-tooltip {
  margin-top: 1.5rem
}
.contact-tooltip,
.distributor-tooltip,
.compare-tooltip {
    width: 27rem;
    top: 6rem;
}

.password-tooltip,
.contact-tooltip,
.distributor-tooltip,
.compare-tooltip {
    position: absolute;
    z-index: 3;
    display: none;
    background: $white;
    margin-right: -16rem;
    padding: 1.3rem;
    border: 1px solid $grey70;
    /*right: initial;
    transform: translateY(20%);*/

    .title {
        text-transform: none;
        display: block;
        font-size: 1.6rem;
        font-weight: 700;
        margin-bottom: 1.3rem;
    }

    .description {
        text-transform: none;
        display: block;
        font-size: 1.5rem;
        font-weight: 700;
        margin-bottom: 1rem;
    }

    span {
        font-size: 1.5rem;
        display: block;

        &.subtitle {
            font-weight: 700;
            font-size: 1.5rem;
            color: $black;
            margin-bottom: 0;
        }
    }

    ul {
        position: relative;
    }

    li {
        font-size: 1.5rem;
        margin-left: 4rem;
        color: $black;

        &.validated {
            .checked-icon {
                color: $accent;
                position: absolute;
                left: 1rem;

                &:before {
                    content: "\f00c";
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    padding-right: 1rem;
                    font-size: 1.5rem;
                }
            }
        }
    }

    &:after,
    &:before {
        bottom: 100%;
        left: 14%;
        /*right: initial;
        top: initial;*/
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    &:after {
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: $white;
        border-width: 14px;
        margin-left: -15px;
    }

    &:before {
        border-color: rgba($grey70, 0);
        border-bottom-color: $grey70;
        border-width: 16px;
        margin-left: -17px;
    }

    &.arrow-bottom:after,
    &.arrow-bottom:before {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    &.arrow-bottom:after {
        border-color: rgba(136, 183, 213, 0);
        border-top-color: $white;
        border-width: 15px;
        margin-left: -15px;
    }

    &.arrow-bottom:before {
        border-color: rgba(79, 69, 63, 0);
        border-top-color: $grey70;
        border-width: 16px;
        margin-left: -16px;
    }
}

