//SHARED by header and footer
.social-media-component {
     display: flex;
     .media-icon {
         margin-left: .4rem;
         &:first-child {
             margin: 0;
         }
         .media-link {
             max-width: 2.4rem;
             display: inline-block;
             .icon {
                 width: 24px;
                 height:auto;
             }
         }
     }
     @media (max-width: 767px) {
         display: none;
     }
 }
