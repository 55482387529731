.shipping-address-root {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3.2rem;
    //gap: 3.2rem 4.8rem;

    .shipping-address-container {
        min-height: 225px;
        box-shadow: 0px 2px 6px #00000029;
        padding: 1.6rem 3.2rem;

        .branch-label {
            span {
                //color: $titan-blue;
                font-weight: bold;
            }
        }

        > h3 {
            padding: 0;
        }

        .buttons-holder {
            text-align: right;

            > button {
                font-size: 1.6rem;
                padding-left: 1rem;
                padding-right: 1rem;

                &:last-child {
                    padding-right: 0;
                }

                &.primary-link {
                    color: $shippingAddressPrimaryLinkColor;
                    text-decoration: underline;
                }

                &.secondary-link {
                    color: $shippingAddressSecondaryLinkColor;
                    text-decoration: underline;
                }
            }
        }
    }

    .add-shipping-address-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;

        button {
            width: 100%;
            height: 100%;
        }
    }
}
