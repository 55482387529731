
// Color classes text & background.
.light-gray-text {
    color: $grey70;
}

.light-gray-bg {
    background: $grey70;
}

.light-black-text {
    color: $grey20;
}

.light-black-bg {
    background: $grey20;
}

.lighter-gray-text {
    color: $grey90;
}

.lighter-gray-bg {
    background: $grey90;
}

.green-text {
    color: $accent;
}

.dark-gray-text {
    color: $grey60;
}

.dark-gray-bg {
    background: $grey60;
}

.white-text {
    color: $white;
}

.white-bg {
    background: $white;
}

.highlight-bg {
    background: $highlight;
}