//SHARED by add-to-cart and cart maintenance 
.quantity-field-component {
    font-size: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    span {
        font-size: 1.4rem;
        position: relative;
        color: $accent;
    }

    button {
        background-color: $grey70;
        border: none;
        color: $black;
        font-size: 2rem;
        height: 4.7rem;
        padding: 0;
        width: 4.7rem;

        @media (max-width: 1200px) {
            width: 4rem;
            height: 4rem;
        }
    }

    input {
        border: 1px solid $grey90;
        color: $black;
        font-family: $primaryFont;
        font-size: 1.8rem;
        font-weight: 700;
        height: 4.7rem;
        line-height: 3rem;
        text-align: center;
        width: 4.7rem;
        margin: 0;
        background: $white;

        @media (max-width: 1200px) {
            width: 4rem;
            height: 4rem;
        }
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        appearance: none;
    }

    input[type="number"] {
        appearance: none;
        -moz-appearance: textfield;
    }
}
