.square-items-component {
    display: flex;
    padding: 4rem 0;

    @media (max-width: 767px) {
        padding: 0 3.5rem;
    }

    .block-link-2 {
        //width: 50%;
        display: inline-block;
        //border: 1px solid $grey60;
        margin: 40px;

        h3 {
            font-size: 3rem !important;
        }

        @media (max-width: 767px) {
            width: 100%;
            margin: 2rem 0;
        }

        img {
            width: 100%;
        }

        .content-wrapper-block-link {
            text-align: center;
            margin-top: 10px;

            .title-block-link {
                color: #707070;
                font-size: 3rem;
                margin-bottom: 15px;
                margin-top: 15px;
            }

            .subtitle-block-link {
                color: #F15C31 !important;
                //margin-top: 10px;
                //margin-bottom: 25px;
                margin: auto;
                margin-bottom: 25px;
                font-size: 2rem !important;
                width: 80%;
            }
        }

        button {
            font-size: 1.5rem !important;
            font-weight: 700 !important;
            border-radius: -3.6rem !important;
            padding: 1rem 5.2rem !important;
            //text-transform: capitalize;
            //line-height: 1;
            //border: cadetblue;
            //border: orange !important;
            border-color: #F15C31 !important;
            background: white !important;
        }
    }

    .block-link {
        width: 25%;
        display: inline-block;
        border: 1px solid $grey60;
        margin: 0 1rem;

        @media (max-width: 767px) {
            width: 100%;
            margin: 2rem 0;
        }

        img {
            width: 100%;
        }
    }



    @media (max-width: 767px) {
        display: block;
    }
}
