/// <reference path="variables.scss" />

//*************************************
//*************************************
.offscreen {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

//*************************************
//*************************************
.pre-header {
    background: $headerRibbonBackgroundColor;
    display: flex;
   // padding-top: 2px;
    justify-content: space-between;
    height: 3.6rem;

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

//*************************************
//*************************************
.brand-logo-component {
    padding-left: 2rem;
    display: flex;
    align-items: center;

    li {
        display: inline;
    }

    .brand-logo {
        margin-right: 2rem;

        img {
            max-height: 2rem;
        }
    }

    .brand-logo-image {
        max-height: 1.3rem;
    }
}

//*************************************
//*************************************
.international-header-component {
    //display: flex;
    max-height: 1.25rem;
    height: auto;
    margin-right: 1.25rem;
    vertical-align: middle;

    .int-flag-icon-tiny {
        max-height: 1.7rem;
        height: auto;
        padding-left: 0.5rem;
    }

    a {
        display: flex;
        font-size: 1.2rem;
        height: auto;
        color: $internationalSelectorColor;
        text-wrap: nowrap;
    }
}


//*************************************
//*************************************
.header-component {
    font-size: 1.6rem;
    background-color: $navMenuBackgroundColor;
    border-bottom: $headerBorderWidth solid $headerBorderColor;

    @media (max-width: 767px) {
        border: none;
        padding-bottom: 0;
    }

    .form-group {
        margin-bottom: 0;
    }

    #skip {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        margin-left: 0;
        text-align: center;
        list-style: outside none;

        li {
            list-style-type: none;

            a {
                position: absolute;
                left: -99999px;
                top: 50px;

                &:focus, &:active {
                    position: relative;
                    left: 0;
                    z-index: 9999;
                    width: auto;
                    height: auto;
                    margin: 0 auto;
                    padding: 5px 20px;
                    text-align: center;
                    text-decoration: none;
                    font: bold 30px mr-eaves-sans, sans-serif;
                    border: none;
                    /* color information, edit this to suit the colors of your layout */
                    color: #ffffff;
                    background: #055dfd;
                    float: left;
                    outline: none;
                }
            }
        }
    }

    .category-link {
        font-size: 1.6rem;
        text-transform: uppercase;
        font-weight: 600;
        padding: 0;
        color: $navMenuColor;

        @media (min-width: 768px) {
            color: $navMenuColor;
        }

        &:hover,
        &:active,
        &:focus {
            color: $navMenuFocusColor;
            text-decoration: none;
            border-bottom: 3px solid $navMenuBorderColor;
        }
    }

    .desktop-nav {
        .container {
            display: flex;
            justify-content: space-between;
            margin: 2rem auto $headerNavContainerBottomMargin;

            .nav-content {
                display: flex;
                justify-content: space-between;
                flex-direction: column;
            }

            .nav-brand {
                width: 32rem;
                min-width: 20rem;

                @media (min-width: 1401px) {
                    width: 40rem;
                }
            }

            .primary-nav {
                display: flex;
                align-items: center;
                justify-content: $headerMenuAlignment;
                flex-wrap: wrap;
                gap: 1rem 3.2rem;

                .nav-item {
                    .category-link {
                        font-size: $headerPrimaryNavLinkFontSize;
                    }

                    .submenu {
                        display: none;
                    }
                }
            }

            .secondary-nav {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                justify-content: flex-end;
                margin-bottom: 2.2rem;
                gap: 2rem;

                .separator {
                    margin: 0;
                    display: inline-block;
                }

                .menu-link {
                    color: $navMenuColor2;
                    display: flex;
                    align-items: center;

                    &:active {
                        color: $navMenuActiveColor2 !important;
                    }

                    &:hover,
                    &:focus {
                        color: $navMenuFocusColor2 !important;
                    }

                    &:hover::after,
                    &:active::after,
                    &:focus::after {
                        display: block;
                    }

                    &:last-child {
                        margin-right: 0
                    }

                    &.icon {
                        &:before {
                            margin-right: .5rem;
                            font-size: 2.6rem;

                            @media (max-width: 1240px) {
                                font-size: 2rem;
                            }
                        }
                    }

                    &:after {
                        display: none;
                        content: " ";
                        width: calc(100% - 2.4rem);
                        height: .3rem;
                        background: $navMenuBorderColor2;
                        position: absolute;
                        bottom: 0;
                        right: 0;
                    }
                }

                .dropdown-wrapper {
                    z-index: 30;
                }

                .account-dropdown {
                    padding: 3rem 4rem 1rem 1.5rem;
                    background: $white;
                    position: absolute;
                    left: 0;
                    text-align: left;
                    top: 4rem;
                    box-shadow: 0.5px 0.5px 5px 0px rgba(51,51,51,1);
                }

                .search-bar {
                    .form-control {
                        font-size: 1.6rem;
                        width: 32rem;
                    }

                    #suggestion-box {
                        text-align: left;
                        background: $white;
                        color: $grey60;
                        padding: 1rem 2rem 2rem;
                        font-size: 1.6rem;
                        font-family: $tertiaryFont;
                        position: absolute;
                        left: 0;
                        width: 100%;
                        top: 43px;
                        z-index: 42;
                        box-shadow: 0 0.5px 5px 0px #333333;


                        h3 {
                            color: $grey60;
                            font-size: 1.6rem;
                            font-family: $tertiaryFont;
                            border-bottom: 1px solid $grey80;
                        }

                        .box {
                            margin-top: 1.5rem;

                            &:nth-child(1) {
                                margin-top: 0;
                            }
                        }

                        ul {
                            li {
                                display: inline-block;
                                margin-bottom: 2px;
                                width: 100%;

                                a {
                                    color: $grey60;
                                    font-size: 1.6rem;
                                    font-family: $tertiaryFont;
                                    line-height: 1;
                                    margin-bottom: 2px;
                                    display: inline-block;
                                    border-bottom: 2px solid transparent;

                                    &:hover {
                                        border-bottom: 2px solid $accent;
                                    }

                                    span {
                                        font-size: 1.2rem;
                                        display: block;
                                    }
                                }
                            }
                        }

                        .more-suggestions {
                            font-style: italic;
                            font-size: 1.6rem;
                            font-family: $tertiaryFont;
                            color: $grey60;

                            &:hover {
                                color: $accent;
                            }
                        }
                    }
                }
            }
        }

        @media (max-width:767px) {
            display: none;
        }
    }

    .user-name {
        white-space: nowrap;
        margin-bottom: 1rem;
        display: block;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .account-link {
        display: block;
        margin-bottom: .5rem;
        color: $grey60;

        &:last-child {
            margin: 0;
        }
    }
    // Mobile menu
    .mobile-nav {
        display: none;
        padding: 1rem 0;
        position: relative;

        @media (max-width: 767px) {
            display: block;

            > .container {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }

        .menu-toggler {
            padding: .5rem;
            outline: none;

            .icon-menu {
                &:before {
                    content: "\f0c9";
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    font-size: 2.5rem;
                    color: $accent;
                }
            }
        }

        .nav-brand {
            width: 15rem;
            transform: translateX(2.5rem);
        }

        .account-dropdown-mobile {
            display: none;
            position: absolute;
            padding: 2rem;
            padding: 1.4rem 2rem 2.2rem;
            left: 0;
            right: 0;
            background: $white;
            border-bottom: 1px solid $grey60;
            flex-direction: column;
            align-items: flex-start;
            z-index: 99;

            .user-name {
                font-size: 2rem;
            }

            .close-button-account {
                position: absolute;
                right: 16px;
                margin: 0;
                z-index: 2;
                top: 0;
            }
        }

        .mobile-icons {
            display: flex;

            li {
                margin-left: 1.5rem;

                &:first-child {
                    margin: 0;
                }
            }

            .icon {
                font-size: 2.5rem;
            }

            .icon-search {
                &:before {
                    content: "\f002";
                }
            }
        }

        .register-dropdown {
            right: 0;
            left: auto;
            transform: translateX(0);
            width: 100%;
            display: block;
            top: 3.7rem;
            box-shadow: none;
        }

        .secondary-nav {
            .input-search-wrapper {
                z-index: 999;
                width: 100%;
            }

            .search-bar {
                position: relative;

                .input-search-wrapper {
                    border: 1px solid $grey60;

                    input {
                        font-size: 1.6rem;

                        &:focus {
                            background: $grey90;
                        }
                    }
                }

                #suggestion-box {
                    text-align: left;
                    background: $white;
                    color: $grey60;
                    padding: 1rem 2rem 2rem;
                    font-size: 1.6rem;
                    font-family: $tertiaryFont;
                    position: absolute;
                    left: 0;
                    width: 100%;
                    top: 55px;
                    z-index: 999;


                    h3 {
                        color: $grey60;
                        font-size: 1.6rem;
                        font-family: $tertiaryFont;
                        border-bottom: 1px solid $grey80;
                    }

                    .box {
                        margin-top: 1.5rem;

                        &:nth-child(1) {
                            margin-top: 0;
                        }
                    }

                    ul {
                        li {
                            display: inline-block;
                            margin-bottom: 2px;
                            width: 100%;
                            padding-left: 2rem;

                            a {
                                color: $grey60;
                                font-size: 1.6rem;
                                font-family: $tertiaryFont;
                                line-height: 2.2rem;
                                display: inline-block;
                                border-bottom: 2px solid transparent;

                                &:hover {
                                    border-bottom: 2px solid $accent;
                                }

                                span {
                                    font-size: 1.2rem;
                                    display: block;
                                }
                            }
                        }
                    }

                    .more-suggestions {
                        font-style: italic;
                        font-size: 1.6rem;
                        font-family: $tertiaryFont;
                        color: $grey60;

                        &:hover {
                            color: $accent;
                        }
                    }
                }
            }
        }
    }

    .register-dropdown {
        width: 22.6rem;
        position: absolute;
        left: 0;
        background: $white;
        text-align: left;
        padding: 1.8rem;
        z-index: 1;
        top: 4rem;
        box-shadow: 0.5px 0.5px 5px 0px rgba(51,51,51,1);

        @media (max-width: 1600px) {
            right: 0;
            left: auto;
        }

        .primary-button {
            width: 100%;
        }

        a.account-link {
            display: block;
            border: none;

            &:hover {
                border: none;
            }
        }

        a {
            display: inline-block;
            border-bottom: 2px solid transparent;

            &.primary-button.outline {
                width: 100%;
                border: 2px solid $accent;
                //background-color: transparent;

                &:hover {
                    border: 2px solid $accent;
                    //background-color: #FFD0D9;
                }
            }

            &:hover {
                color: $accent;
                border-bottom-color: $accent;
            }
        }

        .form-group {
            margin-bottom: 0.25rem;

            &.text-right {
                margin-bottom: 0.5rem;
            }
        }

        .form-control {
            width: 100%;
            border: 1px solid $black;
            background-color: $white;
            font-size: 1.6rem;
            height: 4.6rem;
        }
    }
}

//*************************************
//*************************************
.primary-nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 1rem 3.2rem;

    .nav-item {
        margin-left: $headerNaviItemLeftMargin;
        margin-bottom: $headerNaviItemBottomMargin;

        @media (max-width: 1240px) {
            margin-left: 2.4rem;
        }

        @media (max-width: 992px) {
            margin-left: 1.1rem;
        }

        &:first-child {
            margin-right: 0;
        }

        .category-link {
            font-family: $primaryFont;
            font-size: 1.6rem;
        }

        .submenu {
            display: none;
        }
    }
}
//*************************************
//*************************************
.secondary-nav {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-bottom: 2.2rem;
    position: relative;

    .separator {
        margin: 0;
        display: inline-block;
    }

    .primary-button {
        //width: 100%;
        //border: 0;
        font-size: 1.5rem;
        padding: 1.25rem 2rem;
        text-align: center;
        max-width: 100% !important;
        //width: 100% !important;
    }

    .form-group {
        margin: 0;
    }

    .form-control {
        display: inline-block;
        font-size: 1.6rem;
        width: 28rem;

        &#signupEmail {
            font-size: 1.3rem;
        }
    }

    .menu-link {
        color: black;
        display: flex;
        position: relative;
        align-items: center;
        margin-left: 1rem;
        white-space: nowrap;
        font-family: $primaryFont !important;
        z-index: 10;
        /*@media (max-width: 904px) {
                    margin-top: 2rem;
                }*/
        &:active {
            color: $accent;
        }

        &:hover {
            color: $navMenuFocusColor;
        }


        &:hover::after,
        &:active::after,
        &:focus::after {
            display: block;
        }

        &:last-child {
            margin-right: 0
        }

        &.icon {
            &:before {
                margin-right: .5rem;
                font-size: 2.6rem;

                @media (max-width: 1240px) {
                    font-size: 2rem;
                }
            }
        }

        &:after {
            display: none;
            content: " ";
            width: calc(100% - 2.4rem);
            height: .3rem;
            background: $accent;
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }

    .dropdown-wrapper {
        display: inline-block;
        position: relative;
        z-index: 30;
    }

    .account-dropdown {
        padding: 3rem 4rem 1rem 1.5rem;
        background: $white;
        position: absolute;
        left: 0;
        text-align: left;
        top: 4rem;
        -webkit-box-shadow: 0.5px 0.5px 5px 0px rgba(51,51,51,1);
        -moz-box-shadow: 0.5px 0.5px 5px 0px rgba(51,51,51,1);
        box-shadow: 0.5px 0.5px 5px 0px rgba(51,51,51,1);
    }

    .search-bar {
        position: relative;

        #suggestion-box {
            text-align: left;
            background: $white;
            color: $grey60;
            padding: 1rem 2rem 2rem;
            font-size: 1.6rem;
            font-family: $tertiaryFont;
            position: absolute;
            left: 0;
            width: 100%;
            top: 43px;
            z-index: 42;
            box-shadow: 0 0.5px 5px 0px #333333;


            h3 {
                color: $grey60;
                font-size: 1.6rem;
                font-family: $tertiaryFont;
                border-bottom: 1px solid $grey80;
            }

            .box {
                margin-top: 1.5rem;

                &:nth-child(1) {
                    margin-top: 0;
                }
            }

            ul {
                li {
                    display: inline-block;
                    margin-bottom: 2px;
                    width: 100%;

                    a {
                        color: $grey60;
                        font-size: 1.6rem;
                        font-family: $tertiaryFont;
                        line-height: 1;
                        margin-bottom: 2px;
                        display: inline-block;
                        border-bottom: 2px solid transparent;

                        &:hover {
                            border-bottom: 2px solid $accent;
                        }

                        span {
                            font-size: 1.2rem;
                            display: block;
                        }
                    }
                }
            }

            .more-suggestions {
                font-style: italic;
                font-size: 1.6rem;
                font-family: $tertiaryFont;
                color: $grey60;

                &:hover {
                    color: $accent;
                }
            }
        }
    }
}
