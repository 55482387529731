.media-slider-component {
    padding: 4rem 0;
    .section-title {
        margin-bottom: 6rem;
        font-size: 2rem;
    }
    .slider-wrapper {
        padding: 0 2rem;
        .slide-item {
            display: flex;
            .img-wrapper {
                margin-right: 3rem;
                img {
                    height: 12rem;
                    max-width: 5rem;
                }
            }
            .slide-content {
                padding-right: 3rem;
                @media (max-width: 767px) {
                    padding: 0;
                }
                .subtitle {
                    font-family: $primaryFont;
                    font-size: 1.6rem;
                    color: $black;
                    margin-bottom: 1.5rem;
                    line-height: 2rem;
                    @media (max-width: 767px) {
                        font-size: 1.3rem;
                        line-height: 1.8rem;
                        margin: 0;
                    }
                }
                p {
                    font-size: 1.4rem;
                    line-height: 2rem;
                    @media (max-width: 767px) {
                        font-size: 1.1rem;
                        line-height: 1.6rem;
                    }
                }
            }
        }
        @media (max-width: 1460px) {
            margin: 0 3rem;
        }
        @media (max-width: 767px) {
            max-width: 40rem;
            margin: 0 auto;
        }
        @media (max-width: 453px) {
            max-width: 30rem;
        }
    }
    .slick-prev,
    .slick-next {
        &:before {
            color: $black;
        }
    }
}