﻿/**************************************************************************/
/**************************************************************************/
.confirm-component {
    a {
        &.wide {
            width: 100%;
        }
    }

    p {
        margin-bottom: 0;
        font-size: 1.3rem;
    }
}


/**************************************************************************/
/**************************************************************************/
.confirm-cart-component {
    padding-left: 0;

    h2 {
        &.title {
            font-size: 2.4rem;
            text-transform: none;
            margin-bottom: 1rem;
        }
    }

    h3 {
        padding-top: 0;
        padding-bottom: 0;
    }

    h4 {
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 10px;
        color: $accent;

        &.title {
            color: grey;
        }
    }

    button {
        &.wide {
            width: 100%;
        }
    }

    p {
        margin-bottom: 0;
        font-size: 1.3rem;
    }

    .disabled-button {
        background-color: grey;
        border-color: grey;

        &:hover {
            background-color: grey;
            border-color: grey;
        }
    }

    a {
        &.wide {
            width: 100%;
        }

        &.transparent-button {
            background-color: white;
            border-left: none;
            border-right: none;
            border-radius: 0px;
            color: $accent;

            &:hover {
                color: white;
                background-color: $accent;
            }
        }
    }

    .row {
        width: 100%;
    }

    .checkout-description-row {
        border-top: 1px solid $grey70;
        border-bottom: 1px solid $grey70;

        .title {
            font-family: $primaryFont;
            color: $black;
        }
    }

    .bordered {
        padding-top: 1rem;
        //border: 1px solid black;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
        margin-bottom: 15px;
        padding-bottom: 15px;
        padding-left: 15px;
    }

    .confirm-total {
        font-weight: 600;
        font-size: 1.6rem;
        color: black;
    }
}

/**************************************************************************/
/**************************************************************************/
#billing-component {
    width: 100%;
}

.confirm-billing-component {
    width: 100%;
    min-height: 260px;

    h2 {
        &.title {
            font-size: 2.4rem;
            text-transform: none;
            margin-bottom: 1rem;
        }
    }

    h3 {
        padding-top: 0;
        padding-bottom: 0;
    }

    h4 {
        padding-top: 1.6rem;
        padding-bottom: 0;
        color: $accent;
    }

    input {
        width: 100%;
    }

    select {
        margin-top: 0;
        margin-bottom: 0;
        width: 100%;
    }

    button.wide {
        width: 100%;
    }

    .disabled-button {
        background-color: grey;
        border-color: grey;

        &:hover {
            background-color: grey;
            border-color: grey;
        }
    }

    p {
        margin-bottom: 0;
        font-size: 1.3rem;
    }

    .billing-order {
        p {
            min-height: 20px;
        }
    }

    .bordered {
        padding-top: 1rem;
        //border: 1px solid black;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
        margin-bottom: 15px;
        padding-bottom: 15px;
    }

    .row {
        font-size: 1.5rem;
        padding-bottom: 1rem;
    }
}
/**************************************************************************/
/**************************************************************************/
#dest-component {
    width: 100%;
}

.confirm-dest-component {
    min-height: 260px;

    h2 {
        &.title {
            font-size: 2.4rem;
            text-transform: none;
            margin-bottom: 1rem;
        }
    }

    h3 {
        padding-top: 0;
        padding-bottom: 0;
    }

    h4 {
        padding-top: 0;
        padding-bottom: 0;
        color: $accent;
    }

    input[type=text] {
        width: 100%;
    }

    select {
        margin-top: 0;
        margin-bottom: 0rem;
        width: 100%;
    }

    button.wide {
        margin: 1rem 0;
        width: 100%;
    }

    .disabled-button {
        background-color: grey;
        border-color: grey;

        &:hover {
            background-color: grey;
            border-color: grey;
        }
    }

    .bordered {
        padding-top: 1rem;
        //border: 1px solid black;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
        margin-bottom: 15px;
        padding-bottom: 15px;
    }

    .p {
        color: #333333;
    }

    .row {
        width: 100%
    }

    small {
        padding-left: 1.6rem;
    }
}
/**************************************************************************/
/**************************************************************************/
#ship-method-component {
    width: 100%;
}

.confirm-ship-component {
    min-height: 260px;

    h2 {
        &.title {
            font-size: 2.4rem;
            text-transform: none;
            margin-bottom: 1rem;
        }
    }

    h3 {
        padding-top: 0;
        padding-bottom: 0;
    }

    h4 {
        padding-top: 0;
        padding-bottom: 0;
        color: $accent;
    }

    label {
        width: 100%;
        color: $main;
    }

    input[type=text] {
        width: 100%;
    }

    input[type="checkbox"] {
        width: 20px;
    }

    select {
        margin-top: 0;
        margin-bottom: 0rem;
        width: 100%;
    }

    button.wide {
        margin: 1rem 0;
        width: 100%;
    }

    .disabled-button {
        background-color: grey;
        border-color: grey;

        &:hover {
            background-color: grey;
            border-color: grey;
        }
    }

    .bordered {
        padding-top: 1rem;
        //border: 1px solid black;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
        margin-bottom: 15px;
        padding: 15px;
    }

    .p {
        color: #333333;
    }
}

/**************************************************************************/
/**************************************************************************/
#ship-instructions {
    vertical-align: text-top;
    min-height: 100px;
    width: 100%;
}

.modal-content {
    .modal-header {
        background-color: $accent;
    }

    button.close {
        color: #ffffff;
    }

    h3.modal-title {
        margin-bottom: 0;
        line-height: 1.5;
        color: #ffffff;
    }

    .shipping-grid {
        flex-wrap: wrap;
        flex-direction: row;
        display: grid;
        grid-template-columns: 30% 30% 30%;
        gap: 10px;
        padding-top: 20px;
    }

    .shipping-link-desc {
        display: inline-block;
        padding-left: 3px;
        margin-bottom: 0px;
    }

    .shipping-card {
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
        display: flex;
        flex-direction: column;
        height: 20rem;
    }

    p.shipping-title {
        font-size: 1.6rem;
    }

    p.shipping-desc {
        font-size: 1.2rem;
        margin-bottom: 0;

        &:last-child {
            margin-bottom: 1.4rem;
        }

        &:last-of-type {
            margin-bottom: 1.4rem;
        }
    }

    .shipping-select {
        margin-top: auto;
        display: flex;
        flex-direction: row-reverse;
    }


    .shipping-search-container {
        width: 100%;
    }

    .shipping-search-subheader {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
