.hero-slider-component {
    position: relative;
    z-index: 1;

    .slick-list, .slick-slide {
        height: 280px;

        @media (min-width: 768px) {
            height: 425px;
        }

        @media (min-width: 1024px) {
            height: 525px;
        }
    }

    .slide {
        display: flex;
        justify-content: center;
        align-items: center;
        background-size: cover;
        background-position: center center;
        cursor: pointer;
        position: relative;

        .content-slide {
            position: absolute;
            width: 100%;

            &.to-bottom {
                bottom: 5px;
            }
        }

        a {
            display: block;
            width: 100%;
        }

        .link-slide {
            display: block;
            background: pink;
        }

        .content-wrapper {
            max-width: 55%;
            line-height: 1.2;

            .title {
                color: $heroSliderTitleColor;
                font-size: 2.5rem;
                line-height: 1;
                margin-bottom: 1.5rem;

                @media (min-width: 768px) {
                    font-size: 3.75rem;
                }

                @media (min-width: 1024px) {
                    font-size: 6rem;
                    margin-bottom: 2.5rem;
                }

                @media (min-width: 1440px) {
                    font-size: 8rem;
                    margin-bottom: 4rem;
                }
            }

            .subtitle {
                color: $white;
                font-size: 1.5rem;
                margin-bottom: 2rem;

                @media (min-width: 1024px) {
                    font-size: 2.5rem;
                    margin-bottom: 3rem;
                }

                @media (min-width: 1440px) {
                    margin-bottom: 4rem;
                }
            }
        }

        &.content-right {
            text-align: right;

            .content-wrapper {
                text-align: left;
                display: inline-block;
                width: 40%;
            }
        }
    }

    .primary-button {
        width: 23rem;
        padding: 2.3rem 0;
        text-align: center;
        font-size: 2rem;
        background: $accent;
        outline: none;
        font-weight: 600;
        border-radius: .4rem;
        text-transform: capitalize;
        line-height: 1;

        @media (max-width: 767px) {
            padding: 1.2rem 1.7rem;
            width: auto;
        }
    }

    p {
        &.primary-button {
            border: 2px solid $accent;
            background: $accent;
            color: $white;

            &:hover {
                background: rgba($accent, .8);
            }


            &.disabled {
                border: 2px solid $grey70;
                background: $grey70;
            }

            &.outline {
                background: transparent;
                border: 2px solid $accent;
                color: $accent;

                &:hover {
                    background: rgba($accent, .5);
                }

                &.disabled {
                    opacity: 0.4;
                }
            }

            &.white-outline {
                background: transparent;
                border: 2px solid $white;
                color: $white;

                &:hover {
                    background: rgba($white, .5);
                }

                &.disabled {
                    opacity: 0.4;
                }
            }
        }

        &.button-align-right {
            float: right;
        }
    }

    .secondary-button {
        background: transparent;

        @media (max-width: 767px) {
            padding: 1.2rem 1.7rem;
            width: auto;
        }
    }

    .slick-prev {
        @media (max-width: 767px) {
            display: none;
        }
    }

    .slick-prev,
    .slick-next {
        width: 5rem;
        height: 5rem;
        z-index: 1000;
        background-color: transparent;

        &::before {
            font-size: 4rem;
            color: #606060;
            opacity: 80%;
        }

        @media (max-width: 1600px) {
            display: none;
        }
    }

    .slick-next {
        right: 4rem;
    }

    .slick-prev {
        left: 4rem;
    }

    .slick-slider {
        margin-bottom: 0;
    }

    .slick-dots {
        bottom: 1rem;
    }

    .slick-dots li button::before {
        font-size: 3rem;
    }

    video {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        max-width: 120%;
    }
}
