/// <reference path="variables.scss" />

.footer-component {
    background: $footerBackgroundColor;
    border-top: 2px solid $footerBorderColor;
    color: $footerColor;
    font-size: 1.2rem;
    padding: 3rem 0 0;
    z-index: 1;

    & > hr {
        @media (max-width: 767px) {
            display: none;
        }
    }

    .desktop-footer {
        @media (max-width: 767px) {
            display: none;
        }
    }

    a {
        color: inherit;
        font-size: 1.2rem;
    }

    .footer-navigation {
        display: flex;
        margin-bottom: 2rem;

        .title {
            text-transform: none;
            font-size: 1.6rem;
            color: $accent;
        }
    }

    .contact-wrapper {
        padding: 0;
        margin: 0;
        width: 35%;
        display: inline-block;

        .address-wrapper {
            margin-top: 2rem;
        }
    }

    .menu-list {
        width: 65%;
        display: flex;

        .menu-column {
            width: 33.33%;
            padding: 0 1rem;
        }
    }

    .social-content {
        max-width: 40rem;
        margin: 0 auto;

        .title {
            text-transform: none;
            font-size: 1.5rem;
            line-height: 1;
            color: $accent;
        }

        .subtitle {
            font-size: 1.3rem;
        }

        .form-group {
            margin-bottom: .5rem;
            width:unset;

            .form-control {
                font-size: 1.6rem;
            }

            .primary-button {
                margin-left: 3rem;
                padding: 1rem 2.4rem;
            }
        }

        .social-list-wrapper {
            display: flex;
            font-weight: 700;
            align-items: center;

            span {
                margin-right: 1rem;
            }
        }

        .social-media-component {
            margin: 0;

            @media (max-width: 767px) {
                display: flex;
                margin-bottom: 2rem !important;
            }

            .media-link {
                @media (max-width: 767px) {
                    max-width: 3rem;
                    margin: 0 0.1rem;
                }
            }
        }

        @media (max-width: 1024px) {
            #email-form {
                .form-group {
                    input {
                        max-width: 17rem;
                    }
                }
            }
        }
    }

    .secondary-footer {
        padding-bottom: 1rem;
        padding-top: 1rem;
        background-color: $footerRibbonBackgroundColor;

        @media (max-width: 767px) {
            display: none;
        }

        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    .mobile-footer {
        display: none;

        .social-content {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            border-bottom: 1px solid $grey60;
        }

        .subtitle {
            display: block;
            color: white;
            font-weight: 700;
            font-size: 1.6rem;
            margin-bottom: 1.4rem;
            font-family: $primaryFont;
        }

        .social-media-component {
            margin-bottom: 2.4rem;
        }

        .primary-button {
            margin-bottom: 2.8rem;
            padding: 1.1rem 2.4rem;
        }

        .menu-list {
            width: 100%;
            max-width: 35rem;
            margin: 2rem auto;

            .menu-column {
                width: 50%;
                margin-left: 3rem;

                a {
                    line-height: 1.5rem;
                    display: inline-block;
                }

                li {
                    margin-bottom: 2px;
                }
            }
        }

        .contact-wrapper {
            width: 100%;
            text-align: center;
            margin: 0 auto;
            margin-bottom: 1rem;

            .title {
                color: $accent;
                font-size: 1.6rem;
                text-transform: none;
            }
        }

        @media (max-width:767px) {
            display: block;
        }
    }

    .secondary-mobile-footer {
        text-align: center;
        padding-bottom: 1.6rem;
    }

    hr {
        border-top: 1px solid $grey60;
    }

    .quote {
        font-size: 1.6rem;
        color: $white;
        border: 1px $footerBorderColor;
    }

    .form-inline {
        .form-control {
            padding-right: 3rem;
        }
    }

    .signed-up-wrapper {
        display: block;

        .link-item {
            display: block;
        }
    }
}

img.icon {
    width: 1.5rem;
    height: 1.5rem;
}

//*************************************
//*************************************
.post-footer {
    display: flex;
    padding: 2px;
    justify-content: space-between;
    height: 2rem;
    background: $footerRibbonBackgroundColor;

    span {
        color: $white;
    }
}
