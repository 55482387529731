.variant-top-details-component {
    background: $white;
    padding: 7.6rem 0 5.2rem;

    @media (max-width: 767px) {
        padding: 2rem 0 2.2rem;
    }

    .content-row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 767px) {
            display: block;
        }

        .image-col {
            max-width: 40%;

            @media (max-width: 767px) {
                width: 90%;
                max-width: 100%;
                margin: 0 auto 2.6rem;
            }

            .main-image-wrapper {
                max-width: 65rem;
                margin-bottom: 1.8rem;

                img {
                    width: 100%;
                }
            }

            .dimension-image-wrapper {
                max-width: 23rem;
                margin-bottom: 2.1rem;
                display: inline-block;

                @media (max-width: 767px) {
                    width: 11.2rem;
                    margin-bottom: 2.8rem;
                }

                img {
                    width: 100%;
                }
            }

            .info {
                font-size: 1.1rem;
            }
        }

        .dimension-col {
            width: 32.8rem;

            @media (max-width: 1300px) {
                width: 23.4rem;
            }

            @media (max-width: 767px) {
                margin: 0 auto 4rem;
            }

            .dimension-list {
                li {
                    line-height: 3.6rem;
                    display: flex;
                    justify-content: space-between;

                    @media (max-width: 1300px) {
                        line-height: 2.3rem;
                    }

                    span {
                        @media (max-width: 1300px) {
                            font-size: 1.4rem;
                        }

                        &.dimension-text {
                            font-weight: 700;
                            text-align: left;
                        }

                        &.dimension-value {
                            text-align: right;
                        }
                    }
                }
            }

            .cta-link {
                font-family: $primaryFont;
                font-size: 1.8rem;
                color: $grey60;

                @media (max-width: 1300px) {
                    font-size: 1.1rem;
                }
            }
        }

        .tool-info-col {
            width: 40.4rem;
            background-color: $grey90;
            padding: 4.2rem 2rem;

            @media (max-width: 1300px) {
                width: 26.4rem;
                padding: 2.7rem 1.5rem;
            }

            @media (max-width: 767px) {
                margin: 0 auto;
                width: 90%;
            }

            .info-list {
                margin-bottom: 3.5rem;
                color: #222222;

                li {
                    line-height: 3.6rem;
                    display: flex;
                    justify-content: space-between;

                    @media (max-width: 1300px) {
                        line-height: 2.3rem;
                    }

                    span {
                        @media (max-width: 1300px) {
                            font-size: 1.4rem;
                        }
                    }

                    .info-text {
                        font-weight: 700;
                    }
                }
            }

            .cta-wrapper {
                flex-direction: column;
                gap: 1.5rem;
            }

            .rating-component {
                padding-left: 7.5rem;
                align-items: center;
                justify-content: center;


                @media (max-width: 767px) {
                    padding-left: 1.5rem;
                }

                &.col-md-7 {
                    display: inline;

                    @media (max-width: 767px) {
                        display: block;
                    }
                }

                .fa-icon {
                    font-size: 1.9rem;

                    &:before {
                        font-weight: 400;
                    }
                }

                .back-stars {
                    position: relative;
                }

                .front-stars {
                    color: $accent;
                    overflow: hidden;
                    position: absolute;
                    top: 0;

                    .fa-icon {
                        &:before {
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }

    .main-image-wrapper,
    .dimension-image-wrapper {
        .modal {
            background: rgba(54,54,54,0.7);

            .modal-dialog {
                max-width: 80%;
                max-height: 80%;
                margin: 0 auto;

                img {
                    width: auto;
                }

                .modal-content {
                    width: auto;
                    margin: 0 auto;
                }
            }
        }
    }
}

.tech-dimension-component {
    background: $white;

    .content {
        padding: 6.6rem 0 8.5rem;

        @media (max-width: 991px) {
            padding: 2rem 0 2.4rem;
        }

        .dimension-list {
            padding: 0 2rem;

            @media (max-width: 991px) {
                max-width: 26.4rem;
                margin: 0 auto 3.6rem;
            }

            @media (max-width: 767px) {
                /*max-width: 26.4rem;*/
            }

            li {
                line-height: 3.6rem;
                display: flex;
                justify-content: space-between;

                @media (max-width: 1200px) {
                    line-height: 2.3rem;
                }


                span {
                    @media (max-width: 120px) {
                        font-size: 1.6rem;
                    }

                    @media (max-width: 767px) {
                        font-size: 1.4rem;
                    }

                    &.dimension-text {
                        font-weight: 700;
                        font-size: 1.7rem;
                        text-align: left;
                    }

                    &.dimension-value {
                        font-size: 1.5rem;
                        text-align: right;
                    }
                }
            }
        }
    }
}

.tech-info-component {
    background: $white;

    .content {
        padding: 4.3rem 0;
        display: table;
        width: 100%;

        .icons-section-component {
            display: table-cell;

            &.full-width {
                width: 100%;
                display: inline-block;

                .icons-content {
                    display: inline-flex;
                }
            }

            @media (max-width: 720px) {
                display: block !important;
                width: 100%;
            }
        }

        .subtitle {
            font-size: 2rem;
            font-family: $primaryFont;
            margin-bottom: 2rem
        }

        .option-section {
            text-align: center;
            width: 50%;
            position: relative;
            display: table-cell;

            &.full-width {
                width: 100%;
                display: inline-block;

                .option-content {
                    display: inline-block;
                    position: relative;

                    @media (max-width: 991px) {
                        display: flex;
                    }
                }
            }

            @media (max-width: 720px) {
                display: block;
                width: 100%;
            }

            .option-content {
                position: absolute;
                width: 100%;
                top: 50%;
                transform: translateY(-50%);
                //padding: 0 3rem;
                margin-top: 3rem;
                display: flex;
                flex-direction: column;

                @media (max-width: 991px) {
                    position: static;
                    justify-content: center;
                    flex-direction: row;
                    margin: 0;
                    padding: 0;
                    transform: none;
                }

                .progress-bar {
                    display: block;
                    transition: none;
                    height: 1rem;
                    width: 100%;
                    background: $grey70;
                    margin-bottom: 2rem;

                    @media (max-width: 991px) {
                        width: 1rem;
                        height: auto;
                        margin-bottom: 0;
                        order: 2;
                    }
                }

                .option-list {
                    display: flex;
                    flex-wrap: wrap;
                    text-align: left;
                    justify-content: flex-start;
                    padding-left: 12%;

                    @media (max-width: 720px) {
                        //flex-direction: column;
                        padding-left: 18%;
                        order: 1;
                        //margin-right: 2rem;
                    }

                    .material-option {
                        position: relative;
                        font-size: 1.4rem;
                        max-width: 12rem;
                        font-weight: 700;
                        line-height: 1;
                        margin-right: 34px;
                        width: 100%;
                        margin-top: 20px;

                        @media (max-width: 991px) {
                            margin-bottom: 2rem;

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }

                        &.active {
                            color: $accent;

                            &:before {
                                display: block;
                                //content: '';
                                background: $accent;
                                width: 100%;
                                height: 1rem;
                                position: absolute;
                                top: -3rem;

                                @media (max-width: 991px) {
                                    width: 1rem;
                                    height: 100%;
                                    position: absolute;
                                    top: auto;
                                    right: -3rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.tech-resources-component {
    background: $white;

    .new-list-dropdown {
        box-shadow: 0px 3px 6px rgba($black, .26);
        padding: 1rem 2.6rem 3rem;
        background: $white;
        position: absolute;
        z-index: 10;
        right: 2.9rem;
        text-align: left;

        h4 {
            color: $accent;
        }

        .close-button {
            float: right;
            margin-right: 0px;
            margin-top: -5px;
            margin-bottom: 5px;
        }
    }

    .content {
        .row {
            padding: 5rem 0;

            @media (max-width: 767px) {
                padding: 2rem 0;
            }

            .subtitle {
                font-family: $primaryFont;
                font-size: 2rem;
                line-height: 2.3rem;
                margin-bottom: 3rem;

                @media (max-width: 767px) {
                    /*font-size: 1.4rem;*/
                    margin-bottom: 1.5rem;
                }
            }

            .link-list {
                @media (max-width: 767px) {
                    max-width: 40rem;
                    margin: 0 auto;
                }
            }

            .link-item {
                display: flex;
                align-items: center;
                margin-bottom: 3.6rem;

                @media (max-width: 767px) {
                    margin-left: 1rem;
                    margin-bottom: 2.5rem;
                }

                .icon-wrapper {
                    max-width: 4rem;
                    min-width: 3rem;
                    overflow: hidden;
                    margin-right: 1.8rem;

                    .icon {
                        font-size: 3rem;
                        color: $accent;
                    }
                }

                .link {
                    font-size: 2.2rem;
                    font-family: $secondaryFont;
                    font-weight: bold;
                    color: $techResourcesLinkColor;
                    line-height: 1;
                    vertical-align: middle;
                    cursor: pointer;

                    @media (max-width: 1024px) {
                        font-size: 1.8rem;
                    }
                }
            }
        }
    }
}
