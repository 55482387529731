.register-card-component,
.register-card-modal {
    margin: 10rem 0;

    h5 {
        color: $white;
    }

    h1 {
        color: $white;
    }


    @media (max-width: 767px) {
        margin: 0 auto 10rem;
    }

    .content-wrapper {
        padding: 3rem 13rem;

        @media (max-width: 1200px) {
            padding: 3rem 5rem;
        }

        .form-group {
            margin-bottom: 1.7rem;
        }

        .primary-button {
            margin-top: 2rem;
        }
    }

    .media-wrapper {
        padding: 9rem 0;
        text-align: center;

        .icon-wrapper {
            margin-bottom: 5.5rem;
            position: relative;

            .icon {
                font-size: 10rem;
            }
        }

        .email-icon {
            &::after {
                color: $accent;
                font-size: 2.6rem;
                content: "\f00c";
                font-family: "Font Awesome 5 Free";
                font-weight: 700;
                border: 3px solid $accent;
                border-radius: 50%;
                padding: 1rem;
                background: $white;
                position: absolute;
                bottom: -1.2rem;
                transform: translateX(-2rem);
                height: 5rem;
            }
        }

        .description {
            font-size: 1.5rem;
            color: $grey60;

            a {
                color: $accent;
            }
        }
    }

    .EmailSpam {
        margin-top: 15px;

        p {
            line-height: 2rem;
        }
    }
}

.register-card-modal {
    .card {
        border-top-left-radius: 1.3rem;
        border-top-right-radius: 1.3rem;
    }

    .modal-content {
        border-radius: 1.3rem;
        border: none;

        .contact {
            color: $white;
        }

        .content-wrapper {
            padding: 0 1.3rem;
        }
    }

    .title-wrapper {
        position: relative;
        display: block;
        padding: 1.6rem 6.3rem;
        background: $accent;
        color: $white;
        border-radius: 6px;

        .title {
            margin-bottom: 1rem;
            color: $white !important;
        }

        span {
            display: block;
        }

        .close {
            position: absolute;
            top: 50%;
            right: 2rem;
            transform: translateY(-50%);
            margin: 0;

            span {
                font-size: 2rem;
                color: $white;
            }
        }
    }
}

.modal-title {
    padding: 0;
    margin-bottom: 0;
    line-height: 1.5;
    color: #ffffff;
}

.button-container {
    margin: auto;
}