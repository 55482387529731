.error-page .content-wrapper {
    padding: 10rem 0 5.4rem;
    text-align: center
}

.error-page .content-wrapper .title {
    color: $accent;
    font-family: "Titillium Web", sans-serif;
    font-size: 30rem;
    font-weight: 400;
    line-height: 1;
    margin: 0;
}

@media (max-width: 991px) {
    .error-page .content-wrapper .title {
        font-size: 20rem;
    }
}

@media (max-width: 767px) {
    .error-page .content-wrapper .title {
        font-size: 15rem
    }
}

.error-page .content-wrapper .title-small {
    color: $accent;
    font-family: "Titillium Web", sans-serif;
    font-size: 6rem;
    line-height: 1;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 3rem
}

@media (max-width: 991px) {
    .error-page .content-wrapper .title-small {
        font-size: 4rem
    }
}

@media (max-width: 767px) {
    .error-page .content-wrapper .title-small {
        font-size: 3rem
    }
}

.error-page .content-wrapper .subtitle {
    font-size: 2.5rem;
    color: #707070;
    font-weight: 400;
    font-family: "Arimo", sans-serif;
    margin-bottom: 1.5rem
}

@media (max-width: 991px) {
    .error-page .content-wrapper .subtitle {
        font-size: 1.8rem
    }
}

@media (max-width: 767px) {
    .error-page .content-wrapper .large {
        font-size: 1.5rem
    }
}

.error-page .content-wrapper .link-item {
    font-family: "Arimo", sans-serif;
    font-size: 1.8rem;
    color: $accent;
}

.error-page .description-wrapper {
    text-align: center;
    background: #fff;
    padding: 6.6rem 0 20rem
}

.error-page .description-wrapper .description {
    line-height: 4.1rem;
    font-family: "Roboto", sans-serif;
    font-size: 2.9rem;
    color: #707070;
    margin-bottom: 2.8rem
}

@media (max-width: 991px) {
    .error-page .description-wrapper .description {
        font-size: 1.5rem
    }
}

@media (max-width: 767px) {
    .error-page .description-wrapper .description {
        font-size: 1.3rem
    }
}

.error-page .description-wrapper .form-inline {
    display: inline-block;
    margin-bottom: 7.7rem;
    text-align: center
}

.error-page .description-wrapper .form-inline .form-control {
    width: 30rem
}

.error-page .description-wrapper .form-inline .separator {
    margin: 0 1rem
}

@media (max-width: 991px) {
    .error-page .description-wrapper .form-inline .separator {
        display: block;
        margin: 1rem;
        font-size: 1.4rem
    }
}

@media (max-width: 991px) {
    .error-page .description-wrapper .form-inline .primary-button {
        display: block;
        margin: 0 auto
    }
}

.error-page .description-wrapper .return-link {
    display: block;
    font-weight: 700;
    font-family: "Arimo", sans-serif;
    text-decoration: underline;
    font-size: 1.8rem;
    color: $accent;
}

.error-page {
    .content-wrapper {
        background: $grey90;
        padding: 10rem 4rem 5.4rem;
        text-align: center;

        .title {
            color: $accent !important;
            font-family: $tertiaryFont;
            font-size: 16rem;
            font-weight: 400;
            line-height: 1;

            @media (max-width: 991px) {
                font-size: 20rem;
            }

            @media (max-width: 767px) {
                font-size: 15rem;
            }
        }

        .title-small {
            color: $accent !important;
            font-family: $tertiaryFont;
            font-size: 6rem;
            line-height: 1;
            text-transform: uppercase;
            margin-bottom: 2rem;

            @media (max-width: 991px) {
                font-size: 4rem;
            }

            @media (max-width: 767px) {
                font-size: 3rem;
            }
        }

        .subtitle {
            font-size: 2.5rem;
            color: $grey60;
            font-family: $primaryFont;
            margin-bottom: 1.5rem;

            @media (max-width: 991px) {
                font-size: 1.8rem;
            }
        }

        .large {
            margin: 0 auto;
            max-width: 96.6rem;

            @media (max-width: 767px) {
                font-size: 1.5rem;
            }
        }

        .link-item {
            font-family: $primaryFont;
            font-size: 1.8rem;
            color: $accent;
        }
    }

    .description-wrapper {
        text-align: center;
        background: $white;
        padding: 6.6rem 4rem 20rem;

        .description {
            max-width: 60.2rem;
            margin: 0 auto;
            line-height: 4.1rem;
            font-family: $secondaryFont;
            font-size: 2.9rem;
            color: $grey60;
            margin-bottom: 2.8rem;

            @media (max-width: 991px) {
                font-size: 1.5rem;
                line-height: 2;
            }

            @media (max-width: 767px) {
                font-size: 1.3rem;
            }
        }

        .form-inline {
            margin-bottom: 7.7rem;
            text-align: center;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: 2.2rem;
            position: relative;

            .form-control {
                width: 30rem;
                font-size: 1.4rem;
            }
        }

        .separator {
            margin: 0 1rem;

            @media (max-width: 991px) {
                display: block;
                margin: 1rem;
                font-size: 1.4rem;
            }
        }

        .primary-button {
            padding: 1rem 1.7rem;

            @media (max-width: 991px) {
                display: block;
                margin: 0 auto;
            }
        }
    }

    .return-link {
        margin-top: 7rem;
        display: block;
        font-weight: 700;
        font-family: $primaryFont;
        text-decoration: underline;
        font-size: 1.8rem;
        color: $accent;
    }
}

    .search-bar {
        position: relative;

        input{
            font-size:1.4rem;
        }

        #suggestion-box {
            text-align: left;
            background: $white;
            color: $grey60;
            padding: 1rem 2rem 2rem;
            font-size: 1.6rem;
            font-family: $tertiaryFont;
            position: absolute;
            left: 0;
            width: 100%;
            top: 43px;
            z-index: 42;
            box-shadow: 0 0.5px 5px 0px #333333;


            h3 {
                color: $grey60;
                font-size: 1.6rem;
                font-family: $tertiaryFont;
                border-bottom: 1px solid $grey80;
            }

            .box {
                margin-top: 1.5rem;

                &:nth-child(1) {
                    margin-top: 0;
                }
            }

            ul {
                li {
                    display: inline-block;
                    margin-bottom: 2px;
                    width: 100%;

                    a {
                        color: $grey60;
                        font-size: 1.6rem;
                        font-family: $tertiaryFont;
                        line-height: 1;
                        margin-bottom: 2px;
                        display: block;
                        border-bottom: 2px solid transparent;

                        &:hover {
                            border-bottom: 2px solid $accent;
                        }

                        span {
                            font-size: 1.2rem;
                            display: block;
                        }
                    }
                }
            }

            .more-suggestions {
                font-style: italic;
                font-size: 1.6rem;
                font-family: $tertiaryFont;
                color: $grey60;

                &:hover {
                    color: $accent;
                }
            }
        }
    }

